<template>
  <div>
    <div class="home">
      <div v-for="content in data.explanation_list" v-bind:key="content.id">
        <!-- 説明部分 -->
        <div v-if="content.explanation !== null && content.explanation !== ''">
          <div class="card border-light mb-3 card-style">
            <div class="card-body">
              <p class="card-text title-conten-font">
                {{ content.explanation }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div>
        <!-- ビデオ -->
        <div
          v-if="
            programData.video.video_url !== null &&
              programData.video.video_url !== ''
          "
        >
          <div class="card video-div-style">
            <video
              :src="programData.video.video_url"
              muted
              controls
              preload="metadata"
              class="video-style"
            />
            <br />
          </div>
        </div>
        <div
          v-if="
            programData.image.image_url !== null &&
              programData.image.image_url !== ''
          "
        >
          <img :src="programData.image.image_url" class="image_div" />
          <br />
        </div>
        <!-- タイトル -->
        <div v-if="programData.title !== null && programData.title !== ''">
          <p class="h4 h4-title">{{ programData.title }}</p>
        </div>
        <!-- 説明 -->
        <div
          v-if="
            programData.explanation !== null && programData.explanation !== ''
          "
        >
          <div class="card border-light mb-3 card-style">
            <div class="card-body">
              <p class="card-text title-conten-font">
                {{ programData.explanation }}
              </p>
            </div>
          </div>
        </div>
        <div
          v-if="
            (programData.purpose !== null && programData.purpose !== '') ||
              (programData.movement !== null && programData.movement !== '') ||
              (programData.point !== null && programData.point !== '')
          "
        >
          <!-- 目的、動作、ポイントtable -->
          <div>
            <table>
              <tr>
                <th>目的</th>
                <td>{{ programData.purpose }}</td>
              </tr>
              <tr>
                <th>動作</th>
                <td>{{ programData.movement }}</td>
              </tr>
              <tr>
                <th>ポイント</th>
                <td>{{ programData.point }}</td>
              </tr>
            </table>
          </div>
        </div>
        <br />
        <br />
      </div>
      <div class="button-class">
        <div class="d-flex justify-content-around">
          <button class="button cancel" @click="doCancel()">
            キャンセル
          </button>
          <button class="button confirmation" @click="doConfirmation()">
            インポートする
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import ActivationAbout from "../commentary/ActivationAbout";
export default {
  name: "Activation",
  components: {
    // ActivationAbout,
  },
  props: {
    programData: null,
    program_title: null,
    program_index: null,
  },
  created() {
    // this.getProgramInfo();
  },
  data() {
    return {
      isModal: false,
      program_info: {},
      data: {
        detail_list: [
          {
            title: "",
            purpose: "",
            movement: "",
            point: "",
            explanation: "",
            image: "",
            video: "",
          },
        ],
        explanation_list: [
          {
            explanation: "",
            image: "",
            video: "",
          },
        ],
      },
      event: false,
    };
  },
  methods: {
    doCancel() {
      this.event = false;
      this.$emit("do-back-lib", this.event);
    },
    async doConfirmation() {
      this.event = true;

      let data = await this.glb_get_localStorage_for_program(
        this.mk_program_storage_name
      );
      data.mk_new_import_program_info = this.programData;
      this.glb_set_localStorage_for_program(this.mk_program_storage_name, data);
      this.$emit("do-back-lib", this.event);
    },
    async getProgramInfo() {
      this.program_info = await this.glb_get_mk_program_info();
    },
    async checkContent_type() {
      if (this.program_title == this.glb_program_content.improvement) {
        this.program_info.improvement.detail_list[
          this.program_index
        ] = this.programData;
      }
      if (this.program_title == this.glb_program_content.staticStretch) {
        this.program_info.staticStretch.detail_list[
          this.program_index
        ] = this.programData;
      }
      if (this.program_title == this.glb_program_content.movementTraining) {
        this.program_info.movementTraining.detail_list[
          this.program_index
        ] = this.programData;
      }
      if (this.program_title == this.glb_program_content.dynamicStretch) {
        this.program_info.dynamicStretch.detail_list[
          this.program_index
        ] = this.programData;
      }
      if (this.program_title == this.glb_program_content.circuittraining) {
        this.program_info.circuittraining.detail_list[
          this.program_index
        ] = this.programData;
      }
      if (this.program_title == this.glb_program_content.activation) {
        this.program_info.activation.detail_list[
          this.program_index
        ] = this.programData;
      }
      if (this.program_title == this.glb_program_content.groupWork) {
        this.program_info.groupWork.detail_list[
          this.program_index
        ] = this.programData;
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.heard-style {
  padding-top: 14px;
  height: 50px;
  background-color: #04545c;
  border: 0;
  margin-top: 5%;
  margin-left: 8%;
  margin-right: 8%;
}
.heard-style img {
  float: left;
  margin-left: 2%;
}
.heard-style > h2 {
  color: rgb(255, 255, 255);
  margin-right: 4%;
  font-weight: 900;
}
.close-button {
  height: 50px;
  background-color: #ffffff;
  border: 0;
  margin-left: 8%;
  margin-right: 8%;
}
.close-button-div {
  background-color: rgb(255, 255, 255);
  text-align: right;
  margin-right: 4%;
  padding-top: 12px;
}
.close-button-text {
  font-size: 16px;
  font-weight: 400;
  color: rgb(163, 163, 163);
}

.about {
  text-align: right;
  margin-top: 2%;
  margin-bottom: 3%;
}
.about > a:link,
a:visited,
a:hover,
a:active {
  text-decoration: none;
  font-size: 16px;
  font-weight: 400;
  color: rgb(163, 163, 163);
}
.title {
  padding-top: 4%;
  text-align: left;
}

.card-style {
  max-width: 100rem;
  margin: 2%;
  background-color: rgb(248, 248, 248);
  white-space: pre-wrap;
}
.title-font {
  color: #000;
  font-weight: 600;
  text-align: left;
}
.title-conten-font {
  color: #000;
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  letter-spacing: 1px;
  line-height: 20px;
}
.video-div-style {
  height: auto;
  width: auto;
  margin-top: 15%;
  margin-left: 2%;
  margin-right: 2%;
  border: 0;
  background-color: rgb(233, 233, 233);
}
.video-style {
  height: 250px;
}
.h4-title {
  margin-top: 5%;
  margin-bottom: 4%;
  margin-left: 2%;
  text-align: left;
  font-weight: 600;
  color: #000;
}
.home img {
  padding-top: 1%;
  width: 96%;
  /* height: auto; */
}
.program-font-style {
  font-weight: 600;
  color: #000;
}
.text-style {
  padding-top: 2%;
  text-align: left;
  font-size: 14px;
  font-weight: 500;
}
.font-style {
  letter-spacing: 2px;
  line-height: 25px;
  padding-top: 8px;
}
.span-style {
  margin-left: -5px;
  margin-right: -5px;
  background: linear-gradient(transparent 50%, #f5f100 0%);
}

/* table */
table {
  margin-top: 8%;
  margin-left: 2%;
  margin-right: 2%;
  width: 96%;
  border-collapse: collapse;
}

table tr {
  width: 30px;
  border-bottom: solid 1px white;
}

table tr:last-child {
  border-bottom: none;
}

table th {
  position: relative;
  text-align: left;
  width: 25%;
  height: auto;
  background-color: #e4ecec;
  color: rgb(0, 0, 0);
  text-align: center;
  padding: 10px 0;
  font-size: 14px;
  font-weight: 400;
}
table td {
  position: relative;
  text-align: left;
  height: auto;
  background-color: rgb(247, 247, 247);
  color: rgb(0, 0, 0);
  text-align: left;
  padding: 10px 10px;
  font-size: 14px;
  font-weight: 400;
  white-space: pre-wrap;
}
.popContainer {
  z-index: 2;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(43, 43, 43, 0.3);
}
.card-modal {
  overflow: auto;
  z-index: 2;
  margin-top: 0%;
  margin-left: 8%;
  margin-right: 8%;
  height: 75%;
  background-color: rgb(255, 255, 255);
}
.image_div {
  width: 98%;
  height: auto;
}
.button-class {
  width: 100%;
  height: 70px;
  background: #fafafa;
  margin-top: 200px;
  margin-right: auto;
  margin-left: auto;
  position: fixed;
  justify-content: space-between;
  bottom: 70px;
}
.button-class .button {
  margin-top: 16px;
  width: 140px;
  height: 38px;
  border-radius: 6px;
}
.button-class .confirmation {
  background: #1ea6ae;
  font-family: Work Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  letter-spacing: -0.02em;
  border: none;
  color: #ffffff;
}
.button-class .cancel {
  background: #ffffff;
  border: 1px solid #b7b7b7;
  font-family: Work Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #717171;
}
</style>
