<template>
  <div>
    <div class="heard-style">
      <a href="#">
        <img src="@/assets/home/yajirusi.svg" width="20" height="20" />
      </a>
      <h2>基本解説</h2>
    </div>
    <div class="home home-style">
      <div class="home-img">
        <img src="@/assets/commentary/tomooka_main.jpg" width="100%" height="220" />
      </div>
    </div>
    <div>
      <input id="acd-check1" class="acd-check" type="checkbox" v-model="check[0]" />
      <label class="acd-label" @click="checkEvent(0)">
        <h2 class="display-5 program-font-style">FITNESS CAMPとは</h2>
      </label>
      <div class="acd-content" v-if="check[0]">
        <Fitnesscampis />
      </div>
      <input id="acd-check2" class="acd-check" type="checkbox" v-model="check[1]" />
      <label class="acd-label" @click="checkEvent(1)">
        <h2 class="display-5 program-font-style">FITNESS CAMPの7要素</h2>
      </label>
      <div class="acd-content" v-if="check[1]">
        <FitnessCampElements />
      </div>
      <input id="acd-check3" class="acd-check" type="checkbox" v-model="check[2]" />
      <label class="acd-label" @click="checkEvent(2)">
        <h2 class="display-5 program-font-style">FITNESS CAMP Programの構成</h2>
      </label>
      <div class="acd-content" v-if="check[2]">
        <FitnessCampConstitution />
      </div>
      <input id="acd-check4" class="acd-check" type="checkbox" v-model="check[3]" />
      <label class="acd-label" @click="checkEvent(3)">
        <h2 class="display-5 program-font-style">1.呼吸/アライメント改善</h2>
      </label>
      <div class="acd-content" v-if="check[3]">
        <ImprovementAbout />
      </div>
      <input id="acd-check5" class="acd-check" type="checkbox" v-model="check[4]" />
      <label class="acd-label" @click="checkEvent(4)">
        <h2 class="display-5 program-font-style">2.スタティックストレッチ</h2>
      </label>
      <div class="acd-content" v-if="check[4]">
        <StaticStrechAbout />
      </div>
      <input id="acd-check6" class="acd-check" type="checkbox" v-model="check[5]" />
      <label class="acd-label" @click="checkEvent(5)">
        <h2 class="display-5 program-font-style">3.アクティベーション</h2>
      </label>
      <div class="acd-content" v-if="check[5]">
        <ActivationAbout />
      </div>
      <input id="acd-check7" class="acd-check" type="checkbox" v-model="check[6]" />
      <label class="acd-label" @click="checkEvent(6)">
        <h2 class="display-5 program-font-style">4.ダイナミックストレッチ</h2>
      </label>
      <div class="acd-content" v-if="check[6]">
        <DynamicMovementAbout />
      </div>
      <input id="acd-check8" class="acd-check" type="checkbox" v-model="check[7]" />
      <label class="acd-label" @click="checkEvent(7)">
        <h2 class="display-5 program-font-style">5.ムーブメントトレーニング</h2>
      </label>
      <div class="acd-content" v-if="check[7]">
        <MovementTrainingAbout />
      </div>
      <input id="acd-check9" class="acd-check" type="checkbox" v-model="check[8]" />
      <label class="acd-label" @click="checkEvent(8)">
        <h2 class="display-5 program-font-style">6.サーキットトレーニング</h2>
      </label>
      <div class="acd-content" v-if="check[8]">
        <CircuitTrainingAbout />
      </div>
      <input id="acd-check10" class="acd-check" type="checkbox" v-model="check[9]" />
      <label class="acd-label" @click="checkEvent(9)">
        <h2 class="display-5 program-font-style">7.グループワーク</h2>
      </label>
      <div class="acd-content" v-if="check[9]">
        <GroupWorkAbout />
      </div>
      <input id="acd-check11" class="acd-check" type="checkbox" v-model="check[10]" />
      <label class="acd-label" @click="checkEvent(10)">
        <h2 class="display-5 program-font-style">モニタリング</h2>
      </label>
      <div class="acd-content" v-if="check[10]">
        <MonitoringTheory />
      </div>
      <input id="acd-check12" class="acd-check" type="checkbox" v-model="check[11]" />
      <label class="acd-label" @click="checkEvent(11)">
        <h2 class="display-5 program-font-style">基本理論</h2>
      </label>
      <div class="acd-content" v-if="check[11]">
        <BasicTheory />
      </div>
    </div>
    <div class="sapace-style"></div>
  </div>
</template>

<script>
// @ is an alias to /src
import Fitnesscampis from "../../components/commentary/Fitnesscampis";
import ImprovementAbout from "../../components/commentary/ImprovementAbout";
import FitnessCampElements from "../../components/commentary/FitnessCampElements";
import FitnessCampConstitution from "../../components/commentary/FitnessCampConstitution";
import StaticStrechAbout from "../../components/commentary/StaticStrechAbout";
import ActivationAbout from "../../components/commentary/ActivationAbout";
import DynamicMovementAbout from "../../components/commentary/DynamicMovementAbout";
import MovementTrainingAbout from "../../components/commentary/MovementTrainingAbout";
import CircuitTrainingAbout from "../../components/commentary/CircuitTrainingAbout";
import GroupWorkAbout from "../../components/commentary/GroupWorkAbout";
import MonitoringTheory from "../../components/commentary/MonitoringTheory";
import BasicTheory from "../../components/commentary/BasicTheory";

export default {
  name: "Commentary",
  components: {
    Fitnesscampis,
    ImprovementAbout,
    FitnessCampElements,
    FitnessCampConstitution,
    StaticStrechAbout,
    ActivationAbout,
    DynamicMovementAbout,
    MovementTrainingAbout,
    CircuitTrainingAbout,
    GroupWorkAbout,
    MonitoringTheory,
    BasicTheory
  },
  data() {
    return {
      monitoring: "<モニタリング>",
      check: [
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false
      ]
    };
  },
  methods: {
    checkEvent(index) {
      this.check[index] = !this.check[index];
    }
  }
};
</script>
<style scoped>
.heard-style {
  position: fixed;
  z-index: 2;
  margin-top: 0%;
  padding-top: 18px;
  width: 100%;
  height: 58px;
  background-color: #04545c;
}
.heard-style img {
  float: left;
  margin-left: 2%;
}
.heard-style > h2 {
  color: rgb(255, 255, 255);
  margin-right: 4%;
  font-weight: 900;
}
.home-img img {
  margin-top: 58px;
  width: 100%;
  height: auto;
}
.acd-check {
  display: none;
  border-bottom: 1px solid rgb(177, 177, 177);
}
.acd-label {
  background: #ffffff;
  color: rgb(0, 0, 0);
  display: block;
  margin-bottom: 1px;
  padding: 16px;
  position: relative;
  font-size: 2em;
  height: auto;
  border-bottom: 1px solid rgb(177, 177, 177);
  text-align: left;
}
.acd-label:after {
  background: #ffffff;
  box-sizing: border-box;
  content: url(../../assets/home/downYblack.svg);
  display: block;
  font-family: "Font Awesome 5 Free";
  height: 50px;
  width: 50px;
  padding: 10px 20px;
  position: absolute;
  right: 0;
  top: 0px;
  height: 55px;
  /* border-bottom: 1px solid rgb(177, 177, 177); */
}
.acd-content {
  border: 1px solid #333;
  display: block;
  height: 0;
  opacity: 0;
  padding: 0 10px;
  transition: 0.5s;
  visibility: hidden;
  border: 0;
}
.acd-check:checked + .acd-label:after {
  content: url(../../assets/home/upYblack.svg);
}
.acd-check:checked + .acd-label + .acd-content {
  height: auto;
  opacity: 1;
  padding: 10px;
  visibility: visible;
  border: 0;
  background-color: #f8f8f8;
  border-bottom: 1px solid rgb(177, 177, 177);
}
.program-font-style {
  font-weight: 600;
  width: 90%;
}
.sapace-style {
  margin-top: 40%;
}
</style>
