<template>
  <div>
    <div id="header">
      <ClassHeader :page="5" />
    </div>
    <!-- クラス参加申込み 確認 Modal -->
    <div class="body-page">
      <form class="register-form" @submit.prevent="class_application()">
        <div class="input-form">
          <div class="title">
            <h4 class="input-title">お名前</h4>
            <div class="mandatory">
              <label>必須</label>
            </div>
          </div>
          <div class="d-flex justify-content-between">
            <div class="input-group input-group-lg">
              <input
                type="text"
                class="form-control input-content"
                aria-label="Sizing example input"
                aria-describedby="inputGroup-sizing-lg"
                required
                placeholder="山田"
                v-model="userInfo.last_name"
              />
            </div>
            <div class="space-style"></div>
            <div class="input-group input-group-lg">
              <input
                type="text"
                class="form-control input-content"
                aria-label="Sizing example input"
                aria-describedby="inputGroup-sizing-lg"
                required
                placeholder="一郎"
                v-model="userInfo.first_name"
              />
            </div>
          </div>
        </div>
        <div class="input-form">
          <div class="title">
            <h4 class="input-title">お名前（カタカナ）</h4>
            <div class="mandatory">
              <label>必須</label>
            </div>
          </div>
          <div class="d-flex justify-content-between">
            <div class="input-group input-group-lg">
              <input
                type="text"
                class="form-control input-content"
                aria-label="Sizing example input"
                aria-describedby="inputGroup-sizing-lg"
                required
                placeholder="ヤマダ"
                v-model="userInfo.last_name_kana"
              />
            </div>
            <div class="space-style"></div>
            <div class="input-group input-group-lg">
              <input
                type="text"
                class="form-control input-content"
                aria-label="Sizing example input"
                aria-describedby="inputGroup-sizing-lg"
                required
                placeholder="イチロウ"
                v-model="userInfo.first_name_kana"
              />
            </div>
          </div>
        </div>
        <div class="input-form">
          <div class="title">
            <h4 class="input-title">メール</h4>
            <div class="mandatory">
              <label>必須</label>
            </div>
          </div>
          <div class="input-group input-group-lg">
            <input
              type="email"
              class="form-control input-content"
              aria-label="Sizing example input"
              aria-describedby="inputGroup-sizing-lg"
              required
              placeholder="yamada@fitnesscamp.com"
              v-model="userInfo.mail"
            />
          </div>
        </div>
        <div class="input-form">
          <div class="title">
            <h4 class="input-title">電話番号</h4>
            <div class="mandatory">
              <label>必須</label>
            </div>
          </div>
          <div class="input-group input-group-lg">
            <input
              type="tel"
              class="form-control input-content"
              aria-label="Sizing example input"
              aria-describedby="inputGroup-sizing-lg"
              required
              placeholder="08012345678"
              maxlength="15"
              v-model="userInfo.phone"
            />
          </div>
        </div>
        <div class="input-form">
          <div class="title">
            <h4 class="input-title">生年月日</h4>
            <div class="mandatory">
              <label>必須</label>
            </div>
          </div>
        </div>
        <div class="datepicker-style">
          <v-date-picker :popover="popover" v-model="userInfo.birthday">
            <template v-slot="{ inputValue, inputEvents }">
              <input
                :value="inputValue"
                v-on="inputEvents"
                class="datepicker"
              />
            </template>
          </v-date-picker>
        </div>
        <div class="input-form">
          <div class="title">
            <h4 class="input-title">場所住所</h4>
            <div class="mandatory">
              <label>必須</label>
            </div>
          </div>
          <div class="date-fiex-02">
            <input
              type="text"
              class="form-control input-content input-zip"
              aria-label="Sizing example input"
              aria-describedby="inputGroup-sizing-lg"
              required
              placeholder="150-0001"
              v-model="userInfo.zip_code"
            />
            <div class="date-button get-zip-button" @click="getAddress()">
              <label>住所取得</label>
            </div>
          </div>
          <div class="br-style"></div>

          <div class="input-group mb-3">
            <select
              class="custom-select select-form"
              id="inputGroupSelect01"
              v-model="userInfo.prefecture"
            >
              <option
                v-for="option in prefecture_options"
                v-bind:value="option.value"
                :key="option.value"
                >{{ option.text }}
              </option>
            </select>
          </div>
          <div class="input-group input-group-lg">
            <input
              type="text"
              class="form-control input-content"
              aria-label="Sizing example input"
              aria-describedby="inputGroup-sizing-lg"
              required
              placeholder="渋谷区神泉町"
              v-model="userInfo.city"
            />
          </div>
          <div class="br-style"></div>
          <div class="input-group input-group-lg">
            <input
              type="text"
              class="form-control input-content"
              aria-label="Sizing example input"
              aria-describedby="inputGroup-sizing-lg"
              required
              placeholder="1-1"
              v-model="userInfo.address_1"
            />
          </div>
          <div class="br-style"></div>
          <div class="input-group input-group-lg">
            <input
              type="text"
              class="form-control input-content"
              aria-label="Sizing example input"
              aria-describedby="inputGroup-sizing-lg"
              required
              placeholder="Fitness Camp マンション101号"
              v-model="userInfo.address_2"
            />
          </div>
        </div>

        <div class="input-form">
          <div class="title">
            <h4 class="input-title">ご職業</h4>
          </div>
          <div class="input-group input-group-lg">
            <input
              type="text"
              class="form-control input-content"
              aria-label="Sizing example input"
              aria-describedby="inputGroup-sizing-lg"
              required
              placeholder="会社員"
              v-model="userInfo.profession"
            />
          </div>
        </div>
        <div v-if="error_msg !== ''">
          <p class="text-danger error-color">{{ error_msg }}</p>
        </div>
        <div class="register-button">
          <button>申し込み</button>
        </div>
      </form>
    </div>
  </div>
</template>
<script>
import ClassHeader from "../../../components/class/class_header";

// Userデータ(申込みPersonalData) OgawaK
// import firebase from "firebase/compat";
export default {
  name: "ClassEntry",

  data() {
    return {
      userInfo: {
        last_name: "",
        first_name: "",
        last_name_kana: "",
        first_name_kana: "",
        mail: "",
        phone: "",
        zip_code: "",
        prefecture: 0,
        city: "",
        address_1: "",
        address_2: "",
        profession: "",
        birthday: new Date(),
      },
      error_msg: "",
    };
  },
  async created() {},
  components: {
    ClassHeader,
  },
  methods: {
    async class_application() {
      let checkVarInputData = this.glb_check_input_data(this.userInfo);
      if (!checkVarInputData) {
        this.error_msg = "未入力項目があります";
        return;
      }
      var class_uid = await this.glb_get_localStorage("classID");
      this.userInfo.created_date = await this.glb_get_timestamp_now();
      var checkDbData = await this.glb_set_class_applicant(
        class_uid,
        this.userInfo
      );
      if (!checkDbData) {
        alert("申込失敗");
      } else {
        alert("申込成功");
      }
    },
    async getAddress() {
      if (this.userInfo.profession == "") {
        this.userInfo.profession = "未入力";
      }
      let data = await this.glb_get_address_map(this.userInfo.zip_code);
      this.userInfo.prefecture = data.pref;
      this.userInfo.city = data.city + data.town;
    },
  },
  // スクロールなし
  mounted() {
    // document.addEventListener(
    //   "touchmove",
    //   function(e) {
    //     e.preventDefault();
    //   },
    //   { passive: false }
    // );
  },
};
</script>
<style scoped>
/* 画面全体 */
.body-page {
  position: absolute;
  width: 100%;
  height: auto;
  background-color: #ffffff;
  margin: 0%;
  margin-top: 57px;
  min-height: 1200px;
}
.register-form {
  margin-top: 20px;
}
.input-form {
  margin-left: auto;
  margin-right: auto;
  margin-top: 12px;
  text-align: left;
  width: 95%;
  padding: 5px;
}
.title {
  display: flex;
}
.title .mandatory {
  width: 33px;
  height: 18px;
  margin-left: 4px;
  border-radius: 5px;
  background-color: #970000;
}
.title .mandatory label {
  font-family: Work Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 11px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #ffffff;
  padding-left: 5px;
  padding-top: 1px;
}
.input-title {
  font-weight: 600;
}
.input-content {
  background-color: #ededed;
  border: none;
  outline: none;
  font-size: 16px;
}
.space-style {
  width: 30px;
}
.input-zip {
  width: 150px;
  background-color: #ededed;
  border: none;
  outline: none;
  font-size: 16px;
}
.date-fiex-02 {
  display: flex;
}
.date-button {
  width: 60px;
  height: 25px;
  border-radius: 5px;
}
.get-zip-button {
  margin: 3px 0 0 5px;
  padding: 4px 0 0 4px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
  /* identical to box height */
  letter-spacing: -0.02em;
  background: #ffffff;
  border: 1px solid #c1c1c1;
  box-sizing: border-box;
  border-radius: 5px;
}
.select-form {
  background-color: #ededed;
  border: none;
  outline: none;
  font-size: 14px;
}
.br-style {
  margin-top: 8px;
}
.datepicker-style {
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  margin-top: -10px;
  padding: 5px;
  width: 95%;
}
.datepicker {
  /* font-size: 16px; */
  background-color: #ededed;
  outline: none;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  height: 35px;
  font-weight: 500;
  padding: 5px;
  width: 100%;
}
.register-button {
  margin-top: 40px;
  margin-bottom: 60px;
}
.register-button button {
  width: 145px;
  height: 41px;
  background: #00828a;
  border-radius: 6px;
  border: none;
  font-family: Work Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  align-items: flex-end;
  text-align: center;
  letter-spacing: -0.02em;
  color: #ffffff;
}
.input-content {
  background-color: #ededed;
  border: none;
  outline: none;
  font-size: 16px;
}
.error-color {
  padding-top: 20px;
  font-size: 14px;
  font-weight: 600;
}
</style>
