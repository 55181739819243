<template>
  <div>
    <div class="heard-style">
      <a href="#">
        <img src="@/assets/home/yajirusi.svg" width="20" height="20" />
      </a>
      <h2>キッズプログラム</h2>
    </div>
    <!-- 画像 -->
    <div class="home home-style">
      <div class="home-img">
        <img src="@/assets/kids/kids.jpg" height="250" />
      </div>
    </div>

    <!-- 説明部分 -->
    <div class="card border-light mb-3 card-style">
      <div class="card-body">
        <h3 class="card-title title-font">プログラムのコンセプト</h3>
        <p class="card-text title-conten-font">
          ①運動あそび<br />
          ②親子ペア参加<br />
          ③きつい楽しい<br />
          ④運動遊びの構成要件は、身体活動が原則<br />
          ⑤体力運動能力を刺激する
        </p>
      </div>
    </div>
    <div class="card border-light mb-3 card-style">
      <div class="card-body">
        <h3 class="card-title title-font">認定コーチとしての考え方</h3>
        <p class="card-text title-conten-font">
          Fitness Camp
          Kidsにおける認定コーチの役割は、指導やコーチングではなくプレイリーダーやガイドのようなものです。<br />
          遊びの要件である、【自由】【非日常】【ワクワクドキドキ】【非生産的】【ストーリー性】を持たせたクラス運営をしましょう。<br />
          そのためには、内発的動機づけと外発的動機づけを、うまくバランスさせること、正確さや効果よりも、楽しさ重視でクラスを進めることが重要です。
        </p>
      </div>
    </div>
    <div class="all-open">
      <div v-if="allEventValue">
        <button type="button" class="button-style" @click="allEvent(false)">
          All Close
        </button>
      </div>
      <div v-else-if="checkEventValue">
        <button type="button" class="button-style" @click="allEvent(false)">
          All Close
        </button>
      </div>
      <div v-else>
        <button type="button" class="button-style" @click="allEvent(true)">
          All Open
        </button>
      </div>
    </div>

    <!-- 詳細部分折り畳み -->
    <div class="check-div">
      <input
        id="acd-check1"
        class="acd-check"
        type="checkbox"
        v-model="check[0]"
      />
      <label class="acd-label" @click="checkEvent(0)">
        <h2 class="display-5 program-font-style">
          姿勢制御と重心制御機能系運動あそび<br />(3エクササイズ 合計15分程度)
        </h2>
      </label>
      <div class="acd-content" v-if="check[0]">
        <div class="home">
          <div>
            <div class="card border-light mb-3 card-style">
              <div class="card-body">
                <p class="card-text title-conten-font">
                  ターン&ポーズ
                </p>
              </div>
            </div>
          </div>
          <div class="card video-div-style">
            <video
              :src="url.url_7"
              controls
              preload="auto"
              class="video-style"
              type="video/quicktime"
            />
          </div>
          <br />
          <img class="img-style" :src="url.url_6" height="230" />
          <!-- 目的、動作、ポイントtable -->
          <div>
            <table>
              <tr>
                <th>目的</th>
                <td>
                  姿勢制御機能
                  <br />重心制御機能
                </td>
              </tr>
              <tr>
                <th>動作</th>
                <td>
                  <p class="font-weight-bold text-space">・ターン&ポーズ</p>
                  その場でグルグル回転（約3～5周）し，パッと気を付け姿勢
                  <br />
                  <p class="font-weight-bold text-space">
                    ・ターン&ポーズカカシ
                  </p>
                  その場でグルグル回転（約3～5周）し，カカシのポーズ
                  <p class="font-weight-bold text-space">
                    ・ターン&ポーズヒコーキ
                  </p>
                  その場でグルグル回転（約3～5周）し，ヒコーキのポーズ<br />
                  <br />
                  <p class="font-weight-bold text-space">◆アレンジ</p>
                  その場でグルグル回転（約3～5周）し，パッと気を付け姿勢
                  <p class="font-weight-bold text-space">
                    ・ターン&ポーズダッシュ
                  </p>
                  その場でグルグル回転（約3～5周）し，ダッシュ
                </td>
              </tr>
              <tr>
                <th>ポイント</th>
                <td>
                  ・回った後、直ぐに次の姿勢を作る<br />
                  ・一拍おいたりめまいが収まるのを待たない<br />
                  ・回り過ぎると酔うので回転スピードや回数を調整する<br />
                  ・止まるときにフラフラしないように「バランスが崩れたら海に落ちるぞー」などのハラハラ感を追加することも有効
                </td>
              </tr>
            </table>
          </div>
        </div>

        <div class="home">
          <div>
            <div class="card border-light mb-3 card-style">
              <div class="card-body">
                <p class="card-text title-conten-font">
                  ジャイアントスイング
                </p>
              </div>
            </div>
          </div>
          <div class="card video-div-style">
              <video
                :src="url.url_10"
                controls
                class="video-style"
                type="video/quicktime"
              />
          </div>
          <!-- 目的、動作、ポイントtable -->
          <div>
            <table>
              <tr>
                <th>目的</th>
                <td>
                  姿勢制御機能
                  <br />重心制御機能
                </td>
              </tr>
              <tr>
                <th>動作</th>
                <td>
                  1.親が子供の大腿部を支える。<br />
                  2.子供は親の体を挟む。<br />
                  3.親は子供をしっかりと支えて回転させる<br />
                </td>
              </tr>
              <tr>
                <th>ポイント</th>
                <td>
                  ・回転速度や回数は子供の調子（酔うこともあるので）に合わせて調整する。<br />
                  ・回す側はしっかりと支える<br />
                  ・下ろすときに頭が地面と強くぶつからないようにゆっくり下ろす
                </td>
              </tr>
            </table>
          </div>
        </div>

        <div class="home">
          <div>
            <div class="card border-light mb-3 card-style">
              <div class="card-body">
                <p class="card-text title-conten-font">
                  だるまさんがまわった
                </p>
              </div>
            </div>
          </div>
          <div class="card video-div-style">
              <video
                :src="url.url_4"
                controls
                class="video-style"
                type="video/quicktime"
              />
          </div>
          <!-- 目的、動作、ポイントtable -->
          <div>
            <table>
              <tr>
                <th>目的</th>
                <td>
                  姿勢制御機能
                  <br />重心制御機能
                </td>
              </tr>
              <tr>
                <th>動作</th>
                <td>
                  1.親の周りを子どもがグルグル走る。<br />
                  2.親が「だるまさん...」を言う。<br />
                  3.言い終わったところで子どもが止まり，フラフラしたら負け。<br />
                </td>
              </tr>
              <tr>
                <th>ポイント</th>
                <td>
                  ・回った後、直ぐにピタッと止まる<br />
                  ・一拍おいたりめまいが収まるのを待たない<br />
                  ・回り過ぎると酔うので回転スピードや回 数を調整する<br />
                  ・止まるときにフラフラしないように「バランスが崩れたら海に落ちるぞー」などのハラハラ感を追加することも有効
                </td>
              </tr>
            </table>
          </div>
        </div>

        <div class="home">
          <div>
            <div class="card border-light mb-3 card-style">
              <div class="card-body">
                <p class="card-text title-conten-font">
                  向き合ってしっぽ取り
                </p>
              </div>
            </div>
          </div>
          <div class="card video-div-style">
              <video
                :src="url.url_4"
                controls
                class="video-style"
                type="video/quicktime"
              />
          </div>
          <!-- 目的、動作、ポイントtable -->
          <div>
            <table>
              <tr>
                <th>目的</th>
                <td>
                  姿勢制御機能
                  <br />重心制御機能
                </td>
              </tr>
              <tr>
                <th>動作</th>
                <td>
                  1.タオルを「取る人」と、タオルを取られないように「逃げる人」を決める。<br />
                  2.逃げる人は、タオルをしっぽに見立て
                  て、ズボンの後ろに挟む。<br />
                  3.2人で向かい合って立つ。 <br />
                  4.スタートの合図で2人が左右にグルグル回りながら、取る人はタオルを取ろうとして、逃げる人はタオルを取られ
                  ないように動く。<br />
                  5.制限時間（10秒間）内にタオルを取られたら、取る人の勝ち。タオルを取ら
                  なければ、逃げる人の勝ち。<br />

                  <p class="font-weight-bold text-space">◆アレンジ</p>
                  1.2人がズボンの後ろにタオルを挟み、向かい合って立つ」<br />
                  2.スタートの合図で、右や左に輪を描くように動き、相手のタオルを先に取っ
                  た人の勝ち。
                </td>
              </tr>
              <tr>
                <th>ポイント</th>
                <td>
                  ・親は子どもが回りやすいように動きを誘導する<br />
                  ・回り過ぎると酔うので注意する<br />
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div class="home">
          <div>
            <div class="card border-light mb-3 card-style">
              <div class="card-body">
                <p class="card-text title-conten-font">
                  サークルしっぽ取り
                </p>
              </div>
            </div>
          </div>
            <div class="card video-div-style">
              <video
                :src="url.url_9"
                controls
                class="video-style"
                type="video/quicktime"
              />
            </div>
          <!-- 目的、動作、ポイントtable -->
          <div>
            <table>
              <tr>
                <th>目的</th>
                <td>
                  姿勢制御機能
                  <br />重心制御機能
                </td>
              </tr>
              <tr>
                <th>動作</th>
                <td>
                  1.直径5mほどの円と直線を地面に書く。<br />
                  2.子はしっぽ（紙テープやビニール紐等）をつけて逃げ、オニは追いかける。<br />
                  3.どちらも移動できるのは線の上だけ。<br />
                  4.制限時間（例：20秒間）にしっぽを取 られたら、オニの勝ち。
                </td>
              </tr>
              <tr>
                <th>ポイント</th>
                <td>
                  ・親は子どもが回りやすいように動きを誘導する<br />
                  ・回り過ぎると酔うので注意する<br />
                </td>
              </tr>
            </table>
          </div>
        </div>

        <div class="home">
          <div>
            <div class="card border-light mb-3 card-style">
              <div class="card-body">
                <p class="card-text title-conten-font">
                  あっちとんでほい
                </p>
              </div>
            </div>
          </div>
            <div class="card video-div-style">
              <video
                :src="url.url_1"
                controls
                class="video-style"
                type="video/quicktime"
              />
          </div>
          <!-- 目的、動作、ポイントtable -->
          <div>
            <table>
              <tr>
                <th>目的</th>
                <td>
                  姿勢制御機能
                  <br />重心制御機能
                </td>
              </tr>
              <tr>
                <th>動作</th>
                <td>
                  1.2人でジャンケンをして、勝った人が「あっちとんでほい」と言う。<br />
                  2.「ほい」のタイミングに合わせて、2人とも前後左右のどちらかにジャンプする。<br />
                  3.2人とも同じ方向にジャンプしたら、ジャンケンに勝った人の勝ち。<br />
                  4.2人が違う方向にジャンプしたら、元の位置に戻り、ジャンケンをする。<br />
                  5.勝負がつくまで続ける。<br />

                  <p class="font-weight-bold text-space">◆アレンジ</p>
                  <br />
                  1.ケンケン（片足）で「あっちとんでほい」を行う。<br />
                  2.ジャンケンを「からだジャンケン」や「顔ジャンケン」などにアレンジする。
                </td>
              </tr>
              <tr>
                <th>ポイント</th>
                <td>
                  ・「あっち向いてほい」と一緒で，負けたほうは勝った方の動きを確認してから動かない<br />
                  ・ 周辺の地面状況に注意をする（極端な凹凸等）
                </td>
              </tr>
            </table>
          </div>
        </div>

        <div class="home">
          <div>
            <div class="card border-light mb-3 card-style">
              <div class="card-body">
                <p class="card-text title-conten-font">
                  言うこと一緒、やること一緒
                </p>
              </div>
            </div>
          </div>
            <div class="card video-div-style">
              <video
                :src="url.url_18"
                controls
                class="video-style"
                type="video/quicktime"
              />
            </div>
          <!-- 目的、動作、ポイントtable -->
          <div>
            <table>
              <tr>
                <th>目的</th>
                <td>
                  姿勢制御機能
                  <br />重心制御機能
                </td>
              </tr>
              <tr>
                <th>動作</th>
                <td>
                  1.2人で向かい合って立つ。<br />
                  2.1人が「言うこと一緒、やること一緒」と言ってから、「右」「左」「前」「後ろ」「上」「下」のうち、どれか一つの方向を言って、その方向にジャンプする。<br />
                  3.もう1人は、指示された方向を（例えば「右！」と）言いながら、その方向にジャンプ
                  する。<br />
                  4.1人が方向の指示を繰り返し、もう1人が間違えずに指示された方向にジャンプできるかチャレンジする。<br />
                  <br />
                  <p class="font-weight-bold text-space">◆アレンジ</p>
                  1.1人が「言うこと逆、やること逆」と行ってから、
                  「右」「左」「前」「後ろ」「上」
                  「下」のうち、どれか一つの方向を言って指示 をする。<br />
                  2.もう1人は、指示された方向と逆を言いながら、
                  逆方向にジャンプする。<br />
                  3.「言うこと一緒、やること逆」や、「言うこと
                  逆、やること一緒」と指示して行っても良い。
                </td>
              </tr>
              <tr>
                <th>ポイント</th>
                <td>
                  ・指示された跳ぶ方向へ素早く移動する<br />
                  ・考えない（考える時間を短くする）<br />
                  ・周辺の地面状況に注意をする（極端な凹凸等）<br />
                </td>
              </tr>
            </table>
          </div>
        </div>

        <div class="home">
          <div>
            <div class="card border-light mb-3 card-style">
              <div class="card-body">
                <p class="card-text title-conten-font">
                  タッチマッチ
                </p>
              </div>
            </div>
          </div>
          <div class="card video-div-style">
              <video
                :src="url.url_11"
                controls
                class="video-style"
                type="video/quicktime"
              />
            </div>
          <!-- 目的、動作、ポイントtable -->
          <div>
            <table>
              <tr>
                <th>目的</th>
                <td>
                  姿勢制御機能
                  <br />重心制御機能
                </td>
              </tr>
              <tr>
                <th>動作</th>
                <td>
                  1.「タッチする人」と「逃げる人」を決める。<br />
                  2.向かい合って立ち、握手する。<br />
                  3.スタートの合図で、逃げる人は、体の部位
                  （例：背中）を言う。<br />
                  4.制限時間（10秒間）内に、相手のからだの部位にタッチできたら、タッチする人の勝ち。タッチされずに逃げ続けることができたら、逃げる人の勝ち<br />
                  <p class="font-weight-bold text-space">◆アレンジ</p>
                  1.両手と両足を床につけて、向かい合う。<br />
                  2.スタートの合図で、1人がタッチする部位を言う。<br />
                  3.相手のからだの部位に、先にタッチできた人の勝ち
                </td>
              </tr>
              <tr>
                <th>ポイント</th>
                <td>
                  ・他のペアとぶつからないよう周辺のスペース確保する
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <input
        id="acd-check3"
        class="acd-check"
        type="checkbox"
        v-model="check[2]"
      />
      <label class="acd-label" @click="checkEvent(2)">
        <h2 class="display-5 program-font-style">操作系 運動あそび</h2>
      </label>
      <div class="acd-content" v-if="check[2]">
        <div class="home">
          <div>
            <!-- 説明部分 -->
            <div class="card border-light mb-3 card-style">
              <div class="card-body">
                <p class="card-text title-conten-font">
                  風船しりとり
                </p>
              </div>
            </div>
          </div>
            <div class="card video-div-style">
              <video
                :src="url.url_20"
                controls
                class="video-style"
                type="video/quicktime"
              />
            </div>
          <!-- 目的、動作、ポイントtable -->
          <div>
            <table>
              <tr>
                <th>目的</th>
                <td>
                  操作系能力
                </td>
              </tr>
              <tr>
                <th>動作</th>
                <td>
                  1.風船を膨らませて、ボールの代わりにする。<br />
                  2.2人でしりとりをしながら、バレーボールのように風船を打ち合う。<br />
                  3.言うことを考えている間に風船が床に落ちたり、しりとりを続けられなかったりたら負け。<br />

                  <p class="font-weight-bold text-space">◆アレンジ</p>
                  1.風船を膨らませて、ボールの代わりにする。<br />
                  2.2人でしりとりをしながら風船を蹴り合う。<br />
                  3.言うことを考えている間は、1人でリフティングを続ける
                </td>
              </tr>
              <tr>
                <th>ポイント</th>
                <td>
                  ・アウトドアの場合，風船が風に流されるので，風が強い場合は適さない<br />
                  ・風船を爆弾に見立てて「落ちたら爆発するぞー」などのドキドキ感を与えることも大切<br />
                </td>
              </tr>
            </table>
          </div>
        </div>

        <div class="home">
          <div>
            <div class="card border-light mb-3 card-style">
              <div class="card-body">
                <p class="card-text title-conten-font">
                  棒キャッチ
                </p>
              </div>
            </div>
          </div>
            <div class="card video-div-style">
              <video
                :src="url.url_2"
                controls
                class="video-style"
                type="video/quicktime"
              />
            </div>
          <!-- 目的、動作、ポイントtable -->
          <div>
            <table>
              <tr>
                <th>目的</th>
                <td>
                  操作系能力
                </td>
              </tr>
              <tr>
                <th>動作</th>
                <td>
                  1.5枚ほど重ねた新聞紙を丸めて、テープで止めて、ボールを作る。<br />
                  2.ボールを立たせて、倒れる前にキャッチする。<br />
                  キャッチするまでの間に手を何回叩けるか、チャレンジする。<br />
                  3.キャッチするまでの間に、からだをクルンと一回転する<br />

                  <p class="font-weight-bold text-space">◆アレンジ</p>
                  1.2人がそれぞれボールを持ち、向かい合ってたつ<br />
                  2.スタートの合図で、自分のボールから手を離し、相手のボールが倒れる前にキャッチする。ケンケンで移動しても良い。
                </td>
              </tr>
              <tr>
                <th>ポイント</th>
                <td>
                  ・落ちている枝などで代用してもよい<br />
                  ・棒を氷の棒に見立てて、「倒れたら折れてしまうぞー」などの声かけをしてドキドキ感を与えることも有効<br />
                </td>
              </tr>
            </table>
          </div>
        </div>

        <div class="home">
          <div>
            <div class="card border-light mb-3 card-style">
              <div class="card-body">
                <p class="card-text title-conten-font">
                  親子で輪投げ
                </p>
              </div>
            </div>
          </div>
          <div class="card video-div-style">
            <video
              :src="url.url_3"
              controls
              class="video-style"
              type="video/quicktime"
            />
          </div>
          <!-- 目的、動作、ポイントtable -->
          <div>
            <table>
              <tr>
                <th>目的</th>
                <td>
                  操作系能力
                </td>
              </tr>
              <tr>
                <th>動作</th>
                <td>
                  1.捻った新聞紙で輪（リング）を作り、テープで止める。<br />
                  2.1人がリングを投げて、もう1人がキャッチする。<br />
                  3.リングを片手に通したり、片足に通したり、いろいろな方法でチャレンジをする。<br />
                </td>
              </tr>
              <tr>
                <th>ポイント</th>
                <td>
                  ・ミニフラフープなどを代用してもよい<br />
                  ・難しい場合には距離を近くしたり、リングを大きくしたりして個人のレベルに調整をする<br />
                </td>
              </tr>
            </table>
          </div>
        </div>

        <div class="home">
          <div>
            <div class="card border-light mb-3 card-style">
              <div class="card-body">
                <p class="card-text title-conten-font">
                  風船サッカー
                </p>
              </div>
            </div>
          </div>
          <div class="card video-div-style">
            <video
              :src="url.url_19"
              controls
              class="video-style"
              type="video/quicktime"
            />
          </div>

          <!-- 目的、動作、ポイントtable -->
          <div>
            <table>
              <tr>
                <th>目的</th>
                <td>
                  操作系能力
                </td>
              </tr>
              <tr>
                <th>動作</th>
                <td>
                  1.段ボール箱などでゴールを準備する<br />
                  2.スタートラインに風船（ビーチボール 等）を置く<br />
                  3.2人で風船を蹴りゴールに入れる。
                </td>
              </tr>
              <tr>
                <th>ポイント</th>
                <td>
                  ・小さいゴムボールを代用してもよい<br />
                  ・他のペアとぶつからないよう周辺のスペース確保<br />
                  ・簡単すぎたり難しすぎる場合には、ゴールまでの距離やゴールの向き、簡単なボールに変えたりして調整をする<br />
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <input
        id="acd-check4"
        class="acd-check"
        type="checkbox"
        v-model="check[3]"
      />
      <label class="acd-label" @click="checkEvent(3)">
        <h2 class="display-5 program-font-style">
          エネルギー体力系 運動あそび
        </h2>
      </label>
      <div class="acd-content" v-if="check[3]">
        <div class="home">
          <div>
            <!-- 説明部分 -->
            <div class="card border-light mb-3 card-style">
              <div class="card-body">
                <p class="card-text title-conten-font">
                  親子おにごっこ
                </p>
              </div>
            </div>
          </div>
          <div class="card video-div-style">
            <video
              :src="url.url_15"
              controls
              class="video-style"
              type="video/quicktime"
            />
          </div>
          <!-- 目的、動作、ポイントtable -->
          <div>
            <table>
              <tr>
                <th>目的</th>
                <td>
                  エネルギー系体力
                </td>
              </tr>
              <tr>
                <th>動作</th>
                <td>
                  1.公園や自然な地形を含めた（約10m四方の）範囲を決める。<br />
                  2.親子でオニと子の役割を決める。<br />
                  3.子が逃げた数秒後にオニが動き出し子を捕まえる。<br />
                  4.制限時間（例：30-60秒程度）内に、オニが子をタッチしたらオニの勝ち。<br />
                  5.子が逃げ切ることができたら、子の勝ち
                </td>
              </tr>
              <tr>
                <th>ポイント</th>
                <td>
                  ・制限時間は，運動量の観点から決めると運動量確保につながる<br />
                  ・他のペアとぶつからないよう周辺のスペース確保<br />
                  ・「捕まったらオニに食べられちゃうぞー」や追い掛けているときに「食べちゃうぞ」と言いながら追いかけることも良い
                </td>
              </tr>
            </table>
          </div>
        </div>

        <div class="home">
          <div>
            <!-- 説明部分 -->
            <div class="card border-light mb-3 card-style">
              <div class="card-body">
                <p class="card-text title-conten-font">
                  親子かけっこ
                </p>
              </div>
            </div>
          </div>
          <div class="card video-div-style">
            <video
              :src="url.url_16"
              controls
              class="video-style"
              type="video/quicktime"
            />
          </div>

          <!-- 目的、動作、ポイントtable -->
          <div>
            <table>
              <tr>
                <th>目的</th>
                <td>
                  エネルギー系体力
                </td>
              </tr>
              <tr>
                <th>動作</th>
                <td>
                  1.公園や自然な地形を利用した場所で目標地点を決めて親子で一緒に走る<br />
                  2.親子で競い合って走ったり、親子がチームになって、他の家族と競ったりする。
                </td>
              </tr>
              <tr>
                <th>ポイント</th>
                <td>
                  •本数は，運動量の観点から決めると運動量確保につながる<br />
                  •他のペアとぶつからないよう周辺のスペース確保<br />
                  •親は「ライオンが追いかけてくるぞー」などハラハラ感を出すような声かけをしながら走ることも大切
                </td>
              </tr>
            </table>
          </div>
        </div>

        <div class="home">
          <div>
            <!-- 説明部分 -->
            <div class="card border-light mb-3 card-style">
              <div class="card-body">
                <p class="card-text title-conten-font">
                  親子リレー
                </p>
              </div>
            </div>
          </div>
            <div class="card video-div-style">
              <video
                :src="url.url_17"
                controls
                class="video-style"
                type="video/quicktime"
              />
            </div>
          <!-- 目的、動作、ポイントtable -->
          <div>
            <table>
              <tr>
                <th>目的</th>
                <td>
                  エネルギー系体力
                </td>
              </tr>
              <tr>
                <th>動作</th>
                <td>
                  1.公園や自然の地形を利用した場所にコースを作り、親子でリレー形式で走る。<br />
                  2.他の親子と競ったり、制限時間や目標時間を決めて親子で一緒に走る。
                </td>
              </tr>
              <tr>
                <th>ポイント</th>
                <td>
                  ・ 制限時間は，運動量の観点から決めると運動量確保につながる
                  ・「金メダルをかけたオリンピックのリレー」などストーリー性を持たせてから走ることも有効
                </td>
              </tr>
            </table>
          </div>
        </div>

        <div class="home">
          <div>
            <!-- 説明部分 -->
            <div class="card border-light mb-3 card-style">
              <div class="card-body">
                <p class="card-text title-conten-font">
                  腕立てふせ
                </p>
              </div>
            </div>
          </div>
            <div class="card video-div-style">
              <video
                :src="url.url_13"
                controls
                class="video-style"
                type="video/quicktime"
              />
            </div>
          <!-- 目的、動作、ポイントtable -->
          <div>
            <table>
              <tr>
                <th>目的</th>
                <td>
                  エネルギー系体力
                </td>
              </tr>
              <tr>
                <th>動作</th>
                <td>
                  1.手を脇の下に置いて腕立て伏せの開始姿勢をとる。<br />
                  2.膝をついたりしても良い。<br />
                  3.回数を決めて親子で一緒に行ったり、交互に行ったりする。
                </td>
              </tr>
              <tr>
                <th>ポイント</th>
                <td>
                  •手首や肩など体が痛む場合には無理をしない<br />
                  •「子供（親に）に負けないように」など勝負に働きかけたり、「くまが襲ってきても戦えるように頑張ろう」などストーリー性を持たせるような声がけが大切<br />
                  •エクササイズの習得にならないように注意をする
                </td>
              </tr>
            </table>
          </div>
        </div>

        <div class="home">
          <div>
            <!-- 説明部分 -->
            <div class="card border-light mb-3 card-style">
              <div class="card-body">
                <p class="card-text title-conten-font">
                  手押し車
                </p>
              </div>
            </div>
          </div>
          <div class="card video-div-style">
              <video
                :src="url.url_12"
                controls
                class="video-style"
                type="video/quicktime"
              />
          </div>
          <!-- 目的、動作、ポイントtable -->
          <div>
            <table>
              <tr>
                <th>目的</th>
                <td>
                  エネルギー系体力
                </td>
              </tr>
              <tr>
                <th>動作</th>
                <td>
                  1.公園や自然な地形を利用した場所にコースを作り手押し車で移動する。<br />
                  2.親は子供の能力に合わせておんぶ をしたりしてお互いに行う。
                </td>
              </tr>
              <tr>
                <th>ポイント</th>
                <td>
                  ・極端に怪我の危険のある地面状況には注意をする<br />
                  ・手首や肩など体が痛む場合には無理をしない<br />
                  ・背中に畳んだタオルを置いたりして「落とさないように運んでみよう」などのアレンジも大切<br />
                  ・エクササイズの習得にならないように注意をする
                </td>
              </tr>
            </table>
          </div>
        </div>

        <div class="home">
          <div>
            <!-- 説明部分 -->
            <div class="card border-light mb-3 card-style">
              <div class="card-body">
                <p class="card-text title-conten-font">
                  綱引き（タオル引き）
                </p>
              </div>
            </div>
          </div>
            <div class="card video-div-style">
              <video
                :src="url.url_13"
                controls
                class="video-style"
                type="video/quicktime"
              />
            </div>
          <!-- 目的、動作、ポイントtable -->
          <div>
            <table>
              <tr>
                <th>目的</th>
                <td>
                  エネルギー系体力
                </td>
              </tr>
              <tr>
                <th>動作</th>
                <td>
                  1.大きめなタオルを綱に見立てて親子でタオルを持つ。<br />
                  2.子供は両手にして、大人は片手にしたりして強度を調整しながらお互いに引き合う。<br />
                  3.引き切った方が勝ち。
                </td>
              </tr>
              <tr>
                <th>ポイント</th>
                <td>
                  •親の姿勢や持ち方を変化させてお互いに力が均一になるところから始めることも大切<br />
                  •周りを水に例えてたりなどイメージを追加する とより運動を促せる
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <input
        id="acd-check5"
        class="acd-check"
        type="checkbox"
        v-model="check[4]"
      />
    </div>
    <div class="sapace-style"></div>
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: "ResetProgram",
  components: {},
  data() {
    return {
      monitoring: "<モニタリング>",
      check: [
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
      ],
      allEventValue: false,
      checkEventValue: false,
      url: {
        //あっちとんでぴょん.mov
        url_1:
          "https://firebasestorage.googleapis.com/v0/b/fitnesscamp-428f2.appspot.com/o/program%2Fkids%2F%E3%81%82%E3%81%A3%E3%81%A1%E3%81%A8%E3%82%93%E3%81%A6%E3%82%99%E3%81%B2%E3%82%9A%E3%82%87%E3%82%93.mov?alt=media&token=ce102008-bb00-4715-816f-0fddc27250ef",
        //キャッチポール.mov
        url_2:
          "https://firebasestorage.googleapis.com/v0/b/fitnesscamp-428f2.appspot.com/o/program%2Fkids%2F%E3%82%AD%E3%83%A3%E3%83%83%E3%83%81%E3%83%9B%E3%82%9A%E3%83%BC%E3%83%AB.mov?alt=media&token=e896c419-45b1-4de5-afb2-ae3d89c7ff7d",
        //キャッチリング.mov
        url_3:
          "https://firebasestorage.googleapis.com/v0/b/fitnesscamp-428f2.appspot.com/o/program%2Fkids%2F%E3%82%AD%E3%83%A3%E3%83%83%E3%83%81%E3%83%AA%E3%83%B3%E3%82%AF%E3%82%99.mov?alt=media&token=17070763-8b31-433f-bb44-a6cf409bb42e",
        //グルグルしっぽ取り.mov
        url_4:
          "https://firebasestorage.googleapis.com/v0/b/fitnesscamp-428f2.appspot.com/o/program%2Fkids%2F%E3%82%AF%E3%82%99%E3%83%AB%E3%82%AF%E3%82%99%E3%83%AB%E3%81%97%E3%81%A3%E3%81%BB%E3%82%9A%E5%8F%96%E3%82%8A.mov?alt=media&token=82fb6755-569b-48dd-9166-c23c9f74f1f6",
        //グルグルだるまさんが転んだ.mov
        url_5:
          "https://firebasestorage.googleapis.com/v0/b/fitnesscamp-428f2.appspot.com/o/program%2Fkids%2F%E3%82%AF%E3%82%99%E3%83%AB%E3%82%AF%E3%82%99%E3%83%AB%E3%81%9F%E3%82%99%E3%82%8B%E3%81%BE%E3%81%95%E3%82%93%E3%81%8B%E3%82%99%E8%BB%A2%E3%82%93%E3%81%9F%E3%82%99.mov?alt=media&token=6c8fb0a7-5254-4922-b371-d9d66569da37",
        //グルグルカカシ（写真）.png
        url_6:
          "https://firebasestorage.googleapis.com/v0/b/fitnesscamp-428f2.appspot.com/o/program%2Fkids%2F%E3%82%AF%E3%82%99%E3%83%AB%E3%82%AF%E3%82%99%E3%83%AB%E3%82%AB%E3%82%AB%E3%82%B7%EF%BC%88%E5%86%99%E7%9C%9F%EF%BC%89.png?alt=media&token=0983dd9b-38c5-423b-8d47-be226c7247c7",
        //グルグルパmov.mov
        url_7:
          "https://firebasestorage.googleapis.com/v0/b/fitnesscamp-428f2.appspot.com/o/program%2Fkids%2F%E3%82%AF%E3%82%99%E3%83%AB%E3%82%AF%E3%82%99%E3%83%AB%E3%83%8F%E3%82%9Amov.mov?alt=media&token=2952f307-2cf8-470c-bad9-7ac4929a0d9a",
        //グルグルヒコーキ（写真）.jpeg
        url_8:
          "https://firebasestorage.googleapis.com/v0/b/fitnesscamp-428f2.appspot.com/o/program%2Fkids%2F%E3%82%AF%E3%82%99%E3%83%AB%E3%82%AF%E3%82%99%E3%83%AB%E3%83%92%E3%82%B3%E3%83%BC%E3%82%AD%EF%BC%88%E5%86%99%E7%9C%9F%EF%BC%89.jpeg?alt=media&token=c8e83561-476a-4926-b475-6e058913f2bd",
        //サークルオニ.MOV
        url_9:
          "https://firebasestorage.googleapis.com/v0/b/fitnesscamp-428f2.appspot.com/o/program%2Fkids%2F%E3%82%B5%E3%83%BC%E3%82%AF%E3%83%AB%E3%82%AA%E3%83%8B.MOV?alt=media&token=075e4bb2-6577-4230-9bd4-7bb14892fe16",
        //ジャイアントスイング.mov
        url_10:
          "https://firebasestorage.googleapis.com/v0/b/fitnesscamp-428f2.appspot.com/o/program%2Fkids%2F%E3%82%B7%E3%82%99%E3%83%A3%E3%82%A4%E3%82%A2%E3%83%B3%E3%83%88%E3%82%B9%E3%82%A4%E3%83%B3%E3%82%AF%E3%82%99.mov?alt=media&token=3d0e981b-3411-4415-9e61-de3e029c8c4e",
        //タッチミー.mov
        url_11:
          "https://firebasestorage.googleapis.com/v0/b/fitnesscamp-428f2.appspot.com/o/program%2Fkids%2F%E3%82%BF%E3%83%83%E3%83%81%E3%83%9F%E3%83%BC.mov?alt=media&token=108b886d-01d7-43bc-9f48-3f8cb3afd02e",
        //手押し車.MOV
        url_12:
          "https://firebasestorage.googleapis.com/v0/b/fitnesscamp-428f2.appspot.com/o/program%2Fkids%2F%E6%89%8B%E6%8A%BC%E3%81%97%E8%BB%8A.MOV?alt=media&token=5737cce4-4344-4810-b5d9-3530796fc255",
        //綱引き（タオル引き）.MOV
        url_13:
          "https://firebasestorage.googleapis.com/v0/b/fitnesscamp-428f2.appspot.com/o/program%2Fkids%2F%E7%B6%B1%E5%BC%95%E3%81%8D%EF%BC%88%E3%82%BF%E3%82%AA%E3%83%AB%E5%BC%95%E3%81%8D%EF%BC%89.MOV?alt=media&token=869e6482-a914-4b04-98d0-5a5721c3a406",
        //腕立てふせ.MOV
        url_14:
          "https://firebasestorage.googleapis.com/v0/b/fitnesscamp-428f2.appspot.com/o/program%2Fkids%2F%E8%85%95%E7%AB%8B%E3%81%A6%E3%81%B5%E3%81%9B.MOV?alt=media&token=1d777b63-73cc-4b94-a18c-752e2cb67499",
        //親子おにごっこ.MOV
        url_15:
          "https://firebasestorage.googleapis.com/v0/b/fitnesscamp-428f2.appspot.com/o/program%2Fkids%2F%E8%A6%AA%E5%AD%90%E3%81%8A%E3%81%AB%E3%81%93%E3%82%99%E3%81%A3%E3%81%93.MOV?alt=media&token=53702721-c798-4e3f-be26-bf0dce17db64",
        //親子かけっこ.MOV
        url_16:
          "https://firebasestorage.googleapis.com/v0/b/fitnesscamp-428f2.appspot.com/o/program%2Fkids%2F%E8%A6%AA%E5%AD%90%E3%81%8B%E3%81%91%E3%81%A3%E3%81%93.MOV?alt=media&token=c50a507e-31e2-4e88-9d4b-7ab8346d43ea",
        //親子リレー.MOV
        url_17:
          "https://firebasestorage.googleapis.com/v0/b/fitnesscamp-428f2.appspot.com/o/program%2Fkids%2F%E8%A6%AA%E5%AD%90%E3%83%AA%E3%83%AC%E3%83%BC.MOV?alt=media&token=1715ba2c-170c-459f-bd0e-aaad691b600b",
        //言うこと一緒、やること一緒.mov
        url_18:
          "https://firebasestorage.googleapis.com/v0/b/fitnesscamp-428f2.appspot.com/o/program%2Fkids%2F%E8%A8%80%E3%81%86%E3%81%93%E3%81%A8%E4%B8%80%E7%B7%92%E3%80%81%E3%82%84%E3%82%8B%E3%81%93%E3%81%A8%E4%B8%80%E7%B7%92.mov?alt=media&token=a6b89d79-c239-4988-b94c-0a234ab70047",
        //風船サッカー.MOV
        url_19:
          "https://firebasestorage.googleapis.com/v0/b/fitnesscamp-428f2.appspot.com/o/program%2Fkids%2F%E9%A2%A8%E8%88%B9%E3%82%B5%E3%83%83%E3%82%AB%E3%83%BC.MOV?alt=media&token=b57ab587-b35c-4f42-8525-9ff91f66d3c2",
        //風船しりとり
        url_20:
          "https://firebasestorage.googleapis.com/v0/b/fitnesscamp-428f2.appspot.com/o/program%2Fkids%2Fvideo-%E9%A2%A8%E8%88%B9%E3%81%97%E3%82%8A%E3%81%A8%E3%82%8A.mp4?alt=media&token=2f532809-bb92-4f15-bf0a-b1a5291c5b9b"
      },
    };
  },
  methods: {
    checkEvent(index) {
      if (!this.check[index]) {
        this.checkEventValue = true;
      } else {
        this.checkEventValue = false;
      }
      this.check[index] = !this.check[index];
    },
    allEvent(isValue) {
      if (!isValue) {
        this.checkEventValue = false;
      }
      let self = this;
      self.check.forEach(function(value, index) {
        self.check[index] = isValue;
      });
      self.allEventValue = isValue;
    },
  },
};
</script>
<style scoped>
.heard-style {
  position: fixed;
  z-index: 2;
  margin-top: 0%;
  padding-top: 18px;
  width: 100%;
  height: 58px;
  background-color: #fc6404;
}
.heard-style img {
  float: left;
  margin-left: 2%;
}
.heard-style > h2 {
  color: rgb(255, 255, 255);
  margin-right: 4%;
  font-weight: 900;
}
.home-img img {
  margin-top: 58px;
  width: 100%;
  /* height:auto; */
  padding: 0;
}
.home-img img::before {
  content: "";
  display: block;
  padding-top: calc(((720 / 1280) * 100%));
}
.card-style {
  max-width: 100rem;
  margin: 2%;
  background-color: rgb(248, 248, 248);
}
.title-font {
  color: #000;
  font-weight: 600;
  text-align: left;
}
.title-conten-font {
  color: #000;
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  letter-spacing: 1px;
  line-height: 20px;
}
.all-open {
  text-align: right;
  margin-right: 2%;
  margin-top: 5%;
  margin-bottom: 5%;
}
.button-style {
  width: 60px;
  height: 30px;
  font-size: 12px;
  text-align: center;
  padding: 0%;
  background: #ffffff;
  border-radius: 5px;
  border: 1px solid #fc6404;
}
.text-space {
  padding-top: 6px;
  margin: 0%;
}

/* 折りたたみ */
.check-div {
}
.acd-check {
  display: none;
  border-bottom: 1px solid rgb(177, 177, 177);
}
.acd-label {
  background: #fc6404;
  color: rgb(255, 255, 255);
  display: block;
  margin-bottom: 1px;
  padding: 16px;
  position: relative;
  font-size: 2em;
  height: auto;
  border-bottom: 0.5px solid rgb(255, 254, 240);
  text-align: left;
  margin: 0%;
}
.acd-label:after {
  background: #fc6404;
  box-sizing: border-box;
  content: url(../../assets/home/downY.svg);
  display: block;
  font-family: "Font Awesome 5 Free";
  height: 50px;
  width: 50px;
  padding: 10px 20px;
  position: absolute;
  right: 0;
  top: 0px;
  height: 55px;
  border-bottom: 0px solid rgb(255, 254, 240);
}
.acd-content {
  border: 1px solid #333;
  display: block;
  height: 0;
  opacity: 0;
  padding: 0 10px;
  transition: 0.5s;
  visibility: hidden;
  border: 0;
}
.acd-check:checked + .acd-label:after {
  content: url(../../assets/home/upY.svg);
}
.acd-check:checked + .acd-label + .acd-content {
  height: auto;
  opacity: 1;
  padding: 10px;
  visibility: visible;
  border: 0;
  background-color: #ffffff;
  border-bottom: 0.5px solid rgb(255, 254, 240);
}
.program-font-style {
  font-weight: 600;
  width: 90%;
}
.sapace-style {
  height: 200px;
  background: #ffffff;
  margin-bottom: 0%;
}
.about {
  text-align: left;
  margin-top: 2%;
  margin-left: 2%;
  margin-bottom: 3%;
}
.about > a:link,
a:visited,
a:hover,
a:active {
  text-decoration: none;
  font-size: 14px;
  font-weight: 400;
  color: rgb(163, 163, 163);
}

.home > img {
  padding-top: 1%;
  width: 96%;
}
.home > img::before {
  content: "";
  display: block;
  padding-top: calc(((720 / 1280) * 100%));
}

/* table */
table {
  margin-top: 8%;
  margin-left: 2%;
  margin-right: 2%;
  width: 96%;
  border-collapse: collapse;
}

table tr {
  width: 30px;
  border-bottom: solid 1px white;
}

table tr:last-child {
  border-bottom: none;
}

table th {
  position: relative;
  text-align: left;
  width: 25%;
  height: auto;
  background-color: #e4ecec;
  color: rgb(0, 0, 0);
  text-align: center;
  padding: 10px 0;
  font-size: 14px;
  font-weight: 400;
}
table td {
  position: relative;
  text-align: left;
  height: auto;
  background-color: rgb(247, 247, 247);
  color: rgb(0, 0, 0);
  text-align: left;
  padding: 10px 10px;
  font-size: 14px;
  font-weight: 400;
}

.video-div-style {
  height: auto;
  width: auto;
  margin-top: 5%;
  margin-left: 2%;
  margin-right: 2%;
  border: 0;
  background-color: rgb(233, 233, 233);
}
.video-style {
  height: 250px;
}
.img-style {
  height: auto;
}
</style>
