<template>
  <div>
    <Header :page="10" :title="title" @my-click="reflectNum" />
    <div v-if="program_page.program_name.event">
      <div class="ft-content">
        <MkProgramName @do-back="doBack" />
      </div>
    </div>
    <div v-if="program_page.improvement.event">
      <div class="ft-content">
        <MkProgramImprovement @do-back="doBack" />
      </div>
    </div>
    <div v-if="program_page.staticStretch.event">
      <div class="ft-content">
        <MkProgramStaticStretch @do-back="doBack" />
      </div>
    </div>
    <div v-if="program_page.movementTraining.event">
      <div class="ft-content">
        <MkProgramMovementTraining @do-back="doBack" />
      </div>
    </div>
    <div v-if="program_page.dynamicStretch.event">
      <div class="ft-content">
        <MkProgramDynamicStretch @do-back="doBack" />
      </div>
    </div>
    <div v-if="program_page.circuittraining.event">
      <div class="ft-content">
        <MkProgramCircuittraining @do-back="doBack" />
      </div>
    </div>
    <div v-if="program_page.activation.event">
      <div class="ft-content">
        <MkProgramActivation @do-back="doBack" />
      </div>
    </div>
    <div v-if="program_page.groupWork.event">
      <div class="ft-content">
        <MkProgramgroupWork @do-back="doBack" />
      </div>
    </div>
    <div v-if="program_page.status.event">
      <div class="ft-content">
        <MkProgramgroupStatus @do-back="doBack" />
      </div>
    </div>
  </div>
</template>

<script>
// import firebase from "firebase/compat";
import Header from "@/components/Header";
import MkProgramName from "@/components/mk_program/mk_program_content/mk_program_name";
import MkProgramImprovement from "@/components/mk_program/mk_program_content/mk_program_improvement";
import MkProgramStaticStretch from "@/components/mk_program/mk_program_content/mk_program_staticStretch";
import MkProgramActivation from "@/components/mk_program/mk_program_content/mk_program_activation";
import MkProgramDynamicStretch from "@/components/mk_program/mk_program_content/mk_program_dynamicStretch";
import MkProgramMovementTraining from "@/components/mk_program/mk_program_content/mk_program_movementTraining";
import MkProgramCircuittraining from "@/components/mk_program/mk_program_content/mk_program_circuittraining";
import MkProgramgroupWork from "@/components/mk_program/mk_program_content/mk_program_groupWork";
import MkProgramgroupStatus from "@/components/mk_program/mk_program_content/mk_program_status";
export default {
  name: "Modal",
  components: {
    Header,
    MkProgramName,
    MkProgramImprovement,
    MkProgramStaticStretch,
    MkProgramActivation,
    MkProgramDynamicStretch,
    MkProgramMovementTraining,
    MkProgramCircuittraining,
    MkProgramgroupWork,
    MkProgramgroupStatus,
  },
  data() {
    return {
      programList: [],
      title: "",
      program_info: {},
      program_page: {},
    };
  },
  created: async function() {
    await this.getProgramInfo();
  },
  methods: {
    doBack() {
      this.program_page.program_name = false;
      this.$emit("do-back");
    },
    async getProgramInfo() {
      this.program_page = this.glb_program_page;
      this.mk_program_storage = this.glb_get_localStorage_for_program(
        this.mk_program_storage_name
      );
      this.title = this.mk_program_storage.mk_program_page;
      if (this.title == this.glb_program_title.program_name) {
        this.program_page.program_name.event = true;
      }
      if (this.title == this.glb_program_title.improvement) {
        this.program_page.improvement.event = true;
      }
      if (this.title == this.glb_program_title.staticStretch) {
        this.program_page.staticStretch.event = true;
      }
      if (this.title == this.glb_program_title.movementTraining) {
        this.program_page.movementTraining.event = true;
      }
      if (this.title == this.glb_program_title.dynamicStretch) {
        this.program_page.dynamicStretch.event = true;
      }
      if (this.title == this.glb_program_title.circuittraining) {
        this.program_page.circuittraining.event = true;
      }
      if (this.title == this.glb_program_title.activation) {
        this.program_page.activation.event = true;
      }
      if (this.title == this.glb_program_title.groupWork) {
        this.program_page.groupWork.event = true;
      }
      if (this.title == this.glb_program_title.status) {
        this.program_page.status.event = true;
      }
    },
  },
};
</script>

<style scoped>
img {
  width: 150px;
  height: 150px;
  padding: 10px;
}
.mk-program-list {
  width: 100%;
  margin: auto;
}
.mk-program-icon {
  text-align: center;
  width: 84%;
  margin: auto;
  /* padding: 8%; */
}
.ft-content {
  position: absolute;
  width: 100%;
  height: 100vh;
  background-color: rgb(255, 255, 255);
  margin: 0%;
  margin-top: 58px;
  min-height: 700px;
}
</style>
