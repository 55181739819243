<template>
  <div class="home">
    <div class="title">
      <h2 class="display-6 program-font-style">目的(呼吸)</h2>
    </div>
    <div class="text-style">
      <p class="fw-normal font-style">
        <span class="span-style span-start">プログラムの最初に行うことで、筋肉の過緊張を軽減することが目的です。</span>
        <span>アライメントや姿勢を改善するためには、トレーニングの前半に以下の３つの観点からアプローチをする必要があります。</span>
      </p>
      <p class="fw-normal font-style">
        <span class="span-style span-ul">神経的：</span>
        <span>脳からの命令による筋肉の弛緩、緊張</span>
        <br />
        <span class="span-style span-ul">構造的：</span>
        <span>骨の配列による筋肉の弛緩、緊張</span>
        <br />
        <span class="span-style span-ul">軟部組織：</span>
        <span>筋肉、腱の適切な緊張を保持</span>
      </p>

      <p class="fw-normal font-style">
        <span>＊これらは、独立して問題を引き起こすわけではなく、それぞれが複合的に関係して姿勢不良や機能不全を引き起こします。</span>
      </p>
      <img class="image-style" src="@/assets/commentary/lung.jpg" />

      <p class="fw-normal font-style">
        <span>これらを適切な状態に保ったのち、機能面へのアプローチを行っていきます。</span>
        <span class="span-style span-end">腹式呼吸により、副交感神経を刺激し軟部組織の過緊張を最小限に抑えることができます。</span>
      </p>
      <p class="fw-normal font-style">
        <span>また、正しい呼吸がやりずらい場合は、</span>
        <span class="span-style span-end">普段慣れた姿勢とは別の姿勢で行うことによって、適切な呼吸を行うことができるため、さまざまな姿勢で呼吸を行います。</span>
        <br />
        <span>また、スタティックストレッチをしながらも呼吸を止めることによって、筋肉が緊張するため、腹式呼吸を意識したスタティックストレッチを行うことによって筋肉の弛緩を促すためにとても効果的です。</span>
      </p>

      <div class="title">
        <h2 class="display-6 program-font-style">呼吸の基本姿勢</h2>
      </div>
      <p class="fw-normal font-style">
        <span>①仰臥位</span>
      </p>
      <p class="fw-normal font-style">
        <span>②３ヶ月仰臥位</span>
      </p>
      <p class="fw-normal font-style">
        <span>③６ヶ月仰臥位</span>
      </p>
      <p class="fw-normal font-style">
        <span>④トランクツイスト</span>
      </p>
      <p class="fw-normal font-style">
        <span>⑤クロコダイルブリージング</span>
      </p>
      <p class="fw-normal font-style">
        <span>⑥チャイルドポーズ</span>
      </p>
      <img class="image-style" src="@/assets/commentary/alignment.png" height="380"/>

      <div class="sapace-style"></div>
      <div class="title">
        <h2 class="display-6 program-font-style">目的(アライメント改善)</h2>
      </div>
      <p class="fw-normal font-style">
        <span class="span-style span-start">関節のアライメント</span>
        <span>（関節の中立位、またはニュートラル）を適切にすることによって、関節周辺の筋肉群の緊張をバランス良く一定に保つことが目的です。</span>
      </p>
      <p class="fw-normal font-style">
        <span>アライメントが適切であるとは、</span>
        <span class="span-style span-end">関節内の部分的な負荷が最低限かつ、力の伝達がもっとも効果的な状態</span>
        <span>を差します。</span>
      </p>
      <img class="image-style" src="@/assets/commentary/born.jpg" height="240"/>
      <p class="fw-normal font-style">
        <span>正しい姿勢にあることは、すべての関節のアライメントが適切にあり、必要最低限の力で同じ動作を行うことができる利点があります。いわゆる、“エコ”な動作ができるということです。</span>
      </p>
      <p class="fw-normal font-style">
        <span class="span-style span-start">アライメントが不適切な状態</span>
        <span>では、特定の筋肉群が過緊張にあるため、繰り返し負荷のかかった動作を行うことで</span>
        <span class="span-style span-end">慢性的な障害につながる可能性があります。</span>
        <span>特に、肩甲骨、胸郭、骨盤、股関節のポジションを最適化してから、次のカテゴリに当たる「スタティックストレッチ」、そして、主要筋群の活性化を実施する流れとなります。</span>
      </p>

      <div class="sapace-style"></div>
      <div class="title">
        <h2 class="display-6 program-font-style">部位別のアライメント改善</h2>
      </div>
      <p class="fw-normal font-style">
        <span>①肩甲骨</span>
        <br />
        <span>肩甲骨の下制を促すためのCKCエクサイズ</span>
        <br />
        <br />
        <span>②胸郭／骨盤帯</span>
        <br />
        <span>IAPやドローインを意識したエクササイズ</span>
        <br />
        <br />
        <span>③股関節</span>
        <br />
        <span>股関節の内旋/外旋、屈曲/伸展、内転/外転の筋バランス</span>
      </p>

      <div class="sapace-style"></div>
      <div class="title">
        <h2 class="display-6 program-font-style">アラインメント改善プログラムの種類</h2>
      </div>
      <p class="fw-normal font-style">
        <span>①ストレッチポール</span>
      </p>
      <p class="fw-normal font-style">
        <span>②スイングストレッチ</span>
      </p>
      <p class="fw-normal font-style">
        <span>③リアライン</span>
      </p>
      <p class="fw-normal font-style">
        <span>④リセットプログラム</span>
      </p>
      <img class="image-style" src="@/assets/commentary/reset_program.png" height="230"/>

      <div class="sapace-style"></div>
      <div class="title">
        <h2 class="display-6 program-font-style">リセットプログラム</h2>
      </div>
      <p class="fw-normal font-style">
        <span class="span-style span-start">運動発達学に基づいて身体アライメントを整え動きやすい状態にリセットします。</span>
        <span>肩甲骨帯、体幹の安定性を促すことにより、姿勢改善、また、股関節、脊椎、肩関節のスムーズな動きづくりを行います。</span>
      </p>
      <p class="fw-normal font-style">
        <span class="span-style span-start">動作に伴って呼吸を行うことが大切です。</span>
        <span>表層の筋肉を使って動くのではなく、固定点に加重することによって動きを促していきます。</span>
      </p>

      <div class="card video-div-style">
        <video :src="video_url_1" controls preload="metadata" class="video-style" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ImprovementAbout",
  props: {},
  data() {
    return {
      video_url_1:
        "https://firebasestorage.googleapis.com/v0/b/fitnesscamp-428f2.appspot.com/o/reset_program%2Fcontent%2Fmovie%2FFCresetprogram.mp4?alt=media&token=650ed04a-f657-4aa4-8b68-eb975fac32d5"
    };
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.title {
  padding-top: 4%;
  text-align: left;
}
.home > img {
  padding-top: 1%;
  width: 96%;
  height: auto;
  min-height: 100px;
}
.home > img::before {
  content: "";
  display: block;
  padding-top: calc(((720 / 1280) * 100%));
}
.program-font-style {
  font-weight: 600;
  color: #000;
}
.text-style {
  padding-top: 2%;
  text-align: left;
  font-size: 14px;
  font-weight: 500;
}
.font-style {
  letter-spacing: 1px;
  line-height: 25px;
  padding-top: 8px;
}
.span-style {
}
.span-start {
  background: linear-gradient(transparent 50%, #fcf49c 0%);
}
.span-end {
  margin-left: 0px;
  margin-right: 0px;
  background: linear-gradient(transparent 50%, #fcf49c 0%);
}
.span-ul {
  color: #000;
  font-size: 14px;
  font-weight: 600;
  margin-right: -5px;
}
.image-style {
  background-color: #000;
  width: 98%;
  margin-bottom: 5%;
}

.sapace-style {
  margin-top: 10%;
}

.video-div-style {
  height: auto;
  width: auto;
  margin-left: 2%;
  margin-right: 2%;
  border: 0;
  background-color: rgb(233, 233, 233);
}
.video-style {
  height: 250px;
}

</style>
