<template>
  <div>
    <div class="home">
      <br />
      <div class="about">
        <a href="#" v-on:click.stop.prevent="openAbotModal(1)" class="alert-link">>>リセットプログラム全体</a>
      </div>
      <div class="about">
        <a href="#" v-on:click.stop.prevent="openAbotModal(2)" class="alert-link">>>モニタリング</a>
      </div>

      <div v-if="isModal">
        <div class="popContainer">
          <div v-if="modalValue===1">
            <div class="heard-style">
              <h2>リセットプログラム</h2>
            </div>
            <div class="card card-modal">
              <div class="card-body">
                <div class="sapace-style"></div>
                <div class="title">
                  <h2 class="display-6 program-font-style">リセットプログラム</h2>
                </div>
                <p class="fw-normal font-style">
                  <span class="span-style span-start">運動発達学に基づいて身体アライメントを整え動きやすい状態にリセットします。</span>
                  <span>肩甲骨帯、体幹の安定性を促すことにより、姿勢改善、また、股関節、脊椎、肩関節のスムーズな動きづくりを行います。</span>
                </p>
                <p class="fw-normal font-style">
                  <span class="span-style span-start">動作に伴って呼吸を行うことが大切です。</span>
                  <span>表層の筋肉を使って動くのではなく、固定点に加重することによって動きを促していきます。</span>
                </p>

                <div class="card video-div-style">
                  <video :src="video_url_1" controls preload="metadata" class="video-style" />
                </div>
                <br />
                <br />
              </div>
            </div>
          </div>

          <div v-else-if="modalValue===2">
            <div class="heard-style">
              <h2>モニタリング</h2>
            </div>
            <div class="card card-modal">
              <div class="card-body">
                <MonitoringTheory />
                <br />
                <br />
              </div>
            </div>
          </div>



          <div class="close-button">
          <div class="close-button-div">
            <a
              href="#"
              v-on:click.stop.prevent="closeAbotModal()"
              class="alert-link close-button-text"
            >閉じる</a>
          </div>
        </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MonitoringTheory from "../commentary/MonitoringTheory";
export default {
  name: "ResetProgramAbout",
  components: {
    MonitoringTheory
  },
  props: {},
  data() {
    return {
      isModal: false,
      modalValue: 0,
      video_url_1:
        "https://firebasestorage.googleapis.com/v0/b/fitnesscamp-428f2.appspot.com/o/reset_program%2Fcontent%2Fmovie%2FFCresetprogram.mp4?alt=media&token=650ed04a-f657-4aa4-8b68-eb975fac32d5"
    };
  },
  methods: {
    openAbotModal(value) {
      this.modalValue = value;
      this.isModal = true;
    },
    closeAbotModal() {
      this.modalValue = 0;
      this.isModal = false;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.heard-style {
  padding-top: 14px;
  height: 50px;
  background-color: #04545c;
  border: 0;
  margin-top: 5%;
  margin-left: 8%;
  margin-right: 8%;
}
.heard-style img {
  float: left;
  margin-left: 2%;
}
.heard-style > h2 {
  color: rgb(255, 255, 255);
  margin-right: 4%;
  font-weight: 900;
}
.close-button {
  height: 50px;
  background-color: #ffffff;
  border: 0;
  margin-left: 8%;
  margin-right: 8%;
}
.close-button-div {
  background-color: rgb(255, 255, 255);
  text-align: right;
  margin-right: 4%;
  padding-top: 12px;
}
.close-button-text {
  font-size: 16px;
  font-weight: 400;
  color: rgb(163, 163, 163);
}

.about {
  text-align: left;
  margin-top: 2%;
  margin-left: 2%;
  margin-bottom: 3%;
}
.about > a:link,
a:visited,
a:hover,
a:active {
  text-decoration: none;
  font-size: 14px;
  font-weight: 400;
  color: rgb(163, 163, 163);
}

.popContainer {
  z-index: 2;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(43, 43, 43, 0.3);
}
.card-modal {
  overflow: auto;
  z-index: 2;
  margin-top: 0%;
  margin-left: 8%;
  margin-right: 8%;
  height: 75vh;
  background-color: rgb(255, 255, 255);
}

.monitoring {
  margin-bottom: 5%;
  background-color: #000000;
}
.program-font-style {
  font-weight: 600;
  text-align: left;
  color: #000;
}
.text-style {
  padding-top: 2%;
  text-align: left;
  font-size: 14px;
  font-weight: 500;
}
.font-style {
  letter-spacing: 1px;
  line-height: 25px;
  padding-top: 8px;
  font-size: 14px;
  text-align: left;
}
.span-style {
}
.span-start {
  background: linear-gradient(transparent 50%, #fcf49c 0%);
}
.span-end {
  margin-left: -5px;
  margin-right: -5px;
  background: linear-gradient(transparent 50%, #fcf49c 0%);
}
.video-div-style {
  height: auto;
  width: auto;
  margin-left: 2%;
  margin-right: 2%;
  border: 0;
  background-color: rgb(233, 233, 233);
}
.video-style {
  height: 250px;
}

.close-button {
  height: 50px;
  background-color: #ffffff;
  border: 0;
  margin-left: 8%;
  margin-right: 8%;
}
.close-button-div {
  background-color: rgb(255, 255, 255);
  text-align: right;
  margin-right: 4%;
  padding-top: 12px;
}
.close-button-text {
  font-size: 16px;
  font-weight: 400;
  color: rgb(163, 163, 163);
}
</style>
