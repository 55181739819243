<template>
  <div class="fullview">
    <div class="loading-div">
      <p class="h4 message-color" v-if="page === 1">
        更新失敗しました。<br />入力内容をご確認いただき、もう一回更新してください。
      </p>
      <p class="h4 message-color" v-if="page === 2">
        クラス申請失敗しました。<br />入力内容をご確認いただき、もう一回申請してください。
      </p>
      <button type="button" class="button-style" @click="doAlertCancel">
        キャンセル
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "Alert",
  props: {
    page: String,
  },
  components: {},
  methods: {
    doAlertCancel() {
      this.$emit("do-alert-cancel");
    },
  },
};
</script>

<style scoped>
.fullview {
  z-index: 10;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
}

.loading-div {
  padding: 10px;
  margin-top: 200px;
  background-color: #ffffff;
  width: 300px;
  height: 200px;
  margin-right: auto;
  margin-left: auto;
  border-radius: 10px;
}
.message-color {
  padding: 20px 10px 10px 10px;
  font-weight: 550;
  color: rgb(207, 0, 0);
}
.button-style {
  margin-top: 24px;
  width: 117px;
  height: 41px;
  background: #ffffff;
  border: 1px solid #b7b7b7;
  border-radius: 6px;
  font-size: 16px;
  text-align: center;
  color: #717171;
}
</style>
