<template>
  <div class="navigation">
    <div class="d-flex justify-content-around" v-if="page === 1">
      <div class="img-style">
        <router-link to="/">
          <!-- home画面へ -->
          <img src="@/assets/navigation/active/home02.svg" class="active" />
        </router-link>
      </div>
      <div class="img-style">
        <!-- 遷移先指定 OgawaK    -->
        <router-link to="/newProgram">
          <!-- 検索画面へ -->
          <img src="@/assets/navigation/news.svg" />
        </router-link>
      </div>
      <div class="img-style">
        <!-- <a href="https://class.fitnesscamp.jp/#/"> -->
        <a href="http://class.fitnesscamp.jp?trainer=true/#/">
          <!-- 地図へ -->
          <img src="@/assets/navigation/map.svg" />
        </a>
      </div>
      <div class="img-style">
        <router-link to="/profile">
          <!-- プロフィールへ -->
          <img src="@/assets/navigation/profile.svg" />
        </router-link>
      </div>
    </div>

    <div class="d-flex justify-content-around" v-if="page === 2">
      <div class="img-style">
        <router-link to="/">
          <img src="@/assets/navigation/home02.svg" />
        </router-link>
      </div>
      <div class="img-style">
        <router-link to="/newProgram">
          <img src="@/assets/navigation/active/news.svg" class="active" />
        </router-link>
      </div>
      <div class="img-style">
        <a href="http://class.fitnesscamp.jp?trainer=true/#/">
          <img src="@/assets/navigation/map.svg" />
        </a>
      </div>
      <div class="img-style">
        <router-link to="/profile">
          <img src="@/assets/navigation/profile.svg" />
        </router-link>
      </div>
    </div>

    <div class="d-flex justify-content-around" v-if="page === 3">
      <div class="img-style">
        <router-link to="/">
          <img src="@/assets/navigation/home02.svg" />
        </router-link>
      </div>
      <div class="img-style">
        <router-link to="/newProgram">
          <img src="@/assets/navigation/news.svg" />
        </router-link>
      </div>
      <div class="img-style">
        <a href="http://class.fitnesscamp.jp?trainer=true/#/">
          <img src="@/assets/navigation/active/map.svg" class="active" />
        </a>
      </div>
      <div class="img-style">
        <router-link to="/profile">
          <img src="@/assets/navigation/profile.svg" />
        </router-link>
      </div>
    </div>

    <div class="d-flex justify-content-around" v-if="page === 4">
      <div class="img-style">
        <router-link to="/">
          <img src="@/assets/navigation/home02.svg" />
        </router-link>
      </div>
      <div class="img-style">
        <router-link to="/newProgram">
          <img src="@/assets/navigation/news.svg" />
        </router-link>
      </div>
      <div class="img-style">
        <a href="http://class.fitnesscamp.jp?trainer=true/#/">
          <img src="@/assets/navigation/map.svg" />
        </a>
      </div>
      <div class="img-style">
        <router-link to="/profile">
          <img src="@/assets/navigation/active/profile.svg" class="active" />
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Navigation",
  props: {
    page: String,
  },
  methods: {
    sample() {},
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.navigation {
  width: 100%;
  height: 70px;
  bottom: 0; /*下に固定*/
  background-color: rgb(245, 245, 245);
  /* display: -webkit-flex; */
  /* display: flex; */
  position: fixed;
  justify-content: space-between;
  bottom: 0;
}
.d-flex {
  height: 100%;
  align-items: center;
}
.img-style img {
  width: 25px;
  height: 25px;
  margin-bottom: 2%;
}

.img-style .active {
  width: 35px;
  height: 35px;
}
</style>
