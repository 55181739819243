<template>
  <div class="home">
    <div class="title">
      <h4 class="display-6 program-font-style">①DNS(Dynamic Neuromuscular Stabilization)</h4>
    </div>

    <div class="text-style">
      <p class="fw-normal font-style">
        <span>発達運動学理論ともいい、運動制御の神経発達の仕組みは、運動機能修復と身体機能の基本となるという考え方。</span>
      </p>
    </div>

    <div class="sapace-style"></div>
    <div class="title">
      <h4 class="display-6 program-font-style">②FMS(Functional Movement Systems)</h4>
    </div>

    <div class="text-style">
      <p class="fw-normal font-style">
        <span>
          様々な対象者の動作パターンを調べ、機能解剖学に基づく一貫性のある基準を用いて問題の有無を判断するスクリーニングとその評価方法、そして問題を修正するために適切なエクササイズを処方する包括的なシステム。
        </span>
      </p>
    </div>

    <div class="sapace-style"></div>
    <div class="title">
      <h4 class="display-6 program-font-style">③Functional Training</h4>
    </div>

    <div class="text-style">
      <p class="fw-normal font-style">
        <span>
          直訳は「機能的なトレーニング」。Vern GambettaとGary Grayは「特定の筋のみを強化する単関節運動はとても機能的とはいえない。それに対し、多くの筋群を動作パターンに統合していく多関節運動は機能的である。」と述べ、Michael 。Boyleは「ファンクショナルトレーニングは“動き”のトレーニングであり、“筋”の強化ではない」としている。
        </span>
      </p>
    </div>

    <div class="sapace-style"></div>
    <div class="title">
      <h4 class="display-6 program-font-style">④Anatomy Trains</h4>
    </div>

    <div class="text-style">
      <p class="fw-normal font-style">
        <span>トム・マイヤーズ氏の著書「アナトミー・トレイン 筋膜解剖 ～筋筋膜経線の解剖～」。</span>
      </p>
    </div>

    <div class="sapace-style"></div>
    <div class="title">
      <h4 class="display-6 program-font-style">⑤MovNat</h4>
    </div>

    <div class="text-style">
      <p class="fw-normal font-style">
        <span>自然環境の中で、12の原則にしたがってトレーニングをすることで本来備わっている能力を顕在化するという理論。</span>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "BasicTheory",
  props: {}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.title {
  padding-top: 4%;
  text-align: left;
}
.home > img {
  padding-top: 1%;
  width: 96%;
  height: auto;
  min-height: 100px;
}
.program-font-style {
  font-weight: 600;
  color: #000;
}
.text-style {
  padding-top: 2%;
  text-align: left;
  font-size: 14px;
  font-weight: 500;
}
.font-style {
  letter-spacing: 1px;
  line-height: 25px;
  padding-top: 8px;
}
.span-style {
}
.span-start {
  background: linear-gradient(transparent 50%, #fcf49c 0%);
}
.span-end {
  margin-left: -4px;
  margin-right: -4px;
  background: linear-gradient(transparent 50%, #fcf49c 0%);
}
.sapace-style {
  margin-top: 10%;
}
.video-div-style {
  height: auto;
  width: auto;
  margin-top: 5%;
  margin-left: 2%;
  margin-right: 2%;
  border: 0;
  background-color: rgb(233, 233, 233);
}
.video-style {
  height: 250px;
}

</style>
