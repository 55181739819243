<template>
  <div>
    <div class="heard-style">
      <h2>FITNESS CAMP</h2>
    </div>
    <div class="home home-style">
      <div class="home-img">
        <img src="@/assets/home/home.png" width="100%" height="300" />

        <div class="start-div">
          <h1>FITNESS CAMP基本解説</h1>
          <div class="login-button">
            <a href="#/commentary" class="btn btn--orange">ベーシック理論編</a>
          </div>
          <div class="login-button" v-if="authority > '1'">
            <a href="#/kids" class="btn kids-button">キッズ理論編</a>
          </div>
          <div class="login-button" v-else>
            <a href="#" class="btn kids-button-gary">キッズ理論編</a>
          </div>
        </div>
      </div>
      <div class="program-text">
        <div class="program-div">
          <h2 class="display-5 program-font-style">公式プログラム</h2>
        </div>
        <div class="monitoring-div">
          <a href="#/monitoring">
            <h4 class="display-5 program-font-style">{{ monitoring }}</h4>
          </a>
        </div>
      </div>
      <div class="program-static">
        <ProgramStatic />
      </div>
      <div class="div-space"></div>
    </div>
    <div class="navigation-style">
      <Navigation :page="1" />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from "@/components/HelloWorld.vue";
import Navigation from "../../components/Navigation";
import ProgramStatic from "../../components/ProgramStatic";
import firebase from "firebase/compat";
export default {
  name: "Home",
  components: {
    //HelloWorld,
    Navigation,
    ProgramStatic,
  },
  created: function() {
    this.getUserAuthority();
  },
  data() {
    return {
      monitoring: "<モニタリング>",
      authority: "",
      class_info: {
        lesson_name: "テスト１",
        class_name: "テスト１",
        coach_name: "テスト１",
        location_address: "テスト１",
      },
    };
  },
  methods: {
    getUserAuthority() {
      let self = this;
      firebase.auth().onAuthStateChanged(function(user) {
        firebase
          .firestore()
          .collection(self.glb_table.users)
          .doc(user.uid)
          .get()
          .then((snap) => {
            self.authority = snap.data().authority;
          });
      });
    },
  },
};
</script>
<style scoped>
.heard-style {
  position: fixed;
  z-index: 2;
  margin-top: 0%;
  padding-top: 18px;
  width: 100%;
  height: 58px;
  background-color: #04545c;
}
.navigation-style {
  width: 100%;
  bottom: 0;
}
.heard-style > h2 {
  color: rgb(255, 255, 255);
  margin-right: 4%;
  font-weight: 900;
}
.home-img {
  width: 100%; /* 画像の横幅（自由に指定） */
  position: relative;
}
.home-img img {
  margin-top: 58px;
  width: 100%;
}
.home-img > .start-div {
  color: #fff;
  text-align: center;
  width: 100%; /* 画像の幅に合わせるため */
  height: 50%;
  padding: 0 0;
  margin-bottom: 0%;
  position: absolute; /* relativeの要素を基準にする */
  left: 0; /* 基準からみて左寄せ */
  bottom: 0; /* 基準からみて下寄せ */
  background: rgba(255, 255, 255, 0.7); /* 半透明の背景。 */
}
.home-img > .start-div > h1 {
  padding-top: 4%;
  font-weight: 900;
  color: rgb(0, 0, 0);
}

.btn,
a.btn,
button.btn {
  margin-top: 2%;
  margin-bottom: 2%;
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 1.5;
  position: relative;
  display: inline-block;
  padding: 1rem 4rem;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  text-align: center;
  vertical-align: middle;
  text-decoration: none;
  letter-spacing: 0.1em;

  border-radius: 0.5rem;
  width: 50%;
  height: 50px;
}
.btn--orange,
a.btn--orange {
  color: #212529;
  color: rgb(255, 255, 255);
  background-color: #00645c;
}
.btn--orange:hover,
a.btn--orange:hover {
  color: #fff;
  background: #00645c;
}
.kids-button {
  color: #ffffff;
  background: #fc6404;
}
.kids-button-gary {
  color: #e9e9e9;
  background: #949494;
}
.program-font-style {
  font-weight: 600;
}
.program-text {
  margin-top: 2%;
}
.program-div {
  float: left;
  text-align: left;
  padding-left: 2%;
}
.program-div > .program-font-style {
  color: rgb(0, 0, 0);
}
.monitoring-div {
  float: right;
  text-align: right;
  padding-right: 2%;
}

.monitoring-div > a:link,
.monitoring-div > a:visited,
.monitoring-div > a:hover,
.monitoring-div > a:active {
  text-decoration: none;
  font-size: 14px;
  font-weight: 400;
  color: rgb(0, 0, 0);
}

.div-space {
  width: 100%;
  height: 400px;
}
.login-button {
}
.login-button a {
  height: 45px;
  text-align: center;
  padding: auto;
  font-size: 16px;
  width: 60%;
}
</style>
