<template>
  <div>
    <div>
      <h2 class="display-5 program-font-style">>プログラム（公式）</h2>
      <div class="mk-program-list">
        <div class="d-flex justify-content-around mk-program-icon">
          <div v-for="content in programList" v-bind:key="content.id">
            <div @click="doProgramCreated(content.uid)">
              <label>
                <img :src="content.home_image.image_url" />
                <br />
                <div class="programlist-font">
                  <h4 class="display-5 program-font-style">
                    {{ content.name }}
                  </h4>
                </div>
              </label>
            </div>
          </div>
        </div>
      </div>
      <!-- </div> -->
    </div>
    <div>
      <h2 class="display-5 program-font-style">>プログラム（個人）</h2>
      <div class="mk-program-list">
        <div class="d-flex justify-content-around mk-program-icon">
          <div v-for="content in informalProgramList" v-bind:key="content.id">
            <label @click="doProgramCreated(content.uid)">
              <div>
                <img :src="content.home_image.image_url" />
                <div class="programlist-font">
                  <h4 class="display-5 program-font-style">
                    {{ content.name }}
                  </h4>
                </div>
              </div>
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "firebase/compat";
export default {
  name: "Modal",
  components: {},
  data() {
    return {
      programList: [],
      informalProgramList: [],
    };
  },
  created: function() {
    this.getProgramList();
  },
  methods: {
    doProgramCreated(uid) {
      this.mk_program_storage.mk_copy_to_program_uid = uid;
      this.glb_set_localStorage_for_program(
        this.mk_program_storage_name,
        this.mk_program_storage
      );
      // this.glb_set_localStorage("mk_program_uid", uid);
      this.$router.push({ path: "/programCreated" });
    },
    getProgramList() {
      localStorage.removeItem("program_uid");
      let self = this;
      firebase
        .firestore()
        .collection(self.glb_table.program)
        .get()
        .then(function(querySnapshot) {
          querySnapshot.forEach(function(doc) {
            firebase
              .firestore()
              .collection(self.glb_table.program)
              .doc(doc.id)
              .get()
              .then((snap) => {
                if (snap.data().type == self.glb_mk_program_type.official) {
                  self.programList.push({
                    uid: doc.id,
                    name: snap.data().name,
                    home_image: snap.data().home_image,
                    type: snap.data().type,
                  });
                }
                if (snap.data().type == self.glb_mk_program_type.informal) {
                  self.informalProgramList.push({
                    uid: doc.id,
                    name: snap.data().name,
                    home_image: snap.data().home_image,
                    type: snap.data().type,
                  });
                }
              });
          });
        });
    },
  },
};
</script>

<style scoped>
img {
  width: 150px;
  height: 150px;
  padding: 10px;
}
.mk-program-list {
  width: 100%;
  margin: auto;
}
.mk-program-icon {
  text-align: center;
  width: 84%;
  margin: auto;
  /* padding: 8%; */
}
.program-font-style {
  color: rgb(0, 0, 0);
  font-weight: bold;
  font-size: 16px;
  padding: 14px;
}
</style>
