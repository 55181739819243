<template>
  <div>
    <div class="comfirmEllent" v-show="confirmModal">
      <ConfirmModal />
    </div>
    <div class="about" v-if="ClassHeader">
      <Header :page="7" @my-click="reflectNum" />
      <!-- <ClassHeader :page="4" /> -->
    </div>
    <div class="popContainer-edit" v-if="class_edit">
      <classRegisterAgain :class_data="class_info" />
    </div>
    <div v-if="menuClick">
      <div class="popContainer" @click="closeMenu()">
        <div class="menu">
          <div class="choices">
            <table class="choices-table" @click="classRegister()">
              <tr>
                <td class="choices-icon">
                  <img
                    src="@/assets/profile/授業風景のアイコン.svg"
                    height="20"
                    width="20"
                  />
                </td>
                <td class="choices-title">
                  <h4 class="display-6 choices-text">クラス再開</h4>
                </td>
              </tr>
            </table>

            <!-- <a href="#/profile/mypage" class="a-style">
              <table class="choices-table">
                <tr>
                  <td class="choices-icon">
                    <img
                      src="@/assets/commentary/edit_black.svg"
                      height="20"
                      width="20"
                    />
                  </td>
                  <td class="choices-title">
                    <h4 class="display-6 choices-text">マイページ編集</h4>
                  </td>
                </tr>
              </table>
            </a>

            <a href="#/profile/emailEdit" class="a-style">
              <table class="choices-table">
                <tr>
                  <td class="choices-icon">
                    <img src="@/assets/login/mail.png" height="20" width="20" />
                  </td>
                  <td class="choices-title">
                    <h4 class="display-6 choices-text">メールアドレス変更</h4>
                  </td>
                </tr>
              </table>
            </a>
            <table class="choices-table">
              <tr>
                <td class="choices-icon">
                  <img
                    src="@/assets/login/password.png"
                    height="20"
                    width="20"
                  />
                </td>
                <td class="choices-title" @click="openModal(1)">
                  <h4 class="display-6 choices-text">パスワード変更</h4>
                </td>
              </tr>
            </table>

            <a href="#/profile/userinfo" class="a-style">
              <table class="choices-table">
                <tr>
                  <td class="choices-icon">
                    <img
                      src="@/assets/navigation/profileMenu.svg"
                      height="20"
                      width="20"
                    />
                  </td>
                  <td class="choices-title">
                    <h4 class="display-6 choices-text">登録情報確認・更新</h4>
                  </td>
                </tr>
              </table>
            </a>
            <table class="choices-table">
              <tr>
                <td class="choices-icon">
                  <img
                    src="@/assets/profile/logout.svg"
                    height="20"
                    width="20"
                  />
                </td>
                <td class="choices-title" @click="openModal(2)">
                  <h4 class="display-6 choices-text">ログアウト</h4>
                </td>
              </tr>
            </table> -->
          </div>
        </div>
      </div>
    </div>
    <div class="body-page" v-show="!confirmModal">
      <div v-if="class_info != null">
        <img
          :src="class_info.class_icon.image_url"
          id="class-img"
          class="img-fluid"
          alt="Responsive image"
        />
        <div class="class-info-card">
          <table class="table table-bordered">
            <tbody>
              <tr>
                <th scope="col" class="th-title">レッスン</th>

                <td class="detail">{{ class_info.lesson_name }}</td>
              </tr>
              <tr>
                <th scope="col" class="th-title">クラス名</th>
                <td class="detail">{{ class_info.class_name }}</td>
              </tr>
              <tr>
                <th scope="col" class="th-title">コーチ名</th>
                <td class="detail">{{ class_info.coach_name }}</td>
              </tr>

              <!-- 定期開催 -->
              <tr v-if="class_info.organize_type === '定期'" class="orgTime">
                <th scope="col" class="th-title">
                  日時(定期)
                </th>
                <td class="detail">
                  <div
                    v-for="time in class_info.fixed_term_organize"
                    :key="time.id"
                    class="orgTime"
                  >
                    <div
                      v-if="time.start_time !== '' || time.end_time !== ''"
                      class="date-font"
                    >
                      {{ time.date }}曜日：{{ time.start_time }}~{{
                        time.end_time
                      }}
                    </div>
                    <br />
                  </div>
                </td>
              </tr>
              <tr v-else>
                <th scope="col" class="th-title">
                  日時(不定期)
                </th>
                <td class="detail">
                  <div
                    v-for="time in class_info.irregular_organize"
                    :key="time.id"
                  >
                    {{ toTime(time.start_time) }}~{{ justTime(time.end_time) }}
                  </div>
                </td>
              </tr>
              <tr>
                <th scope="col" class="th-title">場所</th>
                <td class="detail">
                  {{ class_info.location }}
                </td>
              </tr>
              <tr>
                <th scope="col" class="th-title">住所</th>
                <td class="detail">
                  〒{{ class_info.location_zip }}
                  <br />
                  {{ class_info.location_address }}
                </td>
              </tr>
              <tr>
                <th scope="col" class="th-title">URL</th>
                <td class="detail">
                  <div @click="openDetail()" id="detailLink">
                    詳細
                  </div>
                </td>
              </tr>
              <tr>
                <th scope="col" class="th-title">PDF資料</th>
                <td class="detail">
                  <div @click="openPDFDetail()" id="detailLink">
                    資料
                  </div>
                </td>
              </tr>
              <tr>
                <th scope="col" class="th-title">料金</th>
                <td class="detail">
                  ￥
                  {{ class_info.price }}
                </td>
              </tr>
              <tr>
                <th scope="col" class="th-title">申込方法</th>
                <!-- 配列要素 から文字列に変更された為 修正対象 12/16 OgawaK-->
                <td class="detail">
                  {{ class_info.application_process }}
                </td>
              </tr>
              <tr>
                <th scope="col" class="th-title">お問合せ先</th>
                <td class="detail">
                  <div>
                    <!-- 確認 12/16 OgawaK -->
                    {{ class_info.contact }}
                  </div>
                </td>
              </tr>
              <tr>
                <th scope="col" class="th-title">備考</th>
                <!-- 配列要素 から文字列に変更された為 修正対象 12/16 OgawaK-->
                <td class="detail">
                  {{ class_info.remarks }}
                </td>
              </tr>
            </tbody>
          </table>
          <ImageListView :image_view_list="class_info.profile_image_list" />
          <br />
          <br />
        </div>
      </div>
      <!-- 
      <button
        id="entry-btn"
        type="button"
        class="btn btn-info"
        @click="openconfirm()"
      >
        申込み
      </button> -->
    </div>
  </div>
</template>
<script>
// import ClassHeader from "@/components/class/class_header";
import moment from "moment";
import ConfirmModal from "@/components/class/modal/class_Enttry_comfirm";
import ImageListView from "@/components/tool/ImageListView";
import firebase from "firebase/compat";
import Header from "@/components/Header";
import classRegisterAgain from "@/components/class/modal/ClassRegisterAgain";
export default {
  // 前画面から引き継ぎData
  props: [],

  name: "ClassInFoEdit",
  components: {
    // ClassHeader,
    Header,
    ConfirmModal,
    ImageListView,
    classRegisterAgain,
    // Datepicker,
  },
  data() {
    return {
      image_src: null,
      memoList: [],
      body: true,
      ClassHeader: true,
      confirmModal: false,
      date: new Date(),
      add_image_url:
        "https://firebasestorage.googleapis.com/v0/b/fitnesscamp-428f2.appspot.com/o/user_upload%2Fimage-add.svg?alt=media&token=7006d278-166f-4675-8e09-81fdd569ca1d",
      dateSelect: "定期",
      imageList: [],
      deleteImage: false,
      class_info: null,
      menuClick: false,
      menuClickVar: 0,
      class_edit: false,
    };
  },
  async created() {
    this.getDateTime();
    //クラスIDでDB検索 (UID)表示するデータ取得
    await this.getClassInfo();
  },
  methods: {
    classRegister() {
      // let classID = localStorage.getItem("classID");
      this.class_edit = true;
      // alert(classID);
    },
    reflectNum(value) {
      if (value == 0) {
        this.menuClickVar = 1;
        sessionStorage.setItem("menu_click", this.menuClickVar);
        this.menuClick = false;
      } else {
        this.menuClickVar = 0;
        sessionStorage.setItem("menu_click", this.menuClickVar);
        this.menuClick = true;
      }
    },
    closeMenu() {
      this.menuClickVar = 1;
      sessionStorage.setItem("menu_click", this.menuClickVar);
      this.menuClick = false;
    },
    async getClassInfo() {
      // DBからクラス情報取得 LocalStorage使用しなくなる可能性あるため、再度DBから取得
      let self = this;
      let classID = localStorage.getItem("classID");
      await firebase
        .firestore()
        .collection(self.glb_table.class)
        //クラスID
        .doc(classID)
        .get()
        .then((snap) => {
          self.class_info = snap.data();
        });
      self.glb_set_localStorage("classInfo", JSON.stringify(self.class_info));
    },

    // 時間フォーマット FullData
    toTime(timeValue) {
      let TIME = this.toDateTime(timeValue);
      return TIME;
    },
    // 時間フォーマット ShortTime  [19:00]
    justTime(timeValue) {
      let TIME = this.toJustTime(timeValue);
      return TIME;
    },
    openconfirm() {
      this.confirmModal = true;

      this.ClassHeader = false;
    },
    openPDFDetail() {
      const self = this;
      self.$router.push(window.open(self.class_info.pdf, "_blank"));
    },
    // 開催クラス詳細リンク newTabでopen
    openDetail() {
      const self = this;
      self.$router.push(window.open(self.class_info.url, "_blank"));
    },

    getDate(value, index) {
      this.class_info.irregular_organize[index].date = value;
    },
    getDateTime() {
      this.start_date_h_option = this.glb_get_time_h();
      this.start_date_m_option = this.glb_get_time_m();
    },
    plusDate(index) {
      let datalist = {
        date: moment(new Date()).format("YYYY/MM/DD"),
        start_time: "12:00",
        end_time: "",
      };
      this.class_info.irregular_organize.splice(index + 1, 0, datalist);
    },
    deleteDate(index) {
      this.class_info.irregular_organize.splice(index, 1);
    },
    async getAddress() {
      this.class_info.location_address = await this.glb_get_address(
        this.class_info.location_zip
      );
    },
    profile_image_list_up(event) {
      let self = this;
      let selectPic = event.target.files[0];
      var pos = selectPic.name.split(".").pop();
      let imageCheck = this.glb_validete_img(pos);
      if (imageCheck) {
        self.imageList.push(event.target.files[0]);
        var reader = new FileReader();
        reader.onload = function(event) {
          self.class_info.profile_image_list.push({
            image_url: event.target.result,
            image_name: "",
          });
        };
        reader.readAsDataURL(event.target.files[0]);
      }
    },
  },
};
</script>
<style scoped>
.body-page {
  position: absolute;
  overflow: auto;
  width: 100%;
  height: auto;
  background-color: #ffffff;
  margin: 0%;
  margin-top: 57px;
}
#class-img {
  margin-top: 10px;
  width: 100%;
  min-height: 250px;
  height: auto;
}
/* .class-info-card {
  width: 95%;
  height: auto;
  margin: 12px auto 24px auto;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
} */

.th-title {
  width: 35%;
  font-size: 14px;
  background-color: rgb(214, 214, 214);
  border-top: none;
}

.detail {
  text-align: start;
  padding-left: 3%;
  font-size: 14px;
  border-top: none;
}
#detailLink {
  border-bottom: solid;
  border-color: #868686;
  width: 28px;
  color: rgb(0, 0, 0);
}

#entry-btn {
  margin-top: 10%;
  margin-bottom: 10%;
  width: 30%;
  padding: 0%;
  color: #ffffff;
  background-color: rgb(3, 163, 168);
  text-align: center;
  height: 35px;
  border: none;
  font-size: 16px;
  font-weight: 700;
  border-radius: 10px;
  box-shadow: 3px 6px 9px rgb(139 139 139);
}

.orgTime {
  font-size: 1px;
}
.table {
  font-size: 1.7rem;
  width: 100%;
  border-radius: 10px;
  border: none;
  padding-top: 12px;
  margin: 0% auto 0% auto;
}
.date-font {
  font-size: 14px;
}
.popContainer {
  z-index: 2;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
}
.popContainer-edit {
  z-index: 2;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  overflow: auto;
}
.menu {
  float: right;
  margin-top: 59px;
  width: 70%;
  height: 80%;
  background-color: #ffffff;
}
.qualification {
  padding-right: 10px;
}

.qualification-div a {
  display: inline-block;
  margin: 0 0.1em 0.6em 0;
  padding: 0.8em;
  line-height: 1;
  text-decoration: none;
  background: #ededed;
  font-size: 12px;
  font-weight: 500;
  border-radius: 8px;
  box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.16);
}
.qualification-div {
  display: inline-flex;
  padding: 4px;
  overflow: auto;
}
.choices .choices-table {
  width: 100%;
  margin-top: 1px;
  height: 50px;
  background: #fff;
  text-align: center;
  box-shadow: 3px 9px 9px rgba(0, 0, 0, 0.16);
}
.choices .choices-table .choices-icon {
  width: 20%;
  text-align: center;
}
.choices .choices-table .choices-title {
  width: 70%;
  text-align: left;
}
.choices .choices-text {
  margin-top: 2%;
  background-color: rgb(255, 255, 255);
}
.choices-table :active {
  background-color: rgb(223, 223, 223);
}
a:visited {
  color: inherit;
}
a {
  color: #000;
}
</style>
