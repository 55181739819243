<template>
  <div>
    <div class="heard-style">
      <a href="#">
        <img src="@/assets/home/yajirusi.svg" width="20" height="20" />
      </a>
      <h2>KIDS理論編</h2>
    </div>
    <div class="home home-style">
      <div class="home-img d-flex justify-content-between">
        <div class="profile-title">
          <p class="h4 title-1">【監修者】</p>
          <p class="h4 title-2">小俣よしのぶ</p>
          <p class="h4 title-3">Yoshinobu Omata</p>
          <p class="h4 title-4">
            筑波大学スポーツ環境デザインR&Dプロジェクト室 <br />
            Waisportsジャパン研究員
          </p>
        </div>
        <img src="@/assets/kids/01.png" />
      </div>
    </div>
    <div>
      <input
        id="acd-check1"
        class="acd-check"
        type="checkbox"
        v-model="check[0]"
      />
      <label class="acd-label" @click="checkEvent(0)">
        <h2 class="display-5 program-font-style">
          FITNESS CAMPキッズ<br />プログラムコンセプト
        </h2>
      </label>
      <div class="acd-content" v-if="check[0]">
        <KidsTheory :page="1" />
      </div>
      <input
        id="acd-check2"
        class="acd-check"
        type="checkbox"
        v-model="check[1]"
      />
      <label class="acd-label" @click="checkEvent(1)">
        <h2 class="display-5 program-font-style">
          キッズプログラム<br />認定コーチとして考え方
        </h2>
      </label>
      <div class="acd-content" v-if="check[1]">
        <KidsTheory :page="2" />
      </div>
      <input
        id="acd-check3"
        class="acd-check"
        type="checkbox"
        v-model="check[2]"
      />
      <label class="acd-label" @click="checkEvent(2)">
        <h2 class="display-5 program-font-style">
          FITNESS CAMPキッズ<br />理論編
        </h2>
      </label>
      <div class="acd-content" v-if="check[2]">
        <KidsTheory :page="3" />
      </div>
      <input
        id="acd-check4"
        class="acd-check"
        type="checkbox"
        v-model="check[3]"
      />
      <label class="acd-label" @click="checkEvent(3)">
        <h2 class="display-5 program-font-style">
          FITNESS CAMPキッズ<br />指導編
        </h2>
      </label>
      <div class="acd-content" v-if="check[3]">
        <KidsTheory :page="4" />
      </div>
    </div>
    <div class="sapace-style"></div>
  </div>
</template>

<script>
// @ is an alias to /src
import KidsTheory from "@/components/kids/KidsTheory";

export default {
  name: "Commentary",
  components: {
    KidsTheory,
  },
  data() {
    return {
      monitoring: "<モニタリング>",
      check: [
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
      ],
    };
  },
  methods: {
    checkEvent(index) {
      this.check[index] = !this.check[index];
    },
  },
};
</script>
<style scoped>
.heard-style {
  position: fixed;
  z-index: 2;
  margin-top: 0%;
  padding-top: 18px;
  width: 100%;
  height: 58px;
  background-color: #fc6404;
}
.heard-style img {
  float: left;
  margin-left: 2%;
}
.heard-style > h2 {
  color: rgb(255, 255, 255);
  margin-right: 4%;
  font-weight: 900;
}
.profile-title {
  text-align: left;
  padding-left: 4%;
  margin-top: 80px;
  color: #000;
}
.profile-title .title-1 {
  font-size: 14px;
  font-weight: 500;
}
.profile-title .title-2 {
  font-size: 16px;
  font-weight: 550;
}
.profile-title .title-3 {
  font-size: 16px;
  font-weight: 500;
}
.home-img img {
  margin-top: 70px;
  margin-bottom: 24px;
  margin-right: 4%;
  width: 40%;
  height: auto;
}
.acd-check {
  display: none;
  border-bottom: 1px solid rgb(177, 177, 177);
}
.acd-label {
  background: #ffffff;
  color: rgb(0, 0, 0);
  display: block;
  margin-bottom: 1px;
  padding: 16px;
  position: relative;
  font-size: 2em;
  height: auto;
  border-bottom: 1px solid rgb(177, 177, 177);
  text-align: left;
}
.acd-label:after {
  background: #ffffff;
  box-sizing: border-box;
  content: url(../../assets/home/downYblack.svg);
  display: block;
  font-family: "Font Awesome 5 Free";
  height: 50px;
  width: 50px;
  padding: 10px 20px;
  position: absolute;
  right: 0;
  top: 0px;
  height: 55px;
  /* border-bottom: 1px solid rgb(177, 177, 177); */
}
.acd-content {
  border: 1px solid #333;
  display: block;
  height: 0;
  opacity: 0;
  padding: 0 10px;
  transition: 0.5s;
  visibility: hidden;
  border: 0;
}
.acd-check:checked + .acd-label:after {
  content: url(../../assets/home/upYblack.svg);
}
.acd-check:checked + .acd-label + .acd-content {
  height: auto;
  opacity: 1;
  padding: 10px;
  visibility: visible;
  border: 0;
  background-color: #f8f8f8;
  border-bottom: 1px solid rgb(177, 177, 177);
}
.program-font-style {
  font-weight: 600;
  width: 90%;
}
.sapace-style {
  margin-top: 40%;
}
</style>
