<template>
  <div>
    <div class="home">
      <div class="about">
        <a href="#" v-on:click.stop.prevent="openAbotModal()" class="alert-link">>>About</a>
      </div>
      <div v-if="isModal">
        <div class="popContainer">
          <div class="heard-style">
            <h2>6.サーキットトレーニング</h2>
          </div>
          <div class="card card-modal">
            <div class="card-body">
              <CircuitTrainingAbout />
              <br />
              <br />
            </div>
          </div>
          <div class="close-button">
            <div class="close-button-div">
              <a
                href="#"
                v-on:click.stop.prevent="closeAbotModal()"
                class="alert-link close-button-text"
              >閉じる</a>
            </div>
          </div>
        </div>
      </div>

      <div>
        <!-- 説明部分 -->
        <div class="card border-light mb-3 card-style">
          <div class="card-body">
            <p class="card-text title-conten-font">
              最後はサーキットトレーニングです。
              <br />人間の原始的な動きを運動に置き換えて、いろいろな場所へ移動して運動を行なっていきます。
              <br />運動は6人（4-6人）が1組になって行います。
              <br />まずは種目ごとに練習していきましょう。
            </p>
          </div>
        </div>
      </div>

      <div>
        <!-- ビデオ -->
        <div class="card video-div-style">
          <video :src="video_url_1" controls preload="metadata" class="video-style" />
        </div>
        <!-- タイトル -->
        <p class="h4 h4-title">ハンドウォーク</p>
        <!-- 説明 -->
        <div class="card border-light mb-3 card-style">
          <div class="card-body">
            <p class="card-text title-conten-font">
              ハンドウォークは、持っている人は“持つ”“運ぶ”の能力を中心に養い、手をついている人は“這う”や“登る”などを養います。
              <br />持っている人は、姿勢や動きに対するバランスに気をつけましょう。
              <br />手を付いている人は、頭を一緒の位置に保ちながら回りましょう。
            </p>
          </div>
        </div>
        <!-- 目的、動作、ポイントtable -->
        <div>
          <table>
            <tr class="td-th-color_1">
              <th>目的</th>
              <td>
                持つ側：持つ／運ぶ能力
                <br />持たれる側：登る／這う
              </td>
            </tr>
            <tr class="td-th-color_1">
              <th>動作</th>
              <td>
                姿勢
                <br />１．2人1組になり役割を決める
                <br />２．持っている人が360°回転し、下の人がそれに合わせて360°回転する
                <br />３．1周終わったら10m先のポイントに移動
                <br />４．ポイントに移動したら役割を変えハンドウォークを1周行う
                <br />５．2人で元の位置に戻る
                <br />６．2往復繰り返す
                <br />※体力レベルに合わせて持つ位置を変える
                <br />※3人1組になって2人で片足ずつ持ったりなど、人数を変化させたり、地面の形状に合わせて移動したりと状況によって変化させる
              </td>
            </tr>
            <tr class="td-th-color_1">
              <th>ポイント</th>
              <td>
                2人の息を合わせて回転を行う
                <br />1回目と2回目の回転方向を変える
              </td>
            </tr>
          </table>
        </div>
      </div>

      <div>
        <!-- ビデオ -->
        <div class="card video-div-style">
          <video :src="video_url_2" controls preload="metadata" class="video-style" />
        </div>
        <!-- タイトル -->
        <p class="h4 h4-title">V字バランス＆クロール</p>
        <!-- 説明 -->
        <div class="card border-light mb-3 card-style">
          <div class="card-body">
            <p class="card-text title-conten-font">
              次は、V字バランス＆クロールです。
              <br />まずは3人1組になります。
              <br />くぐる人は“這う”と”登る”動作を中心に、V字を作る側は”バランス”能力を中心に養います。
              <br />くぐる人は鉄柵をくぐるイメージで背中が当たらないように注意をして、V字側は足を
              高い位置に合わせて手を話してバランスを保つことを頑張りましょう。
            </p>
          </div>
        </div>
        <!-- 目的、動作、ポイントtable -->
        <div>
          <table>
            <tr class="td-th-color_2">
              <th>目的</th>
              <td>這って移動する能力</td>
            </tr>
            <tr class="td-th-color_2">
              <th>動作</th>
              <td>
                姿勢
                <br />１．3(4)1人組で2人は対になってVじを作る
                <br />２．1(2)が足の下を這って潜る
                <br />３．潜ったら3-5m先まで走って戻ってくる
                <br />４．戻って来たら再度潜る
                <br />５．2-3往復を何秒でできるかチームで競う
                <br />※１列に並んで足の間を這って進んだり、足から這って見たりアレンジをする
              </td>
            </tr>
            <tr class="td-th-color_2">
              <th>ポイント</th>
              <td>動作を急いて散漫にならないようにする</td>
            </tr>
          </table>
        </div>
      </div>
      <div>
        <!-- ビデオ -->
        <div class="card video-div-style">
          <video :src="video_url_3" controls preload="metadata" class="video-style" />
        </div>
        <!-- タイトル -->
        <p class="h4 h4-title">ステップオーバー＆アンダー</p>
        <!-- 説明 -->
        <div class="card border-light mb-3 card-style">
          <div class="card-body">
            <p class="card-text title-conten-font">
              （ステップオーバー＆アンダーステーションに移動して）
              <br />3人1組なって1人がくぐると乗り越えるを繰り返します。
              <br />他の2人は片手でタオルを持ち合って、片足のスクワット動作をしてタオルの高さを腰と膝の高さに上下します。
              <br />くぐる、乗り越える人は“くぐる”の能力や“走る”“歩く”の基礎能力を養い、ハードルを作る側は“バランス”能力や“飛ぶ”の基礎動作を養います。
              <br />くぐる時はお尻を引きながら膝をまげて低い動作を行い、おへそから足を引き揚げて乗り越えましょう。
              <br />ハードル側は、足を揃えた状態で片足でスクワット動作を行いバランスを取りましょう。
            </p>
          </div>
        </div>
        <!-- 目的、動作、ポイントtable -->
        <div>
          <table>
            <tr class="td-th-color_3">
              <th>目的</th>
              <td>くぐる、乗り越える能力</td>
            </tr>
            <tr class="td-th-color_3">
              <th>動作</th>
              <td>
                １．3人1組になって2人はペアになってタオルを持ち片足でハードルを作る
                <br />２．ハードルを作る側は片足スクワット で高さを調整(腰と膝の高さ）する
                <br />３．ハードルの高さに応じて潜ったり乗り越えたりする
                <br />４．30秒(-1分)行い数を数える
                <br />※潜り方や乗り越え方（ジャンプを入れたり）、移動方向などを工夫したり、自然環境や学校環境を利用して課題を作る
              </td>
            </tr>
            <tr class="td-th-color_3">
              <th>ポイント</th>
              <td>立位で潜る場合にはスクワット動作を思い出したり、ニーアップ動作ではハイプランク を思い出したりする</td>
            </tr>
          </table>
        </div>
      </div>

      <div>
        <!-- ビデオ -->
        <div class="card video-div-style">
          <video :src="video_url_4" controls preload="metadata" class="video-style" />
        </div>
        <!-- タイトル -->
        <p class="h4 h4-title">ターン＆ポーズ</p>
        <!-- 説明 -->
        <div class="card border-light mb-3 card-style">
          <div class="card-body">
            <p class="card-text title-conten-font">
              この種目は全員で一緒に行いましょう。
              <br />回転をしてバランスを取る動作は、脳機能（前庭系）を刺激しバランス能力を高めます。
              <br />回転した後に頑張ってバランスを保ちましょう。
              <br />回転のスピードは能力に合わせて調整して大丈夫です。もし気持ちが悪くなったりしたら無理をしないでください。
            </p>
          </div>
        </div>
        <!-- 目的、動作、ポイントtable -->
        <div>
          <table>
            <tr class="td-th-color_4">
              <th>目的</th>
              <td>バランスをとる力</td>
            </tr>
            <tr class="td-th-color_4">
              <th>動作</th>
              <td>
                １．その場で3周回転する
                <br />２．片足で立って5秒程度バランスを取る
                <br />３．逆足も同じように行う
                <br />４．右回りで右足で立つ、左回りで左足で立つ、右回りで右足エアプレーン、左回り左足エアプレーンを各1回ずつ行う
                <br />※回転してから直線を走ったり、ボールをパスしながら走ったりのアレンジも可能。
                <br />丸太や平均台があれば、そのうえを渡ったり、渡りながらボールを暑かったりなどのバリエーションもある。
              </td>
            </tr>
            <tr class="td-th-color_4">
              <th>ポイント</th>
              <td>片足運動で学んだポイントと同様</td>
            </tr>
          </table>
        </div>
      </div>

      <div>
        <!-- ビデオ -->
        <div class="card video-div-style">
          <video :src="video_url_5" controls preload="metadata" class="video-style" />
        </div>
        <!-- タイトル -->
        <p class="h4 h4-title">ジャンプ＆ラン</p>
        <!-- 説明 -->
        <div class="card border-light mb-3 card-style">
          <div class="card-body">
            <p class="card-text title-conten-font">
              次はジャンプ＆ランです。
              <br />様々なジャンプ動作は、“飛ぶ”能力を中心に養います。
              <br />飛び方や着地の仕方によっては“バランス”や“登る”の基礎機能を養ったりします。
              <br />2人1組になってお互いに5m程度距離を取りましょう。
              <br />1人が馬になって、1人が走って行ってまずは180°回転して飛びます。
              <br />お尻を高く、馬に当たらないように飛びましょう。
              <br />飛んだら体の向きを変えて馬跳びで乗り越えましょう。
              <br />そして、元の位置まで走って戻り馬を作りましょう。
            </p>
          </div>
        </div>
        <!-- 目的、動作、ポイントtable -->
        <div>
          <table>
            <tr class="td-th-color_5">
              <th>目的</th>
              <td>飛ぶ能力</td>
            </tr>
            <tr class="td-th-color_5">
              <th>動作</th>
              <td>
                １．2人1組になって1人が馬跳の馬を作る
                <br />２．3-5mの距離を作る
                <br />３．走って行って180°回転するように飛ぶ
                <br />４．戻りは馬跳びをして元いた位置に戻る
                <br />５．交代して同じ動作をする
                <br />６．30秒-1分間に何回できるか数える
                <br />※馬跳の飛び方や、片手をついて飛んだりなど飛び方を工夫したり、石に飛び乗ったりなどの環境も考えて種目を選択する
              </td>
            </tr>
            <tr class="td-th-color_5">
              <th>ポイント</th>
              <td>ムーブメントトレーニングに準じた手のつき方、や飛び方を思い出す</td>
            </tr>
          </table>
        </div>
      </div>

      <div>
        <!-- ビデオ -->
        <div class="card video-div-style">
          <video :src="video_url_6" controls preload="metadata" class="video-style" />
        </div>
        <!-- タイトル -->
        <p class="h4 h4-title">ボールパス＆ランリレー</p>
        <!-- 説明 -->
        <div class="card border-light mb-3 card-style">
          <div class="card-body">
            <p class="card-text title-conten-font">
              ボールパス＆ランリレーです。
              <br />主に“投げる”と“とる”能力を鍛えます。
              <br />5-6人が2つに別れて5〜10mの幅を取り投げる→走る→取るを1分間繰り返し回数を数えます。
              <br />投げる時は相手の中心に向かって体全体を使って投げます。取る側はボールの動きをしっかり追って、ボールに対応してキャッチします。
            </p>
          </div>
        </div>
        <!-- 目的、動作、ポイントtable -->
        <div>
          <table>
            <tr class="td-th-color_6">
              <th>目的</th>
              <td>投げる、獲る能力</td>
            </tr>
            <tr class="td-th-color_6">
              <th>動作</th>
              <td>
                １．5(4-6)人1組が5-10m幅をとって半分に分かれる
                <br />２．1人がボールを投げ走って対面の列の後ろに並ぶ
                <br />３．ボールを獲った人は再び正面の人に投げて走って対面の後方に並ぶ
                <br />４．30秒-1分間繰り返して何回できたか他と競う
                <br />５．ボールを落としてしまった場合はペナルティを課す（0からカウント等）
                <br />※ボールの投げ方や取り方をアレンジしたりも可能
              </td>
            </tr>
            <tr class="td-th-color_6">
              <th>ポイント</th>
              <td>
                ボール投げる、取るの動作を優先する
                <br />走ったり飛んだりが先行して主動作が散漫にならないようにする。
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CircuitTrainingAbout from "../commentary/CircuitTrainingAbout";
export default {
  name: "CircuitTraining",
  components: {
    CircuitTrainingAbout
  },
  props: {},
  data() {
    return {
      isModal: false,
      video_url_1:
        "https://firebasestorage.googleapis.com/v0/b/fitnesscamp-428f2.appspot.com/o/program%2Fcontent%2Fmovie%2Fcircuit%2Fhand_walk.mp4?alt=media&token=0e508062-54aa-455f-9f09-0718e10ff9b6",
      video_url_2:
        "https://firebasestorage.googleapis.com/v0/b/fitnesscamp-428f2.appspot.com/o/program%2Fcontent%2Fmovie%2Fcircuit%2Fv_balance.mp4?alt=media&token=fbf59c78-0c32-4bfb-aa26-2b05af886e50",
      video_url_3:
        "https://firebasestorage.googleapis.com/v0/b/fitnesscamp-428f2.appspot.com/o/program%2Fcontent%2Fmovie%2Fcircuit%2Funder_over.mp4?alt=media&token=6f9a2d60-a38d-48c1-adc9-a1a1e5c977a2",
      video_url_4:
        "https://firebasestorage.googleapis.com/v0/b/fitnesscamp-428f2.appspot.com/o/program%2Fcontent%2Fmovie%2Fcircuit%2Fturn_pose.mp4?alt=media&token=dfa17e38-1e62-4806-855d-b31efa630c2f",
      video_url_5:
        "https://firebasestorage.googleapis.com/v0/b/fitnesscamp-428f2.appspot.com/o/program%2Fcontent%2Fmovie%2Fcircuit%2Fjump_run.mp4?alt=media&token=2044876a-8ea3-426a-a0ba-f312adda9bc9",
      video_url_6:
        "https://firebasestorage.googleapis.com/v0/b/fitnesscamp-428f2.appspot.com/o/program%2Fcontent%2Fmovie%2Fcircuit%2Fball_relay.mp4?alt=media&token=08bf2aee-c443-406e-9117-fbde8baf80a6"
    };
  },
  methods: {
    openAbotModal() {
      this.isModal = true;
    },
    closeAbotModal() {
      this.isModal = false;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.heard-style {
  padding-top: 14px;
  height: 50px;
  background-color: #04545c;
  border: 0;
  margin-top: 5%;
  margin-left: 8%;
  margin-right: 8%;
}
.heard-style img {
  float: left;
  margin-left: 2%;
}
.heard-style > h2 {
  color: rgb(255, 255, 255);
  margin-right: 4%;
  font-weight: 900;
}
.close-button {
  height: 50px;
  background-color: #ffffff;
  border: 0;
  margin-left: 8%;
  margin-right: 8%;
}
.close-button-div {
  background-color: rgb(255, 255, 255);
  text-align: right;
  margin-right: 4%;
  padding-top: 12px;
}
.close-button-text {
  font-size: 16px;
  font-weight: 400;
  color: rgb(163, 163, 163);
}

.about {
  text-align: right;
  margin-top: 2%;
  margin-bottom: 3%;
}
.about > a:link,
a:visited,
a:hover,
a:active {
  text-decoration: none;
  font-size: 16px;
  font-weight: 400;
  color: rgb(163, 163, 163);
}
.title {
  padding-top: 4%;
  text-align: left;
}

.card-style {
  max-width: 100rem;
  margin: 2%;
  background-color: rgb(248, 248, 248);
}
.title-font {
  color: #000;
  font-weight: 600;
  text-align: left;
}
.title-conten-font {
  color: #000;
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  letter-spacing: 1px;
  line-height: 20px;
}
.video-div-style {
  height: auto;
  width: auto;
  margin-top: 15%;
  margin-left: 2%;
  margin-right: 2%;
  border: 0;
  background-color: rgb(233, 233, 233);
}
.video-style {
  height: 250px;
}
.h4-title {
  margin-top: 5%;
  margin-bottom: 4%;
  margin-left: 2%;
  text-align: left;
  font-weight: 600;
  color: #000;
}
.home > img {
  padding-top: 1%;
  width: 96%;
}
.program-font-style {
  font-weight: 600;
  color: #000;
}
.text-style {
  padding-top: 2%;
  text-align: left;
  font-size: 14px;
  font-weight: 500;
}
.font-style {
  letter-spacing: 2px;
  line-height: 25px;
  padding-top: 8px;
}
.span-style {
  margin-left: -5px;
  margin-right: -5px;
  background: linear-gradient(transparent 50%, #f5f100 0%);
}

/* table */
table {
  margin-top: 8%;
  margin-left: 2%;
  margin-right: 2%;
  width: 96%;
  border-collapse: collapse;
}

table tr {
  width: 30px;
  border-bottom: solid 1px white;
}

table tr:last-child {
  border-bottom: none;
}

table th {
  position: relative;
  text-align: left;
  width: 25%;
  height: auto;
  text-align: center;
  padding: 10px 0;
  font-size: 14px;
  font-weight: 400;
}
table td {
  position: relative;
  text-align: left;
  height: auto;
  text-align: left;
  padding: 10px 10px;
  font-size: 14px;
  font-weight: 400;
  border-left: solid 1px white;
}
.td-th-color_1 {
  color: #ffffff;
  background-color: #14745cff;
}
.td-th-color_2 {
  color: #ffffff;
  background-color: #14547cff;
}
.td-th-color_3 {
  color: #ffffff;
  background-color: #7c7c7cff;
}
.td-th-color_4 {
  color: #ffffff;
  background-color: #f4c474ff;
}
.td-th-color_5 {
  color: #ffffff;
  background-color: #c40404ff;
}
.td-th-color_6 {
  color: #ffffff;
  background-color: #94d454ff;
}

.popContainer {
  z-index: 2;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(43, 43, 43, 0.3);
}
.card-modal {
  overflow: auto;
  z-index: 2;
  margin-top: 0%;
  margin-left: 8%;
  margin-right: 8%;
  height: 75%;
  background-color: rgb(255, 255, 255);
}
</style>
