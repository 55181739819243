<template>
  <div class="fullview">
    <div class="loading-div">
      <div
        class="spinner-border color-style"
        style="width: 5rem; height: 5rem;"
        role="status"
      >
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "loading",
  components: {},
};
</script>

<style>
.fullview {
  z-index: 10;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
}
.loading-spacer {
  height: 30%;
}
.loading-div {
  padding-top: 70%;
}
.color-style {
  text-align: center;
  color: #ffffff;
}
</style>
