import GoogleMapsApiLoader from "google-maps-api-loader";
export default {
  data() {
    return {
      google: null,
      map: {},
      marker: [],
      geocoder: null
    };
  },
  async mounted() {
    await this.setGoogleConfing();

  },
  computed: {
    hello: function () {
      return this.greeting + " " + this.world + "!";
    },
  },
  methods: {
    async setGoogleConfing() {
      this.google = await new GoogleMapsApiLoader({
        apiKey: "AIzaSyAAi_xslHLasOUhkzGZD-8QFgtYkLYvaFk",
      });
      this.geocoder = new this.google.maps.Geocoder();
      return this.google
    },
    //経緯度を取得
    async glb_get_latandlan(value) {
      //let data = {}
      let resData = {}
      try {
        await this.geocoder.geocode({
          'address': value
        }, (results, status) => {
          let res = {}
          if (status === this.google.maps.GeocoderStatus.OK) {
            //this.map.setCenter(results[0].geometry.location);
            // 緯度経度の取得
            res.lat = results[0].geometry.location.lat();
            res.lng = results[0].geometry.location.lng();
            resData = res
          } else {
            resData = ""
          }
        });
        return resData
      } catch (error) {
        return ""
      }
    }
  },
};
