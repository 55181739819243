<template>
  <div class="mypage">
    <Header :page="3" />
    <div class="space"></div>

    <div v-show="error">
      <ErrrorAlert :page="1" @do-alert-cancel="doAlertCancel" />
    </div>
    <Loading v-show="loading" />
    <div v-show="delete_alert">
      <!-- 画像削除 モーダル表示内容 -->
      <div class="popContainer">
        <div class="alert-div">
          <h4 class="display-6 alert-msg">{{ alert_msg }}</h4>
          <h4 class="display-6 msg-color">{{ error_msg }}</h4>
          <!-- 確認、CXLボタン-->
          <div class="button-div">
            <table>
              <tr>
                <td>
                  <button
                    class="button-style cancel-button"
                    @click="closeModal()"
                  >
                    キャンセル
                  </button>
                </td>
                <td>
                  <button
                    class="button-style send-button"
                    @click="deleteUpdate()"
                  >
                    確認
                  </button>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div v-show="modal">
      <div class="open-modal">
        <div class="open-image-div">
          <img class="open-image" :src="openImageUrl" />
        </div>
        <div class="d-flex justify-content-around">
          <img
            class="close-image"
            src="@/assets/profile/back.svg"
            @click="imageEvent(0)"
          />
          <img
            class="close-image"
            src="@/assets/profile/close.svg"
            @click="imageEvent(1)"
          />
          <img
            class="close-image"
            src="@/assets/profile/next.svg"
            @click="imageEvent(2)"
          />
        </div>
      </div>
    </div>

    <!-- 資格に関するモーダル   modal2 -->
    <div v-show="modal2">
      <div class="popContainer">
        <div class="qualification-modal2">
          <table>
            <tr>
              <p class="h3 title-style-2">保有資格</p>
              <div
                class="qualification-div"
                v-for="(content, index) in userInfoEdit.qualification"
                v-bind:key="content"
              >
                <div
                  style="display=inline-block"
                  @click="deleteQualification(index)"
                >
                  <img
                    src="@/assets/profile/マイナスのアイコン素材.svg"
                    height="20"
                    width="20"
                  />
                  <a>{{ content }}</a>
                </div>
              </div>
              <br />
            </tr>
          </table>

          <table>
            <tr>
              <hr />
              <p class="h3 title-style-2">資格追加</p>
              <div>
                <img
                  src="@/assets/profile/plus-icon.svg"
                  height="20"
                  width="20"
                  @click="plusOtherQualification()"
                />
                <input
                  type="text"
                  placeholder="その他 :（自由記述）"
                  class="plus-qualification"
                  v-model="other_qualification"
                />
              </div>
              <hr style="border: 1px solid transparent;" />
              <div
                class="qualification-div"
                v-for="(content, index) in qualification_list"
                v-bind:key="content"
              >
                <div
                  style="display=inline-block"
                  @click="plusQualification(index)"
                >
                  <img
                    src="@/assets/profile/plus-icon.svg"
                    height="20"
                    width="20"
                  />
                  <a>{{ content }}</a>
                </div>
              </div>
              <br />
            </tr>
          </table>
        </div>
        <div>
          <table class="qualificat-button-table">
            <tr>
              <th>
                <button
                  class="qualificat-button-style qualificat-cancel-button"
                  @click="cancel()"
                >
                  キャンセル
                </button>
              </th>
              <td>
                <button
                  class="qualificat-button-style qualificat-update-button"
                  @click="upDate()"
                >
                  保存
                </button>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
    <!--  / 資格に関するモーダル -->

    <div class="home">
      <div class="profile">
        <table class="profile-table">
          <tr>
            <!-- editIcon true -->
            <td class="icon-td" v-if="!editIcon">
              <div>
                <div class="icon-style">
                  <div class="selectPicture">
                    <label class="selectPicture__label">
                      <div
                        v-if="userInfo.icon"
                        class="image_circle"
                        :style="{
                          backgroundImage: 'url(' + userInfo.icon + ')',
                        }"
                      >
                        <!-- 画像置き換え処理(preview) -->
                        <input
                          class="image_input"
                          type="file"
                          ref="preview"
                          id="selectingItem"
                          @change="iconPreview($event)"
                        />
                      </div>
                      <!-- 選択されていない場合の描写 -->
                      <div
                        v-else
                        class="image_circle"
                        :style="{ backgroundImage: 'url()' }"
                      >
                        <input
                          class="image_input"
                          type="file"
                          ref="preview"
                          id="selectingItem"
                          accept="image/*"
                          @change="iconPreview($event)"
                        />
                      </div>
                    </label>
                  </div>
                </div>
              </div>
            </td>
            <!-- editIcon false -->
            <td class="icon-td" v-else>
              <div>
                <div class="icon-style">
                  <div class="selectPicture">
                    <label class="selectPicture__label">
                      <div
                        class="image_circle"
                        :style="{
                          backgroundImage: 'url(' + iconPreviewSrc + ')',
                        }"
                      >
                        <input
                          class="image_input"
                          type="file"
                          ref="preview"
                          id="selectingItem"
                          accept="image/*"
                          @change="iconPreview($event)"
                        />
                      </div>
                    </label>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </table>

        <div class="nicknme-div">
          <p class="h3 title-style-2 font-color">認定コーチ名</p>

          <input
            type="text"
            maxlength="30"
            class="form-control"
            id="introduceInput1"
            placeholder="認定コーチ名を入力します"
            height="90"
            ref="adjust_textarea"
            @keydown="adjustHeight"
            v-model="userInfo.certified_coach"
            @change="edit()"
          />
        </div>
        <br />
        <div class="introduce-div">
          <p class="h3 title-style-2 font-color">自己紹介</p>
          <textarea
            class="form-control"
            id="introduceTextarea1"
            rows="5"
            maxlength="400"
            placeholder="最大400文字"
            v-model="userInfo.self_pr"
            @change="edit()"
          ></textarea>
        </div>
      </div>
      <br />

      <div class="fitness-card">
        <br />
        <div>
          <h4 class="display-5 title-style title-div title-font">
            FITNESS CAMP指導拠点
          </h4>
        </div>
        <div
          class="guidance_base_card"
          v-for="(content, index) in userInfo.guidance_base"
          v-bind:key="content.id"
        >
          <div class="display-info">
            <textarea
              id="infoGuide"
              v-model="content.location"
              @change="edit()"
            ></textarea>
          </div>
          <div class="error-msg" v-show="guidance_base_error[index]">
            <p>
              指導拠点の経緯度を取得失敗しましたので、ご確認ください。
            </p>
          </div>
          <div class="d-flex justify-content-start guidance_base_button">
            <label
              class="guidance_base_plus plus-button"
              @click="guidance_base_plus(index)"
              >拠点追加</label
            >
            <label
              class="guidance_base_plus delete-button"
              @click="guidance_base_delete(index)"
              >拠点削除</label
            >
          </div>
        </div>
        <br />
        <h4 class="display-5 title-style title-div title-font">
          その他保有資格
        </h4>
        <div class="display-info-1">
          <div
            class="qualification-div"
            v-for="content in userInfo.qualification"
            v-bind:key="content"
            @change="edit()"
          >
            <a>{{ content }}</a>
          </div>
        </div>
        <!-- 資格追加Btn -->
        <div @click="doQualificationEdit()">
          <img src="@/assets/profile/plus-icon.svg" height="30" width="30" />
        </div>

        <br />
        <h4 class="display-5 title-style title-div title-font">FaceBook</h4>
        <table class="user-info-input">
          <tr>
            <!-- FaceBookURL入力箇所  -->
            <td class="td-4">
              <input
                id="urlText1"
                @keyup="urlValue(1)"
                type="text"
                pattern="/^(https|http):\/\/([a-z]{1,}\.|)(qiita\.com)(\/(.*)|\?(.*)|$)$/g"
                placeholder="URLをペーストして下さい"
                v-model="userInfo.sns.facebook"
                @change="edit()"
              />
            </td>
          </tr>
        </table>

        <div class="error-msg">
          <p>{{ sns_error.facebook }}</p>
        </div>

        <br />
        <h4 class="display-5 title-style title-div title-font">Instagram</h4>
        <table class="user-info-input">
          <tr>
            <!-- InstagramURL入力箇所  -->
            <td class="td-4">
              <input
                id="urlText2"
                @keyup="urlValue(2)"
                type="text"
                pattern="/^(https|http):\/\/([a-z]{1,}\.|)(qiita\.com)(\/(.*)|\?(.*)|$)$/g"
                placeholder="URLをペーストして下さい"
                v-model="userInfo.sns.instagram"
                @change="edit()"
              />
            </td>
          </tr>
        </table>

        <div class="error-msg">
          <p>{{ sns_error.instagram }}</p>
        </div>

        <br />
        <h4 class="display-5 title-style title-div title-font">個人サイト</h4>
        <table class="user-info-input">
          <tr>
            <!-- 個人サイトURL入力箇所 -->
            <td class="td-4">
              <input
                id="urlText3"
                @keyup="urlValue(3)"
                type="text"
                pattern="/^(https|http):\/\/([a-z]{1,}\.|)(qiita\.com)(\/(.*)|\?(.*)|$)$/g"
                placeholder="URLをペーストして下さい"
                v-model="userInfo.sns.mypage"
                @change="edit()"
              />
            </td>
          </tr>
        </table>

        <br />
        <br />
      </div>

      <br />
      <div class="introduce-div">
        <p class="h3 title-style-2 font-color">SNS</p>
        <div class="display-info-1" v-if="userInfo.sns">
          <img
            v-if="userInfo.sns.facebook"
            src="@/assets/profile/FACEBOOK.svg"
            class="icon-div"
            @click="doSns(1)"
          />
          <img
            v-if="userInfo.sns.instagram"
            src="@/assets/profile/インスタグラム.svg"
            class="icon-div"
            @click="doSns(2)"
          />
          <img
            v-if="userInfo.sns.mypage"
            src="@/assets/profile/link.svg"
            class="icon-div"
            @click="doSns(3)"
          />
        </div>
      </div>

      <!-- 活動写真一覧(編集) tittle -->
      <div class="d-flex justify-content-center">
        <div class="introduce-div">
          <p class="h3 title-style-2 font-color">画像一覧</p>
        </div>
        <div class="introduce-div edit-div">
          <button v-show="!deleteImage" @click="editImage(1)">画像編集</button>
          <button v-show="deleteImage" @click="editImage(2)">取り消し</button>
        </div>
      </div>

      <br />
      <!-- 活動写真一覧 インスタンス -->
      <div class="text-center">
        <div class="img-div">
          <div class="d-flex justify-content-center img-fiex">
            <div
              v-for="(content, index) in userInfo.profile_image_list"
              v-bind:key="content.id"
            >
              <div v-show="deleteImage" class="introduce-div edit-div">
                <img
                  class="mainasu-icon"
                  src="@/assets/profile/close.svg"
                  height="30"
                  width="30"
                  @click="deleteImageEvent(index)"
                />
              </div>
              <div
                class="image-list"
                :style="{
                  backgroundImage: 'url(' + content.image_url + ')',
                }"
                @click="openImage(index)"
              ></div>
            </div>

            <div>
              <div class="selectPicture">
                <label class="selectPicture_label_image">
                  <div
                    class="add-image-back"
                    :style="{
                      backgroundImage: 'url(' + add_image_url + ')',
                    }"
                  >
                    <input
                      class="image_input"
                      type="file"
                      ref="preview"
                      id="selectingItem"
                      accept="image/*"
                      @change="profile_image_list_up($event)"
                    />
                  </div>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="div-space"></div>
      <div v-if="editEvent || editIcon">
        <!-- 更新ボタン：修正があるとき表示 -->
        <div class="update-div">
          <!-- 更新押下 = モーダル出現 -->
          <button class="update-button" @click="upload()">更新</button>
        </div>
      </div>
      <div class="div-space"></div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from "../../../components/Header";
import firebase from "firebase/compat";
import Loading from "@/components/Loading";
import ErrrorAlert from "@/components/tool/ErrrorAlert";
import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";

export default {
  name: "Mypage",
  components: {
    Header,
    Loading,
    ErrrorAlert,
  },
  mounted() {},
  data() {
    return {
      error: false,
      errorCheck: false,
      sns_error: {
        facebook: "",
        instagram: "",
      },
      userInfoEdit: {},
      loading: false,
      delete_alert: false,
      modal: false,
      modal2: false,
      deleteImage: false,
      alert_msg: "",
      openImageUrl: "",
      delete_index: "",
      image_index: "",
      userInfo: {},
      qualification_list: [],
      other_qualification: "",
      userInfoBack: {},
      imageList: [],
      url: "",
      imagUrl: "",
      iconPreviewSrc: "",
      editEvent: false,
      editIconUrl: "",
      editIcon: false,
      addImageEvent: false,
      image_list: [],
      add_image_url:
        "https://firebasestorage.googleapis.com/v0/b/fitnesscamp-428f2.appspot.com/o/user_upload%2Fimage-add.svg?alt=media&token=7006d278-166f-4675-8e09-81fdd569ca1d",
      urlParm1: "",
      urlParm2: "",
      guidance_base_error: [],
    };
  },
  created: function() {
    this.getUserInfo();
  },
  methods: {
    guidance_base_plus(index) {
      let data = {
        location: "",
        lat: "",
        lng: "",
      };
      this.userInfo.guidance_base.splice(index + 1, 0, data);
    },
    guidance_base_delete(index) {
      if (this.userInfo.guidance_base.length > 1) {
        this.userInfo.guidance_base.splice(index, 1);
        this.editEvent = true;
      } else {
        this.userInfo.guidance_base = [];
        this.userInfo.guidance_base.push({
          location: "",
          lat: "",
          lng: "",
        });
        this.editEvent = true;
      }
    },
    doAlertCancel() {
      this.error = false;
    },
    urlValue(value) {
      // sns リンク判定 FB,IG
      let self = this;

      self.urlParm1 = self.userInfo.sns.facebook;
      self.urlParm2 = self.userInfo.sns.instagram;

      switch (value) {
        case 1:
          if (!self.urlParm1.match("facebook.com/")) {
            self.sns_error.facebook = " facebookのURLではありません";

            //self.userInfo.sns.facebook = "";
          } else {
            self.sns_error.facebook = "";
          }

          break;
        case 2:
          if (!self.urlParm2.match("instagram.com/")) {
            self.sns_error.instagram = " instagramのURLではありません";

            //self.userInfo.sns.instagram = "";
          } else {
            self.sns_error.instagram = "";
          }
          break;

        default: // 条件通過時
      }
      if (
        self.userInfo.sns.facebook != null ||
        self.userInfo.sns.facebook != ""
      ) {
        self.checkSnsUrl();
      } else {
        self.sns_error.facebook = "";
      }
      if (
        self.userInfo.sns.instagram != null ||
        self.userInfo.sns.instagram != ""
      ) {
        self.checkSnsUrl();
      } else {
        self.sns_error.instagram = "";
      }
    },
    //url判定
    checkSnsUrl() {
      if (this.sns_error.facebook != "") {
        this.editEvent = false;
      } else if (this.sns_error.instagram != "") {
        this.editEvent = false;
      } else {
        this.editEvent = true;
      }
    },
    // Modal削除時
    closeModal() {
      this.delete_alert = false;
      clearAllBodyScrollLocks();
    },
    // 画像削除時のModal
    async deleteUpdate() {
      let self = this;
      self.loading = true;
      await firebase.auth().onAuthStateChanged(async function(user) {
        if (
          self.userInfo.profile_image_list[self.delete_index].image_name !=
            null &&
          self.userInfo.profile_image_list[self.delete_index].image_name != ""
        ) {
          const file_name =
            self.userInfo.profile_image_list[self.delete_index].image_name;
          self.userInfo.profile_image_list.splice(self.delete_index, 1);
          self.delete_alert = false;
          await firebase
            .firestore()
            .collection(self.glb_table.users)
            .doc(user.uid)
            .update({
              profile_image_list: self.userInfo.profile_image_list,
              updated_date: firebase.firestore.Timestamp.now(),
            })
            .then(() => {
              let deleteImageRef = firebase
                .storage()
                .ref()
                .child(file_name);
              deleteImageRef.delete().then(function() {
                localStorage.setItem(
                  "user_info",
                  JSON.stringify(self.userInfo)
                );
                clearAllBodyScrollLocks();
                self.loading = false;
                self.delete_alert = false;
              });
            });
        }
      });
    },
    // 画像削除時のModal
    deleteImageEvent(index) {
      this.delete_alert = true;
      this.alert_msg = "この画像を削除してよろしいでしょうか？";
      this.delete_index = index;
      const modal = document.querySelector(".open-modal");
      disableBodyScroll(modal);
    },
    // 画像編集時のModal
    editImage(value) {
      if (value == 1) {
        this.deleteImage = true;
      }
      if (value == 2) {
        this.deleteImage = false;
      }
    },
    // 画像編集時のModal
    imageEvent(value) {
      switch (value) {
        case 0:
          if (this.image_index > 0) {
            this.openImageUrl = this.userInfo.profile_image_list[
              this.image_index - 1
            ].image_url;
            this.image_index = this.image_index - 1;
          }
          break;
        case 1: {
          clearAllBodyScrollLocks();
          this.modal = false;
          this.openImageUrl = "";
          break;
        }
        case 2: {
          if (this.image_index < this.userInfo.profile_image_list.length - 1) {
            this.openImageUrl = this.userInfo.profile_image_list[
              this.image_index + 1
            ].image_url;
            this.image_index = this.image_index + 1;
          }
          break;
        }
      }
    },

    // 画像一覧 モーダル表示
    openImage(index) {
      this.modal = true;
      this.openImageUrl = this.userInfo.profile_image_list[index].image_url;
      this.image_index = index;
      const modal = document.querySelector(".open-modal");
      disableBodyScroll(modal);
    },
    // SNSの編集に関して
    doSns(value) {
      switch (value) {
        case 1:
          window.open(this.userInfo.sns.facebook, "_blank");
          break;
        case 2:
          window.open(this.userInfo.sns.instagram, "_blank");
          break;
        case 3:
          window.open(this.userInfo.sns.mypage, "_blank");
          break;
      }
    },
    // 画像リストアップ ? OgawaK 2021/12/13
    profile_image_list_up(event) {
      let self = this;
      let selectPic = event.target.files[0];
      var pos = selectPic.name.split(".").pop();
      let imageCheck = self.validete(pos);

      if (imageCheck) {
        this.editEvent = true;
        this.addImageEvent = true;
        let self = this;
        self.imageList.push(event.target.files[0]);
        var reader = new FileReader();
        reader.onload = function(event) {
          self.userInfo.profile_image_list.push({
            image_url: event.target.result,
            image_name: "",
          });
        };
        reader.readAsDataURL(event.target.files[0]);
      }
    },
    edit() {
      let self = this;
      if (
        self.userInfo.sns.facebook == null ||
        self.userInfo.sns.facebook == ""
      ) {
        self.sns_error.facebook = "";
      }

      if (
        self.userInfo.sns.instagram == null ||
        self.userInfo.sns.instagram == ""
      ) {
        self.sns_error.instagram = "";
      }
      if (self.sns_error.facebook != "") {
        self.editEvent = false;
      } else if (self.sns_error.instagram != "") {
        self.editEvent = false;
      } else {
        self.editEvent = true;
      }

      if (
        self.userInfo.guidance_base == null ||
        self.userInfo.guidance_base == ""
      ) {
        self.guidance_base.error = "";
      }
    },
    validete(pos) {
      switch (pos) {
        case "jpeg":
        case "jpg":
        case "PNG":
        case "png":
        case "GIF":
        case "gif":
        case "TIFF":
        case "tiff":
        case "WebP":
        case "webp":
        case "SVG":
        case "svg":
          return true;

        default:
          this.userInfo.icon = "";
          return false;
      }
    },
    //icon画像判定関数
    iconPreview(event) {
      this.editIcon = true;
      let self = this;
      self.editIconUrl = event.target.files[0];
      // Debai
      var reader = new FileReader();
      reader.onload = function(event) {
        //  switch で画像判定 11/25
        self.iconPreviewSrc = event.target.result;
        //プレビューされたファイルを変数の中に代入
      };
      var pos = self.editIconUrl.name.split(".").pop();
      let imageCheck = self.validete(pos);
      if (imageCheck) {
        // reader.onload;
        reader.readAsDataURL(event.target.files[0]);
      }
    },
    async updateIcon(user) {
      let self = this;
      var pos = self.editIconUrl.name.lastIndexOf(".");
      var file_name = "";
      if (pos === -1) {
        file_name = "";
      } else {
        file_name = self.editIconUrl.name.slice(pos + 1);
      }

      let imageCheck = self.validete(file_name);
      if (imageCheck) {
        //アイコン更新の場合
        var imagePath = "users/" + user.uid + "/icon/icon." + file_name;
        //アイコンアップロード
        if (file_name != null && file_name != "") {
          let iconUplodaRef = firebase
            .storage()
            .ref()
            .child(imagePath);
          await iconUplodaRef.put(self.editIconUrl).then(async () => {
            await iconUplodaRef.getDownloadURL().then(async (url) => {
              //DB更新
              await firebase
                .firestore()
                .collection(self.glb_table.users)
                .doc(user.uid)
                .update({
                  icon: url,
                  updated_date: firebase.firestore.Timestamp.now(),
                })
                .then(() => {
                  self.userInfo.icon = url;
                })
                .catch(() => {
                  // self.errorCheck = true;
                });
            });
          });
        }
      }
    },
    async updateDbOnly(user, latAndLng) {
      let self = this;
      await firebase
        .firestore()
        .collection(self.glb_table.users)
        .doc(user.uid)
        .update({
          certified_coach: self.userInfo.certified_coach,
          self_pr: self.userInfo.self_pr,
          qualification: self.userInfo.qualification,
          sns: self.userInfo.sns,
          guidance_base: self.userInfo.guidance_base,
          guidance_lat_and_lng: latAndLng,
          updated_date: firebase.firestore.Timestamp.now(),
        })
        .then(() => {})
        .catch(() => {
          // self.errorCheck = true;
        });
    },
    async updateProfileImage(user) {
      let self = this;
      let profile_image_list = self.imageList;
      self.userInfo.profile_image_list = self.userInfoBack.profile_image_list;
      for (let i = 0; i < profile_image_list.length; i++) {
        let time = Date.now();
        var imagePath =
          "users/" +
          user.uid +
          "/profile_image_list/" +
          time +
          profile_image_list[i].name;
        let profileImageListRef = firebase
          .storage()
          .ref()
          .child(imagePath);
        await profileImageListRef.put(profile_image_list[i]).then(async () => {
          await profileImageListRef.getDownloadURL().then((url) => {
            self.userInfo.profile_image_list.push({
              image_url: url,
              image_name: imagePath,
            });
          });
        });
      }
      await firebase
        .firestore()
        .collection(self.glb_table.users)
        .doc(user.uid)
        .update({
          profile_image_list: self.userInfo.profile_image_list,
          updated_date: firebase.firestore.Timestamp.now(),
        })
        .then(() => {})
        .catch(() => {
          // self.errorCheck = true;
        });
    },
    async upload() {
      let self = this;
      self.loading = true;
      let latAndLng = "";
      self.guidance_base_error = [];
      if (self.edit) {
        //経緯度を取得処理
        //let guidance_base_data = [];
        for (var index in self.userInfo.guidance_base) {
          //
          self.guidance_base_error.push(false);
          if (
            self.userInfo.guidance_base[index].location == "" ||
            self.userInfo.guidance_base[index].location == null
          ) {
            self.userInfo.guidance_base.splice(index, 1);
          } else {
            latAndLng = await this.glb_get_latandlan(
              self.userInfo.guidance_base[index].location
            );
            if (latAndLng == null || latAndLng == "") {
              // self.guidance_base_error =
              //   "指導拠点の経緯度を取得失敗しましたので、ご確認ください。";
              self.guidance_base_error[index] = true;
              self.loading = false;
              self.error = true;
              return;
            }
            self.userInfo.guidance_base[index].lat = latAndLng.lat;
            self.userInfo.guidance_base[index].lng = latAndLng.lng;
            // guidance_base_data.push({
            //   location: self.userInfo.guidance_base[index].location,
            //   lat: latAndLng.lat,
            //   lng: latAndLng.lng,
            // });
          }
        }
      }
      await firebase.auth().onAuthStateChanged(async function(user) {
        if (self.editIconUrl != "" && self.editIconUrl != null) {
          await self.updateIcon(user);
        }
        // if (self.errorCheck) {
        //   self.loading = false;
        //   self.error = true;
        //   return;
        // }

        if (self.edit) {
          await self.updateDbOnly(user, latAndLng);
        }
        // if (self.errorCheck) {
        //   self.loading = false;
        //   self.error = true;
        //   return;
        // }

        if (self.addImageEvent) {
          await self.updateProfileImage(user);
        }
        // if (self.errorCheck) {
        //   self.loading = false;
        //   self.error = true;
        //   return;
        // }
        localStorage.setItem("user_info", JSON.stringify(self.userInfo));
        self.loading = false;
        window.location.reload();
      });
    },
    getUserInfo() {
      // 全て取得
      this.userInfo = JSON.parse(localStorage.getItem("user_info"));
      //userInfo
      if (this.userInfo.guidance_base == null) {
        this.userInfo.guidance_base = [
          {
            location: "",
            lat: "",
            lng: "",
          },
        ];
      } else if (this.userInfo.guidance_base.length == 0) {
        this.userInfo.guidance_base.push({
          location: "",
          lat: "",
          lng: "",
        });
      }
      this.userInfoBack = JSON.parse(localStorage.getItem("user_info"));
      this.userInfoEdit = JSON.parse(localStorage.getItem("user_info"));
    },

    // 認定コーチ、可変長さテスト
    adjustHeight() {
      const textarea = this.$refs.adjust_textarea;
      const resetHeight = new Promise(function(resolve) {
        resolve((textarea.style.height = "auto"));
      });

      resetHeight.then(function() {
        textarea.style.height = textarea.scrollHeight + "px";
      });
    },

    // 追加 資格追加モーダル

    doQualificationEdit() {
      let self = this;
      firebase
        .firestore()
        .collection(self.glb_table.qualification)
        .doc("qualification_list")
        .get()
        .then((snap) => {
          let data = snap.data().qualification;
          // if (self.userInfo.qualification != null) {
          if (self.userInfo.qualification.length == 0) {
            self.qualification_list = data;
          } else {
            self.qualification_list = data.filter(
              (i) => self.userInfo.qualification.indexOf(i) == -1
            );
          }
        });
      this.modal2 = true;
      this.editEvent = true;
    },
    // 資格Modal
    cancel() {
      this.userInfoEdit = JSON.parse(localStorage.getItem("user_info"));
      this.modal2 = false;
    },

    // 資格Modal
    upDate() {
      this.userInfo.qualification = this.userInfoEdit.qualification;
      this.modal2 = false;
    },

    deleteQualification(index) {
      this.qualification_list.push(this.userInfoEdit.qualification[index]);
      this.userInfoEdit.qualification.splice(index, 1);
    },
    plusQualification(index) {
      if (
        !this.userInfoEdit.qualification.includes(
          this.qualification_list[index]
        )
      ) {
        this.userInfoEdit.qualification.push(this.qualification_list[index]);
        this.qualification_list.splice(index, 1);
      }
    },
    plusOtherQualification() {
      if (this.other_qualification != null && this.other_qualification != "") {
        if (
          !this.userInfoEdit.qualification.includes(this.other_qualification)
        ) {
          this.userInfoEdit.qualification.push(this.other_qualification);
        }
      }
    },
  },
};
</script>

<style scoped>
.mypage {
  background: #fafafa;
}
.home {
  width: 40%;
  margin: auto;
  /* border: 2px  */
  /* background: #dddddd; */
}
.heard-table {
  color: rgb(255, 255, 255);
  width: 100%;
  position: fixed;
  z-index: 2;
  margin-top: 0%;
  width: 100%;
  height: 58px;
  background: #04545c;
  border-bottom: 1px solid #cfcfcf;
}
.back-icon {
  width: 20%;
  color: rgb(255, 255, 255);
}

.heard-title {
  padding-top: 10px;
  width: 60%;
}
.heard-menu {
  width: 20%;
}

.heard-style > h2 {
  color: #414141;
  font-weight: 900;
}

.space-style-1 {
  height: 58px;
}

.slect-div {
  text-align: left;
  padding-left: 5%;
  padding-top: 5%;
}
.slect-style {
  width: 150px;
  height: 35px;
  font-size: 14px;
  background-color: #e2e2e2;
  border-radius: 5px;
  border: none;
  outline: none;
  color: #000;
}
.slect-style :active {
  border: none;
  outline: none;
  color: #000;
}
.slect-style :hover {
  border: none;
  outline: none;
  color: #000;
}
.profile-table {
  width: 100%;
  height: 120px;
  background: #fff;
  box-shadow: 3px 6px 9px rgba(0, 0, 0, 0.16);
  margin-bottom: 5%;
}
.icon-td {
  width: 100%;
  text-align: center;
  min-width: 110px;
}

.yajirushi {
  width: 10%;
  padding: 0%;
  text-align: left;
}
.profile-table td {
  padding-top: 5%;
  padding-bottom: 5%;
  background-color: rgb(255, 255, 255);
  text-align: center;
}

.profile-table td .icon-style {
  padding: 5px;
  border-radius: 50%;
}
.image_circle {
  text-align: center;
  height: 230px;
  width: 230px;
  border-radius: 90%;
  background-position: 54% 36%;
  background-size: cover;
  border: 2px solid rgba(236, 236, 236, 0.5);
  box-shadow: 3px 6px 9px rgba(0, 0, 0, 0.2);
}

.profile-table td .title-style {
  font-weight: 600;
  margin-bottom: 0%;
  padding-left: 2%;
  color: rgb(141, 141, 141);
}

.profile-table td .conten-style {
  font-weight: 600;
  font-size: 12px;
  padding-left: 2%;
  color: rgb(66, 66, 66);
}
.nicknme-div,
.introduce-div {
  margin-left: 2%;
  width: 95%;
  max-width: 100%;
  text-align: left;
}
.ninkname-label,
.introduce-label {
  font-size: 14px;
  font-weight: bold;
  margin-top: 1%;
  margin-bottom: 0%;
  padding-left: 2%;
  padding-bottom: 2%;
  color: #000;
  text-align: left;
}
.form-control {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0%;
  padding-left: 2%;
  color: #000;
  text-align: center;
  border-radius: 11px;
  background-color: #ededed;
  border: none;
  outline: none;
}
#introduceInput1,
#introduceTextarea1 {
  text-align: start;
}

.selectPicture,
.selectPicture__label {
  text-align: center;
  margin: auto;
}
#editPictureBtn {
  height: 10%;
  width: 10%;
  margin-top: 80%;
  margin-left: 80%;
  background-color: white;
}

#selectItem,
#img_name {
  display: none;
}
label {
  padding: 0 3rem;
}

label::after {
  content: "";
  font-size: 1rem;
  color: #888;
  padding-left: 1rem;
}
.image_input {
  display: none;
}
.introImage {
  margin-top: 5%;
  height: 20%;
  width: 20%;
}
.space {
  width: 100%;
  height: 58px;
  background-color: #fff;
}

.profile {
  width: 100%;
  overflow: auto;
}

.update-div {
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0%;
  width: 40%;
  height: 100px;
  /*画面下部 常時表示*/
  padding: 3%;
  position: fixed;
  bottom: 0%;
  background-color: rgb(245, 245, 245);
  text-align: center;
}
.update-button {
  text-align: center;
  width: 100px;
  height: 35px;
  border: none;
  font-size: 16px;
  font-weight: 700;
  border-radius: 10px;
  background-color: rgb(3, 163, 168);
  box-shadow: 0px 3px 6px rgb(214, 214, 214);
  color: #ffffff;
}
.div-space {
  height: 100px;
}

.display-info-1 {
  width: 92%;
  min-height: 80px;
  height: auto;
  margin: 0% auto 0% auto;
  border-radius: 11px;
  background: #ffffff;
}
.icon-div {
  padding: 10px;
  height: 50px;
  width: 50px;
}

.img-fiex {
  display: flex;
  flex-wrap: wrap;
}
.img-div {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.img-div .image-list {
  width: 120px;
  height: 120px;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  /* 白線レイアウト 追加箇所 */
  border: 1px solid #bbbbbb;
}
.image-list {
  width: 4%;
}
.add-image {
  width: 80px;
  height: 80px;
  margin: auto;
}
.selectPicture_label_image {
  background-color: #eeeeee;
  margin-top: 5px;
  margin-left: 5px;
}
.add-image-back {
  text-align: center;
  margin-top: 20px;
  height: 58px;
  width: 58px;
  border-radius: 90%;
  background-position: 54% 36%;
  background-size: cover;
  background-color: #eeeeee;
}
.open-modal {
  z-index: 10;
  width: 100%;
  height: 100%;
  background: rgba(100, 100, 100, 0.9);
  position: fixed;
  top: 0;
  left: 0;
}
.open-image {
  margin-top: 70px;
  width: auto;
  height: auto;
  max-height: 70vh;
  background-color: #fff;
}
.close-image {
  margin-top: 60px;
  width: 50px;
  height: 50px;
}
.font-color {
  color: rgb(136, 136, 136);
  font-weight: 600;
}
.open-image-div {
  width: 100%;
  height: 70vh;
}
.edit-div {
  float: right;
  text-align: right;
  padding-right: 8%;
}
.edit-div button {
  width: 80px;
  height: 25px;
  border: none;
  font-size: 12px;
  font-weight: 600;
  border-radius: 10px;
  background-color: rgb(236, 134, 0);
  box-shadow: 0px 3px 6px rgb(214, 214, 214);
  color: #ffffff;
}
.mainasu-icon {
  margin-top: -10px;
  margin-right: 101%;
}
.img-div img {
  background-color: #858585;
  border-radius: 50%;
  margin-top: 1px;
  margin-left: -4px;
}

.popContainer {
  z-index: 2;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
}
.alert-div {
  margin-left: auto;
  margin-right: auto;
  margin-top: 200px;
  width: 80%;
  height: 200px;
  background-color: #fff;
  border-radius: 5px;
}
.alert-msg {
  padding: 15% 5% 5% 5%;
  color: #000;
  font-weight: 500;
  white-space: pre-wrap;
}
.button-div table {
  margin-top: 15px;
  width: 100%;
}
.button-style {
  width: 100px;
  height: 40px;
  border: 1px solid #dddddd;
}
.cancel-button {
  border-radius: 10px;
  background-color: #fff;
  color: #888888;
  font-size: 14px;
  font-weight: 600;
}
.send-button {
  border-radius: 10px;
  background-color: #fff;
  color: #00aa93;
  font-size: 14px;
  font-weight: 600;
}
.msg-color {
  padding: 1% 1% 1% 1%;
  color: rgb(161, 0, 0);
}
.qualification-div {
  display: inline-flex;
  padding: 5px;
  overflow: auto;
}

#infoGuide {
  width: 100%;
  min-height: 82px;
  height: auto;
  margin: 0% auto 0% auto;
  border-radius: 11px;
  background: #ededed;
  border: none;
  padding: 10px;
  outline: none;
  font-size: 16px;
}

a {
  display: inline-block;
  margin: 0 0.1em 0.6em 0;
  padding: 0.8em;
  line-height: 1;
  text-decoration: none;
  background: #ededed;
  font-size: 12px;
  font-weight: 700;
  border-radius: 8px;
  box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.16);
}
.display-info {
  width: 96%;
  min-height: 80px;
  height: auto;
  margin: 0% auto 0% auto;
  border-radius: 11px;
  background: #ededed;
}
.fitness-card {
  margin: 2% auto 0 auto;
  width: 100%;
  height: auto;
  background: #fff;
  /* filter: drop-shadow(9px 12px 15px rgba(0, 0, 0, 0.16)); */
  border-radius: 8px;
}

.fitness-card table th {
  text-align: left;
  padding: 5px;
  width: 50%;
}
.fitness-card table td {
  text-align: left;
  padding: 5px;
  width: 50%;
}

.user-info-input {
  width: 94%;
  margin-left: auto;
  margin-right: auto;
}
.user-info-input td {
  height: 35px;

  border: 3px #fff;
}
.user-info-input .td-1 {
  width: 48%;
  background: #ededed;
  border-radius: 6px;
}
.user-info-input .td-2 {
  width: 4%;
  background-color: #fff;
}
.user-info-input .td-3 {
  width: 48%;
  background: #ededed;
  border-radius: 6px;
}
.td-4 {
  width: 48%;
  background: #ededed;
  border-radius: 6px;
}
.user-info-input .td-5 {
  width: 25%;
  background: #ededed;
  border-radius: 6px;
  margin-right: 12px;
}
.user-info-input .td-6 {
  width: 2%;
  border-radius: 6px;
}
.user-info-input .td-7 {
  width: 20%;
  background: #ededed;
  border-radius: 6px;
}
.user-info-input .td-8 {
  width: 35%;
  border-radius: 6px;
}

.fitness-card input[type="text"] {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  font-size: 16px;
  background: #ededed;
}
.title-style {
  font-family: "Hiragino Kaku Gothic ProN";
  font-weight: 600;
  text-align: left;
  color: #838383;
  padding: 12px 0 0 12px;
}

/* ---------------モーダルに関して */

.qualificat-button-style {
  border: none;
  width: 60%;
  height: 40px;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 700;
}
.qualification-modal2 {
  margin-left: auto;
  margin-right: auto;
  margin-top: 90px;
  width: 95%;
  height: 70%;
  background-color: #fff;
  overflow: auto;
}
.qualification-modal2 table {
  width: 100%;
}
.qualification-modal2 table th {
  width: 50%;
  background-color: #f2f2f2;
}
.qualification-modal2 table td {
  width: 50%;
  background-color: #ffffff;
}

.plus-qualification {
  width: 50%;
  height: 30px;
  background: #ededed;
  font-size: 16px;
  font-weight: 500;
  border-radius: 8px;
  box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.16);
  padding: 0.8em;
  border: none;
  outline: none;
}
.title-style-2 {
  font-family: "Hiragino Kaku Gothic ProN";
  font-weight: 600;
  text-align: left;
  margin-top: 0%;
  color: #838383;
  padding: 12px 0 0 0px;
}
.qualificat-button-style {
  border: none;
  width: 60%;
  height: 40px;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 700;
}
.qualificat-cancel-button {
  border: 2px #eb7620;
}
.qualificat-update-button {
  background-color: cadetblue;
  color: #ffffff;
  text-align: center;
  width: 100px;
  height: 35px;
  border: none;
  font-size: 16px;
  font-weight: 700;
  border-radius: 10px;
  background-color: rgb(3, 163, 168);
  box-shadow: 3px 6px 9px rgb(139, 139, 139);
}
.qualificat-button-table {
  margin-left: auto;
  margin-right: auto;
  width: 95%;
  background-color: #ffffff;
}
.qualificat-button-table td {
  padding: 10px;
}
.qualificat-button-table th {
  padding: 10px;
}

.error-msg {
  color: rgb(116, 0, 0);
  font-size: 14px;
  padding: 0%;
  margin-left: 4%;
  text-align: left;
}
.error-msg p {
  margin: 0%;
}
.guidance_base_button {
  margin-top: 8px;
  padding-left: 2%;
}
.guidance_base_plus {
  width: 80px;
  height: 24px;
  left: 224px;
  top: 395px;
}
.guidance_base_card {
  width: 96%;
  margin: auto;
  padding: 10px;
  border-radius: 10px;
  background: #fbfbfb;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  margin-bottom: 12px;
}
.plus-button {
  margin-right: 10px;
  background: #ffffff;
  border: 1px solid #c1c1c1;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 4px 0px 0px 12px;
  text-align: center;
}
.delete-button {
  background: #ffffff;
  border: 1px solid #c1c1c1;
  box-sizing: border-box;
  border-radius: 5px;
  padding: 4px 0px 0px 12px;
  text-align: center;
}
</style>
