<template>
  <!-- Map画面でページ最下部 の  クラスリスト押下時 表示される (Modal) -->
  <div>
    <div
      class="change-div"
      v-for="content in classInfoList"
      v-bind:key="content.id"
    >
      <table @click="doClassInfo(content.class_uid)">
        <tr>
          <th>
            <div
              class="image_circle"
              :style="{
                backgroundImage:
                  'url(' + content.profile_image_list[0].image_url + ')',
              }"
            ></div>
          </th>
          <td>
            <!-- 概要タイトル -->
            <div class="card-title">
              <h4>
                {{ content.class_name }}
              </h4>
            </div>
            <!-- 概要コンテント -->
            <div class="card-content">
              <h5>
                {{ content.location_address }}
                <p></p>
                {{ content.remarks }}
              </h5>
            </div>
          </td>
        </tr>
      </table>
    </div>
    <div>
      <div class="back-map" @click="doBackMap()">
        <h4 class="back-map-font">マップで表示</h4>
      </div>
    </div>
  </div>
</template>

<script>
import { clearAllBodyScrollLocks } from "body-scroll-lock";
import firebase from "firebase/compat";
export default {
  name: "ClassList",
  props: {},
  data() {
    return {
      image_url:
        "https://orthostudio.ca/wp-content/uploads/2016/11/image-3.jpg",
      classInfoList: [],
    };
  },
  created() {
    this.setScroll();
  },
  mounted() {
    // this.google = await this.setGoogleConfing();
    // await this.initializeMap();
    this.getClassInfoList();
  },
  methods: {
    async doClassInfo(class_uid) {
      await this.glb_set_localStorage("classID", class_uid);
      this.$router.push({
        name: "ClassInfo",
        path: "/ClassInfo",
        props: true,
      });
    },
    async getClassInfoList() {
      let self = this;
      await firebase
        .firestore()
        .collection(self.glb_table.class)
        .get()
        .then(async function(querySnapshot) {
          await querySnapshot.forEach(async function(doc) {
            await firebase
              .firestore()
              .collection(self.glb_table.class)
              .doc(doc.id)
              .get()
              .then((snap) => {
                //各クラスの情報を取得する
                if (
                  snap.data().status == "公開" &&
                  snap.data().inspection == self.glb_inspection.examined
                ) {
                  if (
                    snap.data().lat_and_lng.lat != null &&
                    snap.data().lat_and_lng.lat != "" &&
                    snap.data().lat_and_lng.lng != null &&
                    snap.data().lat_and_lng.lng != ""
                  ) {
                    let data = snap.data();
                    data.class_uid = doc.id;
                    self.classInfoList.push(data);
                  }
                }
              });
          });
        });
    },
    setScroll() {
      clearAllBodyScrollLocks();
    },
    doBackMap() {
      this.$emit("do-back-map", true);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.change-div {
  overflow: auto;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  width: 95%;
  height: 120px;
  background: rgb(255, 255, 255);
  /* background-color: black; */
  border-radius: 15px;
  box-shadow: 3px 6px 9px rgba(0, 0, 0, 0.2);
}
.change-div table {
  width: 100%;
  height: 100%;
}
.change-div table th {
  width: 120px;
  /* background-color: #fff; */
}
.change-div table td {
  position: relative;
}

.change-div table td .card-title {
  position: absolute;
  top: 12px;
  left: 0px;
  text-align: left;
}
.change-div table td .card-content {
  position: absolute;
  top: 35px;
  left: 0px;
  height: 40px;
  text-align: left;
  width: 100%;
  overflow: auto;
}

.image_circle {
  text-align: center;
  height: 100px;
  width: 100px;
  margin: 5px;
  border-radius: 10%;
  background-position: 54% 36%;
  background-size: cover;
}
.back-map {
  position: fixed;
  z-index: 3;
  margin-left: auto;
  margin-right: auto;
  height: 40px;
  top: 90%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(80, 80, 80, 0.9);
  box-shadow: 3px 6px 9px rgba(0, 0, 0, 0.5);
  border-radius: 15px;
}
.back-map-font {
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  padding: 12px;
}
</style>
