<template>
  <div class="home">
    <div class="text-style">
      <p class="fw-normal font-style">
        <span>
          運動の前後に実施をすることで参加者は運動効果を主観的に実感し、そして指導者側は客観的に成果を評価することができる。
          <br />参加者は、効果を実感することで運動継続へのモチベーションアップに繋がるだけなく、体の変化に敏感になることでより主体的に運動に取り組むことができるようになる。
          <br />指導者側は専門性を持って客観的に評価しフィードバックをすることで、参加者との信頼関係の構築にも繋がる。
        </span>
      </p>
    </div>

    <div class="title">
      <h4 class="display-6 program-font-style">①ニーリフト</h4>
    </div>

    <div class="text-style">
      <p class="fw-normal font-style">
        <span>
          股関節屈曲動作の確認
          <br />バランス機能の確認
        </span>
      </p>
    </div>
    <div class="card video-div-style">
      <video :src="video_url_1" controls preload="metadata" class="video-style" />
    </div>
    <div class="sapace-style"></div>
    <div class="title">
      <h4 class="display-6 program-font-style">②トランクローテーション</h4>
    </div>

    <div class="text-style">
      <p class="fw-normal font-style">
        <span>回旋動作の確認</span>
      </p>
    </div>
    <div class="card video-div-style">
      <video :src="video_url_2" controls preload="metadata" class="video-style" />
    </div>
    <div class="sapace-style"></div>
    <div class="title">
      <h4 class="display-6 program-font-style">③バックエクステンション</h4>
    </div>

    <div class="text-style">
      <p class="fw-normal font-style">
        <span>伸展動作の確認</span>
      </p>
    </div>
    <div class="card video-div-style">
      <video :src="video_url_3" controls preload="metadata" class="video-style" />
    </div>
  </div>
</template>

<script>
export default {
  name: "MonitoringTheory",
  props: {},
  data() {
    return {
      video_url_1:
        "https://firebasestorage.googleapis.com/v0/b/fitnesscamp-428f2.appspot.com/o/monitoring_program%2Fcontent%2Fmovie%2FkneeLift.mov?alt=media&token=b577c8ba-26e3-4bc7-a6c0-7ae3966391e3",
      video_url_2:
        "https://firebasestorage.googleapis.com/v0/b/fitnesscamp-428f2.appspot.com/o/monitoring_program%2Fcontent%2Fmovie%2FtrunkRotation.mov?alt=media&token=54764c98-d1d1-4b79-b1a8-ccf9ebc5f876",
      video_url_3:
        "https://firebasestorage.googleapis.com/v0/b/fitnesscamp-428f2.appspot.com/o/monitoring_program%2Fcontent%2Fmovie%2FbackExtension.mov?alt=media&token=34c5333d-f0ae-4c4d-9a63-322fe08a064b"
    };
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.title {
  padding-top: 4%;
  text-align: left;
}
.home > img {
  padding-top: 1%;
  width: 96%;
  height: auto;
  min-height: 100px;
}
.program-font-style {
  font-weight: 600;
  color: #000;
}
.text-style {
  padding-top: 2%;
  text-align: left;
  font-size: 14px;
  font-weight: 500;
}
.font-style {
  letter-spacing: 1px;
  line-height: 25px;
  padding-top: 8px;
}
.span-style {
}
.span-start {
  background: linear-gradient(transparent 50%, #fcf49c 0%);
}
.span-end {
  margin-left: -4px;
  margin-right: -4px;
  background: linear-gradient(transparent 50%, #fcf49c 0%);
}
.sapace-style {
  margin-top: 10%;
}
.video-div-style {
  height: auto;
  width: auto;
  margin-top: 5%;
  margin-left: 2%;
  margin-right: 2%;
  border: 0;
  background-color: rgb(233, 233, 233);
}
.video-style {
  height: 250px;
}

</style>
