<template>
  <div>
    <div>
      <div
        v-for="(content, index) in program_content.explanation_list"
        v-bind:key="index"
      >
        <div class="title-card">
          <br />
          <div class="input-form">
            <div class="title ">
              <h4 class="input-title">プログラム説明</h4>
            </div>
            <div class="input-group input-group-lg">
              <textarea
                rows="6"
                class="form-control input-content"
                aria-label="With textarea"
                v-model="content.explanation"
              ></textarea>
            </div>
            <div class="date-fiex">
              <div class="date-button plus-button" @click="plusData(index)">
                <label>追加</label>
              </div>
              <div class="date-button delete-button" @click="deleteData(index)">
                <label>削除</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="button-class">
      <div class="d-flex justify-content-around">
        <button class="button cancel" @click="doCancel()">キャンセル</button>
        <button class="button confirmation" @click="doConfirmation()">
          確認
        </button>
      </div>
    </div>
  </div>
</template>

<script>
// import firebase from "firebase/compat";
export default {
  name: "Modal",
  components: {},
  props: {
    index: null,
    content_type: String,
  },
  data() {
    return {
      programList: [],
      title: "",
      program_info: {
        name: "",
        detail_image: "",
        detail_content: {
          detail_list: [],
        },
      },
      program_content: {
        detail_list: [],
        explanation_list: [],
      },
      program_content_back: {
        detail_list: [],
        explanation_list: [],
      },
      program_content_type: null,
      clickIndex: null,
      libraryEvent: false,
      uploadFileImage: {
        image_name: "",
        image_url: "",
      },
      uploadFileVideo: {
        video_name: "",
        video_url: "",
      },
    };
  },
  async created() {
    await this.getProgramInfo();
  },
  methods: {
    async doBack() {
      this.$emit("do-back");
    },
    doCancel() {
      this.$emit("do-back");
    },
    async doConfirmation() {
      // await this.checkContent_type(1);
      await this.glb_set_localStorage_new_program_info(this.program_info);
      this.$emit("do-back");
    },
    plusData(index) {
      this.program_info[this.content_type].explanation_list.splice(
        index + 1,
        0,
        {
          explanation: "",
        }
      );
    },
    deleteData(index) {
      if (this.program_info[this.content_type].explanation_list.length > 1) {
        this.program_info[this.content_type].explanation_list.splice(index, 1);
      }
    },
    async getProgramInfo() {
      this.clickIndex = this.index;
      await this.getProgramInfoForStorage();
      await this.checkContent_type();
    },
    async checkContent_type() {
      this.program_content.explanation_list = this.program_info[
        this.content_type
      ].explanation_list;
    },

    async getProgramInfoForStorage() {
      this.program_info = await this.glb_get_mk_program_info();
    },
  },
};
</script>

<style scoped>
.page-content {
  width: 98%;
  height: auto;
  margin: auto;
  overflow: auto;
  padding-bottom: 200px;
  background-color: #fafafa;
}
.mk-library {
  font-family: Work Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  align-items: center;
  letter-spacing: -0.02em;
  text-decoration-line: underline;
  color: #6b6b6b;
}
.title-image {
  text-align: center;
  margin-right: 8px;
  margin-top: 2px;
}
.title-image img {
  width: 15px;
  height: 15px;
}
.input-form {
  margin-left: auto;
  margin-right: auto;
  text-align: left;
  width: 95%;
  padding: 5px;
  margin-top: 10px;
  margin-bottom: 24px;
}
.input-group-text {
  background-color: #ffffff;
  border: none;
  padding-left: 0%;
  font-weight: 600;
  font-size: 16px;
}
.title {
  display: flex;
}
.input-title {
  font-weight: 600;
}
.input-content {
  background-color: #ededed;
  border: none;
  outline: none;
  font-size: 16px;
  font-size: 1.5rem;
  color: rgb(0, 0, 0);
  outline: none;
}
input.form-control.input-content {
  font-size: 16px;
}
.img-fiex {
  display: flex;
  flex-wrap: wrap;
}
.title-card {
  width: 95%;
  height: 300px;
  margin: auto;
  margin-top: 24px;
  padding: 10px;
  overflow: auto;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
}
.date-fiex {
  display: flex;
  width: 92%;
  margin-top: 12px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 6px;
}
.date-button {
  width: 60px;
  height: 25px;
  border-radius: 5px;
  text-align: center;
}
.date-button label {
}
.plus-button {
  padding-top: 4px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
  /* identical to box height */
  letter-spacing: -0.02em;
  background: #ffffff;
  border: 1px solid #c1c1c1;
  box-sizing: border-box;
  border-radius: 5px;
}
.delete-button {
  margin-left: 10px;
  padding-top: 4px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
  /* identical to box height */
  letter-spacing: -0.02em;
  color: #ffffff;
  background: #a0a0a0;
  border-radius: 5px;
}
.button-class {
  width: 100%;
  height: 70px;
  background: #fafafa;
  margin-top: 200px;
  margin-right: auto;
  margin-left: auto;
  position: fixed;
  justify-content: space-between;
  bottom: 70px;
}
.button-class .button {
  margin-top: 16px;
  width: 140px;
  height: 38px;
  border-radius: 6px;
}
.button-class .confirmation {
  background: #1ea6ae;
  font-family: Work Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  letter-spacing: -0.02em;
  border: none;
  color: #ffffff;
}
.button-class .cancel {
  background: #ffffff;
  border: 1px solid #b7b7b7;
  font-family: Work Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #717171;
}
.video-div {
  margin-top: -12px;
}
</style>
