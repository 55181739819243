<template>
  <div>
    <div class="home">
      <div class="about">
        <a href="#" v-on:click.stop.prevent="openAbotModal()" class="alert-link">>>About</a>
      </div>
      <div v-if="isModal">
        <div class="popContainer">
          <div class="heard-style">
            <h2>3.アクティベーション</h2>
          </div>
          <div class="card card-modal">
            <div class="card-body">
              <ActivationAbout />
              <br />
              <br />
            </div>
          </div>
          <div class="close-button">
            <div class="close-button-div">
              <a
                href="#"
                v-on:click.stop.prevent="closeAbotModal()"
                class="alert-link close-button-text"
              >閉じる</a>
            </div>
          </div>
        </div>
      </div>

      <div>
        <!-- 説明部分 -->
        <div class="card border-light mb-3 card-style">
          <div class="card-body">
            <p class="card-text title-conten-font">
              アクティベーションは筋に刺激を入れる運動です。
              <br />姿勢を正すために必要な筋をオンにします。
            </p>
          </div>
        </div>
      </div>

      <div>
        <!-- ビデオ -->
        <div class="card video-div-style">
          <video :src="video_url_1" controls preload="metadata" class="video-style" />
        </div>
        <!-- タイトル -->
        <p class="h4 h4-title">ショルダーW</p>
        <!-- 説明 -->
        <div class="card border-light mb-3 card-style">
          <div class="card-body">
            <p class="card-text title-conten-font">
              足を開いて安定させて、首を長くしたままお尻を後ろに引いて体を前に倒します
              <br />肘をまげて手を肩の横に保ち親指を上に向けます。
              <br />その状態で背中を絞り込むように背中を寄せまます。
              <br />できるだけ寄せましょう。
              <br />首が短くならないように、腰が丸くならないように。
            </p>
          </div>
        </div>
        <!-- 目的、動作、ポイントtable -->
        <div>
          <table>
            <tr>
              <th>目的</th>
              <td>背部（主に菱形筋、僧帽筋下部）の刺激</td>
            </tr>
            <tr>
              <th>動作</th>
              <td>
                １．足は肩幅程度に開く
                <br />２．体幹が床と平行まで前傾する
                <br />３．肩は外転45°肘は軽度屈曲する（W)
                <br />４．母指は上を向ける（前腕回外）
                <br />５．肩甲骨の内転を行う
                <br />６．最大内転位で5秒静止する
                <br />７．5回行う
                <br />
              </td>
            </tr>
            <tr>
              <th>ポイント</th>
              <td>肩甲骨の挙上に注意する</td>
            </tr>
          </table>
        </div>
      </div>
      <div>
        <!-- ビデオ -->
        <div class="card video-div-style">
          <video :src="video_url_2" controls preload="metadata" class="video-style" />
        </div>
        <!-- タイトル -->
        <p class="h4 h4-title">ハイプランクニーアップ</p>
        <!-- 説明 -->
        <div class="card border-light mb-3 card-style">
          <div class="card-body">
            <p class="card-text title-conten-font">
              まず足にチューブを巻きます。
              <br />肩の真下に手をついて首を長く保ちます（頭から足まで一直線を保ちます）
              <br />おへそから足が生えているイメージでまっすぐ足をあげます。
              <br />息を吐きながら（最後まで吐きながら）足をあげていきます。
            </p>
          </div>
        </div>
        <!-- 目的、動作、ポイントtable -->
        <div>
          <table>
            <tr>
              <th>目的</th>
              <td>鼠径部への刺激（肩甲骨、体幹含む）</td>
            </tr>
            <tr>
              <th>動作</th>
              <td>
                １．手は肩の真下で体幹を一直線にする
                <br />２．姿勢を保ったまま片足を屈曲する
                <br />３．屈曲ポジションで5秒静止する
                <br />４．交互に8回（片側4回ずつ）行う
              </td>
            </tr>
            <tr>
              <th>ポイント</th>
              <td>腰椎の後弯、骨盤後傾、頭部が下がるのに注意をする</td>
            </tr>
          </table>
        </div>
      </div>
      <div>
        <!-- ビデオ -->
        <div class="card video-div-style">
          <video :src="video_url_3" controls preload="metadata" class="video-style" />
        </div>
        <!-- タイトル -->
        <p class="h4 h4-title">エルボーニーサイドプランク</p>
        <!-- 説明 -->
        <div class="card border-light mb-3 card-style">
          <div class="card-body">
            <p class="card-text title-conten-font">
              最終的に脇が90°くらいになるように肘を構え、上の手はまっすぐ上にあげましょう。
              <br />下の足は膝をまげて地面につけて、上の足は膝を伸ばして構えましょう。
              <br />肘から上の手まで一本の棒のように、肘から根が生えているように体を上にあげます。
              <br />お腹はお屁を引っ込めて首を長くして、上の足の爪先は下を向けて。
            </p>
          </div>
        </div>
        <!-- 目的、動作、ポイントtable -->
        <div>
          <table>
            <tr>
              <th>目的</th>
              <td>肩甲骨、体幹、股関節側面への刺激</td>
            </tr>
            <tr>
              <th>動作</th>
              <td>
                １．肩は90°外転し床につける
                <br />２．頭から下の足まで一直線を保つ
                <br />３．上の手は90°外転する
                <br />４．上の足は軽度外転する
                <br />５．目線は正面を向けポジションを保つ
                <br />６．20秒行う
              </td>
            </tr>
            <tr>
              <th>ポイント</th>
              <td>
                肩甲骨にしっかり荷重する
                <br />リブフレアに気を付ける
                <br />上の足の爪先を下に向ける
              </td>
            </tr>
          </table>
        </div>
      </div>
      <div>
        <!-- ビデオ -->
        <div class="card video-div-style">
          <video :src="video_url_4" controls preload="metadata" class="video-style" />
        </div>
        <!-- タイトル -->
        <p class="h4 h4-title">片側ヒップエクスターナルローテーション</p>
        <!-- 説明 -->
        <div class="card border-light mb-3 card-style">
          <div class="card-body">
            <p class="card-text title-conten-font">
              チューブを膝下に巻いて肩幅よりも足を開いで
              <br />お尻を引いて膝を曲げましょう。（低く構える）
              <br />爪先は少し開いで膝を同じ方向に向けましょう。
              <br />足の裏全体を地面につけて離れないようにする、上半身は首を長く保ちます。
              <br />足裏が地面から離れないように内側から外側へ絞り込むように広げましょう。
              <br />膝を開く時に体が一緒に回ったり、反対側の膝が内側に入らないように。
            </p>
          </div>
        </div>
        <!-- 目的、動作、ポイントtable -->
        <div>
          <table>
            <tr>
              <th>目的</th>
              <td>臀筋群への刺激</td>
            </tr>
            <tr>
              <th>動作</th>
              <td>
                １．足を肩幅よりも若干開く
                <br />２．大腿が床から45°位を保つ
                <br />パワーポジション
                <br />３．足の裏をつけたまま股関節を内外旋する
                <br />４．逆側の足は動かさないように注意する
                <br />５．10回ずつ行う
              </td>
            </tr>
            <tr>
              <th>ポイント</th>
              <td>体幹や反対の股関節の回旋に注意する</td>
            </tr>
          </table>
        </div>
      </div>
      <div>
        <!-- ビデオ -->
        <div class="card video-div-style">
          <video :src="video_url_5" controls preload="metadata" class="video-style" />
        </div>
        <!-- タイトル -->
        <p class="h4 h4-title">両側ヒップエクスターナルローテーション</p>
        <!-- 説明 -->
        <div class="card border-light mb-3 card-style">
          <div class="card-body">
            <p class="card-text title-conten-font">
              （片側が終わったらそのまま）
              <br />両足を内側から外側へ広げましょう。
            </p>
          </div>
        </div>
        <!-- 目的、動作、ポイントtable -->
        <div>
          <table>
            <tr>
              <th>目的</th>
              <td>臀筋群、内転筋群への刺激</td>
            </tr>
            <tr>
              <th>動作</th>
              <td>
                １．足を肩幅よりも若干開く
                <br />２．大腿が床から45°位を保つ
                <br />パワーポジション
                <br />３．足の裏をつけたまま股関節を内外旋する
                <br />４．両側動に内外旋する
                <br />５．10回行う
                <br />※片側ヒップエクスターナルローテーションと連続して行う
              </td>
            </tr>
            <tr>
              <th>ポイント</th>
              <td>
                過度な内旋に注意する（膝への負担）
                <br />内外旋に伴う爪先の動きに注意をする
              </td>
            </tr>
          </table>
        </div>
      </div>

      <div>
        <!-- ビデオ -->
        <div class="card video-div-style">
          <video :src="video_url_6" controls preload="metadata" class="video-style" />
        </div>
        <!-- タイトル -->
        <p class="h4 h4-title">ディープスクワットシークエンス</p>
        <!-- 説明 -->
        <div class="card border-light mb-3 card-style">
          <div class="card-body">
            <p class="card-text title-conten-font">
              足を肩幅くらい、爪先を４５°くらい開いて立ちましょう。
              <br />そのまま蹲み込んで爪先を持ちます。
              <br />その時に肘で膝をしっかり開き、そして首を長く背中を保ちましょう。
              <br />胸から手が生えているように目線は手を追いながら手をあげましょう。
              <br />最後に首を長く保ったまま両手をあげて立ち上がりましょう。
            </p>
          </div>
        </div>
        <!-- 目的、動作、ポイントtable -->
        <div>
          <table>
            <tr>
              <th>目的</th>
              <td>股関節、胸椎中心の全身的なアクティベーション</td>
            </tr>
            <tr>
              <th>動作</th>
              <td>
                １．肩幅で立ち爪先は45°に外旋する
                <br />２．膝の内側から爪先を掴む
                <br />骨盤脊柱のアライメントを保つ
                <br />３．片側の胸椎を回旋させる
                <br />目線は指先を見る
                <br />４．逆側の胸椎を回旋させる
                <br />５．両手を挙上し胸椎を伸展させる
                <br />６．OHのまま立ち上がる
                <br />７．右手スタート、左手スタート、右手スタート、左手スタートと合計4回行う
              </td>
            </tr>
            <tr>
              <th>ポイント</th>
              <td>
                胸椎のアライメントをに特に注意する
                <br />逆の手は膝が内側に入らないように押さえる
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ActivationAbout from "../commentary/ActivationAbout";
export default {
  name: "Activation",
  components: {
    ActivationAbout
  },
  props: {},
  data() {
    return {
      isModal: false,
      video_url_1:
        "https://firebasestorage.googleapis.com/v0/b/fitnesscamp-428f2.appspot.com/o/program%2Fcontent%2Fmovie%2Factive%2Fshoulder.mp4?alt=media&token=e889e7b0-affb-4679-a73a-338a0542d4be",
      video_url_2:
        "https://firebasestorage.googleapis.com/v0/b/fitnesscamp-428f2.appspot.com/o/program%2Fcontent%2Fmovie%2Factive%2Fhigh_prank.mp4?alt=media&token=1fd5a237-e66a-49a4-8110-421aa9fec51b",
      video_url_3:
        "https://firebasestorage.googleapis.com/v0/b/fitnesscamp-428f2.appspot.com/o/program%2Fcontent%2Fmovie%2Factive%2Felbow_prank.mp4?alt=media&token=5e4b543c-8d7f-4461-8ad1-d6635c1f9ddc",
      video_url_4:
        "https://firebasestorage.googleapis.com/v0/b/fitnesscamp-428f2.appspot.com/o/program%2Fcontent%2Fmovie%2Factive%2Fside.mp4?alt=media&token=74de4ed8-5f1d-4f67-8b2a-141f177e02f8",
      video_url_5:
        "https://firebasestorage.googleapis.com/v0/b/fitnesscamp-428f2.appspot.com/o/program%2Fcontent%2Fmovie%2Factive%2Fdouble.mp4?alt=media&token=248a8bd8-41f6-46ad-8863-c6e7f6c239c0",
      video_url_6:
        "https://firebasestorage.googleapis.com/v0/b/fitnesscamp-428f2.appspot.com/o/program%2Fcontent%2Fmovie%2Factive%2Fdeep_squat.mp4?alt=media&token=6046fa09-6276-4bdf-b505-ca70e156b3e9"
    };
  },
  methods: {
    openAbotModal() {
      this.isModal = true;
    },
    closeAbotModal() {
      this.isModal = false;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.heard-style {
  padding-top: 14px;
  height: 50px;
  background-color: #04545c;
  border: 0;
  margin-top: 5%;
  margin-left: 8%;
  margin-right: 8%;
}
.heard-style img {
  float: left;
  margin-left: 2%;
}
.heard-style > h2 {
  color: rgb(255, 255, 255);
  margin-right: 4%;
  font-weight: 900;
}
.close-button {
  height: 50px;
  background-color: #ffffff;
  border: 0;
  margin-left: 8%;
  margin-right: 8%;
}
.close-button-div {
  background-color: rgb(255, 255, 255);
  text-align: right;
  margin-right: 4%;
  padding-top: 12px;
}
.close-button-text {
  font-size: 16px;
  font-weight: 400;
  color: rgb(163, 163, 163);
}

.about {
  text-align: right;
  margin-top: 2%;
  margin-bottom: 3%;
}
.about > a:link,
a:visited,
a:hover,
a:active {
  text-decoration: none;
  font-size: 16px;
  font-weight: 400;
  color: rgb(163, 163, 163);
}
.title {
  padding-top: 4%;
  text-align: left;
}

.card-style {
  max-width: 100rem;
  margin: 2%;
  background-color: rgb(248, 248, 248);
}
.title-font {
  color: #000;
  font-weight: 600;
  text-align: left;
}
.title-conten-font {
  color: #000;
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  letter-spacing: 1px;
  line-height: 20px;
}
.video-div-style {
  height: auto;
  width: auto;
  margin-top: 15%;
  margin-left: 2%;
  margin-right: 2%;
  border: 0;
  background-color: rgb(233, 233, 233);
}
.video-style {
  height: 250px;
}
.h4-title {
  margin-top: 5%;
  margin-bottom: 4%;
  margin-left: 2%;
  text-align: left;
  font-weight: 600;
  color: #000;
}
.home > img {
  padding-top: 1%;
  width: 96%;
}
.program-font-style {
  font-weight: 600;
  color: #000;
}
.text-style {
  padding-top: 2%;
  text-align: left;
  font-size: 14px;
  font-weight: 500;
}
.font-style {
  letter-spacing: 2px;
  line-height: 25px;
  padding-top: 8px;
}
.span-style {
  margin-left: -5px;
  margin-right: -5px;
  background: linear-gradient(transparent 50%, #f5f100 0%);
}

/* table */
table {
  margin-top: 8%;
  margin-left: 2%;
  margin-right: 2%;
  width: 96%;
  border-collapse: collapse;
}

table tr {
  width: 30px;
  border-bottom: solid 1px white;
}

table tr:last-child {
  border-bottom: none;
}

table th {
  position: relative;
  text-align: left;
  width: 25%;
  height: auto;
  background-color: #e4ecec;
  color: rgb(0, 0, 0);
  text-align: center;
  padding: 10px 0;
  font-size: 14px;
  font-weight: 400;
}
table td {
  position: relative;
  text-align: left;
  height: auto;
  background-color: rgb(247, 247, 247);
  color: rgb(0, 0, 0);
  text-align: left;
  padding: 10px 10px;
  font-size: 14px;
  font-weight: 400;
}
.popContainer {
  z-index: 2;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(43, 43, 43, 0.3);
}
.card-modal {
  overflow: auto;
  z-index: 2;
  margin-top: 0%;
  margin-left: 8%;
  margin-right: 8%;
  height: 75%;
  background-color: rgb(255, 255, 255);
}
</style>
