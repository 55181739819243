<template>
  <div>
    <div class="heard-style">
      <a href="#">
        <img src="@/assets/home/yajirusi.svg" width="20" height="20" />
      </a>
      <h2>ベーシックプログラム</h2>
    </div>
    <!-- 画像 -->
    <div class="home home-style">
      <div class="home-img">
        <img src="@/assets/basic/basic_main.png" loading="lazy" height="230" />
      </div>
    </div>

    <!-- 説明部分 -->
    <div class="card border-light mb-3 card-style">
      <div class="card-body">
        <h3 class="card-title title-font">まずは基本を抑えましょう</h3>
        <p class="card-text title-conten-font">
          呼吸からサーキットトレーニングまでの基本的な種目を集めたベーシックプログラムです。しっかり流れを覚えてから、次のプログラムに移りましょう。
        </p>
      </div>
    </div>
    <div class="card border-light mb-3 card-style">
      <div class="card-body">
        <h3 class="card-title title-font">必要なもの</h3>
        <p class="card-text title-conten-font">
          ビーチボール、コーン、ミーバンド、フェイスタオル、バスタオル or マット
        </p>
      </div>
    </div>

    <div class="all-open">
      <div v-if="allEventValue">
        <button type="button" class="button-style" @click="allEvent(false)">All Close</button>
      </div>
      <div v-else-if="checkEventValue">
        <button type="button" class="button-style" @click="allEvent(false)">All Close</button>
      </div>
      <div v-else>
        <button type="button" class="button-style" @click="allEvent(true)">All Open</button>
      </div>
    </div>

    <!-- 詳細部分折り畳み -->
    <div class="check-div">
      <input id="acd-check1" class="acd-check" type="checkbox" v-model="check[0]" />
      <label class="acd-label" @click="checkEvent(0)">
        <h2 class="display-5 program-font-style">呼吸/アライメント改善</h2>
      </label>
      <div class="acd-content" v-if="check[0]">
        <Improvement />
      </div>
      <input id="acd-check2" class="acd-check" type="checkbox" v-model="check[1]" />
      <label class="acd-label" @click="checkEvent(1)">
        <h2 class="display-5 program-font-style">スタティックストレッチ</h2>
      </label>
      <div class="acd-content" v-if="check[1]">
        <StaticStrech />
      </div>
      <input id="acd-check3" class="acd-check" type="checkbox" v-model="check[2]" />
      <label class="acd-label" @click="checkEvent(2)">
        <h2 class="display-5 program-font-style">アクティベーション</h2>
      </label>
      <div class="acd-content" v-if="check[2]">
        <Activation />
      </div>
      <input id="acd-check4" class="acd-check" type="checkbox" v-model="check[3]" />
      <label class="acd-label" @click="checkEvent(3)">
        <h2 class="display-5 program-font-style">ダイナミックストレッチ</h2>
      </label>
      <div class="acd-content" v-if="check[3]">
        <DynamicMovement />
      </div>
      <input id="acd-check5" class="acd-check" type="checkbox" v-model="check[4]" />
      <label class="acd-label" @click="checkEvent(4)">
        <h2 class="display-5 program-font-style">ムーブメントトレーニング</h2>
      </label>
      <div class="acd-content" v-if="check[4]">
        <MovementTraining />
      </div>
      <input id="acd-check6" class="acd-check" type="checkbox" v-model="check[5]" />
      <label class="acd-label" @click="checkEvent(5)">
        <h2 class="display-5 program-font-style">サーキットトレーニング</h2>
      </label>
      <div class="acd-content" v-if="check[5]">
        <CircuitTraining />
      </div>
      <input id="acd-check7" class="acd-check" type="checkbox" v-model="check[6]" />
      <label class="acd-label" @click="checkEvent(6)">
        <h2 class="display-5 program-font-style">グループワーク</h2>
      </label>
      <div class="acd-content" v-if="check[6]">
        <GroupWorkAbout />
      </div>
    </div>
    <div class="sapace-style"></div>
  </div>
</template>

<script>
// @ is an alias to /src
import Improvement from "../../components/basic/Improvement";
import StaticStrech from "../../components/basic/StaticStrech";
import Activation from "../../components/basic/Activation";
import DynamicMovement from "../../components/basic/DynamicMovement";
import MovementTraining from "../../components/basic/MovementTraining";
import CircuitTraining from "../../components/basic/CircuitTraining";
import GroupWorkAbout from "../../components/basic/GroupWork";

export default {
  name: "Basic",
  components: {
    Improvement,
    StaticStrech,
    Activation,
    DynamicMovement,
    MovementTraining,
    CircuitTraining,
    GroupWorkAbout
  },
  data() {
    return {
      monitoring: "<モニタリング>",
      check: [false, false, false, false, false, false, false],
      allEventValue: false,
      checkEventValue: false
    };
  },
  methods: {
    checkEvent(index) {
      if (!this.check[index]) {
        this.checkEventValue = true;
      } else {
        this.checkEventValue = false;
      }
      this.check[index] = !this.check[index];
    },
    allEvent(isValue) {
      if (!isValue) {
        this.checkEventValue = false;
      }
      let self = this;
      self.check.forEach(function(value, index) {
        self.check[index] = isValue;
      });
      self.allEventValue = isValue;
    }
  }
};
</script>
<style scoped>
.heard-style {
  position: fixed;
  z-index: 2;
  margin-top: 0%;
  padding-top: 18px;
  width: 100%;
  height: 58px;
  background-color: #04545c;
}
.heard-style img {
  float: left;
  margin-left: 2%;
}
.heard-style > h2 {
  color: rgb(255, 255, 255);
  margin-right: 4%;
  font-weight: 900;
}
.home-img img {
  margin-top: 58px;
  width: 100%;
  /* height: auto; */
}
.home-img img::before {
  content: "";
  display: block;
  padding-top: calc(((720 / 1280) * 100%));
}
.card-style {
  max-width: 100rem;
  margin: 2%;
  background-color: rgb(248, 248, 248);
}
.title-font {
  color: #000;
  font-weight: 600;
  text-align: left;
}
.title-conten-font {
  color: #000;
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  letter-spacing: 1px;
  line-height: 20px;
}
.all-open {
  text-align: right;
  margin-right: 2%;
  margin-top: 5%;
  margin-bottom: 5%;
}
.button-style {
  width: 60px;
  height: 30px;
  font-size: 12px;
  text-align: center;
  padding: 0%;
  background: #ffffff;
  border-radius: 5px;
  border: 1px solid #015a53;
}

/* 折りたたみ */
.check-div {
}
.acd-check {
  display: none;
  border-bottom: 1px solid rgb(177, 177, 177);
}
.acd-label {
  background: #04545c;
  color: rgb(255, 255, 255);
  display: block;
  margin-bottom: 1px;
  padding: 16px;
  position: relative;
  font-size: 2em;
  height: auto;
  border-bottom: 0.5px solid rgb(255, 254, 240);
  text-align: left;
  margin: 0%;
}
.acd-label:after {
  background: #04545c;
  box-sizing: border-box;
  content: url(../../assets/home/downY.svg);
  display: block;
  font-family: "Font Awesome 5 Free";
  height: 50px;
  width: 50px;
  padding: 10px 20px;
  position: absolute;
  right: 0;
  top: 0px;
  height: 55px;
  border-bottom: 0px solid rgb(255, 254, 240);
}
.acd-content {
  border: 1px solid #333;
  display: block;
  height: 0;
  opacity: 0;
  padding: 0 10px;
  transition: 0.5s;
  visibility: hidden;
  border: 0;
}
.acd-check:checked + .acd-label:after {
  content: url(../../assets/home/upY.svg);
}
.acd-check:checked + .acd-label + .acd-content {
  height: auto;
  opacity: 1;
  padding: 10px;
  visibility: visible;
  border: 0;
  background-color: #ffffff;
  border-bottom: 0.5px solid rgb(255, 254, 240);
}
.program-font-style {
  font-weight: 600;
  width: 90%;
}
.sapace-style {
  height: 200px;
  background: #04545c;
  margin-bottom: 0%;
}
</style>
