<template>
  <div>
    <div class="page-content">
      <div class="input-form">
        <div class="title">
          <h4 class="input-title">カテゴリー</h4>
        </div>
        <div class="input-group mb-3">
          <select
            class="custom-select select-form"
            id="inputGroupSelect01"
            v-model="program_info.category"
          >
            <option
              v-for="option in glb_program_category"
              v-bind:value="option.value"
              :key="option.value"
              >{{ option.text }}
            </option>
          </select>
        </div>
        <br />
        <div class="title">
          <h4 class="input-title">公開ステータス</h4>
        </div>
        <div class="input-group mb-3">
          <select
            class="custom-select select-form"
            id="inputGroupSelect01"
            v-model="program_info.status"
          >
            <option
              v-for="option in glb_program_status"
              v-bind:value="option.value"
              :key="option.value"
              >{{ option.text }}
            </option>
          </select>
        </div>
        <br />
        <div class="title">
          <h4 class="input-title">認定コーチオリジナル</h4>
        </div>
        <div class="input-group mb-3">
          <select
            class="custom-select select-form"
            id="inputGroupSelect01"
            v-model="program_info.type"
          >
            <option
              v-for="option in program_type_option"
              v-bind:value="option.value"
              :key="option.value"
              >{{ option.text }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div class="button-class">
      <div class="d-flex justify-content-around">
        <button class="button cancel" @click="doCancel()">キャンセル</button>
        <button class="button confirmation" @click="doConfirmation()">
          確認
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Modal",
  components: {},
  data() {
    return {
      programList: [],
      title: "",

      program_info: {
        name: "",
        detail_image: "",
        detail_content: {
          detail_list: [],
        },
        status: "",
        category: "",
        type: "個人",
      },
      program_type_option: [],
      program_page: {},
    };
  },
  async created() {
    await this.getProgramInfoForStorage();
    await this.setProgramType();
  },
  methods: {
    doCancel() {
      this.$emit("do-back");
    },
    doConfirmation() {
      this.glb_set_localStorage_new_program_info(this.program_info);
      this.$emit("do-back");
    },
    async setProgramType() {
      this.program_type_option = this.glb_mk_program_type_option;
      this.program_info.type = this.glb_mk_program_type.informal;
      let user_info = await this.glb_get_user_info(
        await this.glb_get_user_uid()
      );
      if (user_info.authority != this.glb_user_authority.admin) {
        this.program_type_option = [{ text: "個人", value: "個人" }];
      }
    },
    async getProgramInfoForStorage() {
      this.program_info = await this.glb_get_mk_program_info();
      this.program_info.status = this.glb_program_status_type.draft;
    },
  },
};
</script>

<style scoped>
.page-content {
  width: 98%;
  height: auto;
  min-height: 700px;
  margin: auto;
  overflow: auto;
  padding-bottom: 200px;
  background-color: #fafafa;
}
.input-form {
  margin-left: auto;
  margin-right: auto;
  text-align: left;
  width: 95%;
  padding: 5px;
  margin-top: 10px;
  margin-bottom: 24px;
}
.input-group-text {
  background-color: #ffffff;
  border: none;
  padding-left: 0%;
  font-weight: 600;
  font-size: 16px;
}
.title {
  display: flex;
}
.title .mandatory {
  width: 33px;
  height: 18px;
  margin-left: 4px;
  border-radius: 5px;
  background-color: #970000;
}
.title .mandatory label {
  font-family: Work Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 11px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #ffffff;
  padding-left: 5px;
  padding-top: 1px;
}
.input-title {
  font-weight: 600;
}
.input-content {
  background-color: #ededed;
  border: none;
  outline: none;
  font-size: 16px;
  font-size: 1.5rem;
  color: rgb(0, 0, 0);
  outline: none;
}
input.form-control.input-content {
  font-size: 16px;
}
.img-fiex {
  display: flex;
  flex-wrap: wrap;
}
.title-card {
  width: 95%;
  height: 350px;
  margin: auto;
  margin-top: 24px;
  padding: 10px;
  overflow: auto;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
}
.date-fiex {
  display: flex;
  width: 92%;
  margin-top: 12px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 6px;
}
.date-button {
  width: 60px;
  height: 25px;
  border-radius: 5px;
}
.plus-button {
  padding-top: 4px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
  /* identical to box height */
  letter-spacing: -0.02em;
  background: #ffffff;
  border: 1px solid #c1c1c1;
  box-sizing: border-box;
  border-radius: 5px;
}
.delete-button {
  margin-left: 10px;
  padding-top: 4px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
  /* identical to box height */
  letter-spacing: -0.02em;
  color: #ffffff;
  background: #a0a0a0;
  border-radius: 5px;
}
.button-class {
  width: 100%;
  height: 70px;
  background: #fafafa;
  margin-top: 200px;
  margin-right: auto;
  margin-left: auto;
  position: fixed;
  justify-content: space-between;
  bottom: 70px;
}
.button-class .button {
  margin-top: 16px;
  width: 140px;
  height: 38px;
  border-radius: 6px;
}
.button-class .confirmation {
  background: #1ea6ae;
  font-family: Work Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  letter-spacing: -0.02em;
  border: none;
  color: #ffffff;
}
.button-class .cancel {
  background: #ffffff;
  border: 1px solid #b7b7b7;
  font-family: Work Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #717171;
}
.select-form {
  background-color: #ededed;
  border: none;
  outline: none;
  font-size: 16px;
}
</style>
