<template>
  <div class="home">
    <div class="title">
      <h2 class="display-6 program-font-style">目的</h2>
    </div>
    <div class="text-style">
      <p class="fw-normal font-style">
        <span class="span-style span-start">
          アクティベーション（促通）の目的は、普段あまり使われないことにより姿勢不良や機能障害につながりうる筋肉に刺激を与えることにあります。これらの筋肉は表層の主導筋というよりも、深層にある安定筋です。
        </span>
      </p>
    </div>

    <img src="@/assets/commentary/training_woman.jpg" height="250"/>

    <div class="text-style">
      <p class="fw-normal font-style">
        <span class="span-style span-start">
          主動筋が正しく動くためには、その関節の深層部にある安定筋群によって関節が安定するからこそ、主動筋が効率的に働きます。
        </span>
        <span>ここでは、体幹部、股関節、胸椎周辺の筋肉のアクティベーションを行います。</span>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "ActivationAbout",
  props: {}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.title {
  padding-top: 4%;
  text-align: left;
}
.home > img {
  padding-top: 1%;
  width: 96%;
  height: auto;
  min-height: 100px;
}
.home > img::before {
  content: "";
  display: block;
  padding-top: calc(((720 / 1280) * 100%));
}
.program-font-style {
  font-weight: 600;
  color: #000;
}
.text-style {
  padding-top: 2%;
  text-align: left;
  font-size: 14px;
  font-weight: 500;
}
.font-style {
  letter-spacing: 1px;
  line-height: 25px;
  padding-top: 8px;
}
.span-style {
}
.span-start {
  background: linear-gradient(transparent 50%, #fcf49c 0%);
}
.span-end {
  margin-left: -4px;
  margin-right: -4px;
  background: linear-gradient(transparent 50%, #fcf49c 0%);
}
.sapace-style {
  margin-top: 10%;
}

</style>
