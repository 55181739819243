<template>
  <div>
    <div class="heard-style">
      <a href="#">
        <img src="@/assets/home/yajirusi.svg" width="20" height="20" />
      </a>
      <h2>リセットプログラム</h2>
    </div>
    <!-- 画像 -->
    <div class="home home-style">
      <div class="home-img">
        <img src="@/assets/reset_program/reset_program_main.png" height="200"/>
      </div>
    </div>

    <!-- 説明部分 -->
    <div class="card border-light mb-3 card-style">
      <div class="card-body">
        <h3 class="card-title title-font">発達運動学に基づいたリセットプログラムの目的</h3>
        <p class="card-text title-conten-font">
          このプログラムは発達運動学に基づいて体の状態を「リセット」するためのプログラムである。
          生後間もない赤ん坊が1年と少しで、人類が3億7975万年かけて学習した「直立2足歩行」
          という基本的な機能を習得す ることができます。もちろん、それは直立状態で歩く練習をした
          のではなく、偶然にも(必然的ではあるが)ある特定の時期に、同じ運動パターンを行うことによって、
          人間の最も基本的な機能を獲得するに至ったのだ。つまり、
          腹式呼吸による腹腔内圧(IAP)を高めた体幹部の安定性(矢状面)、
          うなずき動作による定頸、肩甲骨帯や骨盤(上前腸骨棘と恥骨)への荷重、
          寝返り動作によるアウターとインナーの協調性の向上、
          体幹部を安定させながらクロスパターンでの運動を行う這う動作を段階的に無意識に行うことによって、
          直立2足歩行が遂行できるようになったのである。
          もちろん、関節の動きや安定性だけにとどまらず、首を動かすことによる脳の活性、
          そして寝返り動作などで皮膚と床が接することによって感覚受容器への刺激を与えることにもなる。
          <br />このような段階を経て、赤ん坊は機能を構築することが約束されている。
          それであれば、機能を失いかけているアスリートや一般の方も、人工的な「ドリル」を行うだけに終わらずに、
          このような発達運動学に基づいた動作を 改めて実施することによって機能の改善に役立つとのではないかという考え方に基づいている。
        </p>
      </div>
    </div>
    <div class="card border-light mb-3 card-style">
      <div class="card-body">
        <h3 class="card-title title-font">期待される効果</h3>
        <p class="card-text title-conten-font">
          <br />• 胸椎の可動性向上
          <br />• 股関節の可動性向上
          <br />• 荷重位置の改善
          <br />• 姿勢改善(直立姿勢)
          <br />• 全体的な体の軽さ、または動きやすさ
          <br />• 体幹部の安定性
        </p>
      </div>
    </div>

    <div class="card border-light mb-3 card-style">
      <div class="card-body">
        <h3 class="card-title title-font">エクササイズの配列（6つのカテゴリー）</h3>
        <p class="card-text title-conten-font">
          <br />6つのカテゴリーを発達運動学に基づいた順番で行うようにする。
          <br />１．横隔膜呼吸
          <br />２．頭部コントロール、うなずき運動
          <br />３．ローリング
          <br />４．ロッキング
          <br />５．クローリング
          <br />６．クロスマーチング
        </p>
      </div>
    </div>

    <div class="card border-light mb-3 card-style">
      <div class="card-body">
        <h3 class="card-title title-font">実施のポイント</h3>
        <p class="card-text title-conten-font">
          <br />１．トレーニングや練習の前後で行うことにより、体の「リセット」を促す。
          <br />２．ゆっくりと、1連の「流れ」として実施。
          <br />３．首や肩の緊張を最小限にして、「力を抜いて動作を行う」練習として実施する。
          <br />４．固定点を定義付ける。
        </p>
      </div>
    </div>

    <ResetProgramAbout />

    <div class="all-open">
      <div v-if="allEventValue">
        <button type="button" class="button-style" @click="allEvent(false)">All Close</button>
      </div>
      <div v-else-if="checkEventValue">
        <button type="button" class="button-style" @click="allEvent(false)">All Close</button>
      </div>
      <div v-else>
        <button type="button" class="button-style" @click="allEvent(true)">All Open</button>
      </div>
    </div>

    <!-- 詳細部分折り畳み -->
    <div class="check-div">
      <input id="acd-check1" class="acd-check" type="checkbox" v-model="check[0]" />
      <label class="acd-label" @click="checkEvent(0)">
        <h2 class="display-5 program-font-style">IAPブリージング</h2>
      </label>
      <div class="acd-content" v-if="check[0]">
        <div class="home">
          <div>
            <!-- 説明部分 -->
            <div class="card border-light mb-3 card-style">
              <div class="card-body">
                <p class="card-text title-conten-font">
                  仰向けで寝て膝を曲げて掌を上に向けましょう。
                  <br />首を長く保ちながら3秒かけて鼻から息を吸って360°お腹を膨らませましょう
                  <br />お腹が膨らんだところで2秒止めて5秒かけて力を抜きながら口から息をゆっくり吐きましょう
                </p>
              </div>
            </div>
          </div>
          <img class="img-style" src="@/assets/reset_program/1.iapPreathing.png" height="230"/>
          <!-- 目的、動作、ポイントtable -->
          <div>
            <table>
              <tr>
                <th>目的</th>
                <td>
                  筋緊張を緩和させる
                  <br />正しい呼吸を習得する
                </td>
              </tr>
              <tr>
                <th>動作</th>
                <td>
                  １．膝をまげて掌を上に向けて寝る
                  <br />２．3秒かけて鼻から息を吸ってお腹を膨らませる（360°お腹を膨らませる
                  <br />３．腹圧が高まったところで2秒止める
                  <br />４．5秒かけて口からゆっくり息を吐く
                  <br />５．吸気で腹圧の高まりを感じ呼気で横隔膜の弛緩を感じる
                  <br />６．3-5回行う
                </td>
              </tr>
              <tr>
                <th>ポイント</th>
                <td>肩や首、全身に余分な力が入らないようにする</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <input id="acd-check2" class="acd-check" type="checkbox" v-model="check[1]" />
      <label class="acd-label" @click="checkEvent(1)">
        <h2 class="display-5 program-font-style">IAPブリージング（水平面）</h2>
      </label>
      <div class="acd-content" v-if="check[1]">
        <div class="home">
          <div>
            <!-- 説明部分 -->
            <div class="card border-light mb-3 card-style">
              <div class="card-body">
                <p class="card-text title-conten-font">
                  腰を捻って手で支えて、片側の胸を開きましょう
                  <br />息を吸った時に上のお腹に空気を入れて膨らませる
                  <br />ストレッチを感じる場所に空気を送り込むように鼻から息を吸い込みましょう
                  <br />息を吐いた時に開いた側の肩甲骨が下がっていくのを感じましょう
                </p>
              </div>
            </div>
          </div>
          <img class="img-style" src="@/assets/reset_program/2.iapPreathingHorizon.png" height="230"/>
          <!-- 目的、動作、ポイントtable -->
          <div>
            <table>
              <tr>
                <th>目的</th>
                <td>
                  筋緊張を緩和させる
                  <br />正しい呼吸を習得する
                </td>
              </tr>
              <tr>
                <th>動作</th>
                <td>
                  １．仰向けで腰を捻り片側の手で膝を支える
                  <br />２．反対側の手は広げる
                  <br />３．3秒かけて鼻から息を吸って上のお腹を膨らませる
                  <br />４．腹圧が高まったところで2秒止める
                  <br />５．5秒かけて口から息を吐く
                  <br />６．息を吐いた時に開いた側の肩甲骨が下がっているのを感じる
                  <br />７．3-5回ずつ
                </td>
              </tr>
              <tr>
                <th>ポイント</th>
                <td>空気を入れる場所や呼気の時の肩甲骨の意識</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <input id="acd-check3" class="acd-check" type="checkbox" v-model="check[2]" />
      <label class="acd-label" @click="checkEvent(2)">
        <h2 class="display-5 program-font-style">LBハーフロール</h2>
      </label>
      <div class="acd-content" v-if="check[2]">
        <div class="home">
          <div>
            <!-- 説明部分 -->
            <div class="card border-light mb-3 card-style">
              <div class="card-body">
                <p class="card-text title-conten-font">
                  仰向けになって掌を上に向けて手足を伸ばしましょう
                  <br />手足をリラックスしたまま、足を引き上げて
                  <br />足をクロスして背伸びをするように足を下のほうに伸ばしましょう
                  <br />親指で斜め下をタッチするように
                  <br />そして、足を伸ばした側の手を上に伸ばし体側を伸ばしましょう
                </p>
              </div>
            </div>
          </div>
          <div class="card video-div-style">
            <video :src="videoUrl.url_1" controls preload="metadata" class="video-style" />
          </div>
          <!-- 目的、動作、ポイントtable -->
          <div>
            <table>
              <tr>
                <th>目的</th>
                <td>
                  下肢から上肢への力の連動
                  <br />肩甲帯、骨盤帯の安定性
                </td>
              </tr>
              <tr>
                <th>動作</th>
                <td>
                  １．掌を上に向けて手足をリラックして伸ばす
                  <br />２．左膝を引き上げる
                  <br />３．右側の肩甲骨に荷重しながら膝を伸ばして親指で斜め下をタッチする
                  <br />４．同時に上半身も上に伸び上がる
                </td>
              </tr>
              <tr>
                <th>ポイント</th>
                <td>回旋側の肩甲骨と骨盤への荷重の意識</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <input id="acd-check4" class="acd-check" type="checkbox" v-model="check[3]" />
      <label class="acd-label" @click="checkEvent(3)">
        <h2 class="display-5 program-font-style">UBハーフロール</h2>
      </label>
      <div class="acd-content" v-if="check[3]">
        <div class="home">
          <div>
            <!-- 説明部分 -->
            <div class="card border-light mb-3 card-style">
              <div class="card-body">
                <p class="card-text title-conten-font">
                  仰向けになって掌を上に向けて手足を伸ばしましょう
                  <br />手足をリラックスして（特に大腿四頭筋の力を抜いて）
                  <br />膝の斜め下に目線を向けて、そしてタッチするように手を動かす
                  <br />脇の下を見るように手を動かす
                </p>
              </div>
            </div>
          </div>
          <div class="card video-div-style">
            <video :src="videoUrl.url_2" controls preload="metadata" class="video-style" />
          </div>
          <!-- 目的、動作、ポイントtable -->
          <div>
            <table>
              <tr>
                <th>目的</th>
                <td>
                  上肢から下肢への力の連動
                  <br />肩甲帯、骨盤帯の安定性
                </td>
              </tr>
              <tr>
                <th>動作</th>
                <td>
                  １．掌を上に向けて手足をリラックして伸ばす
                  <br />２．大腿四頭筋に力が入らないようにする
                  <br />３．鳩尾を中心にま骨盤に荷重しながら斜め下をタッチする
                  <br />４．最初に目線を向けてから上肢の動作に入る
                </td>
              </tr>
              <tr>
                <th>ポイント</th>
                <td>
                  回旋側の肩甲骨、骨盤への荷重
                  <br />大腿四頭筋に力を入れない
                  <br />鳩尾を中心に手を長くイメージして
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <input id="acd-check5" class="acd-check" type="checkbox" v-model="check[4]" />
      <label class="acd-label" @click="checkEvent(4)">
        <h2 class="display-5 program-font-style">3ヶ月／ペルビックローテーション</h2>
      </label>
      <div class="acd-content" v-if="check[4]">
        <div class="home">
          <div>
            <!-- 説明部分 -->
            <div class="card border-light mb-3 card-style">
              <div class="card-body">
                <p class="card-text title-conten-font">
                  肘を肩よりも前（そして少し外側）に着いて地面を押す
                  <br />背中の中心から首が生えているように首を長く保つ
                  <br />そして、二重顎を作るようにして前方を見る
                  <br />骨盤を左右に振って骨盤への荷重の意識と体の余分な緊張を取りましょう
                </p>
              </div>
            </div>
          </div>
          <div class="card video-div-style">
            <video :src="videoUrl.url_3" controls preload="metadata" class="video-style" />
          </div>
          <!-- 目的、動作、ポイントtable -->
          <div>
            <table>
              <tr>
                <th>目的</th>
                <td>
                  骨盤への荷重の意識付け
                  <br />骨盤帯の安定性
                </td>
              </tr>
              <tr>
                <th>動作</th>
                <td>
                  １．肘の位置を肩よりも前方にして少し開く
                  <br />２．目線を正面にして首を長く保つ（胸椎から首があるイメージ）
                  <br />３．骨盤を左右に揺らし臀部をリラックスさせる
                  <br />※3ヶ月ポジションの荷重ポイント：肘内側、ASIS、恥骨、膝内側への荷重
                </td>
              </tr>
              <tr>
                <th>ポイント</th>
                <td>
                  骨盤が浮かないようにする
                  <br />臀部を過緊張させない
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <input id="acd-check6" class="acd-check" type="checkbox" v-model="check[5]" />
      <label class="acd-label" @click="checkEvent(5)">
        <h2 class="display-5 program-font-style">3ヶ月／スパインロール</h2>
      </label>
      <div class="acd-content" v-if="check[5]">
        <div class="home">
          <div>
            <!-- 説明部分 -->
            <div class="card border-light mb-3 card-style">
              <div class="card-body">
                <p class="card-text title-conten-font">
                  3ヶ月のポジションで、首の上から順番に背中までラクダのコブを作るように丸める
                  <br />コブを作った時に骨盤が浮かないように
                  <br />そして、背中の中心から頚椎に向かって順番に反らせる
                  <br />波を描くように一本ずつ反らせる
                  <br />最後に二重顎を作りながら目線を正面に向ける
                </p>
              </div>
            </div>
          </div>
          <div class="card video-div-style">
            <video :src="videoUrl.url_4" controls preload="metadata" class="video-style" />
          </div>
          <!-- 目的、動作、ポイントtable -->
          <div>
            <table>
              <tr>
                <th>目的</th>
                <td>
                  胸椎、肩甲骨（前鋸筋）、骨盤帯の安定性
                  <br />胸椎の矢状面の可動性
                </td>
              </tr>
              <tr>
                <th>動作</th>
                <td>
                  １．肘の位置を肩よりも前方にして少し開く
                  <br />２．目線を正面にして首を長く保つ（胸椎から首があるイメージ）
                  <br />３．頚椎上部から胸椎（背中の中心）にかけて順番に屈曲する
                  <br />４．胸椎下部（背中の中心）から順番に伸展する
                </td>
              </tr>
              <tr>
                <th>ポイント</th>
                <td>
                  屈曲した時に骨盤が浮かないようにする
                  <br />伸展した時に二重顎の意識（頚椎上部の可動性）
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <input id="acd-check7" class="acd-check" type="checkbox" v-model="check[6]" />
      <label class="acd-label" @click="checkEvent(6)">
        <h2 class="display-5 program-font-style">3ヶ月／ルックバック</h2>
      </label>
      <div class="acd-content" v-if="check[6]">
        <div class="home">
          <div>
            <!-- 説明部分 -->
            <div class="card border-light mb-3 card-style">
              <div class="card-body">
                <p class="card-text title-conten-font">
                  3ヶ月のポジションから、
                  <br />両肘と骨盤が地面から離れないように後ろを振り向く（踵を見る）
                  <br />顎を肩よりも高いところから覗き込むイメージ
                  <br />背骨の横方向の動きを感じる
                </p>
              </div>
            </div>
          </div>
          <div class="card video-div-style">
            <video :src="videoUrl.url_5" controls preload="metadata" class="video-style" />
          </div>
          <!-- 目的、動作、ポイントtable -->
          <div>
            <table>
              <tr>
                <th>目的</th>
                <td>
                  胸椎、肩甲帯（前鋸筋）、骨盤帯の安定性
                  <br />胸椎の前額面の可動性
                </td>
              </tr>
              <tr>
                <th>動作</th>
                <td>
                  １．肘の位置を肩よりも前方にして少し開く
                  <br />２．目線を正面にして首を長く保つ（胸椎から首があるイメージ）
                  <br />３．背中の中心を脊柱を側屈回旋させる
                </td>
              </tr>
              <tr>
                <th>ポイント</th>
                <td>
                  肘や骨盤が浮かないように注意をする
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <input id="acd-check8" class="acd-check" type="checkbox" v-model="check[7]" />
      <label class="acd-label" @click="checkEvent(7)">
        <h2 class="display-5 program-font-style">コマンドロッキング</h2>
      </label>
      <div class="acd-content" v-if="check[7]">
        <div class="home">
          <div>
            <!-- 説明部分 -->
            <div class="card border-light mb-3 card-style">
              <div class="card-body">
                <p class="card-text title-conten-font">
                  まず正座をしてお尻がついた状態で両手を前に着く（お尻が浮かないように）
                  <br />そして膝を少し開く
                  <br />腰をニュートラル（正しいポジション）に保ち、首を長く保ったまま
                  <br />手首の上に肩が来るくらいまで前に移動し元の位置に戻る
                </p>
              </div>
            </div>
          </div>
          <div class="card video-div-style">
            <video :src="videoUrl.url_6" controls preload="metadata" class="video-style" />
          </div>
          <!-- 目的、動作、ポイントtable -->
          <div>
            <table>
              <tr>
                <th>目的</th>
                <td>
                  股関節屈曲の可動性
                  <br />肩甲帯、体幹、骨盤帯の安定性
                  <br />体幹を中心としたモーターコントロール
                </td>
              </tr>
              <tr>
                <th>動作</th>
                <td>
                  １．正座から膝を軽度外転し臀部をつけたまま前方に手を着く
                  <br />２．臀部を浮かせて肩甲骨にしっかりと荷重するを繰り返す
                  <br />３．腰椎のポジションを保ったまま肩甲骨、股関節を中心に前後に動く
                </td>
              </tr>
              <tr>
                <th>ポイント</th>
                <td>
                  肩甲骨に荷重するときは腰椎が伸展しない
                  <br />股関節に荷重するときは腰椎が屈曲しない
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <input id="acd-check9" class="acd-check" type="checkbox" v-model="check[8]" />
      <label class="acd-label" @click="checkEvent(8)">
        <h2 class="display-5 program-font-style">コマンドロッキングルックバック</h2>
      </label>
      <div class="acd-content" v-if="check[8]">
        <div class="home">
          <div>
            <!-- 説明部分 -->
            <div class="card border-light mb-3 card-style">
              <div class="card-body">
                <p class="card-text title-conten-font">
                  前に出ると同時に後ろを覗き込む
                  <br />肩の上から後ろを振り向くイメージ
                </p>
              </div>
            </div>
          </div>
          <div class="card video-div-style">
            <video :src="videoUrl.url_7" controls preload="metadata" class="video-style" />
          </div>
          <!-- 目的、動作、ポイントtable -->
          <div>
            <table>
              <tr>
                <th>目的</th>
                <td>
                  股関節屈曲、胸椎の前額面の可動性
                  <br />肩甲帯、体幹、骨盤帯の前額面、矢状面の安定性
                  <br />体幹を中心としたモーターコントロール
                </td>
              </tr>
              <tr>
                <th>動作</th>
                <td>
                  １．正座から膝を外に開き手を肩より前に着く
                  <br />２．臀部を浮かせて肩甲骨にしっかりと荷重しながら脊柱の側屈回旋を行う
                  <br />３．腰椎のアライメントを保ったまま、肩甲骨と股関節、胸椎下部を支点に柔らかく動かす
                </td>
              </tr>
              <tr>
                <th>ポイント</th>
                <td>
                  上から覗き込む意識（胸椎ニュートラル）で肩甲骨、骨盤への荷重の意識
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>

      <input id="acd-check10" class="acd-check" type="checkbox" v-model="check[9]" />
      <label class="acd-label" @click="checkEvent(9)">
        <h2 class="display-5 program-font-style">プリーチャーズロッキング</h2>
      </label>
      <div class="acd-content" v-if="check[9]">
        <div class="home">
          <div>
            <!-- 説明部分 -->
            <div class="card border-light mb-3 card-style">
              <div class="card-body">
                <p class="card-text title-conten-font">
                  まず正座をしてお尻がついた状態で両肘を少し開いて肩よりも前に着く（お尻が浮かないように）
                  <br />腰をニュートラル（正しいポジション）に保ち、首を長く保ったまま
                  <br />肘の上に肩が来るくらいまで前に移動し元の位置に戻る
                </p>
              </div>
            </div>
          </div>
          <div class="card video-div-style">
            <video :src="videoUrl.url_8" controls preload="metadata" class="video-style" />
          </div>
          <!-- 目的、動作、ポイントtable -->
          <div>
            <table>
              <tr>
                <th>目的</th>
                <td>
                  胸椎、肩関節、股関節の矢状面の可動性
                  <br />肩甲帯、体幹、骨盤帯の矢状面の安定性
                  <br />体幹を中心としたモーターコントロール
                </td>
              </tr>
              <tr>
                <th>動作</th>
                <td>
                  １．正座から膝を外に開き臀部が踵から離れないように肘を肩よりも前に着き少し開く
                  <br />２．臀部を浮かせて肩甲骨にしっかりと荷重する
                  <br />３．肩甲骨と股関節を中心にテンポ良く前後に揺らす
                </td>
              </tr>
              <tr>
                <th>ポイント</th>
                <td>
                  前に移動した時に肘が浮かないようにする
                  <br />臀部を下げた時に肩周囲の伸びを感じる
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>

      <input id="acd-check11" class="acd-check" type="checkbox" v-model="check[10]" />
      <label class="acd-label" @click="checkEvent(10)">
        <h2 class="display-5 program-font-style">プリーチャーズロッキングローテーション</h2>
      </label>
      <div class="acd-content" v-if="check[10]">
        <div class="home">
          <div>
            <!-- 説明部分 -->
            <div class="card border-light mb-3 card-style">
              <div class="card-body">
                <p class="card-text title-conten-font">
                  プリーチャーズロッキングのポジションから
                  <br />股関節（骨盤）、肩甲骨全体で大きく回す
                  <br />肩甲骨と股関節を支点として全身で混ぜるイメージで
                  <br />動物のように柔らかく回すイメージで
                </p>
              </div>
            </div>
          </div>
          <div class="card video-div-style">
            <video :src="videoUrl.url_9" controls preload="metadata" class="video-style" />
          </div>
          <!-- 目的、動作、ポイントtable -->
          <div>
            <table>
              <tr>
                <th>目的</th>
                <td>
                  胸椎、肩関節、股関節の3面の可動性
                  <br />肩甲帯、体幹、骨盤帯の3面の安定性
                  <br />体幹を中心としたモーターコントロール
                </td>
              </tr>
              <tr>
                <th>動作</th>
                <td>
                  １．正座から膝を外に開き肘を肩よりも前に着き少し開く
                  <br />２．臀部を下げたところから回旋を行う
                  <br />３．肩甲骨、股関節を支点に柔らかく全体で回旋させる
                </td>
              </tr>
              <tr>
                <th>ポイント</th>
                <td>
                  脊柱の動きも協調させながら柔らかく行う
                  <br />肩や首に力が入らないように注意する
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>

      <input id="acd-check12" class="acd-check" type="checkbox" v-model="check[11]" />
      <label class="acd-label" @click="checkEvent(11)">
        <h2 class="display-5 program-font-style">ハイプランクロッキング</h2>
      </label>
      <div class="acd-content" v-if="check[11]">
        <div class="home">
          <div>
            <!-- 説明部分 -->
            <div class="card border-light mb-3 card-style">
              <div class="card-body">
                <p class="card-text title-conten-font">
                  肩の真下に手が来るように
                  <br />首を長く保って、お腹を締めて、体を一直線にしましょう
                  <br />そこから膝を床から５cmくらい浮かせたままお尻を下げる
                  <br />両肩の間に頭を入れて
                  <br />そして元のハイプランクに戻るを繰り返す
                </p>
              </div>
            </div>
          </div>
          <div class="card video-div-style">
            <video :src="videoUrl.url_10" controls preload="metadata" class="video-style" />
          </div>
          <!-- 目的、動作、ポイントtable -->
          <div>
            <table>
              <tr>
                <th>目的</th>
                <td>
                  胸椎、肩関節、股関節の3面の可動性
                  <br />肩甲帯、体幹、骨盤帯の3面の安定性
                  <br />体幹を中心としたモーターコントロール
                </td>
              </tr>
              <tr>
                <th>動作</th>
                <td>
                  １．手の幅は肩幅で腕立て姿勢を作る
                  <br />２．頭から足まで一直線を保ち肩に力が入らないようにする
                  <br />３．床から5センチを目安に膝を下げて股関節を屈曲しお尻を下げる
                  <br />４．同時に肩を180°屈曲させる
                  <br />５．ハイププランクに戻る
                </td>
              </tr>
              <tr>
                <th>ポイント</th>
                <td>
                  膝の高さを保ちながら行う
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>

      <input id="acd-check13" class="acd-check" type="checkbox" v-model="check[12]" />
      <label class="acd-label" @click="checkEvent(12)">
        <h2 class="display-5 program-font-style">エレベイトロール</h2>
      </label>
      <div class="acd-content" v-if="check[12]">
        <div class="home">
          <div>
            <!-- 説明部分 -->
            <div class="card border-light mb-3 card-style">
              <div class="card-body">
                <p class="card-text title-conten-font">
                  首を長く保ち肩の真下に手をついてハイプランクの姿勢を作りましょう
                  <br />そして、右足（片足）を引き上げましょう
                  <br />そこから、両手で地面を押しながら肩の間に頭を入れ、上げた足を高く上にあげましょう
                  <br />膝は90°を保ったままで
                  <br />両手を地面につけたまま、軸足を中心に大きく回旋し、そしてあげている足を下ろし
                  <br />足が着いたら片手になって上にあげましょう
                  <br />下の手から上の手まで一直線を意識して
                  <br />この時に軸足は踵を中心に伸展させて、お尻を意識しましょう
                </p>
              </div>
            </div>
          </div>
          <div class="card video-div-style">
            <video :src="videoUrl.url_11" controls preload="metadata" class="video-style" />
          </div>
          <!-- 目的、動作、ポイントtable -->
          <div>
            <table>
              <tr>
                <th>目的</th>
                <td>
                  多面動作の全身のダイナミックストレッチ
                  <br />体幹を中心としたモーターコントロール
                </td>
              </tr>
              <tr>
                <th>動作</th>
                <td>
                  １．ハイプランクから片足の股関節を膝関節を90°屈曲させる
                  <br />２．両手で地面を押しながら肩を180°屈曲させせ目線は軸足の爪先をみる
                  <br />３．同時に上げている膝を90°のまま股関節を伸展させて踵を上にあげる
                  <br />４．上の足で大きな縁を描くように回旋させて足を反対側の地面に近づける
                  <br />５．足が地面に着いたら片手を離し下の手から上の手まで一直線にする
                  <br />６．軸足は踵を視点に伸展させて臀筋も意識する
                </td>
              </tr>
              <tr>
                <th>ポイント</th>
                <td>
                  首を長く保つ
                  <br />柔らかい動作を意識する
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>

      <input id="acd-check14" class="acd-check" type="checkbox" v-model="check[13]" />
      <label class="acd-label" @click="checkEvent(13)">
        <h2 class="display-5 program-font-style">バードドック</h2>
      </label>
      <div class="acd-content" v-if="check[13]">
        <div class="home">
          <div>
            <!-- 説明部分 -->
            <div class="card border-light mb-3 card-style">
              <div class="card-body">
                <p class="card-text title-conten-font">
                  四つ這いの姿勢を作る
                  <br />肩の真下に手を置き、骨盤の真下に膝を着く
                  <br />爪先を両方浮かせる
                  <br />そこから、対角の肘と膝を近づける
                  <br />肘を近づける時ひ背中を丸めて、伸ばすときは全身で伸展させる
                  <br />バランスを取りながら背骨の柔らかさも意識して
                </p>
              </div>
            </div>
          </div>
          <div class="card video-div-style">
            <video :src="videoUrl.url_12" controls preload="metadata" class="video-style" />
          </div>
          <!-- 目的、動作、ポイントtable -->
          <div>
            <table>
              <tr>
                <th>目的</th>
                <td>
                  肩甲骨、体幹（脊柱）、股関節の連動性、モーターコントロール
                </td>
              </tr>
              <tr>
                <th>動作</th>
                <td>
                  １．四つ這いになって足先を浮かせる
                  <br />２．脊柱を屈曲させなが対角の肘と膝を近づける
                  <br />３．脊柱の自然な伸展を伴いながら対角の肩と股関節を伸展させる
                </td>
              </tr>
              <tr>
                <th>ポイント</th>
                <td>
                  脊柱の自然な動きを協調させる
                  <br />首や肩に力が入らないように注意する
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>

      <input id="acd-check15" class="acd-check" type="checkbox" v-model="check[14]" />
      <label class="acd-label" @click="checkEvent(14)">
        <h2 class="display-5 program-font-style">シーテッドクロスマーチング</h2>
      </label>
      <div class="acd-content" v-if="check[14]">
        <div class="home">
          <div>
            <!-- 説明部分 -->
            <div class="card border-light mb-3 card-style">
              <div class="card-body">
                <p class="card-text title-conten-font">
                  座位で膝を曲げる
                  <br />首を長く保ちながら、対角の肘と膝をつけましょう
                  <br />歩行動作を意識して、反対の手は肩甲骨から後ろに引くイメージで
                </p>
              </div>
            </div>
          </div>
          <div class="card video-div-style">
            <video :src="videoUrl.url_13" controls preload="metadata" class="video-style" />
          </div>
          <!-- 目的、動作、ポイントtable -->
          <div>
            <table>
              <tr>
                <th>目的</th>
                <td>
                  歩行動作における四肢と体幹の連動動作
                </td>
              </tr>
              <tr>
                <th>動作</th>
                <td>
                  １．膝を屈曲し座位の姿勢を作る
                  <br />２．脊柱を自然に起立させ肩に力が入らないようにする
                  <br />３．対角の肘と膝を前方でタッチさせる
                  <br />４．反対側の肘を後方に引く意識をする
                </td>
              </tr>
              <tr>
                <th>ポイント</th>
                <td>
                  首を長く保つ
                  <br />肘を後ろに引く
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="sapace-style"></div>
  </div>
</template>

<script>
// @ is an alias to /src
import ResetProgramAbout from "../../components/reset_program/ResetProgramAbout";

export default {
  name: "ResetProgram",
  components: {
    ResetProgramAbout
  },
  data() {
    return {
      monitoring: "<モニタリング>",
      check: [
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false
      ],
      allEventValue: false,
      checkEventValue: false,
      videoUrl: {
        url_1:
          "https://firebasestorage.googleapis.com/v0/b/fitnesscamp-428f2.appspot.com/o/reset_program%2Fcontent%2Fmovie%2F3.LbHalfRoll.mov?alt=media&token=18476b8c-f41f-469d-a32c-cf74540b8a8f",
        url_2:'https://firebasestorage.googleapis.com/v0/b/fitnesscamp-428f2.appspot.com/o/reset_program%2Fcontent%2Fmovie%2F4.UbHalfRoll.mov?alt=media&token=48470af7-bac4-4015-82ee-bbe4432f21b6',
        url_3:'https://firebasestorage.googleapis.com/v0/b/fitnesscamp-428f2.appspot.com/o/reset_program%2Fcontent%2Fmovie%2F5.pervicClotion.mov?alt=media&token=330b36fb-3633-49cb-92c2-2eace1fdc528',
        url_4:'https://firebasestorage.googleapis.com/v0/b/fitnesscamp-428f2.appspot.com/o/reset_program%2Fcontent%2Fmovie%2F6.spineRoll.mov?alt=media&token=cfe6ac33-d216-4826-982c-ff62dc5cd602',
        url_5:'https://firebasestorage.googleapis.com/v0/b/fitnesscamp-428f2.appspot.com/o/reset_program%2Fcontent%2Fmovie%2Flb_backup.mov?alt=media&token=e795f1ec-e29f-4e4b-b83d-494166357386',
        url_6:'https://firebasestorage.googleapis.com/v0/b/fitnesscamp-428f2.appspot.com/o/reset_program%2Fcontent%2Fmovie%2F8.commandLocking.mov?alt=media&token=65d17038-8d9d-4ce3-b6c6-629f3db6fd25',
        url_7:'https://firebasestorage.googleapis.com/v0/b/fitnesscamp-428f2.appspot.com/o/reset_program%2Fcontent%2Fmovie%2F9.commandLockingLookBack.mov?alt=media&token=ec2c539a-f7d1-4ca3-819f-517f10a0bfdf',
        url_8:'https://firebasestorage.googleapis.com/v0/b/fitnesscamp-428f2.appspot.com/o/reset_program%2Fcontent%2Fmovie%2F10.preachersRocking.mov?alt=media&token=ff9bed5f-a86e-483e-a408-bd557f85fb3c',
        url_9:'https://firebasestorage.googleapis.com/v0/b/fitnesscamp-428f2.appspot.com/o/reset_program%2Fcontent%2Fmovie%2F11.preachersRockingRotation.mov?alt=media&token=b7643cef-e724-4166-b1c0-3cca6639b9a1',
        url_10:'https://firebasestorage.googleapis.com/v0/b/fitnesscamp-428f2.appspot.com/o/reset_program%2Fcontent%2Fmovie%2F12.highPlanLocking.mov?alt=media&token=1e4a9fd7-ccac-4e43-b1d8-46aa79135f2b',
        url_11:'https://firebasestorage.googleapis.com/v0/b/fitnesscamp-428f2.appspot.com/o/reset_program%2Fcontent%2Fmovie%2F13.elevateRoll.mov?alt=media&token=b7d0fe50-a413-4983-a983-f124344894cf',
        url_12:'https://firebasestorage.googleapis.com/v0/b/fitnesscamp-428f2.appspot.com/o/reset_program%2Fcontent%2Fmovie%2F14.birdDock.mov?alt=media&token=800169e3-9f21-4531-b238-bf63580aa543',
        url_13:'https://firebasestorage.googleapis.com/v0/b/fitnesscamp-428f2.appspot.com/o/reset_program%2Fcontent%2Fmovie%2F15.seatedCrossMarching.mov?alt=media&token=700c1a49-7008-42de-befd-b11cd8d730f0',
      }
    };
  },
  methods: {
    checkEvent(index) {
      if (!this.check[index]) {
        this.checkEventValue = true;
      } else {
        this.checkEventValue = false;
      }
      this.check[index] = !this.check[index];
    },
    allEvent(isValue) {
      if (!isValue) {
        this.checkEventValue = false
      }
      let self = this;
      self.check.forEach(function(value, index) {
        self.check[index] = isValue;
      });
      self.allEventValue = isValue;
    }
  }
};
</script>
<style scoped>
.heard-style {
  position: fixed;
  z-index: 2;
  margin-top: 0%;
  padding-top: 18px;
  width: 100%;
  height: 58px;
  background-color: #04545c;
}
.heard-style img {
  float: left;
  margin-left: 2%;
}
.heard-style > h2 {
  color: rgb(255, 255, 255);
  margin-right: 4%;
  font-weight: 900;
}
.home-img img {
  margin-top: 58px;
  width: 100%;
  /* height:auto; */
  padding: 0;
}
.home-img img::before {
  content: "";
  display: block;
  padding-top: calc(((720 / 1280) * 100%));
}
.card-style {
  max-width: 100rem;
  margin: 2%;
  background-color: rgb(248, 248, 248);
}
.title-font {
  color: #000;
  font-weight: 600;
  text-align: left;
}
.title-conten-font {
  color: #000;
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  letter-spacing: 1px;
  line-height: 20px;
}
.all-open {
  text-align: right;
  margin-right: 2%;
  margin-top: 5%;
  margin-bottom: 5%;
}
.button-style {
  width: 60px;
  height: 30px;
  font-size: 12px;
  text-align: center;
  padding: 0%;
  background: #ffffff;
  border-radius: 5px;
  border: 1px solid #015a53;
}

/* 折りたたみ */
.check-div {
}
.acd-check {
  display: none;
  border-bottom: 1px solid rgb(177, 177, 177);
}
.acd-label {
  background: #04545c;
  color: rgb(255, 255, 255);
  display: block;
  margin-bottom: 1px;
  padding: 16px;
  position: relative;
  font-size: 2em;
  height: auto;
  border-bottom: 0.5px solid rgb(255, 254, 240);
  text-align: left;
  margin: 0%;
}
.acd-label:after {
  background: #04545c;
  box-sizing: border-box;
  content: url(../../assets/home/downY.svg);
  display: block;
  font-family: "Font Awesome 5 Free";
  height: 50px;
  width: 50px;
  padding: 10px 20px;
  position: absolute;
  right: 0;
  top: 0px;
  height: 55px;
  border-bottom: 0px solid rgb(255, 254, 240);
}
.acd-content {
  border: 1px solid #333;
  display: block;
  height: 0;
  opacity: 0;
  padding: 0 10px;
  transition: 0.5s;
  visibility: hidden;
  border: 0;
}
.acd-check:checked + .acd-label:after {
  content: url(../../assets/home/upY.svg);
}
.acd-check:checked + .acd-label + .acd-content {
  height: auto;
  opacity: 1;
  padding: 10px;
  visibility: visible;
  border: 0;
  background-color: #ffffff;
  border-bottom: 0.5px solid rgb(255, 254, 240);
}
.program-font-style {
  font-weight: 600;
  width: 90%;
}
.sapace-style {
  height: 200px;
  background: #04545c;
  margin-bottom: 0%;
}
.about {
  text-align: left;
  margin-top: 2%;
  margin-left: 2%;
  margin-bottom: 3%;
}
.about > a:link,
a:visited,
a:hover,
a:active {
  text-decoration: none;
  font-size: 14px;
  font-weight: 400;
  color: rgb(163, 163, 163);
}

.home > img {
  padding-top: 1%;
  width: 96%;
}
.home > img::before {
  content: "";
  display: block;
  padding-top: calc(((720 / 1280) * 100%));
}

/* table */
table {
  margin-top: 8%;
  margin-left: 2%;
  margin-right: 2%;
  width: 96%;
  border-collapse: collapse;
}

table tr {
  width: 30px;
  border-bottom: solid 1px white;
}

table tr:last-child {
  border-bottom: none;
}

table th {
  position: relative;
  text-align: left;
  width: 25%;
  height: auto;
  background-color: #e4ecec;
  color: rgb(0, 0, 0);
  text-align: center;
  padding: 10px 0;
  font-size: 14px;
  font-weight: 400;
}
table td {
  position: relative;
  text-align: left;
  height: auto;
  background-color: rgb(247, 247, 247);
  color: rgb(0, 0, 0);
  text-align: left;
  padding: 10px 10px;
  font-size: 14px;
  font-weight: 400;
}

.video-div-style {
  height: auto;
  width: auto;
  margin-top: 5%;
  margin-left: 2%;
  margin-right: 2%;
  border: 0;
  background-color: rgb(233, 233, 233);
}
.video-style {
  height: 250px;
}
.img-style {
  height: auto;
}
</style>
