<template>
  <div>
    <div class="space"></div>
    <div v-show="modal">
      <div class="open-modal">
        <div class="open-image-div">
          <img class="open-image" :src="openImageUrl" />
        </div>
        <div class="d-flex justify-content-around">
          <img
            class="close-image"
            src="@/assets/profile/back.svg"
            @click="imageEvent(0)"
          />
          <img
            class="close-image"
            src="@/assets/profile/close.svg"
            @click="imageEvent(1)"
          />
          <img
            class="close-image"
            src="@/assets/profile/next.svg"
            @click="imageEvent(2)"
          />
        </div>
        <div v-if="event === '1'" class="icon">
          <button @click="setIcon()">アイコンに設定する</button>
        </div>
      </div>
    </div>
    <br />
    <div v-if="event === '1'" class="text-center">
      <div class="img-div">
        <div class="d-flex justify-content-center img-fiex-1">
          <div
            v-for="(content, index) in image_view_list"
            v-bind:key="content.id"
          >
            <div
              class="image-list-1"
              :style="{
                backgroundImage: 'url(' + content.image_url + ')',
              }"
              @click="openImage(index)"
            ></div>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="event === '2'" class="text-center">
      <!-- classデフォルトアイコンを選択 -->
      <div class="img-div">
        <div class="d-flex justify-content-start img-fiex">
          <div
            v-for="(content, index) in class_default_url"
            v-bind:key="content.id"
          >
            <h5>{{ content.image_name }}</h5>

            <div
              class="image-list-1"
              :style="{
                backgroundImage: 'url(' + content.image_url + ')',
              }"
              @click="setDefaultIcon(index)"
            >
              <div class="checkbox" v-if="content.image_check">
                <img src="@/assets/class/check.png" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="event === '3'" class="text-center">
      <!-- classデフォルトアイコンを選択 -->
      <div class="img-div">
        <div class="d-flex justify-content-center img-fiex">
          <div
            v-for="(content, index) in image_view_list"
            v-bind:key="content.id"
          >
            <!-- <h5>{{ content.image_name }}</h5> -->

            <div
              class="image-list-1"
              :style="{
                backgroundImage: 'url(' + content.image_url + ')',
              }"
              @click="setDefaultIcon_1(index)"
            >
              <div class="checkbox" v-if="content.image_check">
                <img src="@/assets/class/check.png" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else-if="event === '4'" class="text-center">
      <!-- classデフォルトアイコンを選択 -->
      <div class="img-div">
        <div class="d-flex justify-content-start img-fiex" v-if="default_image">
          <div
            v-show="class_type === glb_input_class_type.basic"
            class="image-list-2"
            :style="{
              backgroundImage: 'url(' + class_default_url[0].image_url + ')',
            }"
          ></div>
          <div
            v-show="class_type === glb_input_class_type.senior"
            class="image-list-2"
            :style="{
              backgroundImage: 'url(' + class_default_url[1].image_url + ')',
            }"
          ></div>
          <div
            v-show="class_type === glb_input_class_type.free"
            class="image-list-2"
            :style="{
              backgroundImage: 'url(' + class_default_url[2].image_url + ')',
            }"
          ></div>
        </div>
      </div>
    </div>
    <div v-else class="text-center">
      <div class="img-div">
        <div class="d-flex justify-content-center img-fiex">
          <div
            v-for="(content, index) in image_view_list"
            v-bind:key="content.id"
          >
            <div
              class="image-list"
              :style="{
                backgroundImage: 'url(' + content.image_url + ')',
              }"
              @click="openImage(index)"
            ></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";
export default {
  name: "ImageListView",
  components: {
    //Datepicker,
  },
  props: {
    image_view_list: Array,
    event: null,
    class_type: null,
    default_image: null,
  },
  created() {
    this.setScroll();
  },
  // mounted() {
  //   this.sample();
  // },
  data() {
    return {
      //image_view_list: [],
      class_default_url: [
        {
          image_name: "ベーシック",
          image_url:
            "https://firebasestorage.googleapis.com/v0/b/fitnesscamp-428f2.appspot.com/o/class_%20default%2Fbasic_icon.png?alt=media&token=60eb7f17-dac5-44ee-81b0-c134a41acf43",
          image_check: false,
        },
        {
          image_name: "シニア",
          image_url:
            "https://firebasestorage.googleapis.com/v0/b/fitnesscamp-428f2.appspot.com/o/class_%20default%2Fsenior.jpg?alt=media&token=18955389-a31c-4df9-b75e-b7f705edf4f8",
          image_check: false,
        },
        {
          image_name: "Logo",
          image_url:
            "https://firebasestorage.googleapis.com/v0/b/fitnesscamp-428f2.appspot.com/o/tool%2Ffitness_camp.png?alt=media&token=b43aef27-a0dd-400e-b357-48bceccbfe83",
          image_check: false,
        },
      ],
      userInfoEdit: {},
      modal: false,
      modal2: false,
      alert_msg: "",
      openImageUrl: "",
      image_index: "",
      imageList: [],
      url: "",
      imagUrl: "",
      editEvent: false,
      editIconUrl: "",
      editIcon: false,
      addImageEvent: false,
      image_list: [],
      add_image_url:
        "https://firebasestorage.googleapis.com/v0/b/fitnesscamp-428f2.appspot.com/o/user_upload%2Fimage-add.svg?alt=media&token=7006d278-166f-4675-8e09-81fdd569ca1d",
      urlParm1: "",
      urlParm2: "",
      image_icon_list: this.image_view_list,
    };
  },
  methods: {
    setDefaultIcon(index) {
      this.class_default_url[index].image_check = !this.class_default_url[index]
        .image_check;
      this.$emit("edit-icon-click", this.class_default_url);
    },
    setDefaultIcon_1(index) {
      this.$emit("edit-click", index);
    },
    setIcon() {
      this.glb_set_localStorage(
        "class_icon_image",
        JSON.stringify(this.image_view_list[this.image_index].image_url)
      );
      // alert(this.image_view_list[this.image_index].image_url);
    },
    setScroll() {
      clearAllBodyScrollLocks();
    },
    // 画像編集時のModal
    imageEvent(value) {
      switch (value) {
        case 0:
          if (this.image_index > 0) {
            this.openImageUrl = this.image_view_list[
              this.image_index - 1
            ].image_url;
            this.image_index = this.image_index - 1;
          }
          break;
        case 1: {
          clearAllBodyScrollLocks();
          this.modal = false;
          this.openImageUrl = "";
          break;
        }
        case 2: {
          if (this.image_index < this.image_view_list.length - 1) {
            this.openImageUrl = this.image_view_list[
              this.image_index + 1
            ].image_url;
            this.image_index = this.image_index + 1;
          }
          break;
        }
      }
    },
    // 画像一覧 モーダル表示
    openImage(index) {
      this.modal = true;
      this.openImageUrl = this.image_view_list[index].image_url;
      this.image_index = index;
      const modal = document.querySelector(".open-modal");
      disableBodyScroll(modal);
    },

    profile_image_list_up(event) {
      let self = this;
      let selectPic = event.target.files[0];
      var pos = selectPic.name.split(".").pop();
      let imageCheck = self.validete(pos);

      if (imageCheck) {
        this.editEvent = true;
        this.addImageEvent = true;
        let self = this;
        //self.imageList.push(event.target.files[0]);
        //画像アップロード
        self.updateProfileImage(event);

        var reader = new FileReader();
        reader.onload = function(event) {
          self.image_view_list.push({
            image_url: event.target.result,
            image_name: "",
          });
        };
        reader.readAsDataURL(event.target.files[0]);
      }
    },
  },
};
</script>
<style scoped>
.img-fiex {
  display: flex;
  flex-wrap: wrap;
}
.img-fiex-1 {
  margin: auto;
  /* margin-left: 8%; */
  width: 98%;
  display: flex;
  flex-wrap: wrap;
}
.img-div {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.img-div .image-list {
  width: 150px;
  height: 150px;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  /* 白線レイアウト 追加箇所 */
  border: 3px solid #f1f1f1;
}
.img-div .image-list-1 {
  width: 100px;
  height: 100px;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  /* 白線レイアウト 追加箇所 */
  border: 3px solid #f1f1f1;
}
.img-div .image-list-2 {
  width: 100px;
  height: 100px;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
  /* 白線レイアウト 追加箇所 */
  border: 3px solid #f1f1f1;
  float: left;
}
.image-list image {
  width: 45%;
}

.open-modal {
  z-index: 10;
  width: 100%;
  height: 100%;
  background: rgba(100, 100, 100, 0.9);
  position: fixed;
  top: 0;
  left: 0;
}
.open-image {
  margin-top: 70px;
  margin-left: auto;
  margin-right: auto;
  width: 100vw;
  height: auto;
  max-height: 70vh;
  background-color: #fff;
}
.close-image {
  margin-top: 60px;
  width: 50px;
  height: 50px;
}
.open-image-div {
  width: 100%;
  height: 70vh;
}
.popContainer {
  z-index: 2;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
}
/* .icon {
  margin: auto;
  margin-top: 15px;
  width: 120px;
  height: 30px;
  background-color: #f1f1f1;
} */
.icon button {
  margin: auto;
  padding: 10px;
  margin-top: 15px;
  border: none;
  border-radius: 10px;
  font-size: 14px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.checkbox {
  width: 100%;
  height: 100%;
  padding-top: 25%;
  background: rgba(167, 167, 167, 0.4);
}
</style>
