<template>
  <div>
    <p class="fs-1 font-size">マップ画面です、現在開発中です。</p>

    <div class="navigation-style">
      <Navigation :page="3" />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from "@/components/HelloWorld.vue";
import Navigation from "../../components/Navigation";

export default {
  name: "Search",
  components: {
    //HelloWorld,
    Navigation,
  },
};
</script>
<style scoped>
.div-space {
  width: 100%;
  height: 100px;
}
.font-size {
  text-align: center;
  margin-top: 50%;
  font-weight: 600;
  font-size: 14px;
}
</style>
