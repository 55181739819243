<template>
  <div>
    <div v-if="modal2">
      <div class="popContainer">
        <div class="qualification-modal2">
          <table>
            <tr>
              <p class="h3 title-style-2">保有資格</p>
              <div
                class="qualification-div"
                v-for="(content, index) in userInfoEdit.qualification"
                v-bind:key="content"
              >
                <div
                  style="display=inline-block"
                  @click="deleteQualification(index)"
                >
                  <img
                    src="@/assets/profile/マイナスのアイコン素材.svg"
                    height="20"
                    width="20"
                  />
                  <a>{{ content }}</a>
                </div>
              </div>
              <br />
            </tr>
          </table>

          <table>
            <tr>
              <hr />
              <p class="h3 title-style-2">資格追加</p>
              <div>
                <img
                  src="@/assets/profile/plus-icon.svg"
                  height="20"
                  width="20"
                  @click="plusOtherQualification()"
                />
                <input
                  type="text"
                  placeholder="その他：（自由記述）"
                  class="plus-qualification"
                  v-model="other_qualification"
                />
              </div>
              <hr style="border: 1px solid transparent;" />
              <div
                class="qualification-div"
                v-for="(content, index) in qualification_list"
                v-bind:key="content"
              >
                <div
                  style="display=inline-block"
                  @click="plusQualification(index)"
                >
                  <img
                    src="@/assets/profile/plus-icon.svg"
                    height="20"
                    width="20"
                  />
                  <a>{{ content }}</a>
                </div>
              </div>
              <br />
            </tr>
          </table>
        </div>
        <div>
          <table>
            <tr>
              <th>
                <button class="button-style cancel-button" @click="cancel()">
                  キャンセル
                </button>
              </th>
              <td>
                <button class="button-style update-button" @click="update()">
                  保存
                </button>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>

    <Header :page="2" @edit-click="editClick" />
    <div class="profile">
      <p class="h2 title-style" v-show="!editClickVar">Fitness情報</p>
      <!-- ユーザー情報確認画面     編集ではない時(表示のみ)-->
      <div class="fitness-card" v-if="!editClickVar">
        <table>
          <tr>
            <th>
              <h4 class="display-5 title-style title-font">FCCM会員番号：</h4>
            </th>
            <td>
              <h4 class="display-5 title-style content-font">
                <div
                  v-if="userInfo.fccm_no !== '' && userInfo.fccm_no !== null"
                >
                  No.{{ userInfo.fccm_no }}
                </div>
                <div v-else>未登録</div>
              </h4>
            </td>
          </tr>
          <tr>
            <th>
              <h4 class="display-5 title-style title-font">認定コーチ登録：</h4>
            </th>
            <td>
              <h4 class="display-5 title-style content-font">
                <div
                  v-if="
                    userInfo.certified_coach_no !== '' &&
                      userInfo.certified_coach_no !== null
                  "
                >
                  {{ userInfo.certified_coach_no }}
                </div>
                <div v-else>未登録</div>
              </h4>
            </td>
          </tr>
          <tr>
            <th>
              <h4 class="display-5 title-style title-font">養成講座受講日：</h4>
            </th>
            <td>
              <h4 class="display-5 title-style content-font">
                {{ students_date }}
              </h4>
            </td>
          </tr>
        </table>
      </div>
      <!-- ユーザー情報確認画面 -->
      <br />
      <br />
      <p class="h2 title-style">個人情報</p>
      <div class="fitness-card" v-if="!editClickVar">
        <h4 class="display-5 title-style title-div-input">お名前</h4>
        <div class="display-info-input">
          <table class="user-info-input">
            <tr>
              <td class="td-1">
                <p class="h4 user-input-font">{{ userInfo.last_name }}</p>
              </td>
              <td class="td-2"></td>
              <td class="td-3">
                <p class="h4 user-input-font">{{ userInfo.first_name }}</p>
              </td>
            </tr>
          </table>
        </div>
        <h4 class="display-5 title-style title-div-input">お名前（カナ）</h4>
        <div class="display-info-input">
          <table class="user-info-input">
            <tr>
              <td class="td-1">
                <p class="h4 user-input-font">{{ userInfo.last_name_kana }}</p>
              </td>
              <td class="td-2"></td>
              <td class="td-3">
                <p class="h4 user-input-font">{{ userInfo.first_name_kana }}</p>
              </td>
            </tr>
          </table>
        </div>
        <h4 class="display-6 title-style title-div-input">ご住所</h4>
        <table class="user-info-input">
          <tr>
            <td class="td-4">
              <p class="h4 user-input-font">{{ userInfo.zip_code }}</p>
            </td>
            <td class="td-2"></td>
            <td class="td-6">
              <!-- <button class="get-address">住所取得</button> -->
            </td>
          </tr>
        </table>

        <br />
        <table class="user-info-input">
          <tr>
            <td class="td-4">
              <p class="h4 user-input-font">{{ userInfo.prefectures }}</p>
            </td>
          </tr>
        </table>

        <br />
        <table class="user-info-input">
          <tr>
            <td class="td-4">
              <p class="h4 user-input-font">{{ userInfo.city }}</p>
            </td>
          </tr>
        </table>

        <br />
        <table class="user-info-input">
          <tr>
            <td class="td-4">
              <p class="h4 user-input-font">{{ userInfo.address }}</p>
            </td>
          </tr>
        </table>

        <br />
        <table class="user-info-input">
          <tr>
            <td class="td-4">
              <p class="h4 user-input-font">{{ userInfo.room }}</p>
            </td>
          </tr>
        </table>

        <h4 class="display-5 title-style title-div-input">生年月日</h4>
        <table class="user-info-input">
          <tr>
            <td class="td-4">
              <p class="h4 user-input-font">{{ userInfo.birthday }}</p>
            </td>
          </tr>
        </table>

        <h4 class="display-5 title-style title-div-input">性別</h4>
        <table class="user-info-input">
          <tr>
            <td class="td-4">
              <p class="h4 user-input-font">{{ userInfo.sex }}</p>
            </td>
          </tr>
        </table>

        <h4 class="display-5 title-style title-div-input">電話番号</h4>
        <table class="user-info-input">
          <tr>
            <td class="td-4">
              <p class="h4 user-input-font">{{ userInfo.phone }}</p>
            </td>
          </tr>
        </table>

        <h4 class="display-5 title-style title-div-input">会社</h4>
        <table class="user-info-input">
          <tr>
            <td class="td-4">
              <p class="h4 user-input-font">{{ userInfo.company }}</p>
            </td>
          </tr>
        </table>

        <h4 class="display-5 title-style title-div-input">所属クラブ</h4>
        <table class="user-info-input">
          <tr>
            <td class="td-4">
              <p class="h4 user-input-font">{{ userInfo.club }}</p>
            </td>
          </tr>
        </table>

        <br />
        <br />
      </div>
      <!-- 編集時画面 -->
      <div class="fitness-card" v-else>
        <h4 class="display-5 title-style title-div-input">お名前</h4>
        <div class="display-info-input">
          <table class="user-info-input">
            <tr>
              <td class="td-1">
                <input
                  type="text"
                  placeholder="日本"
                  maxlength="10"
                  v-model="userInfo.last_name"
                  required
                />
              </td>
              <td class="td-2"></td>
              <td class="td-3">
                <input
                  type="text"
                  placeholder="花子"
                  maxlength="10"
                  v-model="userInfo.first_name"
                  required
                />
              </td>
            </tr>
          </table>
        </div>
        <h4 class="display-5 title-style title-div-input">お名前（カナ）</h4>
        <div class="display-info-input">
          <table class="user-info-input">
            <tr>
              <td class="td-1">
                <input
                  type="text"
                  placeholder="ニホン"
                  inputmode="katakana"
                  v-model="userInfo.last_name_kana"
                  maxlength="10"
                  required
                />
              </td>
              <td class="td-2"></td>
              <td class="td-3">
                <input
                  type="text"
                  placeholder="ハナコ"
                  inputmode="katakana"
                  v-model="userInfo.first_name_kana"
                  maxlength="10"
                  required
                />
              </td>
            </tr>
          </table>
        </div>
        <h4 class="display-6 title-style title-div-input">ご住所</h4>
        <table class="user-info-input">
          <tr>
            <td class="td-4">
              <input
                type="text"
                placeholder="1400001"
                v-model="userInfo.zip_code"
                required
              />
            </td>
            <td class="td-2"></td>
            <td class="td-6">
              <button class="get-address" @click="getAddress()">
                住所取得
              </button>
            </td>
          </tr>
        </table>

        <br />
        <table class="user-info-input">
          <tr>
            <td class="td-4">
              <!-- <input type="text" v-model="userInfo.prefectures" /> -->
              <select
                name="prefectures"
                id="pet-select"
                v-model="userInfo.prefectures"
                class="select-style"
              >
                <option
                  v-for="option in prefecture_options"
                  v-bind:value="option.value"
                  :key="option.value"
                  >{{ option.text }}
                </option>
              </select>
            </td>
          </tr>
        </table>

        <br />
        <table class="user-info-input">
          <tr>
            <td class="td-4">
              <input type="text" v-model="userInfo.city" required />
            </td>
          </tr>
        </table>

        <br />
        <table class="user-info-input">
          <tr>
            <td class="td-4">
              <input type="text" v-model="userInfo.address" required />
            </td>
          </tr>
        </table>

        <br />
        <table class="user-info-input">
          <tr>
            <td class="td-4">
              <input
                type="text"
                placeholder="例)1丁目1-101-shibuya101号室 "
                maxlength="21"
                v-model="userInfo.room"
                required
              />
            </td>
          </tr>
        </table>

        <h4 class="display-5 title-style title-div-input">生年月日</h4>
        <table class="user-info-input select-input">
          <tr>
            <td class="td-5">
              <select
                name="prefectures"
                id="pet-select"
                v-model="birthday.year"
                class="select-style"
                @change="changeYear()"
              >
                <option
                  v-for="option in date.year"
                  v-bind:value="option.value"
                  :key="option.value"
                  >{{ option.text }}
                </option>
              </select>
            </td>
            <td class="td-6"></td>
            <td class="td-7">
              <select
                name="prefectures"
                id="pet-select"
                v-model="birthday.month"
                class="select-style"
                @change="changemonth()"
              >
                <option
                  v-for="option in date.month"
                  v-bind:value="option.value"
                  :key="option.value"
                  >{{ option.text }}
                </option>
              </select>
            </td>
            <td class="td-6"></td>
            <td class="td-7">
              <select
                name="prefectures"
                id="pet-select"
                v-model="birthday.day"
                class="select-style"
              >
                <option
                  v-for="option in date.day"
                  v-bind:value="option.value"
                  :key="option.value"
                  >{{ option.text }}
                </option>
              </select>
            </td>
            <td class="td-8"></td>

            <!-- <td class="td-4">
                          <input type="text" v-model="userInfo.birthday" />
                        </td> -->
          </tr>
        </table>

        <h4 class="display-5 title-style title-div-input">性別</h4>
        <table class="user-info-input">
          <tr>
            <td class="td-4">
              <select
                name="authority"
                id="authority-select"
                v-model="userInfo.sex"
                class="select-style"
              >
                <option
                  v-for="option in sex_options"
                  v-bind:value="option.value"
                  :key="option.value"
                  >{{ option.text }}
                </option>
              </select>
            </td>
          </tr>
        </table>

        <h4 class="display-5 title-style title-div-input">電話番号</h4>
        <table class="user-info-input">
          <tr>
            <td class="td-4">
              <input
                type="tel"
                placeholder="最大13桁"
                maxlength="13"
                v-model="userInfo.phone"
                required
              />
            </td>
          </tr>
        </table>

        <h4 class="display-5 title-style title-div-input">会社</h4>
        <table class="user-info-input">
          <tr>
            <td class="td-4">
              <input
                type="text"
                placeholder="21文字以内"
                maxlength="21"
                v-model="userInfo.company"
              />
            </td>
          </tr>
        </table>

        <h4
          class="display-5 title-style title-div-input"
          placeholder="21文字以内"
          maxlength="21"
        >
          所属クラブ
        </h4>
        <table class="user-info-input">
          <tr>
            <td class="td-4">
              <input
                type="text"
                placeholder="21文字以内"
                maxlength="13"
                v-model="userInfo.club"
              />
            </td>
          </tr>
        </table>

        <br />
        <br />
      </div>
      <div class="update-div" v-if="editClickVar">
        <button class="update-button" @click="userInfoUpdate()">更新</button>
      </div>
      <br />
      <br />
      <br />
      <br />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Header from "../../../components/Header";
import firebase from "firebase/compat";

export default {
  name: "Mypage",
  components: {
    //HelloWorld,
    Header,
  },
  data() {
    return {
      // 移行 myPage
      sns_error: {
        facebook: "",
        instagram: "",
      },
      // 移行 myPage

      userInfo: {},
      userInfoEdit: {},
      editClickVar: false,
      students_date: "",
      qualification_list: [],
      modal2: false,
      other_qualification: "",
      sex_options: [
        { text: "指定なし", value: "指定なし" },
        { text: "男性", value: "男性" },
        { text: "女性", value: "女性" },
      ],
      birthday: {
        year: "",
        month: "",
        day: "",
      },

      urlParm: "",
    };
  },
  created: function() {
    //画面開くときの処理
    this.getUserInfo();
    this.getDate();
  },
  methods: {
    // リンク判定 FB,IG
    urlValue(value) {
      let self = this;

      if (value == 1) {
        //faceBook(Meta)判定
        self.urlParm = self.userInfo.sns.facebook;
        if (!self.urlParm.match("facebook.com/")) {
          self.sns_error.facebook = " facebookのURLではありません";
          var urlText1 = document.getElementById("urlText1");
          urlText1.value = "";
        } else {
          self.sns_error.facebook = null;
        }
      } else if (value == 2) {
        //instagram判定
        self.urlParm = self.userInfo.sns.instagram;
        if (!self.urlParm.match("instagram.com/")) {
          self.sns_error.instagram = " instagramのURLではありません";
          var urlText2 = document.getElementById("urlText2");
          urlText2.value = "";
        } else {
          self.sns_error.instagram = null;
        }
      } else {
        //個人Site ※条件が出れば追加
        self.urlParm = self.userInfo.sns.mypage;
      }
    },

    doSns(value) {
      switch (value) {
        case 1:
          //facebook
          window.open(this.userInfo.sns.facebook, "_blank");
          break;
        case 2:
          //instagram
          window.open(this.userInfo.sns.instagram, "_blank");
          break;
        case 3:
          //mypage
          window.open(this.userInfo.sns.mypage, "_blank");
          break;
      }
    },
    cancel() {
      this.userInfoEdit = JSON.parse(localStorage.getItem("user_info"));
      this.modal2 = false;
    },
    update() {
      this.userInfo.qualification = this.userInfoEdit.qualification;
      this.modal2 = false;
    },
    doQualificationEdit() {
      let self = this;
      firebase
        .firestore()
        .collection(self.glb_table.qualification)
        .doc("qualification_list")
        .get()
        .then((snap) => {
          let data = snap.data().qualification;
          // if (self.userInfo.qualification != null) {
          if (self.userInfo.qualification.length == 0) {
            self.qualification_list = data;
          } else {
            self.qualification_list = data.filter(
              (i) => self.userInfo.qualification.indexOf(i) == -1
            );
          }
          // } else {
          //   self.qualification_list = data;
          // }
        });
      this.modal2 = true;
    },
    deleteQualification(index) {
      this.qualification_list.push(this.userInfoEdit.qualification[index]);
      this.userInfoEdit.qualification.splice(index, 1);
    },
    plusQualification(index) {
      if (
        !this.userInfoEdit.qualification.includes(
          this.qualification_list[index]
        )
      ) {
        this.userInfoEdit.qualification.push(this.qualification_list[index]);
        this.qualification_list.splice(index, 1);
      }
    },
    plusOtherQualification() {
      if (this.other_qualification != null && this.other_qualification != "") {
        if (
          !this.userInfoEdit.qualification.includes(this.other_qualification)
        ) {
          this.userInfoEdit.qualification.push(this.other_qualification);
        }
      }
    },
    toDate(str) {
      var arr = str.split("/");
      return new Date(arr[0], arr[1] - 1, arr[2]);
    },
    userInfoUpdate() {
      let self = this;
      //var birthdayDate = self.userInfo.birthday;
      var studentsDate = self.students_date;
      var contract_deadlineDate = self.userInfo.contract_deadline;

      //生年月日をデータに入力
      var birthdayFormat = this.toUserDateformat(
        this.birthday.year,
        this.birthday.month,
        this.birthday.day
      );

      //if (self.userInfo.birthday != null && self.userInfo.birthday != "") {
      var birthday = self.toDate(birthdayFormat);
      self.userInfo.birthday = firebase.firestore.Timestamp.fromDate(
        new Date(birthday)
      );
      //}

      if (
        self.userInfo.contract_deadline != null &&
        self.userInfo.contract_deadline != ""
      ) {
        var contract_deadline = self.toDate(self.userInfo.contract_deadline);
        self.userInfo.contract_deadline = firebase.firestore.Timestamp.fromDate(
          new Date(contract_deadline)
        );
      }

      if (
        self.userInfo.students_date != null &&
        self.userInfo.students_date != ""
      ) {
        var students_date = self.toDate(self.userInfo.students_date);
        self.userInfo.students_date = firebase.firestore.Timestamp.fromDate(
          new Date(students_date)
        );
      }

      //更新処理
      firebase.auth().onAuthStateChanged(function(user) {
        self.userInfo.updated_date = firebase.firestore.Timestamp.now();
        delete self.userInfo["created_date"];
        firebase
          .firestore()
          .collection(self.glb_table.users)
          .doc(user.uid)
          .update(self.userInfo)
          .then(() => {
            //localstorage更新
            self.userInfo.birthday = birthdayFormat;
            self.userInfo.students_date = studentsDate;
            self.userInfo.contract_deadline = contract_deadlineDate;
            localStorage.setItem("user_info", JSON.stringify(self.userInfo));
            window.location.reload();
          });
      });
    },
    editClick(value) {
      switch (value) {
        case 0:
          //戻る
          window.location.reload();
          break;
        case 1:
          //編集画面
          this.editClickVar = true;
          break;
        default:
          window.location.reload();
          break;
      }
    },
    formatDate(dt) {
      var y = dt.getFullYear();
      var m = ("00" + (dt.getMonth() + 1)).slice(-2);
      var d = ("00" + dt.getDate()).slice(-2);
      return y + "/" + m + "/" + d;
    },
    toUserDateformat(year, month, day) {
      var m = ("00" + month).slice(-2);
      var d = ("00" + day).slice(-2);
      return year + "/" + m + "/" + d;
    },
    getUserInfo() {
      this.userInfo = JSON.parse(localStorage.getItem("user_info"));
      this.userInfoEdit = JSON.parse(localStorage.getItem("user_info"));
      if (this.userInfo.birthday != null && this.userInfo.birthday != "") {
        let birthdayArr = this.userInfo.birthday.split("/");
        this.birthday.year = birthdayArr[0];
        if (birthdayArr[1].substring(0, 1) == 0) {
          birthdayArr[1] = birthdayArr[1].substring(1, 2);
        }
        this.birthday.month = birthdayArr[1];
        if (birthdayArr[2].substring(0, 1) == 0) {
          birthdayArr[2] = birthdayArr[2].substring(1, 2);
        }
        this.birthday.day = birthdayArr[2];
        this.changeYear();
      } else {
        this.birthday.year = 1900;
        this.birthday.month = 1;
        this.birthday.day = 1;
      }

      this.students_date = this.userInfo.students_date;
    },
    async getAddress() {
      var zipCode = this.userInfo.zip_code.replace("-", "");
      const payload = {
        address: "",
        zip: zipCode,
      };
      await this.axios
        .get("https://api.zipaddress.net/?", {
          params: { zipcode: payload.zip },
        })
        .then((res) => {
          if (res.data.code == 200) {
            this.userInfo.prefectures = res.data.data.pref;
            this.userInfo.city = res.data.data.city;
            this.userInfo.address = res.data.data.town;
          }
        });
    },
    getNewDate(value) {
      var currentTime = new Date();
      //0：年を取り
      if (value == 0) {
        return currentTime.getFullYear();
      }
      //1：月を取り
      if (value == 1) {
        return currentTime.getMonth() + 1;
      }
      //2：日を取り
      if (value == 2) {
        return currentTime.getDate();
      }
    },
    getDate() {
      //01:現在の年を取得する
      var year = this.getNewDate(0);
      //02:現在の年から120年前の
      for (let i = 0; i < 120; i++) {
        this.date.year.push({
          text: year - i,
          value: year - i,
        });
      }
    },
    changeYear() {
      this.date.month = [];
      if (this.birthday.month != "") {
        this.changemonth();
      }
      if (this.birthday.year == this.getNewDate(0)) {
        for (let i = 1; i <= this.getNewDate(1); i++) {
          this.date.month.push({
            text: i,
            value: i,
          });
        }
      } else {
        for (let i = 1; i < 13; i++) {
          this.date.month.push({
            text: i,
            value: i,
          });
        }
      }
    },
    isLeapYear(year) {
      if ((year % 4 === 0 && year % 100 !== 0) || year % 400 === 0) {
        return true;
      } else {
        return false;
      }
    },
    changemonth() {
      this.date.day = [];
      if (
        this.birthday.year == this.getNewDate(0) &&
        this.birthday.month == this.getNewDate(1)
      ) {
        for (let i = 1; i <= this.getNewDate(2); i++) {
          this.date.day.push({
            text: i,
            value: i,
          });
        }
      } else {
        const isLeapYear = this.isLeapYear(this.birthday.year);

        let month31 = [1, 3, 5, 7, 8, 10, 12];
        let month30 = [4, 6, 9, 11];

        if (month31.includes(parseInt(this.birthday.month))) {
          for (let i = 1; i <= 31; i++) {
            this.date.day.push({
              text: i,
              value: i,
            });
          }
        }

        if (month30.includes(parseInt(this.birthday.month))) {
          for (let i = 1; i <= 30; i++) {
            this.date.day.push({
              text: i,
              value: i,
            });
          }
        }

        if (this.birthday.month == 2) {
          if (isLeapYear) {
            for (let i = 1; i <= 29; i++) {
              this.date.day.push({
                text: i,
                value: i,
              });
            }
          } else {
            for (let i = 1; i <= 28; i++) {
              this.date.day.push({
                text: i,
                value: i,
              });
            }
          }
        }
      }
    },
  },
};
</script>
<style scoped>
.profile {
  position: absolute;
  width: 40%;
  height: auto;
  background-color: #f2f2f2;
  margin: 0%;
  margin-top: 57px;
  min-height: 700px;
  left: 30%;
}
.title-style {
  font-family: "Hiragino Kaku Gothic ProN";
  font-weight: 600;
  text-align: left;
  color: #838383;
  padding: 12px 0 0 12px;
}
.title-style-2 {
  font-family: "Hiragino Kaku Gothic ProN";
  font-weight: 600;
  text-align: center;
  margin-top: 0%;
  color: #838383;
  padding: 12px 0 0 12px;
}
.fitness-card {
  margin: 2% auto 0 auto;
  width: 95%;
  height: auto;
  background: #fff;
  filter: drop-shadow(9px 12px 15px rgba(0, 0, 0, 0.16));
  border-radius: 8px;
}

.fitness-card table th {
  text-align: left;
  padding: 5px;
  width: 50%;
}
.fitness-card table td {
  text-align: left;
  padding: 5px;
  width: 50%;
}

.title-div {
  padding: 6px 0 0 17px;
}
.display-info {
  width: 92%;
  min-height: 80px;
  height: auto;
  margin: 0% auto 0% auto;
  border-radius: 11px;
  background: #ededed;
}
.display-info-1 {
  width: 92%;
  min-height: 80px;
  height: auto;
  margin: 0% auto 0% auto;
  border-radius: 11px;
  background: #ffffff;
}
.title-font {
  color: #757575;
}
.content-font {
  color: #262525;
}
.input-font {
  padding: 12px;
  text-align: left;
  font-size: 14px;
  color: #262525;
}
.user-input-font {
  margin: 0%;
  padding-left: 6px;
  text-align: left;
  font-size: 14px;
  color: #262525;
}
.title-div-input {
  padding-top: 20px;
  color: #262525;
}
.name-style {
  margin-left: auto;
  margin-right: auto;
  width: 90%;
  height: 35px;
  background: #ededed;
  border: none;
  font-size: 16px;
  border-radius: 8px;
}
.user-info-input {
  width: 94%;
  margin-left: auto;
  margin-right: auto;
}
.user-info-input td {
  height: 35px;

  border: 3px #fff;
}
.user-info-input .td-1 {
  width: 48%;
  background: #ededed;
  border-radius: 6px;
}
.user-info-input .td-2 {
  width: 4%;
  background-color: #fff;
}
.user-info-input .td-3 {
  width: 48%;
  background: #ededed;
  border-radius: 6px;
}
.user-info-input .td-4 {
  width: 48%;
  background: #ededed;
  border-radius: 6px;
}
.user-info-input .td-5 {
  width: 25%;
  background: #ededed;
  border-radius: 6px;
  margin-right: 12px;
}
.user-info-input .td-6 {
  width: 2%;
  border-radius: 6px;
}
.user-info-input .td-7 {
  width: 20%;
  background: #ededed;
  border-radius: 6px;
}
.user-info-input .td-8 {
  width: 35%;
  border-radius: 6px;
}

.get-address {
  float: left;
  width: 80px;
  height: 28px;
  background: #eb7620;
  filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.16));
  font-family: "Hiragino Kaku Gothic ProN";
  font-weight: 900;
  font-size: 12px;
  text-align: center;
  color: #fff;
  border: none;
  border-radius: 4px;
}
textarea {
  width: 100%;
  min-height: 82px;
  height: auto;
  margin: 0% auto 0% auto;
  border-radius: 11px;
  background: #ededed;
  border: none;
  padding: 10px;
  outline: none;
  font-size: 16px;
}

.profile input[type="text"] {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  font-size: 16px;
  background: #ededed;
}

.profile input[type="tel"] {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  font-size: 16px;
  background: #ededed;
}
.profile .select-style {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  font-size: 16px;
  background: #ededed;
  color: #000;
  outline: none;
}

.qualification {
  padding: 2px 2px 4px 4px;
}
a {
  display: inline-block;
  margin: 0 0.1em 0.6em 0;
  padding: 0.8em;
  line-height: 1;
  text-decoration: none;
  background: #ededed;
  font-size: 12px;
  font-weight: 700;
  border-radius: 8px;
  box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.16);
}
.qualification-div {
  display: inline-flex;
  padding: 5px;
  overflow: auto;
}

.popContainer {
  z-index: 4;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
}
.qualification-modal2 {
  margin-left: auto;
  margin-right: auto;
  margin-top: 90px;
  width: 95%;
  height: 70%;
  background-color: #fff;
  overflow: auto;
}
.qualification-modal2 table {
  width: 100%;
}
.qualification-modal2 table th {
  width: 50%;
  background-color: #f2f2f2;
}
.qualification-modal2 table td {
  width: 50%;
  background-color: #ffffff;
}
.plus-qualification {
  width: 50%;
  height: 30px;
  background: #ededed;
  font-size: 16px;
  font-weight: 500;
  border-radius: 8px;
  box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.16);
  padding: 0.8em;
  border: none;
  outline: none;
}
.popContainer table {
  margin-left: auto;
  margin-right: auto;
  width: 95%;
}
.popContainer table td {
  width: 50%;
  height: 30px;
  background-color: #ffffff;
}
.popContainer table th {
  width: 50%;
  height: 30px;
  background-color: #ffffff;
  padding: 10px;
}
.button-style {
  border: none;
  width: 60%;
  height: 40px;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 700;
}
.cancel-button {
  border: 2px #eb7620;
}
.update-button {
  background-color: cadetblue;
  color: #ffffff;
}
.update-div {
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  width: 60%;
  height: 50px;
}
.update-button {
  text-align: center;
  width: 100px;
  height: 35px;
  border: none;
  font-size: 16px;
  font-weight: 700;
  border-radius: 10px;
  background-color: rgb(3, 163, 168);
  box-shadow: 3px 6px 9px rgb(139, 139, 139);
}
.icon-div {
  padding: 10px;
  height: 50px;
  width: 50px;
}
.error-msg {
  color: rgb(116, 0, 0);
  font-size: 14px;
  padding: 0%;
  margin-left: 4%;
  text-align: left;
}
.error-msg p {
  margin: 0%;
}
</style>
