<template>
  <div>
    <Header :page="8" @my-click="reflectNum" />
    <div class="ft-content">
      <div class="program-text">
        <div class="program-div">
          <MkProgramList />
        </div>
      </div>
    </div>
    <div class="navigation-style">
      <Navigation :page="2" />
    </div>
  </div>
</template>

<script>
import Navigation from "@/components/Navigation";
import Header from "@/components/Header";
import MkProgramList from "../../components/mk_program/mk_program_list";
export default {
  name: "Search",
  data() {
    return {
      const: 0,
    };
  },
  components: {
    Navigation,
    Header,
    MkProgramList,
  },
};
</script>
<style scoped>
.ft-content {
  position: absolute;
  width: 100%;
  height: 100vh;
  background-color: rgb(255, 255, 255);
  margin: 0%;
  margin-top: 58px;
  min-height: 700px;
}
.program-text {
  margin-top: 2%;
}
.program-div {
  float: left;
  text-align: left;
  padding-left: 2%;
}
.program-div > .program-font-style {
  color: rgb(0, 0, 0);
  font-weight: bold;
  font-size: 16px;
  padding-left: 14px;
}
</style>
