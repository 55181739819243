<template>
  <div>
    <div v-if="modal">
      <!-- モーダル表示内容 -->
      <div class="popContainer">
        <div class="alert-div">
          <h4 class="display-6 alert-msg">{{ alert_msg }}</h4>
          <h4 class="display-6 msg-color">{{ error_msg }}</h4>
          <!-- 確認、CXLボタン -->
          <div class="button-div">
            <table>
              <tr>
                <td>
                  <button
                    class="button-style cancel-button"
                    @click="closeModal()"
                  >
                    キャンセル
                  </button>
                </td>
                <td>
                  <button
                    class="button-style send-button"
                    @click="userMailUpdate()"
                  >
                    確認
                  </button>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
    <!-- ヘッダImport部 -->
    <Header :page="4" />

    <div class="heard-style">
      <!--  HeaderBackButtonEllement-->
      <table class="heard-table">
        <tr>
          <td class="back-icon">
            <a href="#/profile">
              <img src="@/assets/home/yajirusi.svg" width="20" height="20" />
            </a>
          </td>
        </tr>
      </table>

      <!-- メール入力エリア -->
      <div class="mailEdit">
        <!-- ユーザ情報があれば出現処理 -->
        <div v-if="userInfo.mail">
          <div>
            <input type="text" class="mail-input" v-model="outputMail" />
          </div>
          <!-- 更新ボタン 現在のMailと比較、相違の場合出現 -->
          <div class="update-div" v-if="editMail">
            <!-- 更新押下 = モーダル出現 -->
            <button class="update-button" @click="openModal()">更新</button>
          </div>
        </div>
      </div>
    </div>
    <!-- アンダーナビバーcompo -->
    <div class="navigation-style">
      <Navigation />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from "@/components/HelloWorld.vue";
import Navigation from "../../../components/Navigation";
import Header from "../../../components/Header";
import firebase from "firebase/compat";

export default {
  name: "Mypage",

  //Module
  components: {
    Navigation,
    Header,
    // ProgramStatic
  },
  //this指定データ
  data() {
    return {
      editClickVar: false,
      userInfo: {},
      outputMail: "",
      editMail: false,
      modal: false,
      alert_msg: "",
      error_msg: "",
    };
  },

  created: function() {
    //画面開くときの処理  ※LocalStorage取得処理
    this.getUserInfo();
  },
  // 監視 :Input現状と比較
  watch: {
    outputMail: function(newMail) {
      //全角->半角  正規Login画面踏襲
      this.outputMail = newMail.replace(/[Ａ-Ｚａ-ｚ０-９]/g, function(s) {
        return String.fromCharCode(s.charCodeAt(0) - 0xfee0);
      });

      if (newMail == this.userInfo.mail) {
        //一致の場合 出現なし
        this.editMail = false;
      } else {
        this.editMail = true;
      }
    },
  },
  methods: {
    openModal() {
      this.alert_msg =
        "メールアドレスを更新してよろしいですか？" +
        "\n" +
        "更新あとには再度ログインが必要です。";
      this.error_msg = "";
      this.modal = true;
    },
    closeModal() {
      this.modal = false;
    },
    getUserInfo() {
      this.userInfo = JSON.parse(localStorage.getItem("user_info"));
      this.outputMail = this.userInfo.mail;
    },
    userMailUpdate() {
      //確定button
      let self = this;
      firebase.auth().onAuthStateChanged(function(user) {
        // メール更新
        firebase
          .firestore()
          .collection(self.glb_table.users)
          .doc(user.uid)
          .update({
            mail: self.outputMail,
          })
          .then(() => {
            //正常処理 -> auth情報を更新
            firebase
              .auth()
              .currentUser.updateEmail(self.outputMail)
              .then(() => {
                //更新日時も更新 mail（store,Auth）が正常更新されたあと
                firebase
                  .firestore()
                  .collection(self.glb_table.users)
                  .doc(user.uid)
                  .update({
                    updated_date: firebase.firestore.Timestamp.now(),
                    //更新日時も同時更新
                  })
                  .then(async () => {
                    await firebase
                      .auth()
                      .signOut()
                      .then(() => {
                        window.location.reload();
                      })
                      .catch(() => {
                        window.location.reload();
                      });
                  });
              })
              .catch(() => {
                self.error_msg = "メールアドレス更新できませんでした。";
                //異常
                firebase
                  .firestore()
                  .collection(self.glb_table.users)
                  .doc(user.uid)
                  .update({
                    mail: self.userInfo.mail,
                  })
                  .then(() => {});
              });
          })
          .catch(() => {
            //エラーが発生
            self.error_msg = "メールアドレス更新できませんでした。";
          });
      });
    },
  },
};
</script>

<style scoped>
/* 修正 */

.heard-style {
  z-index: 2;
  margin-top: 0%;
  width: 100%;
  height: 58px;
  background-color: #04545c;
  display: block;
}

.heard-table {
  color: rgb(255, 255, 255);
  width: 100%;
  z-index: 2;
  margin-top: 0%;
  width: 100%;
  height: 58px;
  background: #04545c;
  border-bottom: 1px solid #cfcfcf;
}
.back-icon {
  width: 20%;
  color: rgb(255, 255, 255);
}

.heard-title {
  padding-top: 10px;
  width: 60%;
}
.heard-menu {
  width: 20%;
}

.heard-style > h2 {
  color: #414141;
  font-weight: 900;
}

.EmailEdit a {
  display: block;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding: 1em 2em;
  width: 60px;
  color: white;
  font-size: 10px;
  font-weight: 700;
  background-color: #007a1b;
  border-radius: 10vh;
}

.EmailEdit a:hover {
  text-decoration: none;
  background-color: #0fcf4f;
}
.card {
  /* margin-top: 10%; */
  width: 100%;
  height: 100%;
  font-size: 14px;
  display: inline-block;
  text-align: left;
  font-weight: 900;
}
.list-group-item {
  margin-top: 1rem;
}
input {
  width: 100%;
  height: 3rem;
  font-size: 2rem;
}
.mail-input {
  height: 35px;
  width: 95%;
  margin: 10px auto 0 auto;
  background: #ededed;
  border-radius: 6px;
  border: none;
  outline: none;
  font-size: 16px;
  padding-left: 8px;
  color: #000;
  font-weight: 600;
}

.update-div {
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  width: 60%;
  height: 50px;
}
.update-button {
  text-align: center;
  width: 100px;
  height: 35px;
  border: none;
  font-size: 16px;
  font-weight: 700;
  border-radius: 10px;
  background-color: rgb(3, 163, 168);
  box-shadow: 0px 3px 6px rgb(214, 214, 214);
  color: #ffffff;
}
.popContainer {
  z-index: 2;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
}
.alert-div {
  margin-left: auto;
  margin-right: auto;
  margin-top: 200px;
  width: 80%;
  height: 250px;
  background-color: #fff;
  border-radius: 5px;
}
.alert-msg {
  padding: 15% 5% 5% 5%;
  color: #000;
  font-weight: 500;
  white-space: pre-wrap;
}
.button-div table {
  margin-top: 15px;
  width: 100%;
}
.button-style {
  width: 100px;
  height: 40px;
  border: 1px solid #dddddd;
}
.cancel-button {
  border-radius: 10px;
  background-color: #fff;
  color: #888888;
  font-size: 14px;
  font-weight: 600;
}
.send-button {
  border-radius: 10px;
  background-color: #fff;
  color: #00aa93;
  font-size: 14px;
  font-weight: 600;
}
.msg-color {
  padding: 1% 1% 1% 1%;
  color: rgb(161, 0, 0);
}
</style>
