export default {
  data() {
    return {
      glb_admin_mail: "iwai@fitnessclub.jp",
      glb_inspection: {
        under_review: "未審査",
        examined: "審査済",
        rejected: "不許可"
      },
      glb_input_data_type: {
        number: 1,
        string: 2
      },
      glb_input_class_type: {
        basic: "ベーシック",
        senior: "シニア",
        free: "〇〇（自由記述）"
      },
      glb_input_class_iconUrl: {
        basic: "https://firebasestorage.googleapis.com/v0/b/fitnesscamp-428f2.appspot.com/o/class_%20default%2Fbasic_icon.png?alt=media&token=60eb7f17-dac5-44ee-81b0-c134a41acf43",
        senior: "https://firebasestorage.googleapis.com/v0/b/fitnesscamp-428f2.appspot.com/o/class_%20default%2Fsenior.jpg?alt=media&token=18955389-a31c-4df9-b75e-b7f705edf4f8",
        free: "https://firebasestorage.googleapis.com/v0/b/fitnesscamp-428f2.appspot.com/o/tool%2Ffitness_camp.png?alt=media&token=b43aef27-a0dd-400e-b357-48bceccbfe83"
      },
      glb_table: {
        class: "class",//クラステーブル
        users: "users",//ユーザーテーブル
        class_applicant: "class_applicant",//申請者テーブル
        mail: "mail",//メールテーブル
        program: "program",//プログラム
        qualification: "qualification"
      },
      glb_program_title:
      {
        program_name: "プログラム名と説明",
        improvement: "呼吸/アライメント改善",
        staticStretch: "スタティックストレッチ",
        movementTraining: "ムーブメントトレーニング",
        dynamicStretch: "ダイナミックストレッチ",
        circuittraining: "サーキットトレーニング",
        activation: "アクティベーション",
        groupWork: "グループワーク",
        status: "ステータス"
      },
      glb_program_content:
      {
        improvement: "improvement",
        staticStretch: "staticStretch",
        movementTraining: "movementTraining",
        dynamicStretch: "dynamicStretch",
        circuittraining: "circuittraining",
        activation: "activation",
        groupWork: "groupWork"
      },
      glb_program_page:
      {
        program_name: {
          name: "プログラム名と説明",
          event: false
        },
        improvement: {
          name: "呼吸/アライメント改善",
          event: false
        },
        staticStretch: {
          name: "スタティックストレッチ",
          event: false
        },
        movementTraining: {
          name: "ムーブメントトレーニング",
          event: false
        },
        dynamicStretch: {
          name: "ダイナミックストレッチ",
          event: false
        },
        circuittraining: {
          name: "サーキットトレーニング",
          event: false
        },
        activation: {
          name: "アクティベーション",
          event: false
        },
        groupWork: {
          name: "グループワーク",
          event: false
        },
        status: {
          name: "ステータス",
          event: false
        }
      },
      glb_upload_file_func: {
        class: "class",
        program_icon: "program_icon",
        program_image: "program_image",
        program_video: "program_video",
        improvement: "improvement",
        staticStretch: "staticStretch",
        movementTraining: "movementTraining",
        dynamicStretch: "dynamicStretch",
        circuittraining: "circuittraining",
        activation: "activation",
        groupWork: "groupWork",
      },
      glb_program_search_option: [
        { text: "呼吸/アライメント改善", value: "improvement" },
        { text: "スタティックストレッチ", value: "staticStretch" },
        { text: "ムーブメントトレーニング", value: "movementTraining" },
        { text: "ダイナミックストレッチ", value: "dynamicStretch" },
        { text: "サーキットトレーニング", value: "circuittraining" },
        { text: "アクティベーション", value: "activation" },
        { text: "グループワーク", value: "groupWork" }
      ],
      glb_program_status: [
        { text: "公開", value: "公開" },
        { text: "非公開", value: "非公開" },
        { text: "下書き", value: "下書き" }
      ],
      glb_program_status_type: {
        public: "公開",
        private: "非公開",
        draft: "下書き"
      },
      glb_alert_title: {
        mk_program_cancel: "保存せずに、画面を閉じてもよろしいですか？"
      },
      glb_program_category: [
        { text: "基礎", value: "基礎" },
        { text: "応用", value: "応用" },
      ],
      glb_mk_program_type:
      {
        official: "公式",
        informal: "個人"
      },
      glb_mk_program_type_option: [
        { text: "公式", value: "公式" },
        { text: "個人", value: "個人" },
      ],
      mk_program_storage: {
        mk_copy_to_program_uid: null,
        mk_program_id: null,
        mk_copy_program_info: null,
        mk_new_program_info: null,
        mk_program_page: null,
        mk_program_icon_image: null,
        mk_program_image_image: null,
        mk_new_import_program_info: null
      },
      mk_program_storage_name: "mk_program_storage",
      glb_file_type: {
        program_icon: "program_icon",
        program_video: "program_video"
      },
      glb_user_authority: {
        admin: "5",
        general: "1"
      }
    };
  },
  async mounted() {
  },
  computed: {

  },
  methods: {
    glb_set_localStorage_for_program(key, value) {
      localStorage.setItem(key, JSON.stringify(value))
    },
    glb_get_localStorage_for_program(key) {
      return JSON.parse(localStorage.getItem(key))
    },
    glb_set_localStorage_new_program_info(value) {
      //this.glb_set_localStorage_new_program_info(this.program_info);
      this.mk_program_storage = this.glb_get_localStorage_for_program(
        this.mk_program_storage_name
      );
      this.mk_program_storage.mk_new_program_info = value;
      this.glb_set_localStorage_for_program(
        this.mk_program_storage_name,
        this.mk_program_storage
      );
    },
    glb_get_mk_program_info() {
      //this.program_info = await this.glb_get_mk_program_info();
      this.mk_program_storage = this.glb_get_localStorage_for_program(
        this.mk_program_storage_name
      );
      let data = {}
      if (this.mk_program_storage.mk_new_program_info == null) {
        data = this.mk_program_storage.mk_copy_program_info
        return data;
      } else {
        data = this.mk_program_storage.mk_new_program_info
        return data;
      }
    },
    glb_check_library(value) {
      switch (value) {
        case this.glb_program_content.improvement:
          return this.glb_program_title.improvement;
        case this.glb_program_content.staticStretch:
          return this.glb_program_title.staticStretch;
        case this.glb_program_content.movementTraining:
          return this.glb_program_title.movementTraining;
        case this.glb_program_content.dynamicStretch:
          return this.glb_program_title.dynamicStretch;
        case this.glb_program_content.circuittraining:
          return this.glb_program_title.circuittraining;
        case this.glb_program_content.activation:
          return this.glb_program_title.activation;
        case this.glb_program_content.groupWork:
          return this.glb_program_title.groupWork;
        default:
          return ""
      }
    }
  },
};
