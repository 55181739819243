<template>
  <div>
    <div class="home">
      <div class="about">
        <a href="#" v-on:click.stop.prevent="openAbotModal()" class="alert-link">>>About</a>
      </div>
      <div v-if="isModal">
        <div class="popContainer">
          <div class="heard-style">
            <h2>1.スタティックストレッチ</h2>
          </div>
          <div class="card card-modal">
            <div class="card-body">
              <StaticStrechAbout />
              <br />
              <br />
            </div>
          </div>
          <div class="close-button">
            <div class="close-button-div">
              <a
                href="#"
                v-on:click.stop.prevent="closeAbotModal()"
                class="alert-link close-button-text"
              >閉じる</a>
            </div>
          </div>
        </div>
      </div>

      <div>
        <!-- 説明部分 -->
        <div class="card border-light mb-3 card-style">
          <div class="card-body">
            <p class="card-text title-conten-font">
              次に、可動範囲を広げる運動（ストレッチ）を行いましょう。
            </p>
          </div>
        </div>
      </div>

      <div>
        <!-- ビデオ -->
        <div class="card video-div-style">
          <video :src="video_url_1" controls preload="metadata" class="video-style" />
        </div>
        <!-- タイトル -->
        <p class="h4 h4-title">トータッチ（前屈）</p>
        <!-- 説明 -->
        <div class="card border-light mb-3 card-style">
          <div class="card-body">
            <p class="card-text title-conten-font">
              まずは、足の幅を狭くしてタオルを膝に挟み、爪先を高くしましょう。
              <br />タオルを内腿でしっかりと潰した状態のまま、口から息を履いて前屈しましょう。
              <br />タオルを絞り込む意識をしながら、息を吐き切って最後まで前屈しましょう。
            </p>
          </div>
        </div>
        <!-- 目的、動作、ポイントtable -->
        <div>
          <table>
            <tr>
              <th>目的</th>
              <td>大腿後面のストレッチ</td>
            </tr>
            <tr>
              <th>動作</th>
              <td>
                １．膝にタオルを挟んで内転筋を刺激する（タオルがない場合には内転する意識で）
                <br />２．足をものを使って背屈（底屈）する（足を置くものがなければフラットで）
                <br />３．5秒かけて息を吐きながら前屈する
                <br />４．5回ずつ（段差が作れれば）行う
              </td>
            </tr>
            <tr>
              <th>ポイント</th>
              <td>
                内転筋や腹横筋を意識した状態で前屈する
              </td>
            </tr>
          </table>
        </div>
      </div>

      <div>
        <!-- ビデオ -->
        <div class="card video-div-style">
          <video :src="video_url_2" controls preload="metadata" class="video-style" />
        </div>
        <!-- タイトル -->
        <p class="h4 h4-title">ディープスクワットプログレッション</p>
        <!-- 説明 -->
        <div class="card border-light mb-3 card-style">
          <div class="card-body">
            <p class="card-text title-conten-font">
              足を肩幅くらい、爪先を45°くらい開いて立ちましょう。
              <br />そのまま蹲み込んで爪先を持ちます。
              <br />その時に肘で膝をしっかり開き、そして首を長く背中を保ちましょう。
              <br />そこから、息を吐きながらお尻を高く膝を伸ばしましょう。
              <br />息を吐きながら最後まで伸ばしましょう。
            </p>
          </div>
        </div>
        <!-- 目的、動作、ポイントtable -->
        <div>
          <table>
            <tr>
              <th>目的</th>
              <td>
                大腿後面、背部のストレッチ<br />
                股関節のモビライゼーション
              </td>
            </tr>
            <tr>
              <th>動作</th>
              <td>
                １．足を肩幅に開いて爪先を45°外旋させる
                <br />２．肘を膝の内側に構え爪先を持つ
                <br />３．脊柱をまっすぐに保つ
                <br />４．爪先を抱え息を吐きながら膝を伸展する
                <br />５．5秒間息を吐きながら伸ばす
                <br />６．5回行う
              </td>
            </tr>
            <tr>
              <th>ポイント</th>
              <td>
                骨盤・脊柱のアライメントを保ち股関節から動作をする
                <br />大腿四頭筋、腹横筋を意識して伸ばす
              </td>
            </tr>
          </table>
        </div>
      </div>
      <div>
        <!-- ビデオ -->
        <div class="card video-div-style">
          <video :src="video_url_3" controls preload="metadata" class="video-style" />
        </div>
        <!-- タイトル -->
        <p class="h4 h4-title">ワールドグレイテストストレッチ</p>
        <!-- 説明 -->
        <div class="card border-light mb-3 card-style">
          <div class="card-body">
            <p class="card-text title-conten-font">
              足を前に出して両手をついて首を長く保ちましょう。
              <br />前足の脛を垂直に保ったまま、肘を地面に近づけましょう。
              <br />手をついて地面を押しながら、上の手を真っ直ぐ上にあげましょう。
              <br />手が地面から生えているように、上の手とつながっているのように、目線は上を向いて。
            </p>
          </div>
        </div>
        <!-- 目的、動作、ポイントtable -->
        <div>
          <table>
            <tr>
              <th>目的</th>
              <td>
                股関節伸筋、内転筋、胸背部、大腿後面のストレッチ
                <br />股関節と胸椎のモビライゼーション
              </td>
            </tr>
            <tr>
              <th>動作</th>
              <td>
                １．片足を前に出し後ろ足を伸展する、頭部から後ろ足まで一直線を保つ前足の下腿は床に対して垂直にする
                <br />２．出した側の肘を床に近づける
                <br />３．肘をついた手を床にして胸椎を回旋させる目線は上の手を見る
                <br />４．手を変えて床に付き回旋させる
                <br />５．各ポジション5秒保持する
                <br />６．交互に4回（片側2回ずつ）行う
              </td>
            </tr>
            <tr>
              <th>ポイント</th>
              <td>前足の下腿を垂直に保つ\n回旋するときは下ので手で床を押す（ことによって回旋が生まれる</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import StaticStrechAbout from "../commentary/StaticStrechAbout";
export default {
  name: "StaticStrech",
  components: {
    StaticStrechAbout
  },
  props: {},
  data() {
    return {
      isModal: false,
      video_url_1:
      "https://firebasestorage.googleapis.com/v0/b/fitnesscamp-428f2.appspot.com/o/program%2Fcontent%2Fmovie%2Fstatic%2Ftoe_touch.mp4?alt=media&token=e850afe3-54ed-42bb-a932-3158b6df99aa",
      video_url_2:
      "https://firebasestorage.googleapis.com/v0/b/fitnesscamp-428f2.appspot.com/o/program%2Fcontent%2Fmovie%2Fstatic%2F%E3%82%B9%E3%82%AF%E3%83%AF%E3%83%83%E3%83%88%E3%83%95%E3%82%9A%E3%83%AD%E3%82%AF%E3%82%99%E3%83%AC%E3%83%83%E3%82%B7%E3%83%A7%E3%83%B3.mp4?alt=media&token=fe032ea9-5d1c-4b77-af3f-3e5c9e15911a",
      video_url_3:
      "https://firebasestorage.googleapis.com/v0/b/fitnesscamp-428f2.appspot.com/o/program%2Fcontent%2Fmovie%2Fstatic%2Fwild_stretch.mp4?alt=media&token=f61c528c-e5d3-4895-9492-cb430582a983",
    };
  },
  methods: {
    openAbotModal() {
      this.isModal = true;
    },
    closeAbotModal() {
      this.isModal = false;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.heard-style {
  padding-top: 14px;
  height: 50px;
  background-color: #04545c;
  border: 0;
  margin-top: 5%;
  margin-left: 8%;
  margin-right: 8%;
}
.heard-style img {
  float: left;
  margin-left: 2%;
}
.heard-style > h2 {
  color: rgb(255, 255, 255);
  margin-right: 4%;
  font-weight: 900;
}
.close-button {
  height: 50px;
  background-color: #ffffff;
  border: 0;
  margin-left: 8%;
  margin-right: 8%;
}
.close-button-div {
  background-color: rgb(255, 255, 255);
  text-align: right;
  margin-right: 4%;
  padding-top: 12px;
}
.close-button-text {
  font-size: 16px;
  font-weight: 400;
  color: rgb(163, 163, 163);
}

.about {
  text-align: right;
  margin-top: 2%;
  margin-bottom: 3%;
}
.about > a:link,
a:visited,
a:hover,
a:active {
  text-decoration: none;
  font-size: 16px;
  font-weight: 400;
  color: rgb(163, 163, 163);
}
.title {
  padding-top: 4%;
  text-align: left;
}

.card-style {
  max-width: 100rem;
  margin: 2%;
  background-color: rgb(248, 248, 248);
}
.title-font {
  color: #000;
  font-weight: 600;
  text-align: left;
}
.title-conten-font {
  color: #000;
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  letter-spacing: 1px;
  line-height: 20px;
}
.video-div-style {
  height: auto;
  width: auto;
  margin-top: 15%;
  margin-left: 2%;
  margin-right: 2%;
  border: 0;
  background-color: rgb(233, 233, 233);
}
.video-style {
  height: 250px;
}
.h4-title {
  margin-top: 5%;
  margin-bottom: 4%;
  margin-left: 2%;
  text-align: left;
  font-weight: 600;
  color: #000;
}
.home > img {
  padding-top: 1%;
  width: 96%;
}
.program-font-style {
  font-weight: 600;
  color: #000;
}
.text-style {
  padding-top: 2%;
  text-align: left;
  font-size: 14px;
  font-weight: 500;
}
.font-style {
  letter-spacing: 2px;
  line-height: 25px;
  padding-top: 8px;
}
.span-style {
  margin-left: -5px;
  margin-right: -5px;
  background: linear-gradient(transparent 50%, #f5f100 0%);
}

/* table */
table {
  margin-top: 8%;
  margin-left: 2%;
  margin-right: 2%;
  width: 96%;
  border-collapse: collapse;
}

table tr {
  width: 30px;
  border-bottom: solid 1px white;
}

table tr:last-child {
  border-bottom: none;
}

table th {
  position: relative;
  text-align: left;
  width: 25%;
  height: auto;
  background-color: #e4ecec;
  color: rgb(0, 0, 0);
  text-align: center;
  padding: 10px 0;
  font-size: 14px;
  font-weight: 400;
}
table td {
  position: relative;
  text-align: left;
  height: auto;
  background-color: rgb(247, 247, 247);
  color: rgb(0, 0, 0);
  text-align: left;
  padding: 10px 10px;
  font-size: 14px;
  font-weight: 400;
}
.popContainer {
  z-index: 2;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(43, 43, 43, 0.3);
}
.card-modal {
  overflow: auto;
  z-index: 2;
  margin-top: 0%;
  margin-left: 8%;
  margin-right: 8%;
  height: 75%;
  background-color: rgb(255, 255, 255);
}
</style>
