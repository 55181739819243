<template>
  <div class="about">
    <ClassHeader :page="1" />
    <div v-show="error">
      <ErrrorAlert :page="2" @do-alert-cancel="doAlertCancel" />
    </div>
    <div v-show="success">
      <SuccessAlert :page="2" @do-alert-cancel="doSuccesAlertCancel" />
    </div>
    <Loading v-show="loading" />
    <div v-show="icon">
      <div class="icon-div">
        <div class="icon-select-box">
          <div class="imageListView">
            <ImageListView
              :image_view_list="class_info.profile_image_list"
              :event="'3'"
              @edit-click="editClick"
            />
          </div>
          <button class="closebutton" @click="closeIcon()">確定</button>
        </div>
      </div>
    </div>
    <div class="body-page">
      <form class="register-form" @submit.prevent="class_register">
        <div class="input-form">
          <div class="title">
            <h4 class="input-title">クラス種類</h4>
            <div class="mandatory">
              <label>必須</label>
            </div>
          </div>
          <div class="input-group mb-3">
            <select
              class="custom-select select-form"
              id="inputGroupSelect01"
              v-model="profile_image_select"
            >
              <option
                v-for="option in profile_image_option"
                v-bind:value="option.value"
                :key="option.value"
                >{{ option.text }}
              </option>
            </select>
          </div>
          <div
            class="title"
            v-if="profile_image_select === glb_input_class_type.free"
          >
            <h4 class="input-title">クラス種類(自由記述)</h4>
            <div class="mandatory">
              <label>必須</label>
            </div>
          </div>
          <div
            class="input-group input-group-lg"
            v-if="profile_image_select === glb_input_class_type.free"
          >
            <input
              type="text"
              class="form-control input-content"
              aria-label="Sizing example input"
              aria-describedby="inputGroup-sizing-lg"
              v-model="class_info.class_name"
            />
          </div>
        </div>
        <div class="input-form">
          <div class="title">
            <h4 class="input-title">クラスアイコン</h4>
            <div class="mandatory">
              <label>必須</label>
            </div>
          </div>
          <!-- <ImageListView :event="'4'" @edit-icon-click="editIconClick" /> -->
          <div class="d-flex justify-content-start img-fiex">
            <ImageListView
              :event="'4'"
              :class_type="profile_image_select"
              :default_image="default_image"
            />
            <ImageListIcon :event="'1'" @update-icon-click="updateIconClick" />
          </div>
        </div>
        <div class="input-form">
          <div class="title">
            <h4 class="input-title">レッスン名</h4>
            <div class="mandatory">
              <label>必須</label>
            </div>
          </div>
          <div class="input-group input-group-lg">
            <input
              type="text"
              class="form-control input-content"
              aria-label="Sizing example input"
              aria-describedby="inputGroup-sizing-lg"
              required
              v-model="class_info.lesson_name"
            />
          </div>
        </div>
        <div class="input-form">
          <div class="title">
            <h4 class="input-title">コーチ名</h4>
            <div class="mandatory">
              <label>必須</label>
            </div>
          </div>
          <div class="input-group input-group-lg">
            <input
              type="text"
              class="form-control input-content"
              aria-label="Sizing example input"
              aria-describedby="inputGroup-sizing-lg"
              required
              v-model="class_info.coach_name"
            />
          </div>
        </div>
        <div class="input-form">
          <div class="title">
            <h4 class="input-title">日時</h4>
            <div class="mandatory">
              <label>必須</label>
            </div>
          </div>
          <div class="input-group mb-3">
            <select
              class="custom-select select-form"
              id="inputGroupSelect01"
              v-model="class_info.organize_type"
            >
              <option
                v-for="option in date_select_options"
                v-bind:value="option.value"
                :key="option.value"
                >{{ option.text }}
              </option>
            </select>
          </div>
        </div>
        <div v-if="class_info.organize_type !== '定期'">
          <hr class="hr-style" />

          <div
            v-for="(content, index) in class_info.irregular_organize"
            v-bind:key="content.index"
          >
            <div class="date-style">
              <br />
              <div class="date-fiex">
                <h5 class="input-title date-font">日付</h5>
                <div class="input-group input-group-lg">
                  <div class="datepicker-style">
                    <v-date-picker :popover="popover" v-model="content.date">
                      <template v-slot="{ inputValue, inputEvents }">
                        <input
                          :value="inputValue"
                          v-on="inputEvents"
                          class="datepicker"
                        />
                      </template>
                    </v-date-picker>
                  </div>
                </div>
              </div>
              <div class="date-fiex">
                <h5 class="input-title date-font">時刻</h5>
                <div class="input-group input-group-lg">
                  <input
                    type="time"
                    class="form-control input-content time-input"
                    aria-label="Sizing example"
                    aria-describedby="inputGroup-sizing-lg"
                    required
                    v-model="content.start_time"
                  />
                  <div class="time_label_02">
                    <label>〜</label>
                  </div>
                  <input
                    type="time"
                    class="form-control input-content time-input"
                    aria-label="Sizing example"
                    aria-describedby="inputGroup-sizing-lg"
                    required
                    v-model="content.end_time"
                  />
                </div>
              </div>
              <div class="date-fiex">
                <div class="date-button plus-button" @click="plusDate(index)">
                  <label>日付追加</label>
                </div>
                <div
                  class="date-button delete-button"
                  @click="deleteDate(index)"
                >
                  <label>日付削除</label>
                </div>
              </div>
            </div>
            <br />
          </div>
        </div>
        <div v-else>
          <div class="date-fiex">
            <div class="time_label_03">
              <label>開始時刻</label>
            </div>
            <div class="time_label_03">
              <label>終了時刻</label>
            </div>
          </div>
          <div
            v-for="content in class_info.fixed_term_organize"
            v-bind:key="content.index"
          >
            <div class="date-fiex">
              <h5 class="input-title date-font">{{ content.date }}</h5>
              <div class="input-group input-group-lg">
                <input
                  type="time"
                  class="form-control input-content time-input"
                  aria-label="Sizing example"
                  aria-describedby="inputGroup-sizing-lg"
                  v-model="content.start_time"
                />
                <div class="time_label_02">
                  <label>〜</label>
                </div>
                <input
                  type="time"
                  class="form-control input-content time-input"
                  aria-label="Sizing example"
                  aria-describedby="inputGroup-sizing-lg"
                  v-model="content.end_time"
                />
              </div>
            </div>
          </div>
        </div>
        <div class="input-form">
          <div class="title">
            <h4 class="input-title">場所名</h4>
            <div class="mandatory">
              <label>必須</label>
            </div>
          </div>
          <div class="input-group input-group-lg">
            <textarea
              rows="3"
              class="form-control input-content"
              aria-label="With textarea"
              required
              v-model="class_info.location"
            ></textarea>
          </div>
        </div>

        <div class="input-form">
          <div class="title">
            <h4 class="input-title">場所住所</h4>
            <div class="mandatory">
              <label>必須</label>
            </div>
          </div>
          <div class="date-fiex-02">
            <input
              type="text"
              pattern="\d{3}-?\d{4}"
              maxlength="8"
              class="form-control input-content input-zip"
              aria-label="Sizing example input"
              aria-describedby="inputGroup-sizing-lg"
              required
              v-model="class_info.location_zip"
            />
            <div class="date-button get-zip-button" @click="getAddress()">
              <label>住所取得</label>
            </div>
          </div>
        </div>
        <div class="input-form">
          <div class="input-group input-group-lg">
            <textarea
              rows="3"
              class="form-control input-content"
              aria-label="With textarea"
              required
              v-model="class_info.location_address"
            ></textarea>
          </div>
        </div>
        <div class="input-form">
          <div class="title">
            <h4 class="input-title">URL/PDF資料</h4>
          </div>
          <div class="input-group input-group-lg">
            <textarea
              rows="2"
              class="form-control input-content"
              aria-label="With textarea"
              v-model="class_info.url"
            ></textarea>
          </div>
        </div>
        <div class="input-form">
          <div class="input-group input-group-lg">
            <input
              type="file"
              class="form-control input-content input-file"
              aria-label="Sizing example input"
              @change="uploadPdf"
            />
          </div>
        </div>
        <br />
        <div class="input-form">
          <div class="title">
            <h4 class="input-title">価格</h4>
            <div class="mandatory">
              <label>必須</label>
            </div>
          </div>
          <div class="input-group input-group-lg">
            <input
              type="number"
              class="form-control input-content"
              aria-label="Sizing example input"
              aria-describedby="inputGroup-sizing-lg"
              required
              v-model="class_info.price"
            />
          </div>
        </div>
        <div class="input-form">
          <div class="title">
            <h4 class="input-title">申込方法</h4>
            <div class="mandatory">
              <label>必須</label>
            </div>
          </div>
          <div class="input-group input-group-lg">
            <textarea
              rows="3"
              class="form-control input-content"
              aria-label="With textarea"
              required
              v-model="class_info.application_process"
            ></textarea>
          </div>
        </div>
        <div class="input-form">
          <div class="title">
            <h4 class="input-title">申込URL</h4>
          </div>
          <div class="input-group input-group-lg">
            <textarea
              rows="3"
              class="form-control input-content"
              aria-label="With textarea"
              v-model="class_info.application_url"
            ></textarea>
          </div>
        </div>

        <div class="input-form">
          <div class="title">
            <h4 class="input-title">問い合わせ先</h4>
            <div class="mandatory">
              <label>必須</label>
            </div>
          </div>
          <div class="input-group input-group-lg">
            <textarea
              rows="3"
              class="form-control input-content"
              aria-label="With textarea"
              required
              v-model="class_info.contact"
            ></textarea>
          </div>
        </div>
        <br />
        <div class="input-form">
          <div class="title">
            <h4 class="input-title">クラスプロフィール</h4>
            <div class="mandatory">
              <label>必須</label>
            </div>
          </div>
          <ImageListView :event="'2'" @edit-icon-click="editIconClick" />
          <ImageList />
        </div>

        <!-- <div class="input-form">
          <div class="title">
            <h4 class="input-title">クラスアイコン</h4>
            <div class="mandatory">
              <label>必須</label>
            </div>
          </div>

          <div class="date-button get-zip-button" @click="getIcon()">
            <label>アイコン</label>
          </div>

        </div> -->
        <div class="input-form">
          <div class="title">
            <h4 class="input-title">備考（クラス説明）</h4>
          </div>
          <div class="input-group input-group-lg">
            <textarea
              rows="4"
              class="form-control input-content"
              aria-label="With textarea"
              v-model="class_info.remarks"
            ></textarea>
          </div>
        </div>
        <div class="input-form">
          <div class="title">
            <h4 class="input-title">一般公開</h4>
            <div class="mandatory">
              <label>必須</label>
            </div>
          </div>
          <div class="input-group mb-3">
            <select
              class="custom-select select-form"
              id="inputGroupSelect01"
              v-model="class_info.status"
            >
              <option
                v-for="option in status_select_options"
                v-bind:value="option.value"
                :key="option.value"
                >{{ option.text }}
              </option>
            </select>
          </div>
        </div>
        <div v-if="error_msg !== ''">
          <p class="text-danger error-color">{{ error_msg }}</p>
        </div>
        <div class="register-button">
          <button>申請する</button>
        </div>
        <!-- <label @click="class_register_1()">
          登録処理テスト
        </label> -->
      </form>
    </div>
  </div>
</template>
<script>
import ClassHeader from "../../../components/class/class_header";
import moment from "moment";
import ImageList from "../../../components/tool/ImageList";
import firebase from "firebase/compat";
import ErrrorAlert from "@/components/tool/ErrrorAlert";
import SuccessAlert from "@/components/tool/SuccessAlert";
import Loading from "@/components/Loading";
import ImageListView from "@/components/tool/ImageListView";
import ImageListIcon from "../../../components/tool/ImageListIcon";

export default {
  name: "ClassRegister",
  components: {
    ClassHeader,
    ImageList,
    ErrrorAlert,
    SuccessAlert,
    Loading,
    ImageListView,
    ImageListIcon,
    // Datepicker,
  },
  data() {
    return {
      loading: false,
      date: new Date(),
      add_image_url:
        "https://firebasestorage.googleapis.com/v0/b/fitnesscamp-428f2.appspot.com/o/user_upload%2Fimage-add.svg?alt=media&token=7006d278-166f-4675-8e09-81fdd569ca1d",
      dateSelect: "定期",
      imageList: [],
      deleteImage: false,
      class_id: "",
      profile_image_select: "ベーシック",
      profile_image_option: [
        { text: "ベーシック", value: "ベーシック" },
        { text: "シニア", value: "シニア" },
        { text: "〇〇（自由記述）", value: "〇〇（自由記述）" },
      ],
      class_info: {
        class_name: "",
        class_icon: {},
        lesson_name: "",
        coach_name: "",
        organize_type: "定期",
        fixed_term_organize: [
          // {
          //   date: "",
          //   start_time: "",
          //   end_time: "",
          // },
        ],
        irregular_organize: [
          {
            date: new Date(),
            start_time: "",
            end_time: "",
          },
        ],
        price: "",
        location: "",
        location_zip: "",
        location_address: "",
        lat_and_lng: "",
        url: "",
        pdf: "",
        application_process: "",
        application_url: "",
        contact: "",
        profile_image_list: [],
        remarks: "",
        status: "公開",
        trainer: "",
        class_date_list: [],
      },
      date_select_options: [
        { text: "定期", value: "定期" },
        { text: "不定期", value: "不定期" },
      ],
      status_select_options: [
        { text: "公開", value: "公開" },
        { text: "未公開", value: "未公開" },
      ],
      irregular_organize: [
        {
          date: "",
          start_time: "",
          end_time: "",
        },
      ],
      pdfFileData: "",
      error_msg: "",
      error: false,
      success: false,
      icon: false,
      default_image_list: [],
      icon_image: {},
      default_image: true,
    };
  },
  watch: {
    "class_info.location_zip": async function(next) {
      for (var i = 0; i < next.length; i++) {
        let resData = await this.glb_check_data(
          this.glb_input_data_type.number,
          next[i]
        );
        if (!resData) {
          this.class_info.location_zip = next.slice(0, i) + next.slice(i + 1);
        }
      }
    },
  },
  created: function() {
    this.getDateTime();
    this.getClassInfo();
    this.setFixed_term_organize();
  },
  methods: {
    updateIconClick(value) {
      if (value) {
        this.default_image = false;
      } else {
        this.default_image = true;
      }
    },
    editClick(value) {
      for (var i = 0; i < this.class_info.profile_image_list.length; i++) {
        if (i != value) {
          this.class_info.profile_image_list[i].image_check = false;
        }
      }
      this.class_info.profile_image_list[value].image_check = true;
      this.icon_image = this.class_info.profile_image_list[value];
    },
    editIconClick(value) {
      for (var i = 0; i < value.length; i++) {
        // if (value[i].image_check) {
        //同じ画像があるかどうか確認する
        const result = this.default_image_list.find(
          (v) => v.image_url === value[i].image_url
        );
        if (result == null) {
          this.default_image_list.push({
            image_name: value[i].image_name,
            image_url: value[i].image_url,
            image_check: value[i].image_check,
          });
        } else {
          this.default_image_list[i] = value[i];
        }
      }
    },
    getIcon() {
      let class_image_list = this.glb_get_localStorage("class_image_list");
      if (class_image_list != null && class_image_list != "") {
        this.class_info.profile_image_list = JSON.parse(class_image_list);
      }
      for (var j = 0; j < this.class_info.profile_image_list.length; j++) {
        this.class_info.profile_image_list[j].image_check = false;
      }
      for (var i = 0; i < this.default_image_list.length; i++) {
        if (this.default_image_list[i].image_check) {
          //チェックされた
          const result = this.class_info.profile_image_list.find(
            (v) => v.image_url === this.default_image_list[i].image_url
          );
          if (result == null) {
            this.class_info.profile_image_list.push({
              image_name: this.default_image_list[i].image_name,
              image_url: this.default_image_list[i].image_url,
              image_check: false,
            });
          }
        } else {
          //チェックされていない
          const index = this.class_info.profile_image_list.findIndex(
            (v) => v.image_url === this.default_image_list[i].image_url
          );
          if (
            this.default_image_list[i].image_name == "ベーシック" ||
            this.default_image_list[i].image_name == "シニア" ||
            this.default_image_list[i].image_name == "Logo"
          ) {
            if (index >= 0) {
              this.class_info.profile_image_list.splice(index, 1);
            }
          }
        }
      }
      this.icon = true;
    },
    closeIcon() {
      this.icon = false;
    },
    doAlertCancel() {
      this.error = false;
    },
    doSuccesAlertCancel() {
      this.success = false;
      window.location.reload();
    },
    async class_register_1() {
      if (this.class_info.organize_type == "定期") {
        //不定期の日付を削除
        this.class_info.irregular_organize = [];

        //定期の日付をチェックする
        const checkDate = this.glb_check_class_time(
          this.class_info.fixed_term_organize
        );
        if (!checkDate) {
          this.error_msg = "日付もしくは時間が正しくないです";
          return;
        }
      } else {
        //定期の日付を削除
        this.class_info.fixed_term_organize = [];

        //不定期の日付をチェックする
        const checkDate = this.glb_check_class_date(
          this.class_info.irregular_organize
        );
        if (!checkDate) {
          this.error_msg = "日付もしくは時間が正しくないです";
          return;
        }

        //不定期の日付を加工：日付+時間
        for (let i = 0; i < this.class_info.irregular_organize.length; i++) {
          this.class_info.irregular_organize[
            i
          ].start_time = this.glb_get_date_to_timestamp(
            moment(
              new Date(
                moment(this.class_info.irregular_organize[i].date).format(
                  "YYYY/MM/DD"
                ) +
                  " " +
                  this.class_info.irregular_organize[i].start_time
              )
            )
          );
          this.class_info.irregular_organize[
            i
          ].end_time = this.glb_get_date_to_timestamp(
            moment(
              new Date(
                moment(this.class_info.irregular_organize[i].date).format(
                  "YYYY/MM/DD"
                ) +
                  " " +
                  this.class_info.irregular_organize[i].end_time
              )
            )
          );
        }
      }
    },
    uploadPdf(e) {
      this.pdfFileData = e.target.files[0];
      //ファイルをアップロード処理を実行
    },
    editImage(value) {
      if (value == 1) {
        this.deleteImage = true;
      }
      if (value == 2) {
        this.deleteImage = false;
      }
    },
    setFixed_term_organize() {
      let data = ["月", "火", "水", "木", "金", "土", "日"];
      let self = this;
      data.forEach((value) => {
        self.class_info.fixed_term_organize.push({
          date: value,
          start_time: "",
          end_time: "",
        });
      });
    },
    getClassInfo() {
      //0.念のため、localStorage削除
      this.glb_delete_localStorage("class_image_list");
      //1.userテーブルから作成中のクラス情報取得
      //2.作成中のクラス情報がないの場合
      this.class_id = this.glb_get_UUID();
      this.glb_set_sessionStorage("register_class_id", this.class_id);
    },
    getDate(value, index) {
      this.class_info.irregular_organize[index].date = value;
    },
    getDateTime() {
      this.start_date_h_option = this.glb_get_time_h();
      this.start_date_m_option = this.glb_get_time_m();
    },
    plusDate(index) {
      let datalist = {
        date: moment(new Date()).format("YYYY/MM/DD"),
        start_time: null,
        end_time: null,
      };
      this.class_info.irregular_organize.splice(index + 1, 0, datalist);
    },
    deleteDate(index) {
      if (this.class_info.irregular_organize.length > 1) {
        this.class_info.irregular_organize.splice(index, 1);
      }
    },
    async getAddress() {
      this.class_info.location_address = await this.glb_get_address(
        this.class_info.location_zip
      );
    },
    profile_image_list_up(event) {
      let self = this;
      let selectPic = event.target.files[0];
      var pos = selectPic.name.split(".").pop();
      let imageCheck = this.glb_validete_img(pos);
      if (imageCheck) {
        self.imageList.push(event.target.files[0]);
        var reader = new FileReader();
        reader.onload = function(event) {
          self.class_info.profile_image_list.push({
            image_url: event.target.result,
            image_name: "",
          });
        };
        reader.readAsDataURL(event.target.files[0]);
      }
    },
    async class_register() {
      try {
        this.loading = true;
        if (this.profile_image_select != this.glb_input_class_type.free) {
          this.class_info.class_name = this.profile_image_select;
        }
        //必須項目チェック
        const checkValue = await this.glb_check_class_register(this.class_info);
        //if (!checkValue) {
        if (!checkValue && this.class_info.price < 0) {
          this.error_msg = "未入力項目があります";
          this.error = true;
          this.loading = false;
          return;
        }

        //3,日付加工
        if (this.class_info.organize_type == "定期") {
          //不定期の日付を削除
          this.class_info.irregular_organize = [];
          //定期の日付をチェックする
          const checkDate = this.glb_check_class_time(
            this.class_info.fixed_term_organize
          );
          if (!checkDate) {
            this.error_msg = "日付もしくは時間が正しくないです";
            this.error = true;
            this.loading = false;
            return;
          }
        } else {
          //定期の日付を削除
          this.class_info.fixed_term_organize = [];

          //不定期の日付をチェックする
          const checkDate = this.glb_check_class_date(
            this.class_info.irregular_organize
          );
          if (!checkDate) {
            this.error_msg = "日付もしくは時間が正しくないです";
            this.error = true;
            this.loading = false;
            return;
          }

          //不定期の日付を加工：日付+時間
          for (let i = 0; i < this.class_info.irregular_organize.length; i++) {
            this.class_info.irregular_organize[
              i
            ].start_time = this.glb_get_date_to_timestamp(
              moment(
                new Date(
                  moment(this.class_info.irregular_organize[i].date).format(
                    "YYYY/MM/DD"
                  ) +
                    " " +
                    this.class_info.irregular_organize[i].start_time
                )
              )
            );
            this.class_info.irregular_organize[
              i
            ].end_time = this.glb_get_date_to_timestamp(
              moment(
                new Date(
                  moment(this.class_info.irregular_organize[i].date).format(
                    "YYYY/MM/DD"
                  ) +
                    " " +
                    this.class_info.irregular_organize[i].end_time
                )
              )
            );
          }
        }
        //2,クラスプロフィールリストを取得する
        let default_image_list = [];
        for (var i = 0; i < this.default_image_list.length; i++) {
          if (this.default_image_list[i].image_check) {
            default_image_list.push({
              image_url: this.default_image_list[i].image_url,
              image_name: this.default_image_list[i].image_name,
            });
          }
        }

        let class_icon_list = this.glb_get_localStorage("class_icon_list");
        if (class_icon_list != null && class_icon_list != "") {
          let class_icon_list_json = JSON.parse(class_icon_list);
          this.class_info.class_icon = class_icon_list_json[0];
        } else {
          var iconUrl = "";
          if (this.profile_image_select == this.glb_input_class_type.basic) {
            iconUrl = this.glb_input_class_iconUrl.basic;
          } else if (
            this.profile_image_select == this.glb_input_class_type.senior
          ) {
            iconUrl = this.glb_input_class_iconUrl.senior;
          } else {
            iconUrl = this.glb_input_class_iconUrl.free;
          }
          this.class_info.class_icon = {
            image_name: "icon",
            image_url: iconUrl,
          };
        }

        let class_image_list = this.glb_get_localStorage("class_image_list");
        if (class_image_list != null && class_image_list != "") {
          this.class_info.profile_image_list = JSON.parse(class_image_list);
          if (default_image_list.length > 0) {
            for (var j = 0; j < default_image_list.length; j++) {
              this.class_info.profile_image_list.push({
                image_url: this.default_image_list[j].image_url,
                image_name: this.default_image_list[j].image_name,
              });
            }
          }
          // return;
        } else {
          if (default_image_list.length <= 0) {
            this.error_msg = "未入力項目があります:クラスプロフィール";
            this.error = true;
            this.loading = false;
            return;
          } else {
            for (var k = 0; k < default_image_list.length; k++) {
              this.class_info.profile_image_list.push({
                image_url: this.default_image_list[k].image_url,
                image_name: this.default_image_list[k].image_name,
              });
            }
          }
        }

        if (
          this.icon_image.image_url != "" &&
          this.icon_image.image_url != null
        ) {
          //チェックされていない
          const index = this.class_info.profile_image_list.findIndex(
            (v) => v.image_url === this.icon_image.image_url
          );
          let data = this.class_info.profile_image_list[index];
          this.class_info.profile_image_list.splice(index, 1);
          this.class_info.profile_image_list.unshift(data);
          //let
        }

        //クラステーブルにアップロードする
        //1,資料のファイルをアップロードし、URLを取得する
        var pdfUrl = "";
        if (this.pdfFileData.name != null && this.pdfFileData.name != "") {
          const path =
            "class/" + this.class_id + "/info/" + this.pdfFileData.name;
          pdfUrl = await this.glb_upload_file(path, this.pdfFileData);
          if (pdfUrl == "" || pdfUrl == null) {
            this.error_msg = "PDF資料アップロード失敗";
            this.error = true;
            this.loading = false;
            return;
          } else {
            this.class_info.pdf = pdfUrl;
          }
        }

        this.class_info.created_date = await this.glb_get_timestamp_now();
        this.class_info.trainer = await this.glb_get_user_uid();
        this.class_info.lat_and_lng = await this.glb_get_latandlan(
          this.class_info.location_address
        );
        //審査必要ので、審査のステータスは未審査
        this.class_info.inspection = this.glb_inspection.under_review;

        //経緯度同じ
        let self = this;
        self.class_info.hold_status = "";
        firebase
          .firestore()
          .collection(self.glb_table.class)
          .doc(self.class_id)
          .set(self.class_info)
          .then(() => {
            firebase
              .firestore()
              .collection(self.glb_table.users)
              .doc(self.class_info.trainer)
              .update({
                class_history: firebase.firestore.FieldValue.arrayUnion(
                  self.class_id
                ),
              })
              .then(() => {
                self.success = true;
                self.loading = false;
                firebase
                  .firestore()
                  .collection(self.glb_table.mail)
                  .add({
                    to: self.glb_admin_mail,
                    message: {
                      subject: "クラス申請のお知らせ",
                      text:
                        "管理員さん \n\n" +
                        "クラス申請のお知らせです、ご確認ください。\n\n" +
                        "レッスン：" +
                        self.class_info.lesson_name +
                        "\n\n" +
                        "クラス名：" +
                        self.class_info.class_name +
                        "\n\n" +
                        "コーチ名：" +
                        self.class_info.coach_name +
                        "\n\n" +
                        "場所：" +
                        self.class_info.location_address +
                        "\n\n" +
                        "https://fitnesscamp-cms.web.app/#/class" +
                        "\n\n",
                    },
                  })
                  .then(() => {
                    this.$router.push({
                      name: "ClassHistory",
                      path: "/classHistory",
                      props: true,
                    });
                  });
              })
              .catch(() => {
                self.error_msg = "クラス登録失敗";
                self.error = true;
                self.loading = false;
              });
            //self.success = true;
          })
          .catch(() => {
            self.error_msg = "クラス登録失敗";
            self.error = true;
            self.loading = false;
          });
      } catch (error) {
        this.error_msg = "クラス登録失敗";
        this.error = true;
        this.loading = false;
      }
    },
  },
};
</script>
<style scoped>
.body-page {
  position: absolute;
  width: 100%;
  height: auto;
  background-color: #ffffff;
  margin: 0%;
  margin-top: 57px;
  min-height: 1200px;
}
.register-form {
  margin-top: 20px;
}
.input-form {
  margin-left: auto;
  margin-right: auto;
  text-align: left;
  width: 95%;
  padding: 5px;
}
.title {
  display: flex;
}
.title .mandatory {
  width: 33px;
  height: 18px;
  margin-left: 4px;
  border-radius: 5px;
  background-color: #970000;
}
.title .mandatory label {
  font-family: Work Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 11px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #ffffff;
  padding-left: 5px;
  padding-top: 1px;
}
.input-title {
  font-weight: 600;
}
.input-content {
  background-color: #ededed;
  border: none;
  outline: none;
  font-size: 16px;
  font-size: 1.5rem;
  color: rgb(0, 0, 0);
}
.select-form {
  background-color: #ededed;
  border: none;
  outline: none;
  font-size: 16px;
}
.select-form-1 {
  background-color: #ededed;
  border: none;
  outline: none;
  font-size: 16px;
  width: 100px;
}
.hr-style {
  width: 90%;
  margin: 0% auto 6px auto;
  margin-top: 12px;
  left: 0px;
  right: 0.5px;
  bottom: 1663px;
  border-top: 1px dashed #ccc;
  transform: rotate(0.15deg);
}
.date-fiex {
  display: flex;
  width: 92%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 6px;
}
.date-fiex-02 {
  display: flex;
}
.date-input {
  margin-left: auto;
  margin-right: auto;
  text-align: left;
  padding: 5px;
  background-color: #ededed;
  border: none;
}
.data-title {
  background-color: #ffffff;
}
.input-group-text {
  background-color: #ffffff;
  border: none;
  padding-left: 0%;
  font-weight: 600;
  font-size: 16px;
}
.date-font {
  width: 40px;
  height: auto;
  padding-top: 8px;
  text-align: left;
}
.datepicker-style {
  width: 100%;
}
.time-input {
  background-color: #ededed;
  border-radius: 5px;
  border: none;
  outline: none;
  font-size: 16px;
  font-weight: 600;
}

.time_label_02 {
  width: 20px;
  background-color: #ffffff;
  padding-top: 5px;
}
.time_label_02 label {
  font-size: 14px;
  font-weight: 600;
}
.time_label_03 {
  width: 100%;
  background-color: #ffffff;
  padding-top: 5px;
}
.time_label_03 label {
  font-size: 14px;
  font-weight: 600;
}
.plus-date {
  text-align: left;
  padding: 5px 0 0 3%;
}
.datepicker {
  /* font-size: 16px; */
  background-color: #ededed;
  outline: none;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  height: 35px;
  font-weight: 500;
  padding: 5px;
  width: 100%;
}
.date-style {
  margin-left: auto;
  margin-right: auto;
  width: 90%;
  height: 130px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.25);
}
.date-button {
  width: 60px;
  height: 25px;
  border-radius: 5px;
}
.plus-button {
  padding-top: 4px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
  /* identical to box height */
  letter-spacing: -0.02em;
  background: #ffffff;
  border: 1px solid #c1c1c1;
  box-sizing: border-box;
  border-radius: 5px;
}
.delete-button {
  margin-left: 10px;
  padding-top: 4px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
  /* identical to box height */
  letter-spacing: -0.02em;
  color: #ffffff;
  background: #a0a0a0;
  border-radius: 5px;
}
.get-zip-button {
  margin: 3px 0 0 5px;
  padding: 4px 0 0 4px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
  /* identical to box height */
  letter-spacing: -0.02em;
  background: #ffffff;
  border: 1px solid #c1c1c1;
  box-sizing: border-box;
  border-radius: 5px;
}
.input-zip {
  width: 150px;
}
.input-file {
  background: #ffffff;
}

/* list view(プロフィール画像アップロード周りcss) */
.img-fiex {
  display: flex;
  flex-wrap: wrap;
}
.img-div {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.img-div .image-list {
  width: 120px;
  height: 120px;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.image-list image {
  width: 45%;
}
.selectPicture,
.selectPicture__label {
  text-align: center;
  margin: auto;
}
.selectPicture_label_image {
  background-color: #eeeeee;
  margin-top: 5px;
  margin-left: 5px;
}
.add-image {
  width: 80px;
  height: 80px;
  margin: auto;
}
.add-image-back {
  text-align: center;
  margin: 10px;
  height: 70px;
  width: 70px;
  border-radius: 90%;
  background-position: 54% 36%;
  background-size: cover;
  background-color: #eeeeee;
}
.image_input {
  display: none;
}
.profile-title {
  float: left;
}
.edit-div {
  margin-top: -4px;
  margin-left: 4px;
  float: right;
  text-align: right;
  padding-right: 8%;
}
.edit-div label {
  text-align: center;
  padding-top: 4px;
  width: 60px;
  height: 25px;
  border: none;
  font-size: 12px;
  font-weight: 600;
  border-radius: 10px;
  background-color: rgb(236, 134, 0);
  box-shadow: 0px 3px 6px rgb(214, 214, 214);
  color: #ffffff;
}
.mainasu-icon {
  margin-top: -10px;
  margin-right: 101%;
}
.register-button {
  margin-top: 40px;
  margin-bottom: 60px;
}
.register-button button {
  width: 145px;
  height: 41px;
  background: #00828a;
  border-radius: 6px;
  border: none;
  font-family: Work Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
  align-items: flex-end;
  text-align: center;
  letter-spacing: -0.02em;
  color: #ffffff;
}
.error-color {
  padding-top: 20px;
  font-size: 14px;
  font-weight: 600;
}
.icon-div {
  z-index: 10;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
}
.icon-select-box {
  overflow: auto;
  width: 80%;
  height: 70%;
  margin: auto;
  margin-top: 100px;
  background-color: #ffffff;
  border-radius: 16px;
}
.imageListView {
  width: 100%;
  height: 80%;
  overflow: auto;
}
.closebutton {
  /* margin-top: 70%; */
  width: 117px;
  height: 41px;
  background: #ffffff;
  border: 1px solid #b7b7b7;
  border-radius: 6px;
  font-family: Work Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #717171;
}
.font-size {
  font-size: 16px;
}
input.form-control.input-content {
  font-size: 16px;
}
textarea.form-control.input-content {
  font-size: 16px;
}
</style>
