import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import firebase from "firebase/compat";
import "bootstrap/dist/css/bootstrap.css"; // add
import "bootstrap-vue/dist/bootstrap-vue.css"; // add
import axios from "axios"; //追記
import VueAxios from "vue-axios"; //追記
import VCalendar from "v-calendar";
// Globalパラメータ : src/assets/mixin/utility.js
import mixIn from "@/assets/mixin/utility";
import glb_google from "@/assets/mixin/glb_google";
import glb_firebase from "@/assets/mixin/glb_firebase";
import glb_date from "@/assets/mixin/glb_date"
import glb_variable from "@/assets/mixin/glb_variable"




const firebaseConfig = {
  apiKey: "AIzaSyALseXY7UpS1GvZxi2EH6626Dh3Ui1EAnQ",
  authDomain: "fitnesscamp-428f2.firebaseapp.com",
  projectId: "fitnesscamp-428f2",
  storageBucket: "fitnesscamp-428f2.appspot.com",
  messagingSenderId: "42603251500",
  appId: "1:42603251500:web:0a4dc3421c7f0b9f9fce89",
  measurementId: "G-1LJMYTLDDP",
};

firebase.initializeApp(firebaseConfig);
createApp(App)
  .use(store)
  .use(router)
  .use(VueAxios, axios)
  .use(VCalendar)
  .mixin(mixIn)
  .mixin(glb_google)
  .mixin(glb_firebase)
  .mixin(glb_date)
  .mixin(glb_variable)
  .mount("#app");
