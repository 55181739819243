<template>
  <div>
    <div v-show="libraryEvent">
      <MkGetLibrary
        :program_title="content_type"
        :program_index="index"
        @do-back="doBack"
      />
    </div>
    <div v-show="!libraryEvent">
      <div class="page-content" v-if="program_info !== null">
        <div class="input-form">
          <br />
          <div class="title">
            <div class="d-flex justify-content-start">
              <div class="title-image">
                <img src="@/assets/mk_program/download.png" />
              </div>
              <h5 class="input-title mk-library" @click="doLibrary">
                ライブラリーからインポートする
              </h5>
            </div>
          </div>
          <br />
        </div>
        <div class="input-form">
          <div class="title">
            <h4 class="input-title">プログラム画像</h4>
          </div>
          <div class="d-flex justify-content-start img-fiex">
            <ImageListView :event="'4'" />
            <UploadImage
              :event="'1'"
              :file_func="content_type"
              :file_type="glb_file_type.program_icon"
              :clickIndex="clickIndex"
              :uploadFileImage="uploadFileImage"
              @update-icon-click="updateImageClick"
            />
          </div>
        </div>
        <div class="input-form">
          <div class="title">
            <h4 class="input-title">プログラム動画</h4>
          </div>
          <div class="video-div">
            <UploadVideo
              :event="'1'"
              :file_func="content_type"
              :file_type="glb_file_type.program_video"
              :clickIndex="clickIndex"
              :uploadFileVideo="uploadFileVideo"
              @update-video-click="updateVideoClick"
            />
          </div>
        </div>
        <div
          v-for="(content, index) in program_content.detail_list"
          v-bind:key="index"
        >
          <div class="input-form">
            <div class="title ">
              <h4 class="input-title">タイトル</h4>
            </div>
            <div class="input-group input-group-lg">
              <textarea
                rows="3"
                class="form-control input-content"
                aria-label="With textarea"
                v-model="content.title"
              ></textarea>
            </div>
            <br />
            <div class="title ">
              <h4 class="input-title">説明</h4>
            </div>
            <div class="input-group input-group-lg">
              <textarea
                rows="8"
                class="form-control input-content"
                aria-label="With textarea"
                v-model="content.explanation"
              ></textarea>
            </div>
            <br />
            <div class="title ">
              <h4 class="input-title">目的</h4>
            </div>
            <div class="input-group input-group-lg">
              <textarea
                rows="4"
                class="form-control input-content"
                aria-label="With textarea"
                v-model="content.purpose"
              ></textarea>
            </div>
            <br />
            <div class="title ">
              <h4 class="input-title">動作</h4>
            </div>
            <div class="input-group input-group-lg">
              <textarea
                rows="10"
                class="form-control input-content"
                aria-label="With textarea"
                v-model="content.movement"
              ></textarea>
            </div>
            <br />
            <div class="title ">
              <h4 class="input-title">ポイント</h4>
            </div>
            <div class="input-group input-group-lg">
              <textarea
                rows="8"
                class="form-control input-content"
                aria-label="With textarea"
                v-model="content.point"
              ></textarea>
            </div>
          </div>
        </div>
        {{ test }}
      </div>
      <div class="button-class">
        <div class="d-flex justify-content-around">
          <button class="button cancel" @click="doCancel()">キャンセル</button>
          <button class="button confirmation" @click="doConfirmation()">
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import firebase from "firebase/compat";
import ImageListView from "@/components/tool/ImageListView";
import UploadImage from "@/components/file/UploadImage";
import UploadVideo from "@/components/file/UploadVideo";
import MkGetLibrary from "@/components/mk_program/mk_library/mk_get_library";
export default {
  name: "Modal",
  components: {
    ImageListView,
    UploadImage,
    UploadVideo,
    MkGetLibrary,
  },
  props: {
    index: null,
    content_type: String,
  },
  data() {
    return {
      programList: [],
      title: "",
      program_info: {
        name: "",
        detail_image: "",
        detail_content: {
          detail_list: [],
        },
      },
      program_content: {
        detail_list: [],
        explanation_list: [],
      },
      program_content_back: {
        detail_list: [],
        explanation_list: [],
      },
      program_content_type: null,
      clickIndex: null,
      libraryEvent: false,
      uploadFileImage: {
        image_name: "",
        image_url: "",
      },
      uploadFileVideo: {
        video_name: "",
        video_url: "",
      },
    };
  },
  async created() {
    await this.getProgramInfo();
  },
  methods: {
    async doBack(value) {
      this.libraryEvent = false;
      if (value) {
        this.test = 2;
        let data = this.glb_get_localStorage_for_program(
          this.mk_program_storage_name
        );
        this.program_content.detail_list[0] = data.mk_new_import_program_info;
      }
      this.setFile();
    },
    setFile() {
      this.uploadFileImage = this.program_content.detail_list[0].image;
      this.uploadFileVideo = this.program_content.detail_list[0].video;
    },
    doLibrary() {
      this.libraryEvent = true;
    },
    doCancel() {
      this.$emit("do-back");
    },
    async doConfirmation() {
      await this.checkContent_type(1);
      this.glb_set_localStorage_new_program_info(this.program_info);
      this.$emit("do-back");
    },
    plusData(index) {
      this.program_info.detail_content.detail_list.splice(index + 1, 0, {});
    },
    deleteData(index) {
      if (this.program_info.detail_content.detail_list.length > 1) {
        this.program_info.detail_content.detail_list.splice(index, 1);
      }
    },
    async getProgramInfo(event) {
      this.clickIndex = this.index;
      await this.getProgramInfoForStorage();
      await this.checkContent_type(0, event);
      await this.setFile();
    },
    // async setProgram_info(value, event) {},
    async checkContent_type(value, event) {
      if (value == 0) {
        this.program_content.detail_list.push(
          this.program_info[this.content_type].detail_list[this.index]
        );
        if (event == 1) {
          this.program_content_back.detail_list.push(
            this.program_info[this.content_type].detail_list[this.index]
          );
        }
      } else {
        this.program_info[this.content_type].detail_list[
          this.index
        ] = this.program_content.detail_list[0];
      }
    },

    async getProgramInfoForStorage() {
      this.program_info = await this.glb_get_mk_program_info();
    },
    async updateImageClick(value) {
      await this.setProgram_info(value, "image");
    },
    async updateVideoClick(value) {
      await this.setProgram_info(value, "video");
    },
    async setProgram_info(value, type) {
      let mk_program_storage_data = await this.glb_get_localStorage_for_program(
        this.mk_program_storage_name
      );
      if (value == this.glb_upload_file_func.program_icon) {
        this.program_info.home_image =
          mk_program_storage_data.mk_program_icon_image[0];
      } else if (value == this.glb_upload_file_func.program_image) {
        this.program_info.detail_image = this.program_info.home_image =
          mk_program_storage_data.mk_program_image_image[0];
      } else if (value == this.glb_upload_file_func.improvement) {
        if (type == "image") {
          let detail_image = await this.setImageUrlToProgramInfo(
            value + "_image"
          );
          this.program_info[value].detail_list[this.index].image =
            detail_image[0];
        } else {
          let detail_video = await this.setVideoUrlToProgramInfo(
            value + "_video"
          );
          this.program_info[value].detail_list[this.index].video =
            detail_video[0];
        }
      }
    },
    async setImageUrlToProgramInfo(value) {
      let mk_program_storage_data = await this.glb_get_localStorage_for_program(
        this.mk_program_storage_name
      );

      let detail_image = mk_program_storage_data[value];
      return detail_image;
    },
    async setVideoUrlToProgramInfo(value) {
      let mk_program_storage_data = await this.glb_get_localStorage_for_program(
        this.mk_program_storage_name
      );
      let detail_video = mk_program_storage_data[value];
      return detail_video;
    },
  },
};
</script>

<style scoped>
.page-content {
  width: 98%;
  height: auto;
  margin: auto;
  overflow: auto;
  padding-bottom: 200px;
  background-color: #fafafa;
}
.mk-library {
  font-family: Work Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  align-items: center;
  letter-spacing: -0.02em;
  text-decoration-line: underline;
  color: #6b6b6b;
}
.title-image {
  text-align: center;
  margin-right: 8px;
  margin-top: 2px;
}
.title-image img {
  width: 15px;
  height: 15px;
}
.input-form {
  margin-left: auto;
  margin-right: auto;
  text-align: left;
  width: 95%;
  padding: 5px;
  margin-top: 10px;
  margin-bottom: 24px;
}
.input-group-text {
  background-color: #ffffff;
  border: none;
  padding-left: 0%;
  font-weight: 600;
  font-size: 16px;
}
.title {
  display: flex;
}
.title .mandatory {
  width: 33px;
  height: 18px;
  margin-left: 4px;
  border-radius: 5px;
  background-color: #970000;
}
.title .mandatory label {
  font-family: Work Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 11px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #ffffff;
  padding-left: 5px;
  padding-top: 1px;
}
.input-title {
  font-weight: 600;
}
.input-content {
  background-color: #ededed;
  border: none;
  outline: none;
  font-size: 16px;
  font-size: 1.5rem;
  color: rgb(0, 0, 0);
  outline: none;
}
input.form-control.input-content {
  font-size: 16px;
}
.img-fiex {
  display: flex;
  flex-wrap: wrap;
}
.title-card {
  width: 95%;
  height: 350px;
  margin: auto;
  margin-top: 24px;
  padding: 10px;
  overflow: auto;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
}
.date-fiex {
  display: flex;
  width: 92%;
  margin-top: 12px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 6px;
}
.date-button {
  width: 60px;
  height: 25px;
  border-radius: 5px;
}
.plus-button {
  padding-top: 4px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
  /* identical to box height */
  letter-spacing: -0.02em;
  background: #ffffff;
  border: 1px solid #c1c1c1;
  box-sizing: border-box;
  border-radius: 5px;
}
.delete-button {
  margin-left: 10px;
  padding-top: 4px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
  /* identical to box height */
  letter-spacing: -0.02em;
  color: #ffffff;
  background: #a0a0a0;
  border-radius: 5px;
}
.button-class {
  width: 100%;
  height: 70px;
  background: #fafafa;
  margin-top: 200px;
  margin-right: auto;
  margin-left: auto;
  position: fixed;
  justify-content: space-between;
  bottom: 70px;
}
.button-class .button {
  margin-top: 16px;
  width: 140px;
  height: 38px;
  border-radius: 6px;
}
.button-class .confirmation {
  background: #1ea6ae;
  font-family: Work Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  letter-spacing: -0.02em;
  border: none;
  color: #ffffff;
}
.button-class .cancel {
  background: #ffffff;
  border: 1px solid #b7b7b7;
  font-family: Work Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #717171;
}
.video-div {
  margin-top: -12px;
}
</style>
