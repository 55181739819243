<template>
  <div class="home">
    <div class="title">
      <h2 class="display-6 program-font-style">失われた動きを取り戻す</h2>
    </div>
    <img src="@/assets/commentary/sea_woman.jpeg" height="230"/>
    <div class="text-style">
      <p class="fw-normal font-style">
        <span>
          定期的にジムに通う人が増えてきている一方で、人々が健康になってきたかと言ったら必ずしもそうではありません。
          生活習慣病や肥満は中年だけでなく、子供たちに広がってきています。
          単にジムに行って錘を持ち上げたり、走ったりするだけではなく、
        </span>
        <span class="span-style span-end">もともと人間が持っているべき機能を改めて再獲得する必要があります。</span>
      </p>
    </div>
    <div class="text-style">
      <p class="fw-normal font-style">
        <span>
          それは単なる筋力だけでなく、柔軟性、可動性、操作性、パワー、スピード、アジリティ、
          反応能力などであり、さまざまな動きを必要としていた原始
        </span>
        <span class="span-style span-end">時代などでは、危険を察知して、生き残るために、無意識のうちのこれら全ての体力要素を身につけていたのです。</span>
      </p>
    </div>
    <img src="@/assets/commentary/jungle.jpg" height="230"/>
    <div class="text-style">
      <p class="fw-normal font-style">
        <span>
          しかし現代社会では、ありとあらゆる危険とされているものや行為がとり除かれ
          必要とされない機能は失われています。人間は不安定な環境の中で、
          その環境に適応する動物です。実は、この「不安定な環境」
          こそが人間の機能を呼び起こすものです。それとは逆に、
        </span>
        <span class="span-style span-end">現代社会では生活から不確定要素が排除されています。</span>
        <span>
          そして、それはトレーニングにおいても同様です。
          例えば繰り返し同じ動作を行い、人間に合わせて調整してくれるマシンなどです。
        </span>
      </p>
    </div>

    <img src="@/assets/commentary/training_man.jpg" height="230"/>
    <div class="text-style">
      <p class="fw-normal font-style">
        <span>
          そうした背景から、様々な行為を実行するのに必要な体力要素が失われてきています。
          例えば、野原を好きなだけ駆け回ったり、思いっきり海を泳いだり、ひたする山を登ったり、
          自由自在に体を動かせるようになるためには、
          同じ動作を繰り返して筋肉を鍛えるのではなく、さまざまな動作に富んだトレーニングをする必要があります。
        </span>
      </p>
    </div>
    <img src="@/assets/commentary/lake.jpg" height="100"/>

    <div class="sapace-style"></div>
    <div class="title">
      <h2 class="display-6 program-font-style">心も身体も健康に</h2>
    </div>
    <img src="@/assets/commentary/sunset_woman.jpeg" height="180"/>
    <div class="text-style">
      <p class="fw-normal font-style">
        <span class="span-style span-start">現代生活では人との関わりが希薄になり、一日中自宅やオフィスの中で過ごす人たちが増えてきています。</span>
        <span>
          また、運動をするにしても、室内の気温が調整されている中で、個人の空間で他人と話し合うことなく、
          自分と向き合って重りを持ち上げるという体力要素のほんの１つだけを中心に行っている場合がよくあります。
        </span>
      </p>
    </div>

    <div class="text-style">
      <p class="fw-normal font-style">
        <span>外に出て、みんなで、跳んだり、駆け回ったり自由に自然の中で体を動かす機会が少なくなってきています。</span>
        <span class="span-style span-start">
          FITNESS CAMP では、運動を手段として、たくさんの人と繋がり、お互いが
          お互いに影響し合うエネルギーあふれるコミュニティづくりを目指します。
        </span>
        <span>それにより、単に身体の健康だけでなく、精神的な健康を促すことができます。</span>
      </p>
    </div>
    <img src="@/assets/commentary/stretch_men.jpg" height="230"/>

    <div class="sapace-style"></div>
    <div class="title">
      <h2 class="display-6 program-font-style">スポーツ科学とFITNESS CAMP</h2>
    </div>
    <img src="@/assets/commentary/about_fitnesscamp.png" height="380"/>

    <div>
      <table>
        <tr>
          <th class="table-td-th-color_1">安定性:</th>
          <td class="table-td-th-color_1">安定性関節の安定力／静的、動的な姿勢・体制の安定性を含む</td>
        </tr>
        <tr>
          <th class="table-td-th-color_1">可動性:</th>
          <td class="table-td-th-color_1">可動性関節の可動力／筋自体の静的・動的な柔軟性だけでなく可動域を生む因子全体を含む</td>
        </tr>
        <tr>
          <th class="table-td-th-color_1">協調性:</th>
          <td class="table-td-th-color_1">
            目や耳などの感覚器で得た情報を処理し、的確な指令を運動器に送り、自分の身体を環境に応じてしなやかに、
            そして思い通りに動かす能力
          </td>
        </tr>
        <tr>
          <th class="table-td-th-color_2">筋力:</th>
          <td class="table-td-th-color_2">時間(s/t)に関係ない力／大きな重さを持ち上げたときに持ち上げる距離とスピードを抜いた力／運動を行うすべての原動力</td>
        </tr>
        <tr>
          <th class="table-td-th-color_2">パワー:</th>
          <td class="table-td-th-color_2">仕事率／筋力×スピード／筋力に時間を加えることにより瞬発的に発揮できる最大の力</td>
        </tr>
        <tr>
          <th class="table-td-th-color_2">持久力:</th>
          <td class="table-td-th-color_2">持続する力/全身持久力（心肺機能）と筋持久力</td>
        </tr>
        <tr>
          <th class="table-td-th-color_3">スピード:</th>
          <td class="table-td-th-color_3">重心移動の速さ／単純な速さ</td>
        </tr>
        <tr>
          <th class="table-td-th-color_3">アジリティ:</th>
          <td class="table-td-th-color_3">アジリティ／素早い方向転換／正しい加速・減速（ローディング・アンローディング）</td>
        </tr>
        <tr>
          <th class="table-td-th-color_3">反応力:</th>
          <td class="table-td-th-color_3">リアクション／刺激に反応して動く力</td>
        </tr>
        <tr>
          <th class="table-td-th-color_4">適応力:</th>
          <td class="table-td-th-color_4">自然の環境に合わせて適応する能力</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: "Fitnesscampis",
  props: {}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.title {
  padding-top: 4%;
  text-align: left;
}
.home > img {
  padding-top: 1%;
  width: 96%;
  height: auto;
  min-height: 100px;
}
.home > img::before {
  content: "";
  display: block;
  padding-top: calc(((720 / 1280) * 100%));
}
.program-font-style {
  font-weight: 600;
  color: #000;
}
.text-style {
  padding-top: 2%;
  text-align: left;
  font-size: 14px;
  font-weight: 500;
}
.font-style {
  letter-spacing: 1px;
  line-height: 25px;
  padding-top: 8px;
}
.span-style {
}
.span-start {
  background: linear-gradient(transparent 50%, #fcf49c 0%);
}
.span-end {
  margin-left: -4px;
  margin-right: -4px;
  background: linear-gradient(transparent 50%, #fcf49c 0%);
}
.sapace-style {
  margin-top: 10%;
}
/* table */
table {
  margin-top: 8%;
  margin-left: 2%;
  margin-right: 2%;
  width: 96%;
  border-collapse: collapse;
}

table tr {
  width: 20px;
  border-bottom: solid 0 white;
}

table tr:last-child {
  border-bottom: none;
}

table th {
  position: relative;
  text-align: left;
  width: 25%;
  height: auto;
  background-color: rgb(247, 247, 247);
  padding: 10px 0;
  font-size: 14px;
  font-weight: 500;
}
table td {
  position: relative;
  text-align: left;
  height: auto;
  background-color: rgb(247, 247, 247);
  text-align: left;
  padding: 10px 10px;
  font-size: 14px;
  font-weight: 500;
}

.table-td-th-color_1 {
  color: #004e44ff;
}
.table-td-th-color_2 {
  color: #279a00ff;
}
.table-td-th-color_3 {
  color: #5c5252ff;
}
.table-td-th-color_4 {
  color: #208fedff;
}

</style>
