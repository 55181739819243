<template>
  <div>
    <div class="heard-style">
      <a href="#">
        <img src="@/assets/home/yajirusi.svg" width="20" height="20" />
      </a>
      <h2>モニタリング</h2>
    </div>
    <div class="home space-style-1"></div>
    <!-- 説明部分 -->
    <div class="card border-light mb-3 card-style">
      <div class="card-body">
        <h3 class="card-title title-font">モニタリングの目的</h3>
        <p class="card-text title-conten-font">
          運動の前後に実施をすることで参加者は運動効果を主観的に実感し、そして指導者側は客観的に成果を評価することができる。
          <br />参加者は、効果を実感することで運動継続へのモチベーションアップに繋がるだけなく、
          体の変化に敏感になることでより主体的に運動に取り組むことができるようになる。
          <br />指導者側は専門性を持って客観的に評価しフィードバックをすることで、参加者との信頼関係の構築にも繋がる。
        </p>
      </div>
    </div>
    <div class="all-open">
      <div v-if="allEventValue">
        <button type="button" class="button-style" @click="allEvent(false)">All Close</button>
      </div>
      <div v-else-if="checkEventValue">
        <button type="button" class="button-style" @click="allEvent(false)">All Close</button>
      </div>
      <div v-else>
        <button type="button" class="button-style" @click="allEvent(true)">All Open</button>
      </div>
    </div>

    <!-- 詳細部分折り畳み -->
    <div class="check-div">
      <input id="acd-check1" class="acd-check" type="checkbox" v-model="check[0]" />
      <label class="acd-label" @click="checkEvent(0)">
        <h2 class="display-5 program-font-style">ニーリフト</h2>
      </label>
      <div class="acd-content" v-if="check[0]">
        <div class="home">
          <div>
            <!-- 説明部分 -->
            <div class="card border-light mb-3 card-style">
              <div class="card-body">
                <p class="card-text title-conten-font">
                  足を揃えて立ち正面を向きましょう
                  <br />足踏みをするように膝を交互にあげましょう
                  <br />上げやすさや、上げたときのつまり感を確認しましょう
                </p>
              </div>
            </div>
          </div>
          <div class="card video-div-style">
            <video :src="videoUrl.url_1" controls preload="metadata" class="video-style" />
          </div>
          <!-- 目的、動作、ポイントtable -->
          <div>
            <table>
              <tr>
                <th>目的</th>
                <td>
                  股関節屈曲動作の確認
                  <br />バランス機能の確認
                </td>
              </tr>
              <tr>
                <th>動作</th>
                <td>
                  １．両足で立ち目線は正面を向ける
                  <br />２．片足を腰の高さまであげる
                  <br />３．その時の上げやすさ、バランスなどの感覚を確認する
                  <br />４．同時に客観的に動作を評価する
                </td>
              </tr>
              <tr>
                <th>ポイント</th>
                <td>動作指導はできるだけ少なく自然な動作を確認する</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <input id="acd-check2" class="acd-check" type="checkbox" v-model="check[1]" />
      <label class="acd-label" @click="checkEvent(1)">
        <h2 class="display-5 program-font-style">トランクローテーション</h2>
      </label>
      <div class="acd-content" v-if="check[1]">
        <div class="home">
          <div>
            <!-- 説明部分 -->
            <div class="card border-light mb-3 card-style">
              <div class="card-body">
                <p class="card-text title-conten-font">
                  両手を肩にして足は腰幅にする
                  <br />そして、片側ずつ後ろを振り向いてみましょう
                  <br />できるだけ後ろを向いてみて張りやつまり感など左右の違いを感じましょう
                </p>
              </div>
            </div>
          </div>
          <div class="card video-div-style">
            <video :src="videoUrl.url_2"  preload="metadata" controls class="video-style" />
          </div>
          <!-- 目的、動作、ポイントtable -->
          <div>
            <table>
              <tr>
                <th>目的</th>
                <td>回旋動作の確認</td>
              </tr>
              <tr>
                <th>動作</th>
                <td>
                  １．手を胸の前で組み足部を正面に向けて腰幅くらいで立つ
                  <br />２．頭頸部も一緒に左右に回旋する
                  <br />３．その時に主観的な左右差やつまり感などを確認する
                  <br />４．客観的には回旋に関わる関節の動きやスムーズさを確認する
                </td>
              </tr>
              <tr>
                <th>ポイント</th>
                <td>客観的に動作を確認する時に、回旋に伴う伸展や側屈、重心変化なども確認する</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <input id="acd-check3" class="acd-check" type="checkbox" v-model="check[2]" />
      <label class="acd-label" @click="checkEvent(2)">
        <h2 class="display-5 program-font-style">バックエクステンション</h2>
      </label>
      <div class="acd-content" v-if="check[2]">
        <div class="home">
          <div>
            <!-- 説明部分 -->
            <div class="card border-light mb-3 card-style">
              <div class="card-body">
                <p class="card-text title-conten-font">
                  足を腰幅にして立ちましょう
                  <br />両手を腰に置いて後屈してみましょう
                  <br />そして後ろの景色がどこまで見えるか確認しましょう
                  <br />その時のつまりや張りのある部分も感じてみましょう
                </p>
              </div>
            </div>
          </div>
          <div class="card video-div-style">
            <video :src="videoUrl.url_3" controls preload="metadata" class="video-style" />
          </div>
          <!-- 目的、動作、ポイントtable -->
          <div>
            <table>
              <tr>
                <th>目的</th>
                <td>伸展動作の確認</td>
              </tr>
              <tr>
                <th>動作</th>
                <td>
                  １．足の幅は腰幅くらいで立ち手を腰に当てる
                  <br />２．後ろを見るようにできるだけ後屈をする
                  <br />３．その時の主観的な動きにくい部分やつまりを感じる部分を確認する
                  <br />４．客観的には伸展に関わる関節の動きやスムーズさなどを確認する
                </td>
              </tr>
              <tr>
                <th>ポイント</th>
                <td>客観的に動作を確認する時に、伸展に伴う回旋や側屈などの不良動作も確認する</td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="sapace-style"></div>
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: "Monitoring",
  components: {
  },
  data() {
    return {
      check: [false, false, false],
      allEventValue: false,
      checkEventValue: false,
      videoUrl: {
        url_1:
          "https://firebasestorage.googleapis.com/v0/b/fitnesscamp-428f2.appspot.com/o/monitoring_program%2Fcontent%2Fmovie%2FkneeLift.mov?alt=media&token=b577c8ba-26e3-4bc7-a6c0-7ae3966391e3",
        url_2:
          "https://firebasestorage.googleapis.com/v0/b/fitnesscamp-428f2.appspot.com/o/monitoring_program%2Fcontent%2Fmovie%2FtrunkRotation.mov?alt=media&token=54764c98-d1d1-4b79-b1a8-ccf9ebc5f876",
        url_3:
          "https://firebasestorage.googleapis.com/v0/b/fitnesscamp-428f2.appspot.com/o/monitoring_program%2Fcontent%2Fmovie%2FbackExtension.mov?alt=media&token=34c5333d-f0ae-4c4d-9a63-322fe08a064b"
      }
    };
  },
  methods: {
    checkEvent(index) {
      if (!this.check[index]) {
        this.checkEventValue = true;
      } else {
        this.checkEventValue = false;
      }
      this.check[index] = !this.check[index];
    },
    allEvent(isValue) {
      if (!isValue) {
        this.checkEventValue = false
      }
      let self = this;
      self.check.forEach(function(value, index) {
        self.check[index] = isValue;
      });
      self.allEventValue = isValue;
    }
  }
};
</script>
<style scoped>
.heard-style {
  position: fixed;
  z-index: 2;
  margin-top: 0%;
  padding-top: 18px;
  width: 100%;
  height: 58px;
  background-color: #04545c;
}
.heard-style img {
  float: left;
  margin-left: 2%;
}
.heard-style > h2 {
  color: rgb(255, 255, 255);
  margin-right: 4%;
  font-weight: 900;
}
.home-img img {
  margin-top: 58px;
  width: 100%;
  padding: 0;
}
.space-style-1 {
  height: 58px;
}

.card-style {
  max-width: 100rem;
  margin: 2%;
  background-color: rgb(248, 248, 248);
}
.title-font {
  color: #000;
  font-weight: 600;
  text-align: left;
}
.title-conten-font {
  color: #000;
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  letter-spacing: 1px;
  line-height: 20px;
}
.all-open {
  text-align: right;
  margin-right: 2%;
  margin-top: 5%;
  margin-bottom: 5%;
}
.button-style {
  width: 60px;
  height: 30px;
  font-size: 12px;
  text-align: center;
  padding: 0%;
  background: #ffffff;
  border-radius: 5px;
  border: 1px solid #015a53;
}

/* 折りたたみ */
.check-div {
}
.acd-check {
  display: none;
  border-bottom: 1px solid rgb(177, 177, 177);
}
.acd-label {
  background: #04545c;
  color: rgb(255, 255, 255);
  display: block;
  margin-bottom: 1px;
  padding: 16px;
  position: relative;
  font-size: 2em;
  height: 55px;
  border-bottom: 0.5px solid rgb(255, 254, 240);
  text-align: left;
  margin: 0%;
}
.acd-label:after {
  background: #04545c;
  box-sizing: border-box;
  content: url(../../assets/home/downY.svg);
  display: block;
  font-family: "Font Awesome 5 Free";
  width: 50px;
  height: 50px;
  padding: 10px 20px;
  position: absolute;
  right: 0;
  top: 0px;
  height: 55px;
  border-bottom: 0.5px solid rgb(255, 254, 240);
}
.acd-content {
  border: 1px solid #333;
  display: block;
  height: 0;
  opacity: 0;
  padding: 0 10px;
  transition: 0.5s;
  visibility: hidden;
  border: 0;
}
.acd-check:checked + .acd-label:after {
  content: url(../../assets/home/upY.svg);
}
.acd-check:checked + .acd-label + .acd-content {
  height: auto;
  opacity: 1;
  padding: 10px;
  visibility: visible;
  border: 0;
  background-color: #ffffff;
  border-bottom: 0.5px solid rgb(255, 254, 240);
}
.program-font-style {
  font-weight: 600;
}
.sapace-style {
  height: 200px;
  background: #04545c;
  margin-bottom: 0%;
}
.about {
  text-align: left;
  margin-top: 2%;
  margin-left: 2%;
  margin-bottom: 3%;
}
.about > a:link,
a:visited,
a:hover,
a:active {
  text-decoration: none;
  font-size: 14px;
  font-weight: 400;
  color: rgb(163, 163, 163);
}

.home > img {
  padding-top: 1%;
  width: 96%;
}
/* table */
table {
  margin-top: 8%;
  margin-left: 2%;
  margin-right: 2%;
  width: 96%;
  border-collapse: collapse;
}

table tr {
  width: 30px;
  border-bottom: solid 1px white;
}

table tr:last-child {
  border-bottom: none;
}

table th {
  position: relative;
  text-align: left;
  width: 25%;
  height: auto;
  background-color: #e4ecec;
  color: rgb(0, 0, 0);
  text-align: center;
  padding: 10px 0;
  font-size: 14px;
  font-weight: 400;
}
table td {
  position: relative;
  text-align: left;
  height: auto;
  background-color: rgb(247, 247, 247);
  color: rgb(0, 0, 0);
  text-align: left;
  padding: 10px 10px;
  font-size: 14px;
  font-weight: 400;
}

.video-div-style {
  height: auto;
  width: auto;
  margin-top: 5%;
  margin-left: 2%;
  margin-right: 2%;
  border: 0;
  background-color: rgb(233, 233, 233);
}
.video-style {
  height: 250px;
}
</style>
