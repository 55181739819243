<template>
  <div>
    <div>
      <div class="loding-div">
        <Loading v-show="loading" />
      </div>
      <div v-show="delete_alert">
        <!-- 画像削除 モーダル表示内容 -->
        <div class="popContainer">
          <div class="alert-div">
            <h4 class="display-6 alert-msg">{{ alert_msg }}</h4>
            <h4 class="display-6 msg-color">{{ error_msg }}</h4>
            <!-- 確認、CXLボタン -->
            <div class="button-div">
              <table>
                <tr>
                  <td>
                    <label
                      class="button-style cancel-button"
                      @click="closeModal()"
                    >
                      キャンセル
                    </label>
                  </td>
                  <td>
                    <label
                      class="button-style send-button"
                      @click="deleteUpdate()"
                    >
                      確認
                    </label>
                  </td>
                </tr>
              </table>
            </div>
          </div>
        </div>
      </div>
      <div v-show="modal">
        <div class="open-modal">
          <div class="open-image-div">
            <img class="open-image" :src="openVideoUrl" />
          </div>
          <div class="d-flex justify-content-around">
            <img
              class="close-image"
              src="@/assets/profile/back.svg"
              @click="imageEvent(0)"
            />
            <img
              class="close-image"
              src="@/assets/profile/close.svg"
              @click="imageEvent(1)"
            />
            <img
              class="close-image"
              src="@/assets/profile/next.svg"
              @click="imageEvent(2)"
            />
          </div>
        </div>
      </div>
      <br />
      <div class="text-center">
        <div class="img-div">
          <div class="video-div">
            <div
              v-for="(content, index) in upload_video_list"
              v-bind:key="content.id"
            >
              <div class="image-list">
                <div v-if="content.video_url">
                  <div class="introduce-div edit-div">
                    <img
                      class="mainasu-icon"
                      src="@/assets/profile/close.svg"
                      height="30"
                      width="30"
                      @click="deleteImageEvent(index)"
                    />
                  </div>
                  <div class="video-div-style">
                    <video
                      :src="content.video_url"
                      controls
                      preload="metadata"
                      class="video-style"
                    />
                  </div>
                </div>

                <div class="image-open" @click="openVideo(index)"></div>
              </div>
            </div>
            <!-- {{ res_upload_image_list.length }} -->
            <div v-if="res_upload_video_list.length === 0">
              <div class="selectPicture">
                <label class="selectPicture_label_image">
                  <div
                    class="add-image-back"
                    :style="{
                      backgroundImage: 'url(' + add_video_url + ')',
                    }"
                  >
                    <input
                      class="image_input"
                      type="file"
                      ref="preview"
                      id="selectingItem"
                      accept="video/*"
                      @change="profile_video_list_up($event)"
                    />
                  </div>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import firebase from "firebase/compat";
import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";
import Loading from "@/components/Loading";
export default {
  name: "VideoListIcon",
  components: {
    Loading,
    //Datepicker,
  },
  props: {
    event: null,
    clickIndex: null,
    icon_view: null,
    file_func: String,
    file_type: String,
    uploadFileVideo: null,
  },
  watch: {
    uploadFileVideo: {
      async handler(val) {
        this.upload_video_list[0] = val;
      },
      deep: true,
    },
  },
  async created() {
    this.setUpload_video_list();
    this.setScroll();
  },
  data() {
    return {
      uid: "",
      upload_video_list: [],
      res_upload_video_list: [],
      userInfoEdit: {},
      loading: false,
      delete_alert: false,
      modal: false,
      modal2: false,
      deleteVideo: false,
      alert_msg: "",
      openVideoUrl: "",
      delete_index: "",
      video_index: "",
      userInfo: {},
      qualification_list: [],
      other_qualification: "",
      userInfoBack: {},
      videoList: [],
      url: "",
      imagUrl: "",
      iconPreviewSrc: "",
      editEvent: false,
      editIconUrl: "",
      editIcon: false,
      addVideoEvent: false,
      video_list: [],
      add_video_url:
        "https://firebasestorage.googleapis.com/v0/b/fitnesscamp-428f2.appspot.com/o/user_upload%2Fimage-add.svg?alt=media&token=7006d278-166f-4675-8e09-81fdd569ca1d",
      urlParm1: "",
      urlParm2: "",
    };
  },
  methods: {
    async setUpload_video_list() {
      if (this.file_func == this.glb_upload_file_func.program_icon) {
        let program_info = await this.glb_set_mk_program();
        this.upload_video_list.push({
          video_name: program_info.home_video.video_name,
          video_url: program_info.home_video.video_url,
        });
      } else if (this.file_func == this.glb_upload_file_func.program_video) {
        let program_info = await this.glb_set_mk_program();
        this.upload_video_list.push({
          video_name: program_info.detail_video.video_name,
          video_url: program_info.detail_video.video_url,
        });
      } else if (this.file_func == this.glb_upload_file_func.improvement) {
        let program_info = await this.glb_set_mk_program();
        this.upload_video_list.push({
          video_name:
            program_info.improvement.detail_list[this.clickIndex].video
              .video_name,
          video_url:
            program_info.improvement.detail_list[this.clickIndex].video
              .video_url,
        });
      } else if (this.file_func == this.glb_upload_file_func.staticStretch) {
        let program_info = await this.glb_set_mk_program();
        this.upload_video_list.push({
          video_name:
            program_info.staticStretch.detail_list[this.clickIndex].video
              .video_name,
          video_url:
            program_info.staticStretch.detail_list[this.clickIndex].video
              .video_url,
        });
      } else if (this.file_func == this.glb_upload_file_func.movementTraining) {
        let program_info = await this.glb_set_mk_program();
        this.upload_video_list.push({
          video_name:
            program_info.movementTraining.detail_list[this.clickIndex].video
              .video_name,
          video_url:
            program_info.movementTraining.detail_list[this.clickIndex].video
              .video_url,
        });
      } else if (this.file_func == this.glb_upload_file_func.dynamicStretch) {
        let program_info = await this.glb_set_mk_program();
        this.upload_video_list.push({
          video_name:
            program_info.dynamicStretch.detail_list[this.clickIndex].video
              .video_name,
          video_url:
            program_info.dynamicStretch.detail_list[this.clickIndex].video
              .video_url,
        });
      } else if (this.file_func == this.glb_upload_file_func.circuittraining) {
        let program_info = await this.glb_set_mk_program();
        this.upload_video_list.push({
          video_name:
            program_info.circuittraining.detail_list[this.clickIndex].video
              .video_name,
          video_url:
            program_info.circuittraining.detail_list[this.clickIndex].video
              .video_url,
        });
      } else if (this.file_func == this.glb_upload_file_func.activation) {
        let program_info = await this.glb_set_mk_program();
        this.upload_video_list.push({
          video_name:
            program_info.activation.detail_list[this.clickIndex].video
              .video_name,
          video_url:
            program_info.activation.detail_list[this.clickIndex].video
              .video_url,
        });
      }
    },
    setScroll() {
      clearAllBodyScrollLocks();
    },
    // 画像削除時のModal
    closeModal() {
      this.delete_alert = false;
      clearAllBodyScrollLocks();
    },
    // 画像削除時のModal
    async deleteUpdate() {
      let self = this;
      //this.upload_video_list.splice(this.delete_index, 1);
      self.loading = true;

      if (
        self.res_upload_video_list[self.delete_index].video_name != null &&
        self.res_upload_video_list[self.delete_index].video_name != ""
      ) {
        const file_name =
          self.res_upload_video_list[self.delete_index].video_name;
        self.res_upload_video_list.splice(self.delete_index, 1);
        self.upload_video_list.splice(self.delete_index, 1);
        self.delete_alert = false;
        if (self.event == "2") {
          self.glb_set_localStorage(
            "class_icon_list",
            JSON.stringify(self.res_upload_video_list)
          );
          clearAllBodyScrollLocks();
          self.loading = false;
          self.delete_alert = false;
          self.$emit("update-icon-click", false);
        } else {
          let deleteVideoRef = firebase
            .storage()
            .ref()
            .child(file_name);
          deleteVideoRef.delete().then(function() {
            self.glb_set_localStorage(
              "class_icon_list",
              JSON.stringify(self.res_upload_video_list)
            );
            clearAllBodyScrollLocks();
            self.loading = false;
            self.delete_alert = false;
            self.$emit("update-icon-click", false);
          });
        }
      }

      clearAllBodyScrollLocks();
    },
    // 画像削除時のModal
    deleteVideoEvent(index) {
      this.delete_alert = true;
      this.alert_msg = "この画像を削除してよろしいでしょうか？";
      this.delete_index = index;
      const modal = document.querySelector(".open-modal");
      disableBodyScroll(modal);
    },
    // 画像編集時のModal
    editVideo(value) {
      if (value == 1) {
        this.deleteVideo = true;
      }
      if (value == 2) {
        this.deleteVideo = false;
      }
    },
    // 画像編集時のModal
    videoEvent(value) {
      switch (value) {
        case 0:
          if (this.video_index > 0) {
            this.openVideoUrl = this.upload_video_list[
              this.video_index - 1
            ].video_url;
            this.video_index = this.video_index - 1;
          }
          break;
        case 1: {
          clearAllBodyScrollLocks();
          this.modal = false;
          this.openVideoUrl = "";
          break;
        }
        case 2: {
          if (this.video_index < this.upload_video_list.length - 1) {
            this.openVideoUrl = this.upload_video_list[
              this.video_index + 1
            ].video_url;
            this.video_index = this.video_index + 1;
          }
          break;
        }
      }
    },

    // 画像一覧 モーダル表示
    openVideo(index) {
      this.modal = true;
      this.openVideoUrl = this.upload_video_list[index].video_url;
      this.video_index = index;
      const modal = document.querySelector(".open-modal");
      disableBodyScroll(modal);
    },

    profile_video_list_up(event) {
      let self = this;
      let selectPic = event.target.files[0];
      var pos = selectPic.name.split(".").pop();
      let videoCheck = self.validete(pos);

      if (videoCheck) {
        this.editEvent = true;
        this.addVideoEvent = true;
        let self = this;
        //self.videoList.push(event.target.files[0]);
        //画像アップロード
        self.updateProfileVideo(event);

        var reader = new FileReader();
        reader.onload = function(event) {
          self.upload_video_list[0] = {
            video_url: event.target.result,
            video_name: "",
          };
        };
        reader.readAsDataURL(event.target.files[0]);
      }
    },
    validete(pos) {
      switch (pos) {
        case "avi":
        case "mp4":
        case "mov":
        case "wmv":
        case "mpg":
        case "mkv":
        case "flv":
        case "asf":
          return true;

        default:
          this.userInfo.icon = "";
          return false;
      }
    },
    //画像判定関数
    iconPreview(event) {
      this.editIcon = true;
      let self = this;
      self.editIconUrl = event.target.files[0];
      // Debai
      var reader = new FileReader();
      reader.onload = function(event) {
        self.iconPreviewSrc = event.target.result;
        //プレビューされたファイルを変数の中に代入
      };
      var pos = self.editIconUrl.name.split(".").pop();
      let videoCheck = self.validete(pos);
      if (videoCheck) {
        // reader.onload;
        reader.readAsDataURL(event.target.files[0]);
      }
    },
    async updateProfileVideo(event) {
      var videoPath = "";
      let localStorageData = await this.glb_get_localStorage_for_program(
        this.mk_program_storage_name
      );
      if (this.file_type == this.glb_upload_file_func.class) {
        this.uid = this.glb_get_sessionStorage("register_class_id");
        videoPath = "user_program/" + this.uid + "/video/";
      } else if (this.file_type == this.glb_upload_file_func.program_video) {
        this.uid = localStorageData.mk_program_id;
        videoPath = "user_program/" + this.uid + "/video/";
      }

      let self = this;
      let fileData = event.target.files[0];
      self.loading = true;
      let time = Date.now();
      videoPath = videoPath + time + fileData.name;
      let videoListRef = firebase
        .storage()
        .ref()
        .child(videoPath);
      await videoListRef.put(fileData).then(async () => {
        await videoListRef.getDownloadURL().then(async (url) => {
          self.res_upload_video_list.push({
            video_url: url,
            video_name: videoPath,
          });
          let res_upload_video_list = [
            {
              video_url: url,
              video_name: videoPath,
            },
          ];

          localStorageData[self.file_func + "_video"] = res_upload_video_list;
          self.glb_set_localStorage_for_program(
            self.mk_program_storage_name,
            localStorageData
          );
          self.$emit("update-video-click", this.file_func);
        });
      });
      //}
      self.loading = false;
    },
    cancel() {
      //this.userInfoEdit = JSON.parse(localStorage.getItem("user_info"));
      this.modal2 = false;
    },
  },
};
</script>
<style scoped>
#selectItem,
#img_name {
  display: none;
}
label {
  padding: 0 3rem;
}

label::after {
  content: "";
  font-size: 1rem;
  color: #888;
  padding-left: 1rem;
}
.image_input {
  display: none;
}
.img-fiex {
  display: flex;
  flex-wrap: wrap;
}
.img-div {
  width: 100%;
  padding: 0%;
  margin-left: auto;
  margin-right: auto;
}

.img-div .image-list {
  width: auto;
  /* height: 100px; */
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
.image-list image {
  width: 45%;
}
.add-image {
  width: 40px;
  height: 60px;
  margin: auto;
}
.selectPicture {
  text-align: left;
}
.selectPicture_label_image {
  background-color: #eeeeee;
  margin-top: 5px;
  margin-left: 5px;
  width: 100px;
}
.add-image-back {
  text-align: center;
  margin-top: 20px;
  margin-left: -10px;
  height: 58px;
  width: 58px;
  border-radius: 90%;
  background-position: 20% 36%;
  background-size: cover;
  background-color: #eeeeee;
}
.open-modal {
  z-index: 10;
  width: 100%;
  height: 100%;
  background: rgba(100, 100, 100, 0.9);
  position: fixed;
  top: 0;
  left: 0;
}
.open-image {
  margin-top: 70px;
  margin-left: auto;
  margin-right: auto;
  width: 100vw;
  height: auto;
  max-height: 70vh;
  background-color: #fff;
}
.close-image {
  margin-top: 60px;
  width: 50px;
  height: 50px;
}
.font-color {
  color: rgb(136, 136, 136);
  font-weight: 600;
}
.open-image-div {
  width: 100%;
  height: 70vh;
}
.edit-div {
  /* margin-left: 0; */
  /* padding-right: 12%; */
}
.edit-div label {
  text-align: center;
  padding: 4px 0 0 13px;
  width: 70px;
  height: 25px;
  border: none;
  font-size: 12px;
  font-weight: 600;
  border-radius: 10px;
  background-color: rgb(236, 134, 0);
  box-shadow: 0px 3px 6px rgb(214, 214, 214);
  color: #ffffff;
}
.mainasu-icon {
  margin-top: 0px;
  margin-right: 101%;
}
.img-div img {
  text-align: left;
  background-color: #858585;
  border-radius: 50%;
  margin-top: 1px;
  margin-left: -4px;
}

.popContainer {
  z-index: 2;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
}
.alert-div {
  margin-left: auto;
  margin-right: auto;
  margin-top: 200px;
  width: 80%;
  height: 200px;
  background-color: #fff;
  border-radius: 5px;
}
.alert-msg {
  padding: 15% 5% 5% 5%;
  color: #000;
  font-weight: 500;
  white-space: pre-wrap;
}
.button-div table {
  margin-top: 15px;
  width: 100%;
}
.button-style {
  width: 100px;
  height: 40px;
  border: 1px solid #dddddd;
}
.cancel-button {
  border-radius: 10px;
  background-color: #fff;
  color: #888888;
  font-size: 14px;
  font-weight: 600;
  padding: 8px 0 0 5px;
  text-align: center;
}
.send-button {
  border-radius: 10px;
  background-color: #fff;
  color: #00aa93;
  font-size: 14px;
  font-weight: 600;
  padding: 8px 0 0 6px;
  text-align: center;
}
.msg-color {
  padding: 1% 1% 1% 1%;
  color: rgb(161, 0, 0);
}
.qualification-div {
  display: inline-flex;
  padding: 5px;
  overflow: auto;
}

#infoGuide {
  width: 100%;
  min-height: 82px;
  height: auto;
  margin: 0% auto 0% auto;
  border-radius: 11px;
  background: #ededed;
  border: none;
  padding: 10px;
  outline: none;
  font-size: 16px;
}

a {
  display: inline-block;
  margin: 0 0.1em 0.6em 0;
  padding: 0.8em;
  line-height: 1;
  text-decoration: none;
  background: #ededed;
  font-size: 12px;
  font-weight: 700;
  border-radius: 8px;
  box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.16);
}
.display-info {
  width: 96%;
  min-height: 80px;
  height: auto;
  margin: 0% auto 0% auto;
  border-radius: 11px;
  background: #ededed;
}
.fitness-card {
  margin: 2% auto 0 auto;
  width: 100%;
  height: auto;
  background: #fff;
  /* filter: drop-shadow(9px 12px 15px rgba(0, 0, 0, 0.16)); */
  border-radius: 8px;
}

.fitness-card table th {
  text-align: left;
  padding: 5px;
  width: 50%;
}
.fitness-card table td {
  text-align: left;
  padding: 5px;
  width: 50%;
}

.user-info-input {
  width: 94%;
  margin-left: auto;
  margin-right: auto;
}
.user-info-input td {
  height: 35px;

  border: 3px #fff;
}
.user-info-input .td-1 {
  width: 48%;
  background: #ededed;
  border-radius: 6px;
}
.user-info-input .td-2 {
  width: 4%;
  background-color: #fff;
}
.user-info-input .td-3 {
  width: 48%;
  background: #ededed;
  border-radius: 6px;
}
.td-4 {
  width: 48%;
  background: #ededed;
  border-radius: 6px;
}
.user-info-input .td-5 {
  width: 25%;
  background: #ededed;
  border-radius: 6px;
  margin-right: 12px;
}
.user-info-input .td-6 {
  width: 2%;
  border-radius: 6px;
}
.user-info-input .td-7 {
  width: 20%;
  background: #ededed;
  border-radius: 6px;
}
.user-info-input .td-8 {
  width: 35%;
  border-radius: 6px;
}

.fitness-card input[type="text"] {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  font-size: 16px;
  background: #ededed;
}
.title-style {
  font-family: "Hiragino Kaku Gothic ProN";
  font-weight: 600;
  text-align: left;
  color: #838383;
  padding: 12px 0 0 12px;
}

/* ---------------モーダルに関して */

.qualificat-button-style {
  border: none;
  width: 60%;
  height: 40px;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 700;
}
.qualification-modal2 {
  margin-left: auto;
  margin-right: auto;
  margin-top: 90px;
  width: 95%;
  height: 70%;
  background-color: #fff;
  overflow: auto;
}
.qualification-modal2 table {
  width: 100%;
}
.qualification-modal2 table th {
  width: 50%;
  background-color: #f2f2f2;
}
.qualification-modal2 table td {
  width: 50%;
  background-color: #ffffff;
}

.plus-qualification {
  width: 50%;
  height: 30px;
  background: #ededed;
  font-size: 16px;
  font-weight: 500;
  border-radius: 8px;
  box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.16);
  padding: 0.8em;
  border: none;
  outline: none;
}
.title-style-2 {
  font-family: "Hiragino Kaku Gothic ProN";
  font-weight: 600;
  text-align: left;
  margin-top: 0%;
  color: #838383;
  padding: 12px 0 0 0px;
}
.qualificat-button-style {
  border: none;
  width: 60%;
  height: 40px;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 700;
}
.qualificat-cancel-button {
  border: 2px #eb7620;
}
.qualificat-update-button {
  background-color: cadetblue;
  color: #ffffff;
  text-align: center;
  width: 100px;
  height: 35px;
  border: none;
  font-size: 16px;
  font-weight: 700;
  border-radius: 10px;
  background-color: rgb(3, 163, 168);
  box-shadow: 3px 6px 9px rgb(139, 139, 139);
}
.qualificat-button-table {
  margin-left: auto;
  margin-right: auto;
  width: 95%;
  background-color: #ffffff;
}
.qualificat-button-table td {
  padding: 10px;
}
.qualificat-button-table th {
  padding: 10px;
}

.error-msg {
  color: rgb(116, 0, 0);
  font-size: 14px;
  padding: 0%;
  margin-left: 4%;
  text-align: left;
}
.error-msg p {
  margin: 0%;
}
table td {
  text-align: center;
}
.loding-div {
  text-align: center;
}
.button-edit-div {
  margin-top: 16px;
}
.image-open {
  width: 100%;
  height: 80%;
  /* background-color: #000; */
}
.video-div-style {
  height: auto;
  width: auto;
  margin-top: 0px;
  border: 0;
  /* background-color: rgb(233, 233, 233); */
}
.video-style {
  width: 100%;
  height: 200px;
}
</style>
