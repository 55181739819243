<template>
  <div class="allEllement">
    <ClassHeader :page="2" />
    <transition name="modal">
      <div class="searchModalBody">
        <form class="search-form">
          <div class="input-form">
            <div class="input-group input-group-lg">
              <form>
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control"
                    id="inputKeyWord"
                    aria-describedby="emailHelp"
                    placeholder="キーワード"
                  />
                </div>
                <div class="form-group">
                  <input
                    type="text"
                    class="form-control"
                    id="inputPrefecture"
                    placeholder="都道府県"
                  />
                </div>
                <div class="input-form">
                  <select
                    class="custom-select select-form"
                    id="inputGroupSelect01"
                    v-model="organize_type"
                  >
                    <option
                      v-for="option in date_select_options"
                      v-bind:value="option.value"
                      :key="option.value"
                      >{{ option.text }}
                    </option>
                  </select>
                </div>

                <div class="d-flex justify-content-around">
                  <div class="checkbox-div">
                    <div class="input-group-prepend">
                      <div
                        class="input-group-text checkbox-style"
                        v-for="content in weekDayCheckList"
                        :key="content.id"
                      >
                        {{ content.text }}
                        <input
                          type="checkbox"
                          aria-label="Checkbox for following text input"
                          v-model="content.value"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <br />

                <!-- <input
                    type="checkbox"
                    class="form-control"
                    id="inputWeek"
                    placeholder="曜日検索"
                  /> -->

                <div class="form-group">
                  <!-- <input
                    type="text"
                    class="form-control"
                    id="inputDate"
                    placeholder="開催日時"
                  /> -->
                  <div class="datepicker-style">
                    <v-date-picker :popover="popover" v-model="date">
                      <template v-slot="{ inputValue, inputEvents }">
                        <input
                          :value="inputValue"
                          v-on="inputEvents"
                          class="datepicker"
                          placeholder="開催日時"
                        />
                      </template>
                    </v-date-picker>
                  </div>
                </div>
                <div class="form-group" id="orgTime">
                  <input
                    type="time"
                    class="form-control time"
                    id="input-sTime"
                    placeholder="開始時間"
                  />
                  <input
                    type="time"
                    class="form-control time"
                    id="input-eTime"
                    placeholder="終了時間"
                  />
                </div>

                {{ weekDayCheckList }}

                <div id="Btn">
                  <!-- 検索cxl-Btn -->
                  <button
                    id="cxlBtn"
                    type="button"
                    class="btn btn-outline-secondary w-auto p-2"
                    v-on:click="$emit('closeModal')"
                  >
                    キャンセル
                  </button>

                  <!-- 検索Btn -->
                  <button
                    id="searchBtn"
                    type="button"
                    class="btn btn-danger w-25 p-3"
                    @click="getOrganizeClass()"
                  >
                    {{ searchParm }}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </form>
      </div>
    </transition>
  </div>
</template>
<script>
import ClassHeader from "../../../components/class/class_header";

export default {
  name: "ClassSearch",

  data() {
    return {
      weekDayCheckList: [],
      date: null,
      organize_type: "定期",
      date_select_options: [
        {
          text: "定期",
          value: "定期",
        },
        {
          text: "不定期",
          value: "不定期",
        },
      ],
      // キーワード
      SearchWord: "",
      // 都道府県 = mixInから取得
      // 曜日検索
      WeekDay: "",
      // 開催日時
      Dates: "",
      // 開始時間
      StartTime: "",
      // 終了時間
      EndTime: "",
    };
  },
  created() {
    this.setWeekDay();
  },
  components: {
    ClassHeader,
  },
  methods: {
    setWeekDay() {
      this.weekDayCheckList = this.glb_set_weekDay_checkList();
    },
    closeModal() {
      this.$emit("closeModal", "false");
    },
    getOrganizeClass() {
      this.searchParm = "検索中";
    },
  },
};
</script>
<style scoped>
/* 画面全体 */
.allEllement {
  position: absolute;
  width: 100%;
  height: auto;
  background-color: #ffffff;
  margin: 0%;
  margin-top: 2%;
}
.search-form {
  margin-top: 20px;
}

#Btn {
  text-align: center;
  margin-top: 43%;
  font-size: 1rem;
}
.btn {
  width: 10%;
  height: 10%;
  font-size: 1rem;

  text-align: center;
  width: 100px;
  height: 35px;
  border: none;
  font-size: 16px;
  font-weight: 700;
  border-radius: 10px;

  box-shadow: 0px 3px 6px rgb(214 214 214);
}

#cxlBtn {
  margin-right: 7%;
}
.p-3 {
  padding: 0.6rem !important;
}

#searchBtn {
  color: white;
}

input {
  background-color: #eeeeee;
  font-size: 1.5rem;
}
.input-group {
  margin-top: 35%;
  display: block;
}
input[type="text"] {
  border: none;
  display: block;

  font-size: 12pt;
  width: 94%;
  margin-left: auto;
  margin-right: auto;
}

.input-form {
  margin-top: 16%;
  margin-left: auto;
  margin-right: auto;
  text-align: left;
  width: 95%;
}
.input-title {
  font-weight: 600;
}
.input-content {
  background-color: #ededed;
  border: none;
  outline: none;
  font-size: 16px;
}
.form-group {
  text-align: center;
}
/* クラス検索対象時間 */
#orgTime {
  display: flex;
}
.time {
  margin-right: 7%;
  display: inline;
  width: 40%;
}
/* 開始時間 */
#input-sTime {
  margin-left: 3%;
}
/* 終了時間 */
#input-eTime {
  margin-left: 7%;
  margin-right: 0%;
}
.datepicker-style {
  width: 100%;
}
.datepicker {
  /* font-size: 16px; */
  background-color: #ededed;
  outline: none;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  height: 35px;
  font-weight: 500;
  padding: 5px;
  width: 94%;
}
.checkbox-div {
  width: 94%;
  margin-left: auto;
  margin-right: auto;
}
.checkbox-flex {
  display: flex;
}
.checkbox-style {
  font-size: 16px;
  width: 100%;
  background-color: #ededed;
  /* border: none; */
  /* padding: 5px; */
}
</style>
