<template>
  <div>
    <div class="heard-style">
      <a href="#/profile">
        <img src="@/assets/profile/矢印アイコン左4_1.svg" width="20" height="20" />
      </a>
      <h2>アカウント設定</h2>
    </div>
    <div class="home space-style-1"></div>
    
    <div class="choices">
        <table class="choices-table icon-style">
          <tr>
            <td class="choices-title icon-title">
              <h4 class="display-6 choices-text">プロフィール</h4>
            </td>
             <td class="choices-icon-circle">
              <div class="icon-style-circle">
                <div
                  v-if="userInfo.icon"
                  class="image_circle"
                  :style=" { backgroundImage: 'url(' + userInfo.icon + ')'}"
                ></div>
                <div v-else class="image_circle" :style=" { backgroundImage: 'url()'}"></div>
              </div>
            </td>
            <td class="choices-yajirushi">
              <img src="@/assets/profile/矢印アイコン右4.svg" height="10" width="10" />
            </td>
          </tr>
        </table>
        <table class="choices-table">
          <tr>
            <td class="choices-title">
              <h4 class="display-6 choices-text">ニックネーム</h4>
            </td>

            <td class="choices-yajirushi">
              <img src="@/assets/profile/矢印アイコン右4.svg" height="10" width="10" />
            </td>
          </tr>
        </table>
        <table class="choices-table">
          <tr>
            <td class="choices-title">
              <h4 class="display-6 choices-text">メールアドレス</h4>
            </td>

            <td class="choices-yajirushi">
              <img src="@/assets/profile/矢印アイコン右4.svg" height="10" width="10" />
            </td>
          </tr>
        </table>
        <table class="choices-table">
          <tr>
            <td class="choices-title">
              <h4 class="display-6 choices-text">パスワード</h4>
            </td>

            <td class="choices-yajirushi">
              <img src="@/assets/profile/矢印アイコン右4.svg" height="10" width="10" />
            </td>
          </tr>
        </table>
      </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "Account",
  components: {
    //HelloWorld,
  },
  data() {
    return {
      userInfo:{}
    };
  },
  created: function() {
    //画面開くときの処理
    this.getUserInfo();
  },
  methods: {
    getUserInfo(){
      this.userInfo = JSON.parse(sessionStorage.getItem('user_info'))
    }
  }
};
</script>
<style scoped>
.heard-style {
  position: fixed;
  z-index: 2;
  margin-top: 0%;
  padding-top: 18px;
  width: 100%;
  height: 58px;
  background: #f2f2f2;
  border-bottom: 1px solid #cfcfcf;

}
.heard-style img {
  float: left;
  margin-left: 4%;
}
.heard-style > h2 {
  color: #414141;
  margin-right: 6%;
  font-weight: 900;
}
.home-img img {
  margin-top: 58px;
  width: 100%;
  padding: 0;
  
}
.space-style-1 {
  height: 58px;
}


/* 選択肢のcss */
.choices-table {
  width: 100%;
  margin-top: 1px;
  height: 50px;
  background: #fff;
  text-align: center;
  box-shadow: 3px 6px 9px rgba(0, 0, 0, 0.16);
}
.icon-style {
  height: 100px;
  text-align: right;
}
.choices-table .choices-title {
  width: 45%;
  padding-left: 5%;
  text-align: left;
}
.choices-table .icon-title {
  width: 20%;
  padding-left: 5%;
  text-align: left;
}

.choices-table .choices-yajirushi {
  width: 5%;
  text-align: left;
}

.choices-text {
  margin-top: 2%;
  background-color: rgb(255, 255, 255);
}

.profile-table :active {
  background-color: rgb(223, 223, 223);
}

.choices-table :active {
  background-color: rgb(223, 223, 223);
}
a:visited{
  color: inherit;
}


.choices-icon-circle {
  width: 30%;
}

.icon-style-circle {
  width: 90px;
  padding: 5px;
  border-radius: 50%;  
  margin-left: 40%
}

.choices-icon-circle .image_circle {
  height: 90px;
  width: 90px;
  border-radius: 50%;
  margin: 0%;
  background-position: 54% 36%;
  background-size: cover;
  border: 2px solid rgba(236, 236, 236, 0.5);
  


}
</style>
