<template>
  <div class="allEllement">
    <div id="header">
      <ClassHeader :page="3" @do-back-home="toBack" />
      <div class="heard-style">
        <!--  HeaderBackButtonEllement-->
        <table class="heard-table">
          <tr>
            <td class="back-icon">
              <a href="#/profile">
                <img src="@/assets/home/yajirusi.svg" width="20" height="20" />
              </a>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <div class="card">
      <div class="info-div">
        <div class="card-title-div">
          <p class="h4 card-title-font">拠点場所</p>
        </div>
        <div class="card-content-div">
          <p class="h4 card-content-font" v-if="point_info.guidance_base">
            {{ point_info.guidance_base.location }}
          </p>
        </div>
      </div>
      <!-- <div class="info-div">
        <div class="card-title-div">
          <p class="h4 card-title-font">開催コーチ</p>
        </div>
        <div class="card-content-div">
          <p class="h4 card-content-font">
            {{ point_info.certified_coach }}
          </p>
        </div>
      </div>
      <div class="info-div">
        <div class="card-title-div">
          <p class="h4 card-title-font">開催者名前</p>
        </div>
        <div class="card-content-div">
          <p class="h4 card-content-font">
            {{ point_info.last_name }} {{ point_info.first_name }}
          </p>
        </div>
      </div>
      <div class="info-div">
        <div class="card-title-div">
          <p class="h4 card-title-font">開催者資格</p>
        </div>
        <div class="card-content-div">
          <div class="qualification">
            <div
              v-for="content in point_info.qualification"
              :key="content.id"
              class="qualification-tag"
            >
              <p class="h4 card-content-font">
                {{ content }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="info-div">
        <div class="card-title-div">
          <p class="h4 card-title-font">開催者紹介</p>
        </div>
        <div class="card-content-div">
          <p class="h4 card-content-font">
            {{ point_info.self_pr }}
          </p>
        </div>
      </div>
      <div class="info-div">
        <div class="card-title-div">
          <p class="h4 card-title-font">開催者SNS</p>
        </div>
        <div class="card-content-div">
          <div v-if="point_info.sns != null">
            <p class="h4 card-content-font">
              <ClassNSN :sns_list="point_info.sns" />
            </p>
          </div>
        </div>
      </div> -->
    </div>
    <div>
      <div class="title-div">
        <p class="h3 title-font">開催中クラス一覧</p>
      </div>
      <div
        class="change-div"
        v-for="content in pointClassInfoList"
        :key="content.id"
      >
        <table @click="doClassInfo(content.class_uid)">
          <tr>
            <th>
              <div
                class="image_circle"
                :style="{
                  backgroundImage:
                    'url(' + content.profile_image_list[0].image_url + ')',
                }"
              ></div>
            </th>
            <td>
              <div class="card-title">
                <h4>
                  {{ content.coach_name }}
                </h4>
              </div>
              <div class="card-content">
                <h5>
                  {{ content.location_address }}
                  <p></p>
                  {{ content.remarks }}
                </h5>
              </div>
            </td>
          </tr>
        </table>
      </div>
    </div>
    <!-- <div>
      <div class="title-div">
        <p class="h3 title-font">周辺開催中クラス一覧</p>
      </div>
      <div
        class="change-div"
        v-for="content in pointClassInfoList"
        :key="content.id"
      >
        <table v-if="pointClassInfoList.length > 0">
          <tr>
            <th>
              <div
                class="image_circle"
                :style="{
                  backgroundImage:
                    'url(' + content.profile_image_list[0].image_url + ')',
                }"
              ></div>
            </th>
            <td>
              <div class="card-title">
                <h4>
                  {{ content.coach_name }}
                </h4>
              </div>
              <div class="card-content">
                <h5>
                  {{ content.location_address }}
                </h5>
              </div>
            </td>
          </tr>
        </table>
      </div>
    </div> -->
  </div>
</template>
<script>
// components/class/class_header
import ClassHeader from "../../components/class/class_header";
import firebase from "firebase/compat";
// import ClassNSN from "@/components/tool/ClassSNS";
export default {
  name: "ClassSearch",
  data() {
    return {
      point_info: {},
      pointClassInfoList: [],
      vicinityPointClassInfoList: [],
    };
  },
  components: {
    ClassHeader,
    // ClassNSN,
  },
  async mounted() {
    await this.getPointInfo();
    await this.getPointClassInfoList();
    //await this.getvicinityPointClassInfoList();
  },
  methods: {
    async doClassInfo(class_uid) {
      await this.glb_set_localStorage("classID", class_uid);
      this.$router.push({
        name: "ClassInfo",
        path: "/ClassInfo",
        props: true,
      });
    },
    async getPointInfo() {
      //拠点情報取得
      let self = this;
      var pointUid = await this.glb_get_localStorage("point_uid");
      var pointLocation = await this.glb_get_localStorage("point_location");
      await firebase
        .firestore()
        .collection(self.glb_table.users)
        .doc(pointUid)
        .get()
        .then((snap) => {
          //各クラスの情報を取得する
          //各クラスの経緯度をマーカーに代入する
          let data = {};
          data = snap.data();
          const targetGuidance_base = snap
            .data()
            .guidance_base.find((v) => v.location === pointLocation);
          data.guidance_base = targetGuidance_base;
          self.point_info = data;
        });
    },
    async getPointClassInfoList() {
      //同じ経緯度のクラス取得
      let self = this;
      let searchData = {
        lat: self.point_info.guidance_base.lat,
        lng: self.point_info.guidance_base.lng,
      };
      await firebase
        .firestore()
        .collection(self.glb_table.class)
        .where("lat_and_lng", "==", searchData)
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            if (
              doc.data().status == "公開" &&
              doc.data().inspection == self.glb_inspection.examined
            ) {
              let data = doc.data();
              data.class_uid = doc.id;
              self.pointClassInfoList.push(data);
            }
          });
        });
    },
    closeModal() {
      // emit で 親変数変更    2021/12/06 Ogawa K
      this.$emit("closeModal", "false");
    },

    // クラス検索
    getOrganizeClass() {
      this.searchParm = "検索中";
    },
    toBack() {
      window.location.reload();
    },
  },
};
</script>
<style scoped>
.card {
  width: 95%;
  height: auto;
  min-height: 80px;
  margin: 60px auto 0px auto;
  background: #fbfbfb;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  border: none;
}
.info-div {
  display: flex;
  width: 100%;
}
.card-title-div {
  /* text-align: center; */
  width: 100px;
  padding: 10px 10px 0 10px;
  text-align: left;
}
.card-title-font {
  font-weight: 650;
  color: rgb(109, 109, 109);
}
.card-content-div {
  width: 300px;
  overflow: auto;
  padding: 10px 10px 0 10px;
  text-align: left;
}
.card-content-font {
  line-height: 22px;
  color: rgb(22, 22, 22);
}
.qualification {
  /* display: flex; */
}
.title-div {
  margin-top: 10px;
  padding: 10px;
  width: 100%;
  height: 30px;
  text-align: left;
}
.title-font {
  text-align: left;
  font-family: Work Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 21px;
  display: flex;
  align-items: flex-end;
  letter-spacing: -0.02em;
  color: #6b6b6b;
}
.change-div {
  /* position: relative; */
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  width: 95%;
  height: 100px;
  background: rgb(255, 255, 255);
  /* background-color: black; */
  border-radius: 15px;
  box-shadow: 3px 6px 9px rgba(0, 0, 0, 0.2);
}
.change-div table {
  width: 100%;
  height: 100%;
}
.change-div table th {
  width: 120px;
  /* background-color: #fff; */
}
.change-div table td {
  position: relative;
}

.change-div table td .card-title {
  position: absolute;
  top: 12px;
  left: 8px;
  text-align: left;
}
.change-div table td .card-content {
  position: absolute;
  top: 45px;
  left: 8px;
  height: 40px;
  width: 98%;
  text-align: left;
  overflow: auto;
}

.image_circle {
  text-align: center;
  height: 100px;
  width: 100px;
  border-radius: 10%;
  background-position: 54% 36%;
  background-size: cover;
}
</style>
