// import { createRouter, createWebHistory } from 'vue-router'
import { createRouter, createWebHashHistory } from "vue-router";
import Home from "../views/mobile/Home.vue";
import Login from "../views/mobile/Login.vue";
import Commentary from "../views/mobile/Commentary.vue";
import Kids from "../views/mobile/Kids.vue";
import Basic from "../views/mobile/Basic.vue";
import ResetProgram from "../views/mobile/ResetProgram.vue";
import KidsProgram from "../views/mobile/KidsProgram.vue";
import Monitoring from "../views/mobile/Monitoring";
import Program from "../views/mobile/Program.vue";
import Profile from "../views/mobile/Profile.vue";
import ResetPassword from "../views/mobile/ResetPassword.vue";
import Map from "../views/mobile/Map";
import NewProgram from "../views/mobile/Search.vue";
import Account from "../views/mobile/profile/Account.vue";
import Mypage from "../views/mobile/profile/Mypage.vue";
import emailEdit from "../views/mobile/profile//EmailEdit.vue";
import UserInfoRegister from "../views/mobile/profile/UserInfoRegister.vue";
import ClassRegister from "../views/mobile/class/ClassRegister.vue";
import Class from "../views/mobile/Class.vue";
import ClassHistory from "../views/mobile/profile/ClassHistory.vue";
import ClassInfoEdit from "../views/mobile/class/ClassInfoEdit.vue";



import HomePad from "../views/ipad/Home_pad.vue";
import ClassRegisterPad from "../views/ipad/class/ClassRegister.vue";
import ProfilePad from "../views/ipad/Profile.vue";
import MypagePad from "../views/ipad/profile/Mypage.vue";
import UserInfoRegisterPad from "../views/ipad/profile/UserInfoRegister.vue";

//プログラム作成
import ProgramCreated from "../views/mobile/ProgramCreated.vue";


import firebase from "firebase/compat";
//   拠点情報 追加 OgawaK
import PointInfo from "../components/class/point_info.vue";

//  クラス情報ページ 追加 OgawaK ../component/class/class_info.vue
import ClassInfo from "../components/class/class_info.vue";

let routes = [];
if (screen.width > 540) {
  routes = [
    {
      path: "/:catchAll(.*)",
      redirect: "login",
    },
    {
      path: "/",
      name: "HomePad",
      component: HomePad,
      meta: { requiresAuth: true },
    },
    {
      path: "/commentary",
      name: "Commentary",
      component: Commentary,
      meta: { requiresAuth: true },
    },
    {
      path: "/basic",
      name: "Basic",
      component: Basic,
      meta: { requiresAuth: true },
    },
    {
      path: "/reset_program",
      name: "ResetProgram",
      component: ResetProgram,
      meta: { requiresAuth: true },
    },
    {
      path: "/commentary",
      name: "Commentary",
      component: Commentary,
      meta: { requiresAuth: true },
    },{
      path: "/kids",
      name: "Kids",
      component: Kids,
      meta: { requiresAuth: true },
    },
    {
      path: "/monitoring",
      name: "Monitoring",
      component: Monitoring,
      meta: { requiresAuth: true },
    },
    {
      path: "/profile",
      name: "ProfilePad",
      component: ProfilePad,
      meta: { requiresAuth: true },
    },
    {
      path: "/newProgram",
      name: "NewProgram",
      component: NewProgram,
      meta: { requiresAuth: true },
    },
    {
      path: "/program",
      name: "Program",
      component: Program,
      meta: { requiresAuth: true },
    },
    {
      path: "/resetPassword",
      name: "ResetPassword",
      component: ResetPassword,
    },
    {
      path: "/login",
      name: "Login",
      component: Login,
    },
    {
      path: "/profile/account",
      name: "Account",
      component: Account,
      meta: { requiresAuth: true },
    },
    {
      path: "/profile/mypage",
      name: "MypagePad",
      component: MypagePad,
      meta: { requiresAuth: true },
    },
    {
      path: "/profile/userinfo",
      name: "UserInfoRegisterPad",
      component: UserInfoRegisterPad,
      meta: { requiresAuth: true },
    },
    {
      path: "/profile/emailEdit",
      name: "EmailEdit",
      component: emailEdit,
      meta: { requiresAuth: true },
    },
    {
      path: "/classRegister",
      name: "ClassRegisterPad",
      component: ClassRegisterPad,
      meta: { requiresAuth: true },
    },
    {
      path: "/class",
      name: "Class",
      component: Class,
      meta: { requiresAuth: true },
    },
    {
      path: "/classHistory",
      name: "ClassHistory",
      component: ClassHistory,
      meta: { requiresAuth: true },
    },

    {
      path: "/classInfoEdit",
      name: "ClassInfoEdit",
      component: ClassInfoEdit,
      meta: { requiresAuth: true },
    },
    //プログラム作成
    {
      path: "/programCreated",
      name: "ProgramCreated",
      component: ProgramCreated,
      meta: { requiresAuth: true },
    },
    {
      path: "/kids_program",
      name: "KidsProgram",
      component: KidsProgram,
      meta: { requiresAuth: true },
    },

  ];
} else {
  //モバイル画面
  routes = [
    {
      path: "/:catchAll(.*)",
      redirect: "login",
    },

    {
      // src/components/class/class_info.vue   クラス情報ページ
      path: "/ClassInfo",
      name: "ClassInfo",
      component: ClassInfo,
      meta: { requiresAuth: true },
    },

    {
      // 拠点情報ページ ページ
      path: "/PointInfo",
      name: "PointInfo",
      component: PointInfo,
      meta: { requiresAuth: true },
    },
    {
      path: "/",
      name: "Home",
      component: Home,
      meta: { requiresAuth: true },
    },
    {
      path: "/commentary",
      name: "Commentary",
      component: Commentary,
      meta: { requiresAuth: true },
    },
    {
      path: "/kids",
      name: "Kids",
      component: Kids,
      meta: { requiresAuth: true },
    },
    {
      path: "/basic",
      name: "Basic",
      component: Basic,
      meta: { requiresAuth: true },
    },
    {
      path: "/reset_program",
      name: "ResetProgram",
      component: ResetProgram,
      meta: { requiresAuth: true },
    },
    {
      path: "/kids_program",
      name: "KidsProgram",
      component: KidsProgram,
      meta: { requiresAuth: true },
    },

    {
      path: "/commentary",
      name: "Commentary",
      component: Commentary,
      meta: { requiresAuth: true },
    },
    {
      path: "/monitoring",
      name: "Monitoring",
      component: Monitoring,
      meta: { requiresAuth: true },
    },
    {
      path: "/profile",
      name: "Profile",
      component: Profile,
      meta: { requiresAuth: true },
    },
    {
      path: "/newProgram",
      name: "NewProgram",
      component: NewProgram,
      meta: { requiresAuth: true },
    },
    {
      path: "/map",
      name: "Map",
      component: Map,
      meta: { requiresAuth: true },
    },
    {
      path: "/program",
      name: "Program",
      component: Program,
      meta: { requiresAuth: true },
    },
    {
      path: "/resetPassword",
      name: "ResetPassword",
      component: ResetPassword,
    },
    {
      path: "/login",
      name: "Login",
      component: Login,
    },
    {
      path: "/profile/account",
      name: "Account",
      component: Account,
      meta: { requiresAuth: true },
    },
    {
      path: "/profile/mypage",
      name: "Mypage",
      component: Mypage,
      meta: { requiresAuth: true },
    },
    {
      path: "/profile/userinfo",
      name: "UserInfoRegister",
      component: UserInfoRegister,
      meta: { requiresAuth: true },
    },
    {
      path: "/profile/emailEdit",
      name: "EmailEdit",
      component: emailEdit,
      meta: { requiresAuth: true },
    },
    {
      path: "/classRegister",
      name: "ClassRegister",
      component: ClassRegister,
      meta: { requiresAuth: true },
    },
    {
      path: "/class",
      name: "Class",
      component: Class,
      meta: { requiresAuth: true },
    },
    {
      path: "/classHistory",
      name: "ClassHistory",
      component: ClassHistory,
      meta: { requiresAuth: true },
    },
    {
      path: "/classInfoEdit",
      name: "ClassInfoEdit",
      component: ClassInfoEdit,
      meta: { requiresAuth: true },
    },
    //プログラム作成
    {
      path: "/programCreated",
      name: "ProgramCreated",
      component: ProgramCreated,
      meta: { requiresAuth: true },
    },
  ];
}

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  if (requiresAuth) {
    // このルートはログインされているかどうか認証が必要です。
    // もしされていないならば、ログインページにリダイレクトします。
    firebase.auth().onAuthStateChanged(function (user) {
      if (user) {
        //権限：ユーザー権限１以上
        if (to.fullPath == '/kids_program' || to.fullPath == '/kids') {
          firebase
            .firestore()
            .collection('users')
            .doc(user.uid)
            .get()
            .then((snap) => {
              var user_authority = snap.data().authority;
              if (user_authority > 1) {
                next();
              } else {
                next({
                  path: "/",
                  query: { redirect: to.fullPath },
                });
              }
            })
        } else {
          next();
        }

      } else {
        next({
          path: "/login",
          query: { redirect: to.fullPath },
        });
      }
    });
  } else {
    next(); // next() を常に呼び出すようにしてください!
  }
});

export default router;
