<template>
  <!-- Map画面でページ最下部 の  拠点リスト押下時 表示される (Modal) -->
  <!-- <div v-if="pontDetail">
    <PointInfo />      <router-link to="/profile">
  </div> -->

  <div>
    <div>
      <!-- CardInstance -->
      <div
        class="change-div"
        v-for="content in point_info_list"
        :key="content.id"
      >
        <!-- <router-link to="/PointInfo"> -->
        <table
          @click="toPointInfo(content.uid, content.guidance_base.location)"
        >
          <tr>
            <th>
              <!-- <img :src="image_url" /> -->
              <div
                class="image_circle"
                :style="{
                  backgroundImage: 'url(' + content.icon + ')',
                }"
              ></div>
            </th>
            <!-- 各カード Listインスタンス -->
            <td @click="openPointInfo(1)">
              <div class="card-title">
                <h4>
                  {{ content.guidance_base.location }}
                </h4>
              </div>
              <div class="card-content">
                <h5>
                  {{ content.certified_coach }}
                  <p></p>
                  {{ content.remarks }}
                </h5>
              </div>
            </td>
          </tr>
        </table>

        <!-- </router-link> -->
      </div>
    </div>
    <div>
      <div class="back-map" @click="doBackMap()">
        <h4 class="back-map-font">マップで表示</h4>
      </div>
    </div>
  </div>
</template>

<script>
// import ClassHeader from "../../components/class/class_header";
// 拠点詳細Modal
// import PointInfo from "../../components/class/point_info";
import firebase from "firebase/compat";
export default {
  name: "ClassList",
  props: {},

  data() {
    return {
      pontDetail: false,
      point_info_list: [],
      image_url:
        "https://www.nobuta-nakameguro.net/wp-content/uploads/2020/06/jason-rosewell-ASKeuOZqhYU-unsplash-scaled.jpg",
    };
  },
  mounted() {
    this.getPointInfoList();
  },
  components: {
    // ClassHeader,
    // PointInfo,
  },
  methods: {
    toPointInfo(uid, location) {
      //拠点UIDはlocalStorgeに保存：userのUID
      this.glb_set_localStorage("point_uid", uid);
      this.glb_set_localStorage("point_location", location);
      this.$router.push({ path: "PointInfo" });
    },
    getPointInfoList() {
      let self = this;
      firebase
        .firestore()
        .collection(self.glb_table.users)
        .get()
        .then(async function(querySnapshot) {
          await querySnapshot.forEach(async function(doc) {
            await firebase
              .firestore()
              .collection(self.glb_table.users)
              .doc(doc.id)
              .get()
              .then((snap) => {
                if (snap.data().guidance_base != null) {
                  for (var i in snap.data().guidance_base) {
                    if (snap.data().guidance_base[i].location != null) {
                      if (snap.data().uid == null) {
                        snap.data().uid = doc.id;
                      }
                      let locationData = snap.data().guidance_base[i];
                      let data = snap.data();
                      data.guidance_base = locationData;
                      self.point_info_list.push(data);
                    }
                  }
                }
                // if (snap.data().guidance_lat_and_lng != null) {
                //   if (snap.data().uid == null) {
                //     snap.data().uid = doc.id;
                //   }
                //   self.point_info_list.push(snap.data());
                // }
              });
          });
        });
    },
    doBackMap() {
      this.$emit("do-back-map", true);
    },
    // 拠点詳細モーダル開示 別ページでテスト
    openPointInfo() {
      // if (value == 1) {
      //   this.pontDetail = true;
      // } else {
      //   this.pontDetail = false;
      // }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.change-div {
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  width: 95%;
  height: 120px;
  background: rgb(255, 255, 255);
  /* background-color: black; */
  border-radius: 15px;
  box-shadow: 3px 6px 9px rgba(0, 0, 0, 0.2);
}
.change-div table {
  width: 100%;
  height: 100%;
}
.change-div table th {
  width: 120px;
  /* background-color: #fff; */
}
.change-div table td {
  position: relative;
}

.change-div table td .card-title {
  position: absolute;
  top: 12px;
  left: 0px;
  text-align: left;
}
.change-div table td .card-content {
  position: absolute;
  top: 45px;
  left: 0px;
  height: 40px;
  text-align: left;
  overflow: auto;
}

.image_circle {
  text-align: center;
  height: 100px;
  width: 100px;
  margin: 5px;
  border-radius: 10%;
  background-position: 54% 36%;
  background-size: cover;
}
.back-map {
  position: fixed;
  z-index: 3;
  margin-left: auto;
  margin-right: auto;
  height: 40px;
  top: 90%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(80, 80, 80, 0.9);
  box-shadow: 3px 6px 9px rgba(0, 0, 0, 0.5);
  border-radius: 15px;
}
.back-map-font {
  color: #ffffff;
  font-size: 16px;
  font-weight: 600;
  padding: 12px;
}
</style>
