<template>
  <div class="home">
    <div class="title">
      <h2 class="display-6 program-font-style">基本動作パターンの土台</h2>
    </div>
    <img src="@/assets/commentary/pattern.png" height="380"/>
    <div class="sapace-style"></div>
    <div class="title">
      <h2 class="display-6 program-font-style">環境</h2>
    </div>
    <img src="@/assets/commentary/forest.jpg" height="230"/>
    <div class="text-style">
      <p class="fw-normal font-style">
        <span class="span-style span-start">人間は環境に適応することで進化を遂げてきた生物</span>
        <span>
          です。気温の変化、地形の違いなど様々な環境の中で狩猟をしたり時には逃げたり、崖を降りて木の実を取ったりなど生きるために環境に適応してきました。温度管理のされたフィットネスクラブでのプログラムされた運動では潜在的な能力を引き出すことができず機能の単一化（退化）が起きてしまいます。
        </span>
      </p>
    </div>
    <div class="text-style">
      <p class="fw-normal font-style">
        <span class="span-style span-start">屋外で自然を感じながら、環境に応じて運動をすることは潜在的な機能を引き出します。</span>
        <span>そして、身体的だけでなく精神的な健康を獲得することができるのです。</span>
      </p>
    </div>

    <div class="sapace-style"></div>
    <div class="title">
      <h2 class="display-6 program-font-style">原始的動作</h2>
    </div>
    <img src="@/assets/commentary/old_man.jpg" height="230"/>
    <div class="text-style">
      <p class="fw-normal font-style">
        <span>
          自然環境の中で食料を調達するために獣を追って槍を投げたり、木に上って木の実を採ったり、住環境を整備するために石を運んだりなど生活をする上で必要でした。このような
        </span>
        <span class="span-style span-end">原始的な動作は人間に本来備わっている能力</span>
        <span>で、現代社会においては不必要となって</span>
        <span class="span-style span-end">体の奥に眠ってしまっています。</span>
        <span>
          マシントレーニングやフリーウェイトとなどのフォーマット化された動作ではなく、原始的な動きを模したトレーニングは根本的な機能向上につながります。
        </span>
      </p>
    </div>

    <div class="sapace-style"></div>
    <div class="title">
      <h2 class="display-6 program-font-style">非特化型</h2>
    </div>
    <img src="@/assets/commentary/swim.jpg" height="230"/>
    <div class="text-style">
      <p class="fw-normal font-style">
        <span>
          人間は他の動物とは違って”走る””泳ぐ”など単一の移動手段に特化せず“登る”“滑る”など多様な移動手段をすることができる非特化型の機能が備わった生物です。四足歩行の動物は速く走ることに特化し、水生物は泳ぐことに特化しましたが、
        </span>
        <span class="span-style span-end">人間は多様な動きができるように進化をしました。</span>
        <span>運動も規則的な動作ではなく多岐にわたって行うことが必要です。</span>
      </p>
    </div>

    <div class="sapace-style"></div>
    <div class="title">
      <h2 class="display-6 program-font-style">変化</h2>
    </div>
    <img src="@/assets/commentary/woman.jpg" height="230"/>
    <div class="text-style">
      <p class="fw-normal font-style">
        <span>
          人間には、刻々と変化する季節と温度、地形や地面の状況など様々な気候や周辺環境に適応する能力を持っています。また、それに対応するために地面を這ったり、水を飛び越えたりなどする多様な運動形態、木を運んだりロープを使って物を引っ張ったりなどツールを使う知恵と手先の器用さといった、生きるために必要な多機能が備わっています。同じ動作を繰り返す現代のエクササイズでは、潜在的な能力を引き出すことができないので、
        </span>
        <span class="span-style span-end">常に多様な変化を与え続けることが必要です。</span>
      </p>
    </div>

    <div class="sapace-style"></div>
    <div class="title">
      <h2 class="display-6 program-font-style">効果的</h2>
    </div>
    <img src="@/assets/commentary/jump_man.jpg" height="230"/>
    <div class="text-style">
      <p class="fw-normal font-style">
        <span class="span-style span-start">適性とは何かをする自然な能力または傾向で、それは自発的で無意識に行われます。</span>
        <span>
          実際には荒削りでまったく巧妙ではない場合があります。丸太を渡る、木を飛び越えるなどの動作は、特定の場所に向かうという目的のために必要な状況であれば誰でも無意識に行います。
        </span>
      </p>
    </div>
    <div class="text-style">
      <p class="fw-normal font-style">
        <span class="span-style span-start">一方、スキルとは何かをうまくやる能力で、高度な適性または専門知識です。</span>
        <span>つまり、正しい丸太の渡り方（バランスの取り方）、木の飛び越え方（ジャンプの仕方）という専門知識にあたります。</span>
        <span class="span-style span-end">効率は適性をスキルに変え、スキルは動きを効率的にします。</span>
        <span>効率から期待できる結果は、パフォーマンスの向上、エネルギーの節約、安全性の向上なのです。</span>
      </p>
    </div>

    <div class="sapace-style"></div>
    <div class="title">
      <h2 class="display-6 program-font-style">実用的</h2>
    </div>
    <img src="@/assets/commentary/pull_rope.jpg" height="230"/>
    <div class="text-style">
      <p class="fw-normal font-style">
        <span>
          走ったり、物を運んだり、引っ張ったりなど自然の中で（延いては現代社会の中で）必要としてきた動きをトレーニングすることが、潜在的な引き出しと運動機能向上につながります。一般的な機能的トレーニングはスポーツ動作や日常動作に対して要素分解したトレーニングであることが多いです。そのため、間接的もしくは基礎的な運動であり、実用的とは少し異なります。
        </span>
        <span class="span-style span-end">太古の昔に必要であった原始的な動きや模倣した動きを直接鍛えることはより実用的と言えます。</span>
      </p>
    </div>

    <div class="sapace-style"></div>
    <div class="title">
      <h2 class="display-6 program-font-style">直感的</h2>
    </div>
    <img src="@/assets/commentary/cat.jpg" height="230"/>
    <div class="text-style">
      <p class="fw-normal font-style">
        <span class="span-style span-start">人間のDNAには太古の昔に生きるために必要であった感覚受容器や運動機能が入力されています。</span>
        <span>
          目的地に行くために乗り越えるべき課題に対して直感的に感じる動きは、人間に予めドライブされた機能で、人間本来の機能を引き出すことにつながります。課題ごとに直感的に感じる動作に取り組むことで潜在的な能力を引き出すことができるのです。
        </span>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "FitnessCampElements",
  props: {}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.title {
  padding-top: 4%;
  text-align: left;
}
.home > img {
  padding-top: 1%;
  width: 96%;
  height: auto;
  min-height: 100px;
}
.home > img::before {
  content: "";
  display: block;
  padding-top: calc(((720 / 1280) * 100%));
}
.program-font-style {
  font-weight: 600;
  color: #000;
}
.text-style {
  padding-top: 2%;
  text-align: left;
  font-size: 14px;
  font-weight: 500;
}
.font-style {
  letter-spacing: 1px;
  line-height: 25px;
  padding-top: 8px;
}
.span-style {
}
.span-start {
  background: linear-gradient(transparent 50%, #fcf49c 0%);
}
.span-end {
  margin-left: 0px;
  margin-right: 0px;
  background: linear-gradient(transparent 50%, #fcf49c 0%);
}
.sapace-style {
  margin-top: 10%;
}

</style>
