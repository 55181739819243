<template>
  <div>
    <div class="page-content" v-if="program_info !== null">
      <div class="input-form">
        <div class="title">
          <h4 class="input-title">プログラム名</h4>
          <div class="mandatory">
            <label>必須</label>
          </div>
        </div>
        <div class="input-group input-group-lg">
          <input
            type="text"
            class="form-control input-content"
            aria-label="Sizing example input"
            aria-describedby="inputGroup-sizing-lg"
            required
            v-model="program_info.name"
          />
        </div>
      </div>
      <div class="input-form">
        <div class="title">
          <h4 class="input-title">プログラム画像</h4>
          <div class="mandatory">
            <label>必須</label>
          </div>
        </div>
        <div class="d-flex justify-content-start img-fiex">
          <ImageListView :event="'4'" />
          <UploadImage
            :event="'1'"
            :file_func="glb_upload_file_func.program_icon"
            :file_type="glb_file_type.program_icon"
            :uploadFileImage="uploadFileIcon"
            @update-icon-click="updateIconClick"
          />
        </div>
      </div>
      <div class="input-form">
        <div class="title">
          <h4 class="input-title">プログラム詳細画像</h4>
          <div class="mandatory">
            <label>必須</label>
          </div>
        </div>
        <div class="d-flex justify-content-start img-fiex">
          <ImageListView :event="'4'" />
          <UploadImage
            :event="'1'"
            :file_func="glb_upload_file_func.program_image"
            :file_type="glb_file_type.program_image"
            :uploadFileImage="uploadFileImage"
            @update-icon-click="updateIconClick"
          />
        </div>
      </div>

      <div
        v-for="(content, index) in program_info.detail_content.detail_list"
        v-bind:key="index"
      >
        <div class="title-card">
          <br />
          <div class="title ">
            <h4 class="input-title">プログラムタイトル</h4>
          </div>
          <div class="input-group input-group-lg">
            <textarea
              rows="3"
              class="form-control input-content"
              aria-label="With textarea"
              v-model="content.title"
            ></textarea>
          </div>
          <br />
          <div class="title ">
            <h4 class="input-title">プログラム説明</h4>
          </div>
          <div class="input-group input-group-lg">
            <textarea
              rows="6"
              class="form-control input-content"
              aria-label="With textarea"
              v-model="content.explanation"
            ></textarea>
          </div>
          <div class="date-fiex">
            <div class="date-button plus-button" @click="plusData(index)">
              <label>追加</label>
            </div>
            <div class="date-button delete-button" @click="deleteData(index)">
              <label>削除</label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="button-class">
      <div class="d-flex justify-content-around">
        <button class="button cancel" @click="doCancel()">キャンセル</button>
        <button class="button confirmation" @click="doConfirmation()">
          確認
        </button>
      </div>
    </div>
  </div>
</template>

<script>
// import firebase from "firebase/compat";
import ImageListView from "@/components/tool/ImageListView";
import UploadImage from "@/components/file/UploadImage";
export default {
  name: "Modal",
  components: {
    ImageListView,
    UploadImage,
  },
  data() {
    return {
      programList: [],
      title: "",
      program_info: {
        name: "",
        detail_image: "",
        detail_content: {
          detail_list: [],
        },
      },
      uploadFileImage: {
        image_name: "",
        image_url: "",
      },
      uploadFileIcon: {
        image_name: "",
        image_url: "",
      },
      program_page: {},
    };
  },
  async created() {
    await this.getProgramInfo();
  },
  methods: {
    doCancel() {
      this.$emit("do-back");
    },
    doConfirmation() {
      this.glb_set_localStorage_new_program_info(this.program_info);
      this.$emit("do-back");
    },
    plusData(index) {
      this.program_info.detail_content.detail_list.splice(index + 1, 0, {});
    },
    deleteData(index) {
      if (this.program_info.detail_content.detail_list.length > 1) {
        this.program_info.detail_content.detail_list.splice(index, 1);
      }
    },
    setFile() {
      this.uploadFileIcon = this.program_info.home_image;
      this.uploadFileImage = this.program_info.detail_image;
    },
    async getProgramInfo() {
      this.program_page = this.glb_program_page;
      let data = await this.glb_get_localStorage_for_program(
        this.mk_program_storage_name
      );
      this.title = data.mk_program_page;
      this.program_info = await this.glb_get_mk_program_info();
      if (this.title == this.glb_program_title.program_name) {
        this.program_page.program_name = true;
      }
      this.setFile();
    },
    async updateIconClick(value) {
      let data = await this.glb_get_localStorage_for_program(
        this.mk_program_storage_name
      );
      if (value == this.glb_upload_file_func.program_icon) {
        let program_icon = data.program_icon_image;
        this.program_info.home_image = program_icon[0];
      } else if (value == this.glb_upload_file_func.program_image) {
        let detail_image = data.program_image_image;
        this.program_info.detail_image = detail_image[0];
      }
      // alert(value);
    },
  },
};
</script>

<style scoped>
.page-content {
  width: 98%;
  height: auto;
  margin: auto;
  overflow: auto;
  padding-bottom: 200px;
  background-color: #fafafa;
}
.input-form {
  margin-left: auto;
  margin-right: auto;
  text-align: left;
  width: 95%;
  padding: 5px;
  margin-top: 10px;
  margin-bottom: 24px;
}
.input-group-text {
  background-color: #ffffff;
  border: none;
  padding-left: 0%;
  font-weight: 600;
  font-size: 16px;
}
.title {
  display: flex;
}
.title .mandatory {
  width: 33px;
  height: 18px;
  margin-left: 4px;
  border-radius: 5px;
  background-color: #970000;
}
.title .mandatory label {
  font-family: Work Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 11px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #ffffff;
  padding-left: 5px;
  padding-top: 1px;
}
.input-title {
  font-weight: 600;
}
.input-content {
  background-color: #ededed;
  border: none;
  outline: none;
  font-size: 16px;
  font-size: 1.5rem;
  color: rgb(0, 0, 0);
  outline: none;
}
input.form-control.input-content {
  font-size: 16px;
}
.img-fiex {
  display: flex;
  flex-wrap: wrap;
}
.title-card {
  width: 95%;
  height: 350px;
  margin: auto;
  margin-top: 24px;
  padding: 10px;
  overflow: auto;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
}
.date-fiex {
  display: flex;
  width: 92%;
  margin-top: 12px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 6px;
}
.date-button {
  width: 60px;
  height: 25px;
  border-radius: 5px;
}
.plus-button {
  padding-top: 4px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
  /* identical to box height */
  letter-spacing: -0.02em;
  background: #ffffff;
  border: 1px solid #c1c1c1;
  box-sizing: border-box;
  border-radius: 5px;
}
.delete-button {
  margin-left: 10px;
  padding-top: 4px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
  /* identical to box height */
  letter-spacing: -0.02em;
  color: #ffffff;
  background: #a0a0a0;
  border-radius: 5px;
}
.button-class {
  width: 100%;
  height: 70px;
  background: #fafafa;
  margin-top: 200px;
  margin-right: auto;
  margin-left: auto;
  position: fixed;
  justify-content: space-between;
  bottom: 70px;
}
.button-class .button {
  margin-top: 16px;
  width: 140px;
  height: 38px;
  border-radius: 6px;
}
.button-class .confirmation {
  background: #1ea6ae;
  font-family: Work Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  letter-spacing: -0.02em;
  border: none;
  color: #ffffff;
}
.button-class .cancel {
  background: #ffffff;
  border: 1px solid #b7b7b7;
  font-family: Work Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #717171;
}
</style>
