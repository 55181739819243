<template>
  <div>
    <div class="heard-style">
      <!-- マイページ -->
      <table class="heard-table" v-if="page === 1">
        <tr>
          <td class="back-icon"></td>
          <td class="heard-title">マイページ</td>
          <td class="heard-menu" @click="doMenu()">
            <img
              class="menu-style"
              src="@/assets/header/メニュー.svg"
              width="25"
              height="25"
            />
          </td>
        </tr>
      </table>

      <!-- ユーザー情報確認更新   src/views/mobile/profile/UserInfoRegister.vue -->
      <table class="heard-table" v-if="page === 2">
        <tr v-if="!doeditVar">
          <td class="back-icon">
            <a href="#/profile">
              <img
                src="@/assets/header/矢印アイコン左4.svg"
                width="20"
                height="20"
              />
            </a>
          </td>
          <td class="heard-title">登録情報</td>
          <td class="heard-menu" @click="doEdit(1)">
            <img
              class="menu-style"
              src="@/assets/header/edit.svg"
              width="20"
              height="20"
            />
          </td>
        </tr>
        <tr v-else>
          <td class="back-icon" @click="doEdit(0)">
            <img
              src="@/assets/header/矢印アイコン左4.svg"
              width="20"
              height="20"
            />
          </td>
          <td class="heard-title">登録情報編集</td>
          <td class="heard-menu"></td>
        </tr>
      </table>

      <!-- プロフィール -->
      <table class="heard-table" v-if="page === 3">
        <tr>
          <td class="back-icon">
            <a href="#/profile">
              <img
                src="@/assets/header/矢印アイコン左4.svg"
                width="20"
                height="20"
              />
            </a>
          </td>
          <td class="heard-title">プロフィール</td>
          <td class="heard-menu" @click="doEdit(2)"></td>
        </tr>
      </table>

      <table class="heard-table" v-if="page === 4">
        <tr v-if="!doeditVar">
          <td class="back-icon">
            <a href="#/profile">
              <img
                src="@/assets/header/矢印アイコン左4.svg"
                width="20"
                height="20"
              />
            </a>
          </td>
          <td class="heard-title">メールアドレス変更</td>
          <td class="heard-menu"></td>
        </tr>
      </table>

      <!-- クラス検索画面ヘッダ OgawaK 2021/12/02 src = × に入れ替え-->
      <table class="heard-table" v-if="page === 5">
        <tr v-if="!doeditVar">
          <td class="back-icon">
            <a href="#/profile">
              <img
                src="@/assets/header/矢印アイコン左4.svg"
                width="20"
                height="20"
              />
            </a>
          </td>
          <td class="heard-title">クラス検索</td>
          <td class="heard-menu"></td>
        </tr>
      </table>

      <!-- クラス履歴-->
      <table class="heard-table" v-if="page === 6">
        <tr v-if="!doeditVar">
          <td class="back-icon">
            <a href="#/profile">
              <img
                src="@/assets/header/矢印アイコン左4.svg"
                width="20"
                height="20"
              />
            </a>
          </td>
          <td class="heard-title">クラス実施履歴</td>
          <td class="heard-menu" @click="doPlus(1)">
            <img
              class="menu-style"
              src="@/assets/header/plus.svg"
              width="28"
              height="28"
            />
          </td>
        </tr>
      </table>

      <table class="heard-table" v-if="page === 7">
        <tr v-if="!doeditVar">
          <td class="back-icon">
            <a href="#/classHistory">
              <img
                src="@/assets/header/矢印アイコン左4.svg"
                width="20"
                height="20"
              />
            </a>
          </td>
          <td class="heard-title">クラス情報</td>
          <td class="heard-menu" @click="doMenu()">
            <img
              class="menu-style"
              src="@/assets/header/メニュー.svg"
              width="25"
              height="25"
            />
          </td>
        </tr>
      </table>

      <!-- 8 -->
      <!-- プログラム作成 -->
      <table class="heard-table" v-if="page === 8">
        <tr>
          <td class="back-icon"></td>
          <td class="heard-title">プログラム作成</td>
          <td class="heard-menu"></td>
        </tr>
      </table>

      <!-- 9 -->
      <!-- プログラム作成 -->
      <table class="heard-table" v-if="page === 9">
        <tr>
          <td class="back-icon">
            <a href="#/search">
              <img
                src="@/assets/header/矢印アイコン左4.svg"
                width="20"
                height="20"
                class="small-image"
              />
            </a>
          </td>
          <td class="heard-title-1">{{ title }}</td>
          <td class="heard-menu"></td>
        </tr>
      </table>

      <!-- 10 -->
      <!-- プログラム名作成サブタイトル -->
      <table class="heard-table" v-if="page === 10">
        <tr>
          <td class="back-icon"></td>
          <td class="heard-title-1">{{ title }}</td>
          <td class="heard-menu"></td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
// 外部ファイル参照
export default {
  name: "Navigation",
  props: {
    page: String,
    title: String,
  },
  // メンバ
  data() {
    return {
      doeditVar: false,
    };
  },
  // 使用メソ
  methods: {
    // サイドパネルOpen
    doMenu() {
      var click = sessionStorage.getItem("menu_click");
      this.$emit("my-click", click);
    },
    doPlus(value) {
      if (value == 1) {
        this.$router.push({
          path: "/classRegister",
        });
      }
    },
    //編集モード
    doEdit(value) {
      this.doeditVar = true;
      this.$emit("edit-click", value);
    },
    //更新モード
    doUpdate() {
      this.$emit("update-click");
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.heard-table {
  width: 100%;
  position: fixed;
  z-index: 3;
  margin-top: 0%;
  width: 100%;
  height: 58px;
  background-color: #04545c;
  border-bottom: 1px solid #cfcfcf;
}
.back-icon {
  width: 20%;
}
.back-icon-1 {
  width: 15%;
}
.heard-title {
  padding-top: 10px;
  width: 60%;
  font-size: 20px;
  font-weight: 900;
  color: #ffffff;
}
.small-image {
  width: 15px;
  height: 15px;
}
.heard-title-1 {
  padding-top: 10px;
  width: 60%;
  font-size: 16px;
  font-weight: 900;
  color: #ffffff;
}
.heard-menu {
  width: 20%;
}

.space-style-1 {
  height: 58px;
}

.slect-div {
  text-align: left;
  padding-left: 5%;
  padding-top: 5%;
}
.slect-style {
  width: 150px;
  height: 35px;
  font-size: 14px;
  background-color: #e2e2e2;
  border-radius: 5px;
  border: none;
  outline: none;
  color: rgb(255, 255, 255);
}
.slect-style :active {
  border: none;
  outline: none;
  color: rgb(255, 255, 255);
}
.slect-style :hover {
  border: none;
  outline: none;
  color: rgb(255, 255, 255);
}
button {
  margin-top: 6px;
  width: 70px;
  height: 28px;
  border: none;
  border-radius: 5px;
  font-size: 14px;
  color: #ffffff;
  font-weight: 600;
  text-align: center;
  background-color: #189f00;
}
</style>
