<template>
  <div>
    <div v-if="clickEvent">
      <MKProgramInputContent
        :index="clickIndex"
        :content_type="content_type"
        @do-back="doBack()"
      />
    </div>
    <div v-else-if="explanationEvent">
      <MKprogramInputExplanation
        :content_type="content_type"
        @do-back="doBack()"
      />
    </div>
    <div v-else>
      <div class="page-content" v-if="program_info !== null">
        <div class="content-card" @click="doExplanation()">
          <div class="d-flex justify-content-between content-card-title">
            <p class="h4 content-card-text">説明</p>
            <div class="title-image">
              <img src="@/assets/mk_program/right.svg" />
            </div>
          </div>
        </div>
        <div
          v-for="(content, index) in program_info.activation.detail_list"
          v-bind:key="index"
        >
          <div class="content-card" @click="doInputContent(index)">
            <div class="d-flex justify-content-between content-card-title">
              <p class="h4 content-card-text">{{ content.title }}</p>
              <div class="title-image">
                <img src="@/assets/mk_program/right.svg" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="button-class">
        <div class="d-flex justify-content-around">
          <button class="button cancel" @click="doCancel()">キャンセル</button>
          <button class="button confirmation" @click="doConfirmation()">
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import firebase from "firebase/compat";
import MKProgramInputContent from "../../../components/mk_program/mk_program_input_content/mk_program_input_content";
import MKprogramInputExplanation from "../../../components/mk_program/mk_program_input_content/mk_program_input_explanation";
export default {
  name: "Modal",
  components: {
    MKProgramInputContent,
    MKprogramInputExplanation,
  },
  data() {
    return {
      programList: [],
      title: "",
      program_info: {
        name: "",
        detail_image: "",
        detail_content: {
          detail_list: [],
        },
        activation: {
          detail_list: [],
        },
      },
      content_type: "",
      clickEvent: false,
      clickIndex: null,
      explanationEvent: false,
    };
  },
  async created() {
    await this.getProgramInfo();
  },
  methods: {
    doBack() {
      this.clickEvent = false;
      this.explanationEvent = false;
      this.getProgramInfoForStorage();
    },
    doInputContent(index) {
      this.clickEvent = true;
      this.clickIndex = index;
    },
    doExplanation() {
      this.explanationEvent = true;
    },
    doCancel() {
      this.$emit("do-back");
    },
    doConfirmation() {
      this.glb_set_localStorage_new_program_info(this.program_info);
      this.$emit("do-back");
    },
    plusData(index) {
      this.program_info.detail_content.detail_list.splice(index + 1, 0, {});
    },
    deleteData(index) {
      if (this.program_info.detail_content.detail_list.length > 1) {
        this.program_info.detail_content.detail_list.splice(index, 1);
      }
    },
    async getProgramInfo() {
      this.content_type = this.glb_program_content.activation;
      this.getProgramInfoForStorage();
    },
    async getProgramInfoForStorage() {
      this.program_info = await this.glb_get_mk_program_info();
    },
  },
};
</script>

<style scoped>
.page-content {
  width: 100%;
  height: auto;
  min-height: 600px;
  margin: auto;
  overflow: auto;
  padding-bottom: 200px;
  /* background-color: #fafafa; */
}
.content-card {
  width: 100%;
  height: 60px;
  margin: auto;
  background: #ffffff;
  border-bottom: 1px solid #969696;
  border-radius: 0px;
}
.content-card-title {
  padding-top: 15px;
}
.content-card-text {
  text-align: left;
  padding-left: 12px;
  font-family: Work Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: -0.02em;
  color: #3f3e3e;
}
.title-image {
  text-align: center;
  margin-right: 25px;
  margin-top: 6px;
}
.title-image img {
  width: 15px;
  height: 15px;
}
.button-class {
  width: 100%;
  height: 70px;
  background: #fafafa;
  margin-top: 200px;
  margin-right: auto;
  margin-left: auto;
  position: fixed;
  justify-content: space-between;
  bottom: 70px;
}
.button-class .button {
  margin-top: 16px;
  width: 140px;
  height: 38px;
  border-radius: 6px;
}
.button-class .confirmation {
  background: #1ea6ae;
  font-family: Work Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  letter-spacing: -0.02em;
  border: none;
  color: #ffffff;
}
.button-class .cancel {
  background: #ffffff;
  border: 1px solid #b7b7b7;
  font-family: Work Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #717171;
}
</style>
