<template>
  <div class="home">
    <div class="title">
      <h2 class="display-6 program-font-style">目的</h2>
    </div>
    <div class="text-style">
      <p class="fw-normal font-style">
        <span class="span-style span-start">
          ダイナミックストレッチの目的は、単に部位的な筋肉のストレッチではなく、様々な動作パターンに対して筋膜の繋がりや力の伝達を感じるようにして動きながらストレッチをすること
        </span>
        <span>
          です。また、重心のコントロールを意識することで固有受容器を刺激し、正しい運動制御（動作コントロール）の習得にもつながります。
        </span>
      </p>
    </div>
    <img src="@/assets/commentary/stretch_woman.jpg" height="250"/>
  </div>
</template>

<script>
export default {
  name: "DynamicMovementAbout",
  props: {}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.title {
  padding-top: 4%;
  text-align: left;
}
.home > img {
  padding-top: 1%;
  width: 96%;
  height: auto;
  min-height: 100px;
}
.home > img::before {
  content: "";
  display: block;
  padding-top: calc(((720 / 1280) * 100%));
}
.program-font-style {
  font-weight: 600;
  color: #000;
}
.text-style {
  padding-top: 2%;
  text-align: left;
  font-size: 14px;
  font-weight: 500;
}
.font-style {
  letter-spacing: 1px;
  line-height: 25px;
  padding-top: 8px;
}
.span-style {
}
.span-start {
  background: linear-gradient(transparent 50%, #fcf49c 0%);
}
.span-end {
  margin-left: -4px;
  margin-right: -4px;
  background: linear-gradient(transparent 50%, #fcf49c 0%);
}
.sapace-style {
  margin-top: 10%;
}

</style>
