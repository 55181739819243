<template>
  <div>
    <Header :page="8" :title="programInfo.name" @my-click="reflectNum" />
    <div v-show="cancel_event">
      <Alert :title="glb_alert_title.mk_program_cancel" @do-alert="doAlert" />
    </div>
    <div v-show="success">
      <SuccessAlert :page="3" @do-alert-cancel="doSuccesAlertCancel" />
    </div>
    <div>
      <div v-if="click_event">
        <!-- モーダル画面： -->
        <MkProgramPage @do-back="doBack" />
      </div>
      <div v-else>
        <div>
          <div class="ft-content">
            <div class="program-div">
              <div
                class="title-div"
                @click="doInputPage(program_title.program_name)"
              >
                <div class="d-flex justify-content-between">
                  <div class="title-font">
                    <p class="h4 title-font-p">
                      {{ program_title.program_name }}
                    </p>
                  </div>
                  <div class="title-image">
                    <img src="@/assets/mk_program/right.svg" />
                  </div>
                </div>
              </div>
              <hr />
              <div
                class="title-div"
                @click="doInputPage(program_title.improvement)"
              >
                <div class="d-flex justify-content-between">
                  <div class="title-font">
                    <p class="h4 title-font-p">
                      {{ program_title.improvement }}
                    </p>
                  </div>
                  <div class="title-image">
                    <img src="@/assets/mk_program/right.svg" />
                  </div>
                </div>
              </div>

              <div
                class="title-div"
                @click="doInputPage(program_title.staticStretch)"
              >
                <div class="d-flex justify-content-between">
                  <div class="title-font">
                    <p class="h4 title-font-p">
                      {{ program_title.staticStretch }}
                    </p>
                  </div>
                  <div class="title-image">
                    <img src="@/assets/mk_program/right.svg" />
                  </div>
                </div>
              </div>
              <div
                class="title-div"
                @click="doInputPage(program_title.activation)"
              >
                <div class="d-flex justify-content-between">
                  <div class="title-font">
                    <p class="h4 title-font-p">
                      {{ program_title.activation }}
                    </p>
                  </div>
                  <div class="title-image">
                    <img src="@/assets/mk_program/right.svg" />
                  </div>
                </div>
              </div>
              <div
                class="title-div"
                @click="doInputPage(program_title.dynamicStretch)"
              >
                <div class="d-flex justify-content-between">
                  <div class="title-font">
                    <p class="h4 title-font-p">
                      {{ program_title.dynamicStretch }}
                    </p>
                  </div>
                  <div class="title-image">
                    <img src="@/assets/mk_program/right.svg" />
                  </div>
                </div>
              </div>
              <div
                class="title-div"
                @click="doInputPage(program_title.movementTraining)"
              >
                <div class="d-flex justify-content-between">
                  <div class="title-font">
                    <p class="h4 title-font-p">
                      {{ program_title.movementTraining }}
                    </p>
                  </div>
                  <div class="title-image">
                    <img src="@/assets/mk_program/right.svg" />
                  </div>
                </div>
              </div>
              <div
                class="title-div"
                @click="doInputPage(program_title.circuittraining)"
              >
                <div class="d-flex justify-content-between">
                  <div class="title-font">
                    <p class="h4 title-font-p">
                      {{ program_title.circuittraining }}
                    </p>
                  </div>
                  <div class="title-image">
                    <img src="@/assets/mk_program/right.svg" />
                  </div>
                </div>
              </div>
              <div
                class="title-div"
                @click="doInputPage(program_title.groupWork)"
              >
                <div class="d-flex justify-content-between">
                  <div class="title-font">
                    <p class="h4 title-font-p">
                      {{ program_title.groupWork }}
                    </p>
                  </div>
                  <div class="title-image">
                    <img src="@/assets/mk_program/right.svg" />
                  </div>
                </div>
              </div>
              <hr />
              <div class="title-div" @click="doInputPage(program_title.status)">
                <div class="d-flex justify-content-between">
                  <div class="title-font">
                    <p class="h4 title-font-p">{{ program_title.status }}</p>
                  </div>
                  <div class="title-image">
                    <img src="@/assets/mk_program/right.svg" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="button-class">
            <div class="d-flex justify-content-around">
              <button class="button cancel" @click="doCancel()">
                キャンセル
              </button>
              <button class="button confirmation" @click="doConfirmation()">
                登録
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="navigation-style">
      <Navigation :page="2" />
    </div>
  </div>
</template>

<script>
import Navigation from "@/components/Navigation";
import Header from "@/components/Header";
import MkProgramPage from "@/components/mk_program/mk_program_page";
import Alert from "@/components/tool/Alert";
import firebase from "firebase/compat";
import SuccessAlert from "@/components/tool/SuccessAlert";
export default {
  name: "Search",
  components: {
    Navigation,
    Header,
    MkProgramPage,
    Alert,
    SuccessAlert,
  },
  data() {
    return {
      const: 0,
      programUid: "",
      programInfo: {},
      program_title: {},
      page: {},
      program_page: {},
      click_event: false,
      cancel_event: false,
      program_info: {
        trainer: "",
      },
      success: false,
    };
  },
  created: function() {
    this.setProgram_title();
    this.getProgramInfo();
  },
  methods: {
    doAlert(event_val) {
      if (event_val) {
        this.$router.push({
          path: "/newProgram",
        });
      } else {
        this.cancel_event = false;
      }
    },
    doCancel() {
      this.cancel_event = true;
    },
    doSuccesAlertCancel() {
      this.success = false;
      this.$router.push({
        path: "/newProgram",
      });
    },
    async doConfirmation() {
      this.program_info = await this.glb_get_mk_program_info();
      var uid = await this.glb_get_user_uid();
      this.mk_program_storage = await this.glb_get_localStorage_for_program(
        this.mk_program_storage_name
      );
      var programId = this.mk_program_storage.mk_program_id;
      let self = this;
      self.program_info.trainer = uid;
      self.program_info.updated_data = await self.glb_get_timestamp_now();
      self.program_info.created_date = await self.glb_get_timestamp_now();
      let user_info = await self.glb_get_user_info(
        await self.glb_get_user_uid()
      );
      if (user_info.authority != self.glb_user_authority.admin) {
        self.program_info.type = self.glb_mk_program_type.informal;
      }
      firebase
        .firestore()
        .collection(self.glb_table.program)
        .doc(programId)
        .set(self.program_info)
        .then(() => {
          self.success = true;
        });
      //プログラム登録
    },
    doBack() {
      this.click_event = false;
    },
    doInputPage(value) {
      this.mk_program_storage = this.glb_get_localStorage_for_program(
        this.mk_program_storage_name
      );
      this.mk_program_storage.mk_program_page = value;
      this.glb_set_localStorage_for_program(
        this.mk_program_storage_name,
        this.mk_program_storage
      );
      this.click_event = true;
    },
    setProgram_title() {
      this.program_title = this.glb_program_title;
      this.program_page = this.glb_program_page;
    },
    async getProgramInfo() {
      let self = this;
      this.mk_program_storage = this.glb_get_localStorage_for_program(
        this.mk_program_storage_name
      );
      self.programUid = this.mk_program_storage.mk_copy_to_program_uid;
      //新規のプログラムIDを作成
      this.mk_program_storage.mk_program_id = await this.glb_get_UUID();
      this.glb_set_localStorage_for_program(
        this.mk_program_storage_name,
        this.mk_program_storage
      );
      firebase
        .firestore()
        .collection(self.glb_table.program)
        .doc(self.programUid)
        .get()
        .then((snap) => {
          self.programInfo = snap.data();
          self.mk_program_storage.mk_copy_program_info = self.programInfo;
          self.glb_set_localStorage_for_program(
            self.mk_program_storage_name,
            self.mk_program_storage
          );
        });
    },
  },
};
</script>
<style scoped>
.ft-content {
  position: absolute;
  width: 100%;
  height: 100vh;
  background-color: rgb(255, 255, 255);
  margin: 0%;
  margin-top: 58px;
  min-height: 700px;
}
.program-div {
  overflow: auto;
  width: 95%;
  height: 100vh;
  min-height: 1000px;
  margin: auto;
  margin-top: 12px;
  margin-bottom: 100px;
}
.title-div {
  width: 100%;
  height: 60px;
  margin: auto;
  margin-bottom: 12px;
  border-radius: 5px;
}
.title-div-1 {
  width: 95%;
  height: 60px;
  margin: auto;
  margin-bottom: 24px;
  background: #fafafa;
  box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  /* background: linear-gradient(90deg, #fde9db 0%, #fafafa 90%); */
  /* background: linear-gradient(
    90deg,
    #f1c47a 0%,
    #f1c47a 5%,
    #fafafa 5%,
    #fafafa 100%
  ); */
}
.title-div-2 {
  width: 95%;
  height: 60px;
  margin: auto;
  margin-bottom: 24px;
  background: #fafafa;
  box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  /* background: linear-gradient(90deg, #d2ffc5 0%, #fafafa 100%); */
  /* background: linear-gradient(
    90deg,
    #f3948e 0%,
    #f3948e 5%,
    #fafafa 5%,
    #fafafa 100%
  ); */
}
.title-font {
  text-align: left;
  padding: 22px 0px 0px 24px;
}
.title-font-p {
  font-weight: bold;
  font-size: 16px;
}
.input-status {
  margin-top: 12px;
  width: 67px;
  height: 26px;
  border-radius: 5px;
  color: #fff;
  font-size: 14px;
  font-weight: bold;
  padding-top: 2px;
  text-align: center;
}
.input-already {
  background: #1ea6ae;
}
.title-image {
  text-align: center;
  margin-top: 22px;
  margin-right: 25px;
}
.title-image img {
  width: 15px;
  height: 15px;
}
.button-class {
  width: 100%;
  height: 70px;
  background: #fafafa;
  margin-top: 200px;
  margin-right: auto;
  margin-left: auto;
  position: fixed;
  justify-content: space-between;
  bottom: 70px;
}
.button-class .button {
  margin-top: 16px;
  width: 140px;
  height: 38px;
  border-radius: 6px;
}
.button-class .confirmation {
  background: #1ea6ae;
  font-family: Work Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  letter-spacing: -0.02em;
  border: none;
  color: #ffffff;
}
.button-class .cancel {
  background: #ffffff;
  border: 1px solid #b7b7b7;
  font-family: Work Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #717171;
}
.cancel_modal {
  z-index: 10;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
}
hr {
  border-top: 1px solid #fff;
  border-bottom: 1px solid #ccc;
  margin: 0%;
}
</style>
