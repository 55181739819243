<template>
  <div class="fullview">
    <div class="loading-div">
      <p class="h4 message-color">
        {{ title }}
      </p>
      <div class="d-flex justify-content-around">
        <button
          type="button"
          class="button-style cancel-button"
          @click="doAlert1()"
        >
          キャンセル
        </button>
        <button
          type="button"
          class="button-style close-button"
          @click="doAlert2()"
        >
          閉じる
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Alert",
  props: {
    title: String,
  },
  data() {
    return {
      event_val: null,
    };
  },
  components: {},
  methods: {
    doAlert1() {
      this.event_val = false;
      this.$emit("do-alert", this.event_val);
    },
    doAlert2() {
      this.event_val = true;
      this.$emit("do-alert", this.event_val);
    },
  },
};
</script>

<style scoped>
.fullview {
  z-index: 10;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  position: fixed;
  top: 0;
  left: 0;
}

.loading-div {
  padding: 10px;
  margin-top: 200px;
  background-color: #ffffff;
  width: 300px;
  height: 200px;
  margin-right: auto;
  margin-left: auto;
  border-radius: 10px;
}
.message-color {
  padding: 20px 20px 20px 20px;
  font-weight: 550;
  color: rgb(47, 48, 47);
  text-align: left;
}
.button-style {
  margin-top: 24px;
  width: 100px;
  height: 41px;
}
.cancel-button {
  background: #1ea6ae;
  border-radius: 6px;
  font-family: Work Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: -0.02em;
  color: #ffffff;
  border: none;
}
.close-button {
  background: #e96a6a;
  border-radius: 6px;
  font-family: Work Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: -0.02em;
  color: #ffffff;
  border: none;
}
</style>
