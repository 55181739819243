import { v4 as uuidv4 } from "uuid";
// Globalパラメータ
export default {
  data() {
    return {
      // 都道府県
      prefecture_options: [
        { text: "都道府県名", value: "0" },
        { text: "北海道", value: "北海道" },
        { text: "青森県", value: "青森県" },
        { text: "岩手県", value: "岩手県" },
        { text: "宮城県", value: "宮城県" },
        { text: "秋田県", value: "秋田県" },
        { text: "山形県", value: "山形県" },
        { text: "福島県", value: "福島県" },
        { text: "茨城県", value: "茨城県" },
        { text: "栃木県", value: "栃木県" },
        { text: "群馬県", value: "群馬県" },
        { text: "埼玉県", value: "埼玉県" },
        { text: "千葉県", value: "千葉県" },
        { text: "東京都", value: "東京都" },
        { text: "神奈川県", value: "神奈川県" },
        { text: "新潟県", value: "新潟県" },
        { text: "富山県", value: "富山県" },
        { text: "石川県", value: "石川県" },
        { text: "福井県", value: "福井県" },
        { text: "山梨県", value: "山梨県" },
        { text: "長野県", value: "長野県" },
        { text: "岐阜県", value: "岐阜県" },
        { text: "静岡県", value: "静岡県" },
        { text: "愛知県", value: "愛知県" },
        { text: "三重県", value: "三重県" },
        { text: "滋賀県", value: "滋賀県" },
        { text: "京都府", value: "京都府" },
        { text: "大阪府", value: "大阪府" },
        { text: "兵庫県", value: "兵庫県" },
        { text: "奈良県", value: "奈良県" },
        { text: "和歌山県", value: "和歌山県" },
        { text: "鳥取県", value: "鳥取県" },
        { text: "島根県", value: "島根県" },
        { text: "岡山県", value: "岡山県" },
        { text: "広島県", value: "広島県" },
        { text: "山口県", value: "山口県" },
        { text: "徳島県", value: "徳島県" },
        { text: "香川県", value: "香川県" },
        { text: "愛媛県", value: "愛媛県" },
        { text: "高知県", value: "高知県" },
        { text: "福岡県", value: "福岡県" },
        { text: "佐賀県", value: "佐賀県" },
        { text: "長崎県", value: "長崎県" },
        { text: "熊本県", value: "熊本県" },
        { text: "大分県", value: "大分県" },
        { text: "宮崎県", value: "宮崎県" },
        { text: "鹿児島県", value: "鹿児島県" },
        { text: "沖縄県", value: "沖縄県" },
      ],
      organize_type: "定期",
      date_select_options: [
        {
          text: "定期",
          value: "定期",
        },
        {
          text: "不定期",
          value: "不定期",
        },
      ],
      date: {
        // 年
        year: [],
        // 月
        month: [],
        // 日
        day: [],
        // 曜日
        week: [],
        // 時間
        time: [],
      },
      //Mixアクセステスト 削除予定
      searchParm: "検索",
    };
  },
  computed: {
    hello: function () {
      return this.greeting + " " + this.world + "!";
    },
  },
  methods: {

    async glb_get_address(value) {
      var zipCode = value.replace("-", "");
      let data = "";
      const payload = {
        address: "",
        zip: zipCode,
      };
      await this.axios
        .get("https://api.zipaddress.net/?", {
          params: { zipcode: payload.zip },
        })
        .then((res) => {
          if (res.data.code == 200) {
            data = res.data.data.pref + res.data.data.city + res.data.data.town;
          }
        })
        .catch(() => {
        });
      return data;
    },
    async glb_get_address_map(value) {
      var zipCode = value.replace("-", "");
      let data = {};
      const payload = {
        address: "",
        zip: zipCode,
      };
      await this.axios
        .get("https://api.zipaddress.net/?", {
          params: { zipcode: payload.zip },
        })
        .then((res) => {
          if (res.data.code == 200) {
            data.pref = res.data.data.pref
            data.city = res.data.data.city
            data.town = res.data.data.town
          }
        })
        .catch(() => {

        });
      return data;
    },
    glb_validete_img(pos) {
      switch (pos) {
        case "jpeg":
        case "jpg":
        case "PNG":
        case "png":
        case "GIF":
        case "gif":
        case "TIFF":
        case "tiff":
        case "WebP":
        case "webp":
        case "SVG":
        case "svg":
          return true;
        default:
          this.userInfo.icon = "";
          return false;
      }
    },
    glb_set_localStorage(key, value) {
      localStorage.setItem(key, value)
    },
    glb_get_localStorage(key) {
      return localStorage.getItem(key)
    },
    glb_delete_localStorage(key) {
      localStorage.removeItem(key)
    },
    glb_set_sessionStorage(key, value) {
      sessionStorage.setItem(key, value)
    },
    glb_get_sessionStorage(key) {
      return sessionStorage.getItem(key)
    },
    glb_get_UUID() {
      return uuidv4();
    },
    glb_set_organize_type() {
      return this.organize_type
    },
    glb_set_organize_options() {
      return this.date_select_options
    },
    glb_get_prefecture_options() {
      return this.prefecture_options;
    },
    glb_check_input_data(value) {
      for (var k in value) {
        if (value[k] == "" || value[k] == null) {
          return false;
        }
      }
      return true;
    },
    glb_check_data(type, value) {
      var resData = null
      if (type == this.glb_input_data_type.number) {
        //数字のみ
        switch (value) {
          case "0":
          case "1":
          case "2":
          case "3":
          case "4":
          case "5":
          case "6":
          case "7":
          case "8":
          case "9":
          case "-":
            resData = true
            break;
          default:
            resData = false
            break;
        }
      }
      return resData

    },
    async glb_set_mk_program() {
      let program_info = {}
      program_info = await this.glb_get_mk_program_info();
      return program_info
    }
  },
};
