<template>
  <div class="home">
    <div class="title">
      <h2 class="display-6 program-font-style">目的</h2>
    </div>
    <div class="text-style">
      <p class="fw-normal font-style">
        <span>グループワークでは、</span>
        <span class="span-style span-end">
          ひと通りのトレーニングの最後に参加者同士で共同作業を行い、
          コミュニケーションの活性化とチーミングを促すことを目指します。
        </span>
      </p>
    </div>
    <div class="text-style">
      <p class="fw-normal font-style">
        <span>
          例えば、公園で開催した場合には、その周辺のゴミ拾いなどが該当します。この部分については、各トレーナーのなかで上記の目的を達成しやすいものであれば構いません
        </span>
      </p>
    </div>
    <img src="@/assets/commentary/sun_set.jpg" height="250"/>
    <div class="text-style">
      <p class="fw-normal font-style">
        <span>
          以上が、FITNESS CAMPの各カテゴリにおける意義を中心としたプログラム構成の説明です。プログラムの一例であるベーシックプログラムを複数回こなしながら要領を掴み、
        </span>
        <span class="span-style span-end">最終的には自身でプログラムを組み立てられるようになりましょう。</span>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "GroupWorkAbout",
  props: {}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.title {
  padding-top: 4%;
  text-align: left;
}
.home > img {
  padding-top: 1%;
  width: 96%;
  height: auto;
  min-height: 100px;
}
.program-font-style {
  font-weight: 600;
  color: #000;
}
.text-style {
  padding-top: 2%;
  text-align: left;
  font-size: 14px;
  font-weight: 500;
}
.font-style {
  letter-spacing: 1px;
  line-height: 25px;
  padding-top: 8px;
}
.span-style {
}
.span-start {
  background: linear-gradient(transparent 50%, #fcf49c 0%);
}
.span-end {
  margin-left: -4px;
  margin-right: -4px;
  background: linear-gradient(transparent 50%, #fcf49c 0%);
}
.sapace-style {
  margin-top: 10%;
}

.home > img::before {
  content: "";
  display: block;
  padding-top: calc(((720 / 1280) * 100%));
}
</style>
