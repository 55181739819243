<template>
  <div class="home">
    <div class="title">
      <h2 class="display-6 program-font-style">目的</h2>
    </div>
    <div class="text-style">
      <p class="fw-normal font-style">
        <span class="span-style span-start">
          ムーブメントトレーニングでは、安定性と操作性の要素を含んだ動作など実用的な動きを習得することを目指します。
        </span>
      </p>
    </div>
    <div class="text-style">
      <p class="fw-normal font-style">
        <span>
          代償動作というのは複数の関節が関わった時、そしてスピードが増した時、もしくは自分自身で重心をコントロールしなくてはいけない時に起こり得ます。そのためムーブメントトレーニングでは、多関節動作、スピード、重心コントロールの3つの要素を中心に構成されています。実際には、シャッフルなどのステップ系の移動動作やベアウォークなどに取り組みます。
        </span>
      </p>
    </div>
    <img src="@/assets/commentary/man_movement_trainig.jpg" height="250"/>
  </div>
</template>

<script>
export default {
  name: "MovementTrainingAbout",
  props: {}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.title {
  padding-top: 4%;
  text-align: left;
}
.home > img {
  padding-top: 1%;
  width: 96%;
  height: auto;
  min-height: 100px;
}
.home > img::before {
  content: "";
  display: block;
  padding-top: calc(((720 / 1280) * 100%));
}
.program-font-style {
  font-weight: 600;
  color: #000;
}
.text-style {
  padding-top: 2%;
  text-align: left;
  font-size: 14px;
  font-weight: 500;
}
.font-style {
  letter-spacing: 1px;
  line-height: 25px;
  padding-top: 8px;
}
.span-style {
}
.span-start {
  background: linear-gradient(transparent 50%, #fcf49c 0%);
}
.span-end {
  margin-left: -4px;
  margin-right: -4px;
  background: linear-gradient(transparent 50%, #fcf49c 0%);
}
.sapace-style {
  margin-top: 10%;
}

</style>
