<template>
  <div>
    <div class="home">
      <div class="about">
        <a href="#" v-on:click.stop.prevent="openAbotModal()" class="alert-link">>>About</a>
      </div>
      <div v-if="isModal">
        <div class="popContainer">
          <div class="heard-style">
            <h2>5.ムーブメントトレーニング</h2>
          </div>
          <div class="card card-modal">
            <div class="card-body">
              <MovementTrainingAbout />
              <br />
              <br />
            </div>
          </div>
          <div class="close-button">
            <div class="close-button-div">
              <a
                href="#"
                v-on:click.stop.prevent="closeAbotModal()"
                class="alert-link close-button-text"
              >閉じる</a>
            </div>
          </div>
        </div>
      </div>

      <div>
        <!-- 説明部分 -->
        <div class="card border-light mb-3 card-style">
          <div class="card-body">
            <p class="card-text title-conten-font">
              ここから動きのトレーニングを行なっていきましょう。
              <br />いろいろな体勢や、テンポの早いスピード、ジャンプなどのダイナミックな動作の中で体をコントロールするトレーニングです。
            </p>
          </div>
        </div>
      </div>

      <div>
        <!-- ビデオ -->
        <div class="card video-div-style">
          <video :src="video_url_1" controls preload="metadata" class="video-style" />
        </div>
        <!-- タイトル -->
        <p class="h4 h4-title">ベアウォーク</p>
        <!-- 説明 -->
        <div class="card border-light mb-3 card-style">
          <div class="card-body">
            <p class="card-text title-conten-font">
              まずはベアウォークです。
              <br />まずは正しい姿勢を作りましょう、正しい姿勢をチェックしましょう。
              <br />爪先を立てて四つ這いの姿勢をとり膝を浮かせます。
              <br />爪先、膝、肩、手の位置は一直線で、首を長く保ちます。
              <br />この姿勢はジムのトレーニングで言えば正しいスクワット動作につながります。
              <br />姿勢をうまく作れたら、
              <br />姿勢を保ったまま、対角の手足を浮かせて前に進みましょう。
              <br />できれば1回1回止まらずに動物をイメージして柔らかく、そして静かに動きましょう。
            </p>
          </div>
        </div>
        <!-- 目的、動作、ポイントtable -->
        <div>
          <table>
            <tr>
              <th>目的</th>
              <td>
                矢上面（前面）の安定性と移動に対する操作性（協調性）
              </td>
            </tr>
            <tr>
              <th>動作</th>
              <td>
                姿勢
                <br />１．足の爪先を立てて膝を浮かし手を突く
                <br />２．足の爪先、膝、肩、手を一直線
                <br />３．顎を引いて肩甲骨を下げる
                <br />４．両手で地面をしっかり押す
                <br />５．背中をまっすぐに保つ動作
                <br />６．対角の手足を同時に前に出し進む
                <br />７．10m行う
              </td>
            </tr>
            <tr>
              <th>ポイント</th>
              <td>
                翼状肩甲にならないよいうにしっかり押す
                <br />動作中も姿勢を崩さない
              </td>
            </tr>
          </table>
        </div>
      </div>

      <div>
        <!-- ビデオ -->
        <div class="card video-div-style">
          <video :src="video_url_2" controls preload="metadata" class="video-style" />
        </div>
        <!-- タイトル -->
        <p class="h4 h4-title">ブリッジウォーク</p>
        <!-- 説明 -->
        <div class="card border-light mb-3 card-style">
          <div class="card-body">
            <p class="card-text title-conten-font">
              次はブリッジウォークです。
              <br />ベアウォークと同じようにまずは正しい姿勢を作りましょう。
              <br />指先は後ろに向けて肩の真下に手をつき首を長く保ちましょう、肩甲骨を引いて胸を開いて
              <br />膝をまげて足をつけて横から見たらMの姿勢を作りましょう。（ヒンジを保って）
              <br />足と膝と股関節は一直線に保ちましょう。
              <br />この姿勢もベアウォーク同様、立ち上がって動作をするときの効果的な股関節と上半身の動作につながります。
              <br />正しいポジションを作れたら、足の方向に動いていきましょう。
            </p>
          </div>
        </div>
        <!-- 目的、動作、ポイントtable -->
        <div>
          <table>
            <tr>
              <th>目的</th>
              <td>
                矢状面（後面）の安定性と移動に対する操作性（協調性）
              </td>
            </tr>
            <tr>
              <th>動作</th>
              <td>
                姿勢
                <br />１．指先は後ろ向ける
                <br />２．顎を引いて背中をまっすぐ肩甲骨を寄せる（横から見たらMの姿勢を作る）
                <br />３．ヒンジを保つ（テーブルトップは股関節を伸展する）
                <br />４．足、股関節、膝は一直線動作
                <br />５．対角の手足を上げて足の方向に動く
                <br />６．10m行う
              </td>
            </tr>
            <tr>
              <th>ポイント</th>
              <td>肩甲骨を引いて胸を広げる</td>
            </tr>
          </table>
        </div>
      </div>
      <div>
        <!-- ビデオ -->
        <div class="card video-div-style">
          <video :src="video_url_3" controls preload="metadata" class="video-style" />
        </div>
        <!-- タイトル -->
        <p class="h4 h4-title">片足アームリーチ</p>
        <!-- 説明 -->
        <div class="card border-light mb-3 card-style">
          <div class="card-body">
            <p class="card-text title-conten-font">
              次にバランス系の動きをいきましょう。
              <br />手を体の横に保ち片足で立ちましょう。
              <br />上半身の意識するポイントはいつも同じで首を長く、足は地面から根っこが生えたように保ちましょう、親指の付け根、小指の付け根、踵で立ちましょう。
              <br />指先は噛まないように伸ばしてリラックスした状態で足の付け根から体を前に倒し、膝と手を真っ直ぐ前に伸ばしましょう。
              <br />親指は上に向けて、足は踵で後ろをけるように
              <br />上げている足を戻して後ろに進んで反対足で繰り返しましょう。
            </p>
          </div>
        </div>
        <!-- 目的、動作、ポイントtable -->
        <div>
          <table>
            <tr>
              <th>目的</th>
              <td>重心、片足の操作性</td>
            </tr>
            <tr>
              <th>動作</th>
              <td>
                １．足首から耳まで一直線
                <br />２．手足を同時に上げる
                <br />３．後ろの踵を蹴り出すように足を伸ばす
                <br />４．後ろ足を地面につけ前足を後ろ足に揃える
                <br />５．交互に繰り返し後方に進む
                <br />６．5m行う（50cmずつくらいしか進めない
              </td>
            </tr>
            <tr>
              <th>ポイント</th>
              <td>骨盤を平行に保つ</td>
            </tr>
          </table>
        </div>
      </div>

      <div>
        <!-- ビデオ -->
        <div class="card video-div-style">
          <video :src="video_url_4" controls preload="metadata" class="video-style" />
        </div>
        <!-- タイトル -->
        <p class="h4 h4-title">サイドステップ</p>
        <!-- 説明 -->
        <div class="card border-light mb-3 card-style">
          <div class="card-body">
            <p class="card-text title-conten-font">
              次は横へのステップです。
              <br />足幅は肩幅くらいで構えお尻を引いて膝をまげてポジションを作ります。
              <br />頭の高さを変えずに横に動きましょう。
              <br />進行方向と逆の足で蹴りながら進みましょう。
            </p>
          </div>
        </div>
        <!-- 目的、動作、ポイントtable -->
        <div>
          <table>
            <tr>
              <th>目的</th>
              <td>
                前額面（横）方向への移動操作性
              </td>
            </tr>
            <tr>
              <th>動作</th>
              <td>
                １．スクワットポジション
                <br />脛と上半身が平行
                <br />ヒンジを作る
                <br />２．トライポッドでバランスをよる
                <br />３．爪先と膝の方向を一直線
                <br />４．進行方向と逆の足で押して進む
                <br />５．10mを2往復行う
              </td>
            </tr>
            <tr>
              <th>ポイント</th>
              <td>スクワットポジションを崩れないようにする</td>
            </tr>
          </table>
        </div>
      </div>

      <div>
        <!-- ビデオ -->
        <div class="card video-div-style">
          <video :src="video_url_5" controls preload="metadata" class="video-style" />
        </div>
        <!-- タイトル -->
        <p class="h4 h4-title">ラテラルフロッグ</p>
        <!-- 説明 -->
        <div class="card border-light mb-3 card-style">
          <div class="card-body">
            <p class="card-text title-conten-font">
              首を長く保ち足を開いて低く構えます。
              <br />両手を横方法に大きく出しましょう。
              <br />両手にしっかりと体重を乗せて体を浮かし、両足を両手の外側に着きましょう。
              <br />その時に、音がしないように柔らかく足を着きましょう。
            </p>
          </div>
        </div>
        <!-- 目的、動作、ポイントtable -->
        <div>
          <table>
            <tr>
              <th>目的</th>
              <td>矢状面、前額面の安定性と操作性</td>
            </tr>
            <tr>
              <th>動作</th>
              <td>
                １．ベアの姿勢から
                <br />２．両手を側方に出して荷重する
                <br />３．荷重した手を軸に体を浮かす
                <br />４．慣性を使って側方へ移動する
                <br />５．5m往復行う
              </td>
            </tr>
            <tr>
              <th>ポイント</th>
              <td>背中のポジションをまっすぐ（ベアのポジションを保つ）</td>
            </tr>
          </table>
        </div>
      </div>

      <div>
        <!-- ビデオ -->
        <div class="card video-div-style">
          <video :src="video_url_6" controls preload="metadata" class="video-style" />
        </div>
        <!-- タイトル -->
        <p class="h4 h4-title">前方スタンディングジャンプ</p>
        <!-- 説明 -->
        <div class="card border-light mb-3 card-style">
          <div class="card-body">
            <p class="card-text title-conten-font">
              腰幅に足を開いて立ちます。
              <br />お尻を引いてしゃがみ込みタイミングよく手を振り上げて前方に飛びましょう。
              <br />接地の時には柔らかく力を吸収するように着地をしましょう。
              <br />着地の時は両手を前に出しながら着地をしましょう。
              <br />ジャンプの瞬間は膝が前に出ますが、着地では前に出ないように、内側に入らないように注意します。
            </p>
          </div>
        </div>
        <!-- 目的、動作、ポイントtable -->
        <div>
          <table>
            <tr>
              <th>目的</th>
              <td>
                全身の連動性と協調性
              </td>
            </tr>
            <tr>
              <th>動作</th>
              <td>
                １．腰幅でたつ
                <br />２．手を引くと同時にスクワットポジション
                <br />３．手を上げると同時に前方に伸び上がる
                <br />４．着地同時に手を前に出し着地する
                <br />５．10m行う
              </td>
            </tr>
            <tr>
              <th>ポイント</th>
              <td>
                飛ぶ時は重心は前方にする
                <br />股関節、膝、足関節を柔らかく着地する
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MovementTrainingAbout from "../commentary/MovementTrainingAbout";
export default {
  name: "MovementTraining",
  components: {
    MovementTrainingAbout
  },
  props: {},
  data() {
    return {
      isModal: false,
      video_url_1:
      "https://firebasestorage.googleapis.com/v0/b/fitnesscamp-428f2.appspot.com/o/program%2Fcontent%2Fmovie%2Fmovement%2Fbear_walk.mp4?alt=media&token=2b3455b3-118c-481f-8134-bef412e5c88a",
      video_url_2:
      "https://firebasestorage.googleapis.com/v0/b/fitnesscamp-428f2.appspot.com/o/program%2Fcontent%2Fmovie%2Fmovement%2Fbridge_walk.mp4?alt=media&token=3c0a8f3d-ff2c-4160-a59b-66a13f5346bb",
      video_url_3:
      "https://firebasestorage.googleapis.com/v0/b/fitnesscamp-428f2.appspot.com/o/program%2Fcontent%2Fmovie%2Fmovement%2Farm_reach%20.mp4?alt=media&token=b49fcc3d-eb97-47ff-88c1-262c65fa6393",
      video_url_4:
      "https://firebasestorage.googleapis.com/v0/b/fitnesscamp-428f2.appspot.com/o/program%2Fcontent%2Fmovie%2Fmovement%2Fside_step.mp4?alt=media&token=c724c9e4-4b25-4ec9-a143-b859411bc2e6",
      video_url_5:
      "https://firebasestorage.googleapis.com/v0/b/fitnesscamp-428f2.appspot.com/o/program%2Fcontent%2Fmovie%2Fmovement%2Flateral_flog.mp4?alt=media&token=74596e75-1562-44b4-8d37-87f36b0d0323",
      video_url_6:
      "https://firebasestorage.googleapis.com/v0/b/fitnesscamp-428f2.appspot.com/o/program%2Fcontent%2Fmovie%2Fmovement%2Fstanding_jumping.mp4?alt=media&token=e251d929-a46f-4d1f-b00e-bad06fce6061",
    };
  },
  methods: {
    openAbotModal() {
      this.isModal = true;
    },
    closeAbotModal() {
      this.isModal = false;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.heard-style {
  padding-top: 14px;
  height: 50px;
  background-color: #04545c;
  border: 0;
  margin-top: 5%;
  margin-left: 8%;
  margin-right: 8%;
}
.heard-style img {
  float: left;
  margin-left: 2%;
}
.heard-style > h2 {
  color: rgb(255, 255, 255);
  margin-right: 4%;
  font-weight: 900;
}
.close-button {
  height: 50px;
  background-color: #ffffff;
  border: 0;
  margin-left: 8%;
  margin-right: 8%;
}
.close-button-div {
  background-color: rgb(255, 255, 255);
  text-align: right;
  margin-right: 4%;
  padding-top: 12px;
}
.close-button-text {
  font-size: 16px;
  font-weight: 400;
  color: rgb(163, 163, 163);
}

.about {
  text-align: right;
  margin-top: 2%;
  margin-bottom: 3%;
}
.about > a:link,
a:visited,
a:hover,
a:active {
  text-decoration: none;
  font-size: 16px;
  font-weight: 400;
  color: rgb(163, 163, 163);
}
.title {
  padding-top: 4%;
  text-align: left;
}

.card-style {
  max-width: 100rem;
  margin: 2%;
  background-color: rgb(248, 248, 248);
}
.title-font {
  color: #000;
  font-weight: 600;
  text-align: left;
}
.title-conten-font {
  color: #000;
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  letter-spacing: 1px;
  line-height: 20px;
}
.video-div-style {
  height: auto;
  width: auto;
  margin-top: 15%;
  margin-left: 2%;
  margin-right: 2%;
  border: 0;
  background-color: rgb(233, 233, 233);
}
.video-style {
  height: 250px;
}
.h4-title {
  margin-top: 5%;
  margin-bottom: 4%;
  margin-left: 2%;
  text-align: left;
  font-weight: 600;
  color: #000;
}
.home > img {
  padding-top: 1%;
  width: 96%;
}
.program-font-style {
  font-weight: 600;
  color: #000;
}
.text-style {
  padding-top: 2%;
  text-align: left;
  font-size: 14px;
  font-weight: 500;
}
.font-style {
  letter-spacing: 2px;
  line-height: 25px;
  padding-top: 8px;
}
.span-style {
  margin-left: -5px;
  margin-right: -5px;
  background: linear-gradient(transparent 50%, #f5f100 0%);
}

/* table */
table {
  margin-top: 8%;
  margin-left: 2%;
  margin-right: 2%;
  width: 96%;
  border-collapse: collapse;
}

table tr {
  width: 30px;
  border-bottom: solid 1px white;
}

table tr:last-child {
  border-bottom: none;
}

table th {
  position: relative;
  text-align: left;
  width: 25%;
  height: auto;
  background-color: #e4ecec;
  color: rgb(0, 0, 0);
  text-align: center;
  padding: 10px 0;
  font-size: 14px;
  font-weight: 400;
}
table td {
  position: relative;
  text-align: left;
  height: auto;
  background-color: rgb(247, 247, 247);
  color: rgb(0, 0, 0);
  text-align: left;
  padding: 10px 10px;
  font-size: 14px;
  font-weight: 400;
}
.popContainer {
  z-index: 2;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(43, 43, 43, 0.3);
}
.card-modal {
  overflow: auto;
  z-index: 2;
  margin-top: 0%;
  margin-left: 8%;
  margin-right: 8%;
  height: 75%;
  background-color: rgb(255, 255, 255);
}
</style>
