<template>
  <div>
    <!-- ヘッダImport部 -->
    <Header :page="6" />
    <div class="history-card">
      <div
        class="change-div"
        v-for="content in classInfoList"
        :key="content.id"
      >
        <table @click="openClassEdit(content.class_uid)">
          <tr>
            <th>
              <div
                class="image_circle"
                :style="{
                  backgroundImage: 'url(' + content.class_icon.image_url + ')',
                }"
              ></div>
            </th>
            <td>
              <!-- 概要タイトル -->
              <div class="card-title">
                <h4>
                  {{ content.class_name }}
                </h4>
              </div>
              <!-- 概要コンテント -->
              <div class="card-content">
                <h5>
                  {{ content.location_address }}
                  <p></p>
                  {{ content.remarks }}
                </h5>
              </div>
              <!-- ステータス -->
              <div class="card-content-status">
                <label
                  v-if="content.inspection === glb_inspection.examined"
                  class="status-1"
                  ><h5>{{ content.inspection }}</h5></label
                >
                <label
                  v-if="content.inspection === glb_inspection.under_review"
                  class="status-2"
                  ><h5>{{ content.inspection }}</h5></label
                >
                <label
                  v-if="content.inspection === glb_inspection.rejected"
                  class="status-3"
                  ><h5>{{ content.inspection }}</h5></label
                >
              </div>
            </td>
          </tr>
        </table>
      </div>
      <br />
      <div class="space-div"></div>
    </div>

    <!-- アンダーナビバーcompo -->
  </div>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from "@/components/HelloWorld.vue";
//import Navigation from "../../../components/Navigation";
import Header from "../../../components/Header";
import firebase from "firebase/compat";

export default {
  name: "ClassHistory",

  //Module
  components: {
    // Navigation,
    Header,
    // ProgramStatic
  },
  //this指定データ
  data() {
    return {
      classInfoList: [],
    };
  },
  async mounted() {
    // this.google = await this.setGoogleConfing();
    // await this.initializeMap();
    await this.getClassInfoList();
  },
  methods: {
    async openClassEdit(class_uid) {
      await this.glb_set_localStorage("classID", class_uid);
      this.$router.push({
        name: "ClassInfoEdit",
        path: "/classInfoEdit",
        props: true,
      });
    },
    async getClassInfoList() {
      const user_uid = await this.glb_get_user_uid();

      let self = this;

      await firebase
        .firestore()
        .collection(self.glb_table.users)
        .doc(user_uid)
        .get()
        .then(async (snap) => {
          let class_history_list = snap.data().class_history;
          // let data_1 = [];
          // let data_2 = [];
          // let data_3 = [];
          // let data_4 = [];
          for (var i in class_history_list) {
            await firebase
              .firestore()
              .collection(self.glb_table.class)
              .doc(class_history_list[i])
              .get()
              .then((snap) => {
                let data = snap.data();
                data.class_uid = class_history_list[i];
                self.classInfoList.push(data);
                // switch (snap.data().inspection) {
                //   case self.glb_inspection.under_review:
                //     data_1.push(snap.data());
                //     break;
                //   case self.glb_inspection.examined:
                //     data_2.push(snap.data());
                //     break;
                //   case self.glb_inspection.rejected:
                //     data_3.push(snap.data());
                //     break;
                //   default:
                //     data_4.push(snap.data());
                //     break;
                // }
              });
          }
          // self.classInfoList = data_1.concat(data_2);
          // self.classInfoList = self.classInfoList.concat(data_3);
          // self.classInfoList = self.classInfoList.concat(data_4);
        });
    },
  },
};
</script>

<style scoped>
/* 修正 */
.history-card {
  position: absolute;
  width: 100%;
  height: auto;
  background-color: #f2f2f2;
  margin: 0%;
  margin-top: 57px;
  margin-bottom: 57px;
  min-height: 700px;
}
.space-div {
  width: 100%;
  height: 40px;
  background-color: #f2f2f2;
}
.change-div {
  /* position: relative; */
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  width: 95%;
  height: 120px;
  background: rgb(255, 255, 255);
  /* background-color: black; */
  border-radius: 15px;
  box-shadow: 3px 6px 9px rgba(0, 0, 0, 0.2);
}
.change-div table {
  width: 100%;
  height: 100%;
}
.change-div table th {
  width: 120px;
  /* background-color: #fff; */
}
.change-div table td {
  position: relative;
}

.change-div table td .card-title {
  position: absolute;
  top: 12px;
  left: 8px;
  text-align: left;
}
.change-div table td .card-content {
  position: absolute;
  top: 45px;
  left: 8px;
  height: 40px;
  width: 98%;
  text-align: left;
  overflow: auto;
}
.change-div table td .card-content-status {
  position: absolute;
  top: 90px;
  left: 8px;
  height: 40px;
  width: 98%;
  text-align: left;
  overflow: auto;
}

.image_circle {
  text-align: center;
  height: 120px;
  width: 120px;
  border-radius: 10%;
  background-position: 54% 36%;
  background-size: cover;
}
.status-1 {
  width: 60px;
  border-radius: 5px;
  padding: 5px 2px 0px 10px;
  background-color: #a1f1b9;
  color: rgb(0, 0, 0);
}
.status-2 {
  width: 60px;
  border-radius: 5px;
  padding: 5px 2px 0px 10px;
  background-color: #fae9b1;
  color: rgb(0, 0, 0);
}
.status-3 {
  width: 60px;
  border-radius: 5px;
  padding: 5px 2px 0px 10px;
  background-color: #ff8383;
  color: rgb(0, 0, 0);
}
</style>
