<template>
  <div>
    <div class="program_static">
      <div class="d-flex justify-content-around flex_box">
        <div
          class="img-style programlist-img-1"
          v-for="content in basicProgramList"
          v-bind:key="content.id"
        >
          <a href="#" v-on:click.stop.prevent="openProgram(content.programUid)">
            <label>
              <img class="basic-img" :src="content.home_image.image_url" />
              <br />
              <div class="programlist-font">
                <h4 class="display-5 program-font-style">
                  {{ content.name }}
                </h4>
              </div>
            </label>
          </a>
        </div>
        <div class="img-style">
          <a href="#/reset_program">
            <label>
              <img src="@/assets/reset_program/reset_program_icon2.png" />
              <br />
              <h4 class="display-5 program-font-style">リセットプログラム</h4>
            </label>
          </a>
        </div>
        <div
          class="img-style programlist-img"
          v-for="content in programList"
          v-bind:key="content.id"
        >
          <a href="#" v-on:click.stop.prevent="openProgram(content.programUid)">
            <label>
              <img :src="content.home_image.image_url" />
              <br />
              <div class="programlist-font">
                <h4 class="display-5 program-font-style">
                  {{ content.name }}
                </h4>
              </div>
            </label>
          </a>
        </div>

        <div class="img-style-1" v-if="authority > '1'">
          <a href="#/kids_program">
            <label>
              <img src="@/assets/kids/kids_icon.png" />
              <br />
              <h4 class="display-5 program-font-style">キッズプログラム</h4>
            </label>
          </a>
        </div>
        <div class="img-style-1" v-else>
          <label>
            <img class="img-gray" src="@/assets/kids/kids_icon.png" />
            <br />
            <h4 class="display-5 program-font-style-gray">キッズプログラム</h4>
          </label>
        </div>
      </div>
    </div>

    <div v-if="authority === '5' || authority === 5">
      <br />
      <br />
      <p class="h4 private-font">
        <ins>下記のプログラムは未公開状態</ins>
      </p>
      <div class="program_static">
        <div class="d-flex justify-content-around flex_box">
          <!-- 未公開のプログラム取得 -->
          <div
            class="img-style programlist-img"
            v-for="content in programListPrivate"
            v-bind:key="content.id"
          >
            <a
              href="#"
              v-on:click.stop.prevent="openProgram(content.programUid)"
            >
              <label>
                <img :src="content.home_image.image_url" />
                <br />
                <div class="programlist-font">
                  <h4 class="display-5 program-font-style">
                    {{ content.name }}
                  </h4>
                </div>
              </label>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "firebase/compat";
export default {
  name: "ProgramStatic",
  props: {},
  data() {
    return {
      programList: [],
      basicProgramList: [],
      programListPrivate: [],
      authority: "",
    };
  },
  created: function() {
    this.getUserAuthority();
    this.getProgramList();
  },
  methods: {
    getUserAuthority() {
      let self = this;
      firebase.auth().onAuthStateChanged(function(user) {
        firebase
          .firestore()
          .collection(self.glb_table.users)
          .doc(user.uid)
          .get()
          .then((snap) => {
            self.authority = snap.data().authority;
          });
      });
    },
    getProgramList() {
      localStorage.removeItem("program_uid");
      let self = this;
      firebase
        .firestore()
        .collection(self.glb_table.program)
        .get()
        .then(function(querySnapshot) {
          querySnapshot.forEach(function(doc) {
            firebase
              .firestore()
              .collection(self.glb_table.program)
              .doc(doc.id)
              .get()
              .then((snap) => {
                if (snap.data().name !== "ベーシック") {
                  if (
                    snap.data().status == "公開" &&
                    snap.data().type == self.glb_mk_program_type.official
                  ) {
                    self.programList.push({
                      programUid: doc.id,
                      name: snap.data().name,
                      home_image: snap.data().home_image,
                    });
                  }
                  if (
                    snap.data().status == "未公開" &&
                    snap.data().type == self.glb_mk_program_type.official
                  ) {
                    self.programListPrivate.push({
                      programUid: doc.id,
                      name: snap.data().name,
                      home_image: snap.data().home_image,
                    });
                  }
                } else {
                  self.basicProgramList.push({
                    programUid: doc.id,
                    name: snap.data().name,
                    home_image: snap.data().home_image,
                  });
                }
              });
          });
        });
    },
    openProgram(programUid) {
      localStorage.setItem("program_uid", programUid);
      this.$router.push({ name: "Program" });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.program_static {
  padding-top: 10%;
}
.img-style {
  width: 50%;
  margin-right: auto;
}
.img-style-1 {
  width: 50%;
  margin-right: auto;
}
.img-style img {
  width: 135px;
  height: 135px;
}
.img-style-1 img {
  width: 130px;
  height: 130px;
}
.img-gray {
  /* filter: opacity(20%); */
  filter: grayscale(80%);
}

.program-font-style {
  font-weight: 600;
}
.program-font-style-gray {
  font-weight: 600;
  color: rgb(129, 129, 129);
}
.programlist-font {
  margin-top: 5px;
}
a:link,
a:visited,
a:hover,
a:active {
  color: rgb(0, 0, 0);
}
.programlist-img {
  padding-top: 6px;
}
.programlist-img img {
  width: 120px;
  height: 120px;
  border-radius: 5px;
}
.programlist-img-1 img {
  width: 130px;
  height: 130px;
  border-radius: 5px;
}
.basic-img {
  width: 150px;
  height: 150px;
}
.flex_box {
  display: flex;
  flex-wrap: wrap;
}
.private-font {
  color: rgb(172, 172, 172);
  font-weight: 600;
}
.img-gray {
  /* background-color: black; */
  background: rgba(211, 211, 211, 0.7); /* 半透明の背景。 */
}
</style>
