<template>
  <div class="home">
    <div class="title">
      <h2 class="display-6 program-font-style">基本的な流れ</h2>
    </div>
    <img src="@/assets/commentary/stream.png" height="500"/>
    <div class="text-style">
      <p class="fw-normal font-style">
        <span>
          プログラムは上記の1カテゴリを順番に行っていく構成になっております。この基本的な流れをまずは覚えるようにしてください。
        </span>
        <span class="span-style span-end">どのプログラムも共通して、この基本的な流れに沿って進めていきます。</span>
      </p>
    </div>

    <div class="text-style">
      <p class="fw-normal font-style">
        <span class="span-style span-start">プログラム1回あたりの所要時間は約45～60分となっております。</span>
        <span>
          必要に応じてミニバンドなどの携帯が容易な道具を利用した種目も織り交ぜる場合があります。プログラムの実施場所は公園などの自然が感じられる場所を想定しております。公園で実施する際は、しっかりと利用許可を取得する必要がありますのでご注意ください。
        </span>
      </p>
    </div>

    <div class="text-style">
      <p class="fw-normal font-style">
        <span>それでは、各カテゴリの具体的な内容について説明していきます。</span>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "FitnessCampConstitution",
  props: {}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.title {
  padding-top: 4%;
  text-align: left;
}
.home > img {
  padding-top: 1%;
  width: 96%;
  height: auto;
  min-height: 100px;
}
.home > img::before {
  content: "";
  display: block;
  padding-top: calc(((720 / 1280) * 100%));
}
.program-font-style {
  font-weight: 600;
  color: #000;
}
.text-style {
  padding-top: 2%;
  text-align: left;
  font-size: 14px;
  font-weight: 500;
}
.font-style {
  letter-spacing: 1px;
  line-height: 25px;
  padding-top: 8px;
}
.span-style {
}
.span-start {
  background: linear-gradient(transparent 50%, #fcf49c 0%);
}
.span-end {
  margin-left: -4px;
  margin-right: -4px;
  background: linear-gradient(transparent 50%, #fcf49c 0%);
}
.sapace-style {
  margin-top: 10%;
}

</style>
