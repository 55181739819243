<template>
  <div>
    <div class="home">
      <div class="about">
        <a href="#" v-on:click.stop.prevent="openAbotModal()" class="alert-link">>>About</a>
      </div>
      <div v-if="isModal">
        <div class="popContainer">
          <div class="heard-style">
            <h2>1.呼吸/アライメント改善</h2>
          </div>
          <div class="card card-modal">
            <div class="card-body">
              <ImprovementAbout />
            </div>
          </div>
          <div class="close-button">
            <div class="close-button-div">
              <a
                href="#"
                v-on:click.stop.prevent="closeAbotModal()"
                class="alert-link close-button-text"
              >閉じる</a>
            </div>
          </div>
        </div>
      </div>

      <div>
        <!-- 説明部分 -->
        <div class="card border-light mb-3 card-style">
          <div class="card-body">
            <p class="card-text title-conten-font">
              まずは4つの姿勢で腹式呼吸をして柔らかい状態を作りましょう。
              <br />正しい呼吸は柔軟性にも影響を与えますが、運動動作中の安定感や日常生活での姿勢改善にもつながります。
            </p>
          </div>
        </div>
      </div>

      <div>
        <!-- ビデオ -->
        <div class="card video-div-style">
          <video :src="video_url_1" controls preload="metadata" class="video-style" />
        </div>
        <!-- タイトル -->
        <p class="h4 h4-title">スタンディングブリージング（立位呼吸）</p>
        <!-- 説明 -->
        <div class="card border-light mb-3 card-style">
          <div class="card-body">
            <p class="card-text title-conten-font">
              まずは真っ直ぐ立った姿勢でお腹を膨らせる呼吸をしましょう。
              <br />首を長くした姿勢でお腹を360°膨らませるように、ズボンの紐を広げるように、
              <br />3秒かけて鼻から息を吸って、2秒止めて、5秒かけて口からゆっくり吐きます。
              <br />
            </p>
          </div>
        </div>
        <!-- 目的、動作、ポイントtable -->
        <div>
          <table>
            <tr>
              <th>目的</th>
              <td>基本の腹式呼吸（腹圧の高まり）を3面で感じる</td>
            </tr>
            <tr>
              <th>動作</th>
              <td>
                １．正しい姿勢で目線は正面、足の幅は腰幅で立つ
                <br />２．人差し指は12肋骨前方下部、中指はASIS内側、母指は12肋骨後方下部を触る
                <br />３．3秒かけて鼻から息を吸ってお腹を膨らませる
                <br />４．腹圧が高まったところで2秒止める
                <br />５．5秒かけて口から息を吐く
                <br />６．吸気で腹圧の高まりを感じ呼気で横隔膜の弛緩を感じる
                <br />７．3-5回行う
                <br />
              </td>
            </tr>
            <tr>
              <th>ポイント</th>
              <td>
                肩や首、全身に余分な力が入らないようにする
                <br />吸気では下位肋骨の挙上、胸椎の過伸展に注意する
              </td>
            </tr>
          </table>
        </div>
      </div>

      <div>
        <!-- ビデオ -->
        <div class="card video-div-style">
          <video :src="video_url_2" controls preload="metadata" class="video-style" />
        </div>
        <!-- タイトル -->
        <p class="h4 h4-title">ラテラルブリージング（側屈呼吸）</p>
        <!-- 説明 -->
        <div class="card border-light mb-3 card-style">
          <div class="card-body">
            <p class="card-text title-conten-font">
              次に体を横に倒して呼吸をしましょう。
              <br />目線は上を向けて
              <br />体を開いて伸びている場所に空気を送り込むようにお腹を膨らましましょう。
              <br />3秒かけて鼻から息を吸って、2秒止めて、5秒かけて口からゆっくり吐きます。
            </p>
          </div>
        </div>
        <!-- 目的、動作、ポイントtable -->
        <div>
          <table>
            <tr>
              <th>目的</th>
              <td>前額面の横隔膜の動きを意識する／肋間筋など側面の弛緩</td>
            </tr>
            <tr>
              <th>動作</th>
              <td>
                １．正しい姿勢で立ち手を組んで掌を上に向けて挙上する
                <br />２．体幹を側屈し目線を上を向いて側腹部の伸張を感じる
                <br />３．3秒かけて鼻から息を吸ってお腹を膨らませる
                <br />４．腹圧が高まったところで2秒止める
                <br />５．5秒かけて口から息を吐く
                <br />６．吸気で腹圧の高まりを感じ呼気で横隔膜の弛緩を感じる
                <br />７．3-5回行う
                <br />
              </td>
            </tr>
            <tr>
              <th>ポイント</th>
              <td>
                目線が下がると円背し胸郭が下がるので注意する
                <br />吸気では側腹部と肋間筋の伸びと腹圧を感じる
                <br />吸気では側腹部と肋間筋の伸びと腹圧を感じる
              </td>
            </tr>
          </table>
        </div>
      </div>
      <div>
        <!-- ビデオ -->
        <div class="card video-div-style">
          <video :src="video_url_3" controls preload="metadata" class="video-style" />
        </div>
        <!-- タイトル -->
        <p class="h4 h4-title">フレクションブリージング（側屈呼吸）</p>
        <!-- 説明 -->
        <div class="card border-light mb-3 card-style">
          <div class="card-body">
            <p class="card-text title-conten-font">
              次に背中を丸めて呼吸をしましょう。
              <br />背中のストレッチを感じている部分に空気を送り込むように／背中にコブができるように息を吸いましょう。
              <br />3秒かけて鼻から息を吸って、2秒止めて、5秒かけて口からゆっくり吐きます。
              <br />
            </p>
          </div>
        </div>
        <!-- 目的、動作、ポイントtable -->
        <div>
          <table>
            <tr>
              <th>目的</th>
              <td>後方の横隔膜の動きを意識する／背部の筋群の弛緩</td>
            </tr>
            <tr>
              <th>動作</th>
              <td>
                １．正しい姿勢で立ち手を前で組んで掌を内側に向ける
                <br />２．手を前に突き出し脊柱を屈曲させる
                <br />３．3秒かけて鼻から息を吸ってお腹を膨らませる
                <br />４．腹圧が高まったところで2秒止める
                <br />５．5秒かけて口から息を吐く
                <br />６．吸気で特に後方の腹圧の高まりと腰背部の伸張および弛緩を感じる
                <br />７．3-5回行う
                <br />
              </td>
            </tr>
            <tr>
              <th>ポイント</th>
              <td>吸気時に肩甲骨の自然な外転を感じる</td>
            </tr>
          </table>
        </div>
      </div>

      <div>
        <!-- ビデオ -->
        <div class="card video-div-style">
          <video :src="video_url_4" controls preload="metadata" class="video-style" />
        </div>
        <!-- タイトル -->
        <p class="h4 h4-title">ローテーションブリージング（回旋呼吸）</p>
        <!-- 説明 -->
        <div class="card border-light mb-3 card-style">
          <div class="card-body">
            <p class="card-text title-conten-font">
              前後に足を広げて万歳をして体を横に倒しましょう。
              <br />首を長くした状態でストレッチされている場所に空気を送り込むように
              <br />3秒かけて鼻から息を吸って、2秒止めて、5秒かけて口からゆっくり吐きます
              <br />
            </p>
          </div>
        </div>
        <!-- 目的、動作、ポイントtable -->
        <div>
          <table>
            <tr>
              <th>目的</th>
              <td>呼吸機能の向上／回旋筋の弛緩</td>
            </tr>
            <tr>
              <th>動作</th>
              <td>
                １．前後に足を開いて手を挙上する
                <br />２．前に出した足の方向へ側屈し回旋する
                <br />３．3秒かけて鼻から息を吸ってお腹を膨らませる
                <br />４．腹圧が高まったところで2秒止める
                <br />５．5秒かけて口から息を吐く
                <br />６．余分な力が入らないように腹部の伸張を感じながら横隔膜の動きを感じる
                <br />７．3-5回行う
                <br />
              </td>
            </tr>
            <tr>
              <th>ポイント</th>
              <td>目線は上を向き胸郭が下がらないように注意する</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ImprovementAbout from "../commentary/ImprovementAbout";
export default {
  name: "Improvement",
  components: {
    ImprovementAbout
  },
  props: {},
  data() {
    return {
      isModal: false,
      video_url_1:
        "https://firebasestorage.googleapis.com/v0/b/fitnesscamp-428f2.appspot.com/o/program%2Fcontent%2Fmovie%2Fimprovement%2Fstanding_breathing.mp4?alt=media&token=ca71e44b-1f10-4a9c-868c-36d89762b3f8",
      video_url_2:
        "https://firebasestorage.googleapis.com/v0/b/fitnesscamp-428f2.appspot.com/o/program%2Fcontent%2Fmovie%2Fimprovement%2Flateral_breathing.mp4?alt=media&token=8c0eac3a-b5bb-40ca-a2ac-8ef68c0e0dd1",
      video_url_3:
        "https://firebasestorage.googleapis.com/v0/b/fitnesscamp-428f2.appspot.com/o/program%2Fcontent%2Fmovie%2Fimprovement%2Ffriction_breathing.mp4?alt=media&token=a50db3c1-9138-4b7a-8f89-1aa1e366f26e",
      video_url_4:
        "https://firebasestorage.googleapis.com/v0/b/fitnesscamp-428f2.appspot.com/o/program%2Fcontent%2Fmovie%2Fimprovement%2F%E3%83%AD%E3%83%BC%E3%83%86%E3%83%B3%E3%82%B7%E3%83%A7%E3%83%B3%E3%83%95%E3%82%99%E3%83%AA%E3%83%BC%E3%82%B7%E3%82%99%E3%83%B3%E3%82%AF%E3%82%99.mp4?alt=media&token=f5f12f24-dec8-4780-9280-3ec1f86b9020"
    };
  },
  methods: {
    openAbotModal() {
      this.isModal = true;
    },
    closeAbotModal() {
      this.isModal = false;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.heard-style {
  padding-top: 14px;
  height: 50px;
  background-color: #04545c;
  border: 0;
  margin-top: 5%;
  margin-left: 8%;
  margin-right: 8%;
}
.heard-style img {
  float: left;
  margin-left: 2%;
}
.heard-style > h2 {
  color: rgb(255, 255, 255);
  margin-right: 4%;
  font-weight: 900;
}
.close-button {
  height: 50px;
  background-color: #ffffff;
  border: 0;
  margin-left: 8%;
  margin-right: 8%;
}
.close-button-div {
  background-color: rgb(255, 255, 255);
  text-align: right;
  margin-right: 4%;
  padding-top: 12px;
}
.close-button-text {
  font-size: 16px;
  font-weight: 400;
  color: rgb(163, 163, 163);
}

.about {
  text-align: right;
  margin-top: 2%;
  margin-bottom: 3%;
}
.about > a:link,
a:visited,
a:hover,
a:active {
  text-decoration: none;
  font-size: 16px;
  font-weight: 400;
  color: rgb(163, 163, 163);
}
.title {
  padding-top: 4%;
  text-align: left;
}

.card-style {
  max-width: 100rem;
  margin: 2%;
  background-color: rgb(248, 248, 248);
}
.title-font {
  color: #000;
  font-weight: 600;
  text-align: left;
}
.title-conten-font {
  color: #000;
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  letter-spacing: 1px;
  line-height: 20px;
}
.video-div-style {
  height: auto;
  width: auto;
  margin-top: 15%;
  margin-left: 2%;
  margin-right: 2%;
  border: 0;
  background-color: rgb(233, 233, 233);
}
.video-style {
  height: 250px;
}
.h4-title {
  margin-top: 5%;
  margin-bottom: 4%;
  margin-left: 2%;
  text-align: left;
  font-weight: 600;
  color: #000;
}
.home > img {
  padding-top: 1%;
  width: 96%;
}
.program-font-style {
  font-weight: 600;
  color: #000;
}
.text-style {
  padding-top: 2%;
  text-align: left;
  font-size: 14px;
  font-weight: 500;
}
.font-style {
  letter-spacing: 2px;
  line-height: 25px;
  padding-top: 8px;
}
.span-style {
  margin-left: -5px;
  margin-right: -5px;
  background: linear-gradient(transparent 50%, #f5f100 0%);
}

/* table */
table {
  margin-top: 8%;
  margin-left: 2%;
  margin-right: 2%;
  width: 96%;
  border-collapse: collapse;
}

table tr {
  width: 30px;
  border-bottom: solid 1px white;
}

table tr:last-child {
  border-bottom: none;
}

table th {
  position: relative;
  text-align: left;
  width: 25%;
  height: auto;
  background-color: #e4ecec;
  color: rgb(0, 0, 0);
  text-align: center;
  padding: 10px 0;
  font-size: 14px;
  font-weight: 400;
}
table td {
  position: relative;
  text-align: left;
  height: auto;
  background-color: rgb(247, 247, 247);
  color: rgb(0, 0, 0);
  text-align: left;
  padding: 10px 10px;
  font-size: 14px;
  font-weight: 400;
}
.popContainer {
  z-index: 2;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(43, 43, 43, 0.3);
}
.card-modal {
  overflow: auto;
  z-index: 2;
  margin-top: 0%;
  margin-left: 8%;
  margin-right: 8%;
  height: 75%;
  background-color: rgb(255, 255, 255);
}
</style>
