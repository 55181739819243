<template>
  <div class="map-div">
    <div>
      <nav class="navbar navbar-light bg-light nav-div" v-show="!searcgModal">
        <div class="search">
          <button
            type="button"
            class="btn btn-light search-button"
            @click="openModal()"
          >
            検索してみましょうか？
          </button>
        </div>
        <div class="tab-wrap">
          <input
            id="TAB-01"
            type="radio"
            name="TAB"
            class="tab-switch"
            checked="checked"
          />
          <label class="tab-label" for="TAB-01" @click="changeTab(0)"
            >クラス</label
          >
          <input id="TAB-03" type="radio" name="TAB" class="tab-switch" />
          <label class="tab-label" for="TAB-03" @click="changeTab(1)"
            >拠点</label
          >
        </div>
        <router-link to="/classRegister">
          <div>クラス申請</div>
        </router-link>
      </nav>
    </div>
    <div v-if="mapTab == 0">
      <div v-show="listModal" class="list-modal-page">
        <ClassList @do-back-map="doBackMap" />
      </div>
    </div>

    <div v-if="mapTab == 1">
      <!-- ページ下部のリストクリック時 Openする -->
      <div v-show="listModal" class="list-modal-page">
        <PointList @do-back-map="doBackMap" />
      </div>
    </div>

    <!-- 検索Modal画面遷移 -->
    <div id="overlay" v-show="searcgModal" class="search-modal-page">
      <ClassSearch @closeModal="closeModal" />
    </div>

    <div v-if="mapTab == 1">
      <PointMap />
    </div>
    <div v-else>
      <ClassMap />
    </div>

    <div v-if="mapTab == 0">
      <div class="class-list-div" @click="openList(0)">
        <br />
        <h4>
          クラスリスト一覧
        </h4>
      </div>
    </div>

    <div v-if="mapTab == 1">
      <div class="class-list-div" @click="openList(1)">
        <br />
        <h4>
          拠点リスト一覧
        </h4>
      </div>
    </div>
  </div>
</template>

<script>
// クラスリスト
import ClassList from "../../components/class/class_list";
// 拠点リスト
import PointList from "../../components/class/point_list";
// クラス検索時
import ClassMap from "../../components/class/class_map";
// 拠点検索時
import PointMap from "../../components/class/point_map.vue";
// クラス検索画面 Mapページ上部 検索してみましょうか？ 押下時
import ClassSearch from "../../components/class/modal/class_search.vue";

export default {
  name: "Map",
  components: {
    // 各List
    ClassList,
    PointList,
    // クラス、拠点 地図
    ClassMap,
    PointMap,
    // 検索画面
    ClassSearch,
  },
  data() {
    return {
      listModal: false,
      searcgModal: false,
      mapTab: 0,
    };
  },
  methods: {
    openModal: function() {
      this.searcgModal = true;
    },
    // クラス、拠点 tab切り替え
    changeTab(val) {
      this.mapTab = val;
    },
    // モーダルDel
    closeModal(value) {
      this.searcgModal = value;
    },

    doSearch() {
      // 検索実行処理
    },
    openList() {
      this.listModal = true;
    },
    doBackMap(value) {
      if (value) {
        this.listModal = !value;
      }
    },
  },
};
</script>

<style scoped>
.map-div {
  height: 100%;
}
.nav-div {
  width: 100%;
  height: 120px;
  left: 0px;
  top: 0px;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.search {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  border: none;
  outline: #fff;
}
.search-button {
  margin-left: auto;
  margin-right: auto;
  height: 46px;
  width: 95%;
  background: #eeeeee;
  border-radius: 20px;
  font-size: 16px;
  outline: #fff;
  border: none !important;
  color: #939393;
  text-align: left;
  padding-left: 20px;
}
.search-button :active {
  color: #939393;
}

.map {
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: 90vh;
}
.tab-wrap {
  margin-top: 5px;
  flex-wrap: wrap;
  overflow: hidden;
  padding: 0 0 20px;
}

.tab-label {
  color: Gray;
  cursor: pointer;
  flex: -1;
  font-weight: bold;
  order: 1;
  padding: 12px 24px;
  position: relative;
  text-align: center;
  transition: cubic-bezier(0.4, 0, 0.2, 1) 0.2s;
  user-select: none;
  white-space: nowrap;
  -webkit-tap-highlight-color: transparent;
  font-size: 16px;
}

.tab-label:hover {
  background: rgba(255, 255, 255, 0.1);
}

.tab-switch:checked + .tab-label {
  color: #6b6b6b;
}

.tab-label::after {
  background: #6b6b6b;
  bottom: 0;
  content: "";
  display: block;
  height: 3px;
  left: 0;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  transform: translateX(100%);
  transition: cubic-bezier(0.4, 0, 0.2, 1) 0.2s 80ms;
  width: 100%;
  z-index: 1;
}

.tab-switch:checked ~ .tab-label::after {
  transform: translateX(-100%);
}

.tab-switch:checked + .tab-label::after {
  opacity: 1;
  transform: translateX(0);
}

.tab-content {
  height: 0;
  opacity: 0;
  padding: 0 20px;
  pointer-events: none;
  transform: translateX(-30%);
  transition: transform 0.3s 80ms, opacity 0.3s 80ms;
  width: 100%;
}

.tab-switch:checked ~ .tab-content {
  transform: translateX(30%);
}

.tab-switch:checked + .tab-label + .tab-content {
  height: auto;
  opacity: 1;
  order: 1;
  pointer-events: auto;
  transform: translateX(0);
}

.tab-wrap::after {
  content: "";
  height: 20px;
  order: -1;
  width: 100%;
}

.tab-switch {
  display: none;
}
.class-list-div {
  position: relative;
  top: 40px;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  height: 130px;
  background-color: #ffffff;
  border-radius: 8% 8% 0% 0%;
  color: rgb(0, 0, 0);
}
.list-modal-page {
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100vh;
  animation: fadeIn 0.5s ease 0.1s 1 normal backwards;
  background-color: #ffffff;
}
.search-modal-page {
  position: absolute;
  z-index: 2;
  width: 100%;
  height: 100vh;
  animation: fadeIn 0.5s ease 0.1s 1 normal backwards;
  background-color: #ffffff;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(600px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>
