<template>
  <div>
    <div v-if="clickEvent">
      <MKProgramInputContent
        :index="clickIndex"
        :content_type="content_type"
        @do-back="doBack()"
      />
    </div>
    <div v-else>
      <div class="page-content" v-if="program_info !== null">
        <div
          v-for="(content, index) in program_info.groupWork.contentList"
          v-bind:key="index"
        >
          <br />
          <div class="content-card">
            <div class="input-form">
              <div class="title ">
                <h4 class="input-title">タイトル</h4>
              </div>
              <div class="input-group input-group-lg">
                <textarea
                  rows="8"
                  class="form-control input-content"
                  aria-label="With textarea"
                  v-model="content.content"
                ></textarea>
              </div>
            </div>
            <div class="date-fiex">
              <div class="date-button plus-button" @click="plusData(index)">
                <label>追加</label>
              </div>
              <div class="date-button delete-button" @click="deleteData(index)">
                <label>削除</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="button-class">
        <div class="d-flex justify-content-around">
          <button class="button cancel" @click="doCancel()">キャンセル</button>
          <button class="button confirmation" @click="doConfirmation()">
            確認
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import firebase from "firebase/compat";
import MKProgramInputContent from "../../../components/mk_program/mk_program_input_content/mk_program_input_content";
export default {
  name: "Modal",
  components: {
    MKProgramInputContent,
  },
  data() {
    return {
      programList: [],
      title: "",
      program_info: {
        name: "",
        detail_image: "",
        detail_content: {
          contentList: [],
        },
        groupWork: {
          detail_list: [],
        },
      },
      content_type: "",
      clickEvent: false,
      clickIndex: null,
    };
  },
  async created() {
    await this.getProgramInfo();
  },
  methods: {
    plusData(index) {
      this.program_info.groupWork.contentList.splice(index + 1, 0, {
        content: "",
      });
    },
    deleteData(index) {
      if (this.program_info.groupWork.contentList.length > 1) {
        this.program_info.groupWork.contentList.splice(index, 1);
      }
    },
    doBack() {
      this.clickEvent = false;
      this.getProgramInfoForStorage();
    },
    doInputContent(index) {
      this.clickEvent = true;
      this.clickIndex = index;
    },
    doCancel() {
      this.$emit("do-back");
    },
    doConfirmation() {
      this.glb_set_localStorage_new_program_info(this.program_info);
      this.$emit("do-back");
    },
    async getProgramInfo() {
      this.content_type = this.glb_program_content.groupWork;
      this.getProgramInfoForStorage();

      // alert(this.content_type);
    },
    async getProgramInfoForStorage() {
      this.program_info = await this.glb_get_mk_program_info();
    },
  },
};
</script>

<style scoped>
.page-content {
  width: 100%;
  height: auto;
  min-height: 600px;
  margin: auto;
  overflow: auto;
  padding-bottom: 200px;
  /* background-color: #fafafa; */
}
.content-card {
  width: 95%;
  height: 280px;
  overflow: auto;
  margin: auto;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
}
.content-card-title {
  padding-top: 15px;
}
.content-card-text {
  text-align: left;
  padding-left: 12px;
  font-family: Work Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: -0.02em;
  color: #3f3e3e;
}
.title-image {
  text-align: center;
  margin-right: 25px;
  margin-top: 6px;
}
.title-image img {
  width: 15px;
  height: 15px;
}
.button-class {
  width: 100%;
  height: 70px;
  background: #fafafa;
  margin-top: 200px;
  margin-right: auto;
  margin-left: auto;
  position: fixed;
  justify-content: space-between;
  bottom: 70px;
}
.button-class .button {
  margin-top: 16px;
  width: 140px;
  height: 38px;
  border-radius: 6px;
}
.button-class .confirmation {
  background: #1ea6ae;
  font-family: Work Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  letter-spacing: -0.02em;
  border: none;
  color: #ffffff;
}
.button-class .cancel {
  background: #ffffff;
  border: 1px solid #b7b7b7;
  font-family: Work Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #717171;
}
.input-form {
  margin-left: auto;
  margin-right: auto;
  text-align: left;
  width: 95%;
  padding: 5px;
  margin-top: 10px;
  margin-bottom: 24px;
}
.input-group-text {
  background-color: #ffffff;
  border: none;
  padding-left: 0%;
  font-weight: 600;
  font-size: 16px;
}
.title {
  display: flex;
}
.title .mandatory {
  width: 33px;
  height: 18px;
  margin-left: 4px;
  border-radius: 5px;
  background-color: #970000;
}
.title .mandatory label {
  font-family: Work Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 11px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #ffffff;
  padding-left: 5px;
  padding-top: 1px;
}
.input-title {
  font-weight: 600;
}
.input-content {
  background-color: #ededed;
  border: none;
  outline: none;
  font-size: 16px;
  font-size: 1.5rem;
  color: rgb(0, 0, 0);
  outline: none;
}
input.form-control.input-content {
  font-size: 16px;
}
.img-fiex {
  display: flex;
  flex-wrap: wrap;
}
.title-card {
  width: 95%;
  height: 350px;
  margin: auto;
  margin-top: 24px;
  padding: 10px;
  overflow: auto;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 20px;
}
.date-fiex {
  display: flex;
  width: 92%;
  margin-top: 12px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 6px;
}
.date-button {
  width: 60px;
  height: 25px;
  border-radius: 5px;
}
.plus-button {
  padding-top: 4px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
  /* identical to box height */
  letter-spacing: -0.02em;
  background: #ffffff;
  border: 1px solid #c1c1c1;
  box-sizing: border-box;
  border-radius: 5px;
}
.delete-button {
  margin-left: 10px;
  padding-top: 4px;
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
  /* identical to box height */
  letter-spacing: -0.02em;
  color: #ffffff;
  background: #a0a0a0;
  border-radius: 5px;
}
</style>
