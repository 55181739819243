<template>
  <div>
    <div class="home">
      <div class="about">
        <a href="#" v-on:click.stop.prevent="openAbotModal()" class="alert-link"
          >>>About</a
        >
      </div>
      <div v-if="isModal">
        <div class="popContainer">
          <div class="heard-style">
            <h2>5.ムーブメントトレーニング</h2>
          </div>
          <div class="card card-modal">
            <div class="card-body">
              <MovementTrainingAbout />
              <br />
              <br />
            </div>
          </div>
          <div class="close-button">
            <div class="close-button-div">
              <a
                href="#"
                v-on:click.stop.prevent="closeAbotModal()"
                class="alert-link close-button-text"
                >閉じる</a
              >
            </div>
          </div>
        </div>
      </div>

      <div v-for="content in data.explanation_list" v-bind:key="content.id">
        <!-- 説明部分 -->
        <div v-if="content.explanation !== null && content.explanation !== ''">
          <div class="card border-light mb-3 card-style">
            <div class="card-body">
              <p class="card-text title-conten-font">
                {{ content.explanation }}
              </p>
            </div>
          </div>
        </div>
      </div>

      <div v-for="content in data.detail_list" v-bind:key="content.id">
        <!-- ビデオ -->
        <div
          v-if="
            content.video.video_url !== null && content.video.video_url !== ''
          "
        >
          <div class="card video-div-style">
            <video
              :src="content.video.video_url"
              controls
              preload="metadata"
              class="video-style"
            />
            <br />
          </div>
        </div>
        <div
          v-if="
            content.image.image_url !== null && content.image.image_url !== ''
          "
        >
          <img :src="content.image.image_url" height="230" />
          <br />
        </div>
        <!-- タイトル -->
        <div v-if="content.title !== null && content.title !== ''">
          <p class="h4 h4-title">{{ content.title }}</p>
        </div>
        <!-- 説明 -->
        <div v-if="content.explanation !== null && content.explanation !== ''">
          <div class="card border-light mb-3 card-style">
            <div class="card-body">
              <p class="card-text title-conten-font">
                {{ content.explanation }}
              </p>
            </div>
          </div>
        </div>
        <!-- 目的、動作、ポイントtable -->
        <div
          v-if="
            (content.purpose !== null && content.purpose !== '') ||
              (content.movement !== null && content.movement !== '') ||
              (content.point !== null && content.point !== '')
          "
        >
          <div>
            <table>
              <tr>
                <th>目的</th>
                <td>{{ content.purpose }}</td>
              </tr>
              <tr>
                <th>動作</th>
                <td>{{ content.movement }}</td>
              </tr>
              <tr>
                <th>ポイント</th>
                <td>{{ content.point }}</td>
              </tr>
            </table>
          </div>
        </div>
        <br />
        <br />
      </div>
    </div>
  </div>
</template>

<script>
import MovementTrainingAbout from "../commentary/MovementTrainingAbout";
export default {
  name: "MovementTraining",
  components: {
    MovementTrainingAbout,
  },
  props: {},
  data() {
    return {
      isModal: false,
      data: {
        detail_list: [
          {
            title: "",
            purpose: "",
            movement: "",
            point: "",
            explanation: "",
            image: "",
            video: "",
          },
        ],
        explanation_list: [
          {
            explanation: "",
            image: "",
            video: "",
          },
        ],
      },
    };
  },
  created: function() {
    //画面開くときの処理
    this.getProgramData();
  },
  methods: {
    openAbotModal() {
      this.isModal = true;
    },
    closeAbotModal() {
      this.isModal = false;
    },
    getProgramData() {
      let program_info = JSON.parse(sessionStorage.getItem("program_info"));
      this.data.detail_list = program_info.movementTraining.detail_list;
      this.data.explanation_list =
        program_info.movementTraining.explanation_list;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.heard-style {
  padding-top: 14px;
  height: 50px;
  background-color: #04545c;
  border: 0;
  margin-top: 5%;
  margin-left: 8%;
  margin-right: 8%;
}
.heard-style img {
  float: left;
  margin-left: 2%;
}
.heard-style > h2 {
  color: rgb(255, 255, 255);
  margin-right: 4%;
  font-weight: 900;
}
.close-button {
  height: 50px;
  background-color: #ffffff;
  border: 0;
  margin-left: 8%;
  margin-right: 8%;
}
.close-button-div {
  background-color: rgb(255, 255, 255);
  text-align: right;
  margin-right: 4%;
  padding-top: 12px;
}
.close-button-text {
  font-size: 16px;
  font-weight: 400;
  color: rgb(163, 163, 163);
}

.about {
  text-align: right;
  margin-top: 2%;
  margin-bottom: 3%;
}
.about > a:link,
a:visited,
a:hover,
a:active {
  text-decoration: none;
  font-size: 16px;
  font-weight: 400;
  color: rgb(163, 163, 163);
}
.title {
  padding-top: 4%;
  text-align: left;
}

.card-style {
  max-width: 100rem;
  margin: 2%;
  background-color: rgb(248, 248, 248);
  white-space: pre-wrap;
}
.title-font {
  color: #000;
  font-weight: 600;
  text-align: left;
}
.title-conten-font {
  color: #000;
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  letter-spacing: 1px;
  line-height: 20px;
}
.video-div-style {
  height: auto;
  width: auto;
  margin-top: 15%;
  margin-left: 2%;
  margin-right: 2%;
  border: 0;
  background-color: rgb(233, 233, 233);
}
.video-style {
  height: 250px;
}
.h4-title {
  margin-top: 5%;
  margin-bottom: 4%;
  margin-left: 2%;
  text-align: left;
  font-weight: 600;
  color: #000;
}
.home img {
  padding-top: 1%;
  width: 96%;
  height: auto;
}
.program-font-style {
  font-weight: 600;
  color: #000;
}
.text-style {
  padding-top: 2%;
  text-align: left;
  font-size: 14px;
  font-weight: 500;
}
.font-style {
  letter-spacing: 2px;
  line-height: 25px;
  padding-top: 8px;
}
.span-style {
  margin-left: -5px;
  margin-right: -5px;
  background: linear-gradient(transparent 50%, #f5f100 0%);
}

/* table */
table {
  margin-top: 8%;
  margin-left: 2%;
  margin-right: 2%;
  width: 96%;
  border-collapse: collapse;
}

table tr {
  width: 30px;
  border-bottom: solid 1px white;
}

table tr:last-child {
  border-bottom: none;
}

table th {
  position: relative;
  text-align: left;
  width: 25%;
  height: auto;
  background-color: #e4ecec;
  color: rgb(0, 0, 0);
  text-align: center;
  padding: 10px 0;
  font-size: 14px;
  font-weight: 400;
}
table td {
  position: relative;
  text-align: left;
  height: auto;
  background-color: rgb(247, 247, 247);
  color: rgb(0, 0, 0);
  text-align: left;
  padding: 10px 10px;
  font-size: 14px;
  font-weight: 400;
  white-space: pre-wrap;
}
.popContainer {
  z-index: 2;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(43, 43, 43, 0.3);
}
.card-modal {
  overflow: auto;
  z-index: 2;
  margin-top: 0%;
  margin-left: 8%;
  margin-right: 8%;
  height: 75%;
  background-color: rgb(255, 255, 255);
}
</style>
