<template>
  <div>
    <div class="page-content" v-if="clickEvend">
      <MkGetProgram
        :programData="programData"
        :program_title="program_title"
        :program_index="program_index"
        @do-back-lib="doBack"
      />
    </div>
    <div class="page-content" v-if="!clickEvend">
      <div class="page-search">
        <div class="input-form">
          <div class="input-group input-group-lg">
            <input
              type="text"
              class="form-control input-content"
              aria-label="Sizing example input"
              aria-describedby="inputGroup-sizing-lg"
              placeholder="検索"
              v-model="search.search_key"
            />
            <button class="button search" @click="doSearch()">
              検索
            </button>
          </div>
        </div>
        <div class="input-form">
          <div class="input-group mb-3">
            <select
              class="custom-select select-form"
              id="inputGroupSelect01"
              v-model="search.search_select"
              @change="change()"
            >
              <option
                v-for="option in glb_program_search_option"
                v-bind:value="option.value"
                :key="option.value"
                >{{ option.text }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div
        v-for="(list_content, index_i) in selectLibraryList"
        v-bind:key="list_content.id"
      >
        <div
          class="change-div"
          v-for="(content, index) in list_content.detail_list"
          v-bind:key="content.id"
        >
          <table @click="getLibraryTitleInfo(index_i, index)">
            <tr>
              <th>
                <div v-if="content.image.image_url !== ''">
                  <div
                    class="image_circle"
                    :style="{
                      backgroundImage: 'url(' + content.image.image_url + ')',
                    }"
                  ></div>
                </div>
                <div
                  class="video-div"
                  v-else-if="content.video.video_url !== ''"
                >
                  <div class="video-div-style">
                    <video
                      :src="content.video.video_url"
                      controls
                      preload="metadata"
                      class="video-style"
                    />
                  </div>
                </div>
              </th>
              <td>
                <!-- 概要タイトル -->
                <div class="card-title">
                  <table>
                    <tr>
                      <td>
                        <p class="font-italic">
                          {{ list_content.name }} >> {{ title }}
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p class="font-weight-bold card-title-font">
                          {{ content.title }}
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <p class="font-weight-normal card-title-font-2">
                          {{ content.explanation }}
                        </p>
                      </td>
                    </tr>
                  </table>
                </div>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
    <div class="button-class" v-if="!clickEvend">
      <div class="d-flex justify-content-around">
        <button class="button cancel" @click="doCancel()">キャンセル</button>
        <button class="button confirmation" @click="doConfirmation()">
          確認
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "firebase/compat";
import MkGetProgram from "@/components/mk_program/mk_library/mk_get_program";
export default {
  name: "Modal",
  components: {
    MkGetProgram,
  },
  props: {
    program_title: null,
    program_index: null,
  },
  data() {
    return {
      search: {
        search_key: "",
        search_select: "",
      },
      clickEvend: false,
      image_url:
        "https://firebasestorage.googleapis.com/v0/b/fitnesscamp-428f2.appspot.com/o/program_list%2F791d9270-bd96-40c7-8c87-bf4f5bb77a9d%2Factivation%2Fvideo%2F%E3%82%B9%E3%82%BF%E3%83%B3%E3%83%86%E3%82%99%E3%82%A3%E3%83%B3%E3%82%AF%E3%82%99%E3%83%81%E3%82%A7%E3%82%B9%E3%83%88%E3%82%AA%E3%83%BC%E3%83%95%E3%82%9A%E3%83%8A%E3%83%BC.MOV?alt=media&token=cb0b8d8a-fbb8-4143-ab0d-38546990b23f",
      programList: [],
      programData: {},
      title: "",
      allLibraryList: [],
      selectLibraryList: [],
      backSelectLibraryList: [],
      program_page: {},
      program_info: {},
    };
  },
  async created() {
    await this.getLibraryInfo();
  },
  methods: {
    doSearch() {
      let self = this;
      // self.selectLibraryList = [];
      if (self.search.search_key == "" || self.search.search_key == null) {
        self.getLibraryInfo();
      }
      for (let i = 0; i < self.selectLibraryList.length; i++) {
        let delete_j = [];
        for (let j = 0; j < self.selectLibraryList[i].detail_list.length; j++) {
          if (
            //検索条件
            self.selectLibraryList[i].detail_list[j].title.indexOf(
              self.search.search_key
            ) < 0
          ) {
            delete_j.push(j);
          }
        }
        for (let k = 0; k < delete_j.length; k++) {
          self.selectLibraryList[i].detail_list.splice(delete_j[k] - k, 1);
          console.log(delete_j[k] - k);
        }
      }
    },
    doBack(event) {
      this.clickEvend = false;
      if (event) {
        this.doConfirmation(event);
      }
    },
    getLibraryTitleInfo(i, j) {
      this.search_select = this.programData = this.selectLibraryList[
        i
      ].detail_list[j];
      this.clickEvend = true;
    },
    doCancel() {
      this.$emit("do-back");
    },
    doConfirmation(event) {
      if (event) {
        this.$emit("do-back", true);
      } else {
        this.$emit("do-back", false);
      }
    },
    getLibraryInfo() {
      this.search.search_select = this.program_title;
      this.checkTitle();
      let self = this;
      firebase
        .firestore()
        .collection(self.glb_table.program)
        .get()
        .then(function(querySnapshot) {
          querySnapshot.forEach(async function(doc) {
            firebase
              .firestore()
              .collection(self.glb_table.program)
              .doc(doc.id)
              .get()
              .then(async (snap) => {
                let data = snap.data();
                self.allLibraryList.push(data);
                let titleValue = await self.getSelectLibraryInfo(data);
                titleValue.name = data.name;
                self.selectLibraryList.push(titleValue);
                self.backSelectLibraryList.push(titleValue);
              });
          });
        });
    },
    async change() {
      this.selectLibraryList = [];
      this.checkTitle();
      for (var i in this.allLibraryList) {
        let data = await this.getSelectLibraryInfo(this.allLibraryList[i]);
        data.name = this.allLibraryList[i].name;
        await this.selectLibraryList.push(data);
      }
    },
    async getSelectLibraryInfo(value) {
      switch (this.search.search_select) {
        case this.glb_program_content.improvement:
          return value.improvement;
        case this.glb_program_content.staticStretch:
          return value.staticStretch;
        case this.glb_program_content.movementTraining:
          return value.movementTraining;
        case this.glb_program_content.dynamicStretch:
          return value.dynamicStretch;
        case this.glb_program_content.circuittraining:
          return value.circuittraining;
        case this.glb_program_content.activation:
          return value.activation;
        case this.glb_program_content.groupWork:
          return value.groupWork;
        default:
          return "";
      }
    },
    async checkTitle() {
      let data = await this.glb_check_library(this.search.search_select);
      this.title = data;
    },
  },
};
</script>

<style scoped>
.page-content {
  width: 100%;
  height: 100vh;
  margin: auto;
  overflow: auto;
  padding-bottom: 200px;
  background-color: #ffffff;
}
.page-search {
  width: 100%;
  height: 149px;
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.change-div {
  overflow: hidden;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  width: 95%;
  height: 120px;
  background: rgb(255, 255, 255);
  /* background-color: black; */
  border-radius: 15px;
  box-shadow: 3px 6px 9px rgba(0, 0, 0, 0.2);
}
.change-div table {
  width: 100%;
  height: 100%;
}
.change-div table th {
  width: 120px;
  /* background-color: #fff; */
}
.change-div table td {
  position: relative;
}
.change-div table td .card-title {
  position: absolute;
  top: 12px;
  left: 0px;
  text-align: left;
}
.change-div table td .card-content {
  position: absolute;
  top: 35px;
  left: 0px;
  height: 40px;
  text-align: left;
  width: 100%;
  overflow: auto;
}
.image_circle {
  text-align: center;
  height: 120px;
  width: 120px;
  margin: 5px;
  border-radius: 10%;
  background-position: 54% 36%;
  background-size: cover;
}
.input-form {
  margin-left: auto;
  margin-right: auto;
  text-align: left;
  width: 80%;
  padding: 5px;
  margin-top: 10px;
  margin-bottom: 24px;
}
.input-group-text {
  background-color: #ffffff;
  border: none;
  padding-left: 0%;
  font-weight: 600;
  font-size: 16px;
}
.select-form {
  background-color: #ededed;
  border: none;
  outline: none;
  font-size: 16px;
}
.title {
  display: flex;
}

.input-title {
  font-weight: 600;
}
.input-content {
  background-color: #ededed;
  border: none;
  outline: none;
  font-size: 16px;
  font-size: 1.5rem;
  color: rgb(0, 0, 0);
  outline: none;
}
input.form-control.input-content {
  font-size: 16px;
}

.search {
  background: #e96a6a;
  color: #ffffff;
  border-radius: 6px;
  border: none;
  font-family: Work Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  letter-spacing: -0.02em;
  width: 60px;
  margin-left: 12px;
}
.card-title-font {
  font-size: 14px;
}
.card-title-font-2 {
  font-size: 10px;
}
.video-div-style {
  height: auto;
  width: auto;
  margin-top: 0px;
  border: 0;
  /* background-color: rgb(233, 233, 233); */
}
.video-style {
  width: 100%;
  height: 100px;
}
.button-class {
  width: 100%;
  height: 70px;
  background: #fafafa;
  margin-top: 200px;
  margin-right: auto;
  margin-left: auto;
  position: fixed;
  justify-content: space-between;
  bottom: 70px;
}
.button-class .button {
  margin-top: 16px;
  width: 160px;
  height: 38px;
  border-radius: 6px;
}
.button-class .confirmation {
  background: #1ea6ae;
  font-family: Work Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  letter-spacing: -0.02em;
  border: none;
  color: #ffffff;
}
.button-class .cancel {
  background: #ffffff;
  border: 1px solid #b7b7b7;
  font-family: Work Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 21px;
  text-align: center;
  letter-spacing: -0.02em;
  color: #717171;
}
</style>
