<template>
  <div class="home">
    <div class="title">
      <h2 class="display-6 program-font-style">目的</h2>
    </div>
    <div class="text-style">
      <p class="fw-normal font-style">
        <span>サーキットトレーニングでは、</span>
        <span class="span-style span-start">7つの原理が示すように自然環境に合わせて直感的にそして不規則な動作を実施し、運動機能の向上を目指します。</span>
      </p>
    </div>
    <div class="text-style">
      <p class="fw-normal font-style">
        <span>
          原始的で実用的な10の運動動作をいくつか組み合わせて4-6つに統合したサーキットトレーニングを実施しますスポーツ科学的視点で言えば、
        </span>
      </p>
    </div>
    <img src="@/assets/commentary/circuit.png" height="100"/>

    <div class="sapace-style"></div>
    <div class="title">
      <h2 class="display-6 program-font-style">歩く</h2>
    </div>

    <div>
      <table>
        <tr>
          <th class="table-td-th-color_1">動作</th>
          <td class="table-td-th-color_1">walking</td>
        </tr>
        <tr>
          <th class="table-td-th-color_1">解説</th>
          <td class="table-td-th-color_1">基本的に"バランス"や、"持つ"などの要素に付帯する。歩き方や方向などでバリエージョンをつけたりアレンジする</td>
        </tr>
        <tr>
          <th class="table-td-th-color_1">種目名</th>
          <td class="table-td-th-color_1">
            おんぶして"歩く
            <br />丸太の上を歩く
          </td>
        </tr>
        <tr>
          <th class="table-td-th-color_1">注意</th>
          <td class="table-td-th-color_1">踵からついて親指と人差し指で蹴る・目線を前につけるなど歩行のゲートに準じたキューイング</td>
        </tr>
      </table>
    </div>
    <br />
    <img src="@/assets/commentary/歩く.jpg" height="250"/>

    <div class="sapace-style"></div>
    <div class="title">
      <h2 class="display-6 program-font-style">走る</h2>
    </div>

    <div>
      <table>
        <tr>
          <th class="table-td-th-color_2">動作</th>
          <td class="table-td-th-color_2">Running</td>
        </tr>
        <tr>
          <th class="table-td-th-color_2">解説</th>
          <td class="table-td-th-color_2">
            基本的にステーションの移動方法としてや、他要素と組み合わせて鍛える。障害物をおいたりしてジグザグに走ったり、砂浜など地面の形状を利用して走ることも新しい刺激として活用できる。また、スピードの強弱を設定することも機能上役立つ。
          </td>
        </tr>
        <tr>
          <th class="table-td-th-color_2">種目名</th>
          <td class="table-td-th-color_2">砂地や山道を走る</td>
        </tr>
        <tr>
          <th class="table-td-th-color_2">注意</th>
          <td class="table-td-th-color_2">
            腕を振るなど走る動作に準じてキューイングをする
            <br />凍結した地面だったり環境に配慮する
          </td>
        </tr>
      </table>
    </div>
    <br />
    <img src="@/assets/commentary/走る.jpeg" height="250"/>

    <div class="sapace-style"></div>
    <div class="title">
      <h2 class="display-6 program-font-style">持つ</h2>
    </div>

    <div>
      <table>
        <tr>
          <th class="table-td-th-color_3">動作</th>
          <td class="table-td-th-color_3">Lifting</td>
        </tr>
        <tr>
          <th class="table-td-th-color_3">解説</th>
          <td class="table-td-th-color_3">
            運動環境の中で対象物を決めて、持ち上げたりパートナーを決めて持ち上げたりなど自然環境や、実施する年齢や性別、フィジカルレベルなどを考慮してアレンジする。
            <br />持ち上げ方や持つ位置、そして対応人数なども踏まえて種目の選択をする。
          </td>
        </tr>
        <tr>
          <th class="table-td-th-color_3">種目名</th>
          <td class="table-td-th-color_3">
            おんぶをする
            <br />木を担ぐ
          </td>
        </tr>
        <tr>
          <th class="table-td-th-color_3">注意</th>
          <td class="table-td-th-color_3">
            対象物や実施人数などによるが、正しい下肢動作や姿勢のキューイングに準じて声かけする
            <br />落としたことを考えて周囲に配慮する
          </td>
        </tr>
      </table>
    </div>
    <br />
    <img src="@/assets/commentary/持つ.jpg" height="250"/>

    <div class="sapace-style"></div>
    <div class="title">
      <h2 class="display-6 program-font-style">運ぶ</h2>
    </div>

    <div>
      <table>
        <tr>
          <th class="table-td-th-color_4">動作</th>
          <td class="table-td-th-color_4">carrying</td>
        </tr>
        <tr>
          <th class="table-td-th-color_4">解説</th>
          <td class="table-td-th-color_4">
            持ち上げて位置を安定させて、1人もしくは複数人で移動する。移動動作中の正しい
            ”歩く“や”走る“を意識しながら安定した体勢で運ぶ。
            <br />移動をする際に、バランス要素を取り入れたり、など他要素を追加することでアレンジが可能となる。
          </td>
        </tr>
        <tr>
          <th class="table-td-th-color_4">種目名</th>
          <td class="table-td-th-color_4">
            肩に担いで歩く
            <br />手押し車で歩く（一輪車を押す）
          </td>
        </tr>
        <tr>
          <th class="table-td-th-color_4">注意</th>
          <td class="table-td-th-color_4">“持つ”や“歩く”“走る”などそれぞれの要素に準じてポイントを考える</td>
        </tr>
      </table>
    </div>
    <br />
    <img src="@/assets/commentary/take.png" height="250"/>

    <div class="sapace-style"></div>
    <div class="title">
      <h2 class="display-6 program-font-style">バランスをとる</h2>
    </div>
    <div>
      <table>
        <tr>
          <th class="table-td-th-color_5">動作</th>
          <td class="table-td-th-color_5">Balancing</td>
        </tr>
        <tr>
          <th class="table-td-th-color_5">解説</th>
          <td class="table-td-th-color_5">
            自然環境であれば石に乗ったり、倒れた木の上を歩いたり、体育館であれば平均台を歩いたりなどのバランスをとる動作。
            <br />バランスを取りながらものを拾ったり、ボールを投げてキャッチしたり、複数であれば手をつないで歩いたりなど他要素と組みあわせる。
            <br />回転動作をすることで前庭系（三半規管や磁石器）への刺激となりバランス機能向上につながるので、何も無い場所では回転動作をして静止したり、その後走ったりなども種目として取り入れることもできる。
          </td>
        </tr>
        <tr>
          <th class="table-td-th-color_5">種目名</th>
          <td class="table-td-th-color_5">
            回転してバランスを取る／操作する
            <br />石に飛び乗る／平均台を渡る
            <br />タイヤの上に乗ってボールを渡す
          </td>
        </tr>
        <tr>
          <th class="table-td-th-color_5">注意</th>
          <td class="table-td-th-color_5">安全な環境に特に配慮する</td>
        </tr>
      </table>
    </div>
    <br />
    <img src="@/assets/commentary/balance.jpg" height="250"/>

    <div class="sapace-style"></div>
    <div class="title">
      <h2 class="display-6 program-font-style">這う</h2>
    </div>
    <div>
      <table>
        <tr>
          <th class="table-td-th-color_6">動作</th>
          <td class="table-td-th-color_6">Crawling</td>
        </tr>
        <tr>
          <th class="table-td-th-color_6">解説</th>
          <td class="table-td-th-color_6">
            下向き、上向き、横向きなど様々な運動面から、体幹及び四肢をコントロールして移動する。基本的に四肢を地面に接地させる動作で速さや動作の音など、原始的なシチュエーションのイメージを共有して動作をする。
            <br />平均台を這って潜ったり、足の間を這ったりなど目標物を置いてぶつからないように移動したりなども可能。
          </td>
        </tr>
        <tr>
          <th class="table-td-th-color_6">種目名</th>
          <td class="table-td-th-color_6">
            ほふく前進
            <br />足の間を潜る
          </td>
        </tr>
        <tr>
          <th class="table-td-th-color_6">注意</th>
          <td class="table-td-th-color_6">地面の状況や関節への負担を考慮して柔軟に種目変更する</td>
        </tr>
      </table>
    </div>
    <br />
    <img src="@/assets/commentary/這う.jpg" height="250"/>

    <div class="sapace-style"></div>
    <div class="title">
      <h2 class="display-6 program-font-style">登る</h2>
    </div>
    <div>
      <table>
        <tr>
          <th class="table-td-th-color_7">動作</th>
          <td class="table-td-th-color_7">Climbing</td>
        </tr>
        <tr>
          <th class="table-td-th-color_7">解説</th>
          <td class="table-td-th-color_7">
            木を登ったり、岩場を登ったり、学校環境ではのぼり棒を登ったりジャングルジムを登ったりなどの動作である。坂道を走って登ったり、鉄棒を手だけで登ったりなど、手足の複合動作に限定せず動作をアレンジする。
            <br />平面地形においては、”這う”動きを“登る”の類似形態と考え組み込むことも可能である。
          </td>
        </tr>
        <tr>
          <th class="table-td-th-color_7">種目名</th>
          <td class="table-td-th-color_7">
            丘を登る
            <br />ジャングルジムを登る
          </td>
        </tr>
        <tr>
          <th class="table-td-th-color_7">注意</th>
          <td class="table-td-th-color_7">高さのある場所を登ったり、自然環境で実施する際は転落等の怪我に注意をする</td>
        </tr>
      </table>
    </div>
    <br />
    <img src="@/assets/commentary/登る.jpg" height="250"/>

    <div class="sapace-style"></div>
    <div class="title">
      <h2 class="display-6 program-font-style">飛ぶ</h2>
    </div>
    <div>
      <table>
        <tr>
          <th class="table-td-th-color_8">動作</th>
          <td class="table-td-th-color_8">Jumping</td>
        </tr>
        <tr>
          <th class="table-td-th-color_8">解説</th>
          <td class="table-td-th-color_8">
            脚だけで飛ぶのではなく手を使って壁を飛び越えたりなど目標物を使って飛び越えたり、もしくは目標に向かって大きく飛ぶ。馬跳び動作でも片手をついて飛んだり、反転しながら飛んだりと動作を工夫する。グループでは“くぐる”動作の併用してリレー形式にしたりも可能。
          </td>
        </tr>
        <tr>
          <th class="table-td-th-color_8">種目名</th>
          <td class="table-td-th-color_8">
            跳び箱（馬跳び）ベーシック、片手、180°
            <br />連続ジャンプ
            <br />ウェイトプレートを置いてジャンプ着地
          </td>
        </tr>
        <tr>
          <th class="table-td-th-color_8">注意</th>
          <td class="table-td-th-color_8">踵からついて親指と人差し指で蹴る・目線を前につけるなど歩行のゲートに準じたキューイング</td>
        </tr>
      </table>
    </div>
    <br />
    <img src="@/assets/commentary/jump.jpg" height="250"/>

    <div class="sapace-style"></div>
    <div class="title">
      <h2 class="display-6 program-font-style">投げる</h2>
    </div>
    <div>
      <table>
        <tr>
          <th class="table-td-th-color_9">動作</th>
          <td class="table-td-th-color_9">Throwing</td>
        </tr>
        <tr>
          <th class="table-td-th-color_9">解説</th>
          <td class="table-td-th-color_9">
            安全な対象（ビーチボールなど）を使って投げる動作を行う。投げるときは、投げ方、方向、スピード、距離などを工夫する。“獲る”動作と組み合わせてラグビーのパスドリルのように走りながら動作をしたりなど多様なアレンジが可能。”投げる”動作にフォーカスするならば距離やスピードに重きを置いたりした種目や設定にする。
          </td>
        </tr>
        <tr>
          <th class="table-td-th-color_9">種目名</th>
          <td class="table-td-th-color_9">
            ボール投げリレー
            <br />ラグビーパスドリル
          </td>
        </tr>
        <tr>
          <th class="table-td-th-color_9">注意</th>
          <td class="table-td-th-color_9">投げる相手の能力や目線を考慮して投げる</td>
        </tr>
      </table>
    </div>
    <br />
    <img src="@/assets/commentary/throw.jpg" height="250"/>

    <div class="sapace-style"></div>
    <div class="title">
      <h2 class="display-6 program-font-style">獲る</h2>
    </div>
    <div>
      <table>
        <tr>
          <th class="table-td-th-color_10">動作</th>
          <td class="table-td-th-color_10">Catching</td>
        </tr>
        <tr>
          <th class="table-td-th-color_10">解説</th>
          <td class="table-td-th-color_10">
            安全で対象（ビーチボールなど）を使って獲る動作を行う。静止したキャッチから、移動動作やバランス要素を組み合わせて行うことで二重タスクとなり難しさ（神経機能への働きが増える）が強調される。
          </td>
        </tr>
        <tr>
          <th class="table-td-th-color_10">種目名</th>
          <td class="table-td-th-color_10">バランスを取りながらボールをとるボールを上に投げて回転してキャッチする</td>
        </tr>
        <tr>
          <th class="table-td-th-color_10">注意</th>
          <td class="table-td-th-color_10">二重タスクにした場合には、周囲の安全に特に配慮する。（バランス→転落、パス→周囲との衝突など）</td>
        </tr>
      </table>
    </div>
    <br />
    <img src="@/assets/commentary/catch.jpg" height="250"/>
    <br />
    <br />
  </div>
</template>

<script>
export default {
  name: "CircuitTrainingAbout",
  props: {}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.title {
  padding-top: 4%;
  text-align: left;
}
.home > img {
  padding-top: 1%;
  width: 96%;
  height: auto;
  min-height: 100px;
}
.home > img::before {
  content: "";
  display: block;
  padding-top: calc(((720 / 1280) * 100%));
}
.program-font-style {
  font-weight: 600;
  color: #000;
}
.text-style {
  padding-top: 2%;
  text-align: left;
  font-size: 14px;
  font-weight: 500;
}
.font-style {
  letter-spacing: 1px;
  line-height: 25px;
  padding-top: 8px;
}
.span-style {
}
.span-start {
  background: linear-gradient(transparent 50%, #fcf49c 0%);
}
.span-end {
  margin-left: -4px;
  margin-right: -4px;
  background: linear-gradient(transparent 50%, #fcf49c 0%);
}
.sapace-style {
  margin-top: 10%;
}

/* table */
table {
  margin-top: 8%;
  margin-left: 2%;
  margin-right: 2%;
  width: 96%;
  border-collapse: collapse;
}

table tr {
  width: 20px;
  border-bottom: solid 1px white;
}

table tr:last-child {
  border-bottom: none;
}

table th {
  position: relative;
  text-align: center;
  width: 25%;
  height: auto;
  padding: 10px 0;
  font-size: 14px;
  font-weight: 500;
  /* border: solid 1px white; */
}
table td {
  position: relative;
  text-align: left;
  height: auto;
  text-align: left;
  padding: 10px 10px;
  font-size: 14px;
  font-weight: 500;
  border-left: solid 1px white;
}

.table-td-th-color_1 {
  color: rgb(255, 255, 255);
  background-color: #5cace4ff;
}
.table-td-th-color_2 {
  color: rgb(255, 255, 255);
  background-color: #24849cff;
}
.table-td-th-color_3 {
  color: rgb(255, 255, 255);
  background-color: #14745cff;
}
.table-td-th-color_4 {
  color: rgb(255, 255, 255);
  background-color: #6c9405ff;
}
.table-td-th-color_5 {
  color: #000000ff;
  background-color: #fcdca4ff;
}
.table-td-th-color_6 {
  color: rgb(255, 255, 255);
  background-color: #8494b4ff;
}
.table-td-th-color_7 {
  color: rgb(255, 255, 255);
  background-color: #a4a4a4ff;
}
.table-td-th-color_8 {
  color: rgb(255, 255, 255);
  background-color: #ec8c64ff;
}
.table-td-th-color_9 {
  color: #000000ff;
  background-color: #94d454ff;
}
.table-td-th-color_10 {
  color: #000000ff;
  background-color: #d4e4b4ff;
}

</style>
