<template>
  <div>
    <div v-show="page === 1">
      <div class="home">
        <img src="@/assets/kids/kids.jpg" height="230" />
        <div class="title">
          <h3 class="display-6 program-font-style">①運動遊び</h3>
        </div>
        <div class="text-style">
          <p class="fw-normal font-style">
            <span>
              「運動遊び」とは遊びの中で活発に身体を使う遊び。「遊び」とは自由、非日常、非生産的、ワクワクドキドキ、ストーリーが含まれる行為で、つまり
              遊びは自由、言い換えると自発的であること、強制されないことが重要となる。習い事や教室になると苦手なことや、やりたくないこともやらなければ
              ならないが、遊びは自由であるから参加するしない、やるやらないの自由がある。つまり「運動遊び」は子供たちにとって楽しい運動である。
            </span>
          </p>
        </div>
        <div class="title">
          <h3 class="display-6 program-font-style">②親子ペア参加</h3>
        </div>
        <div class="text-style">
          <p class="fw-normal font-style">
            <span>
              幼児児童期の運動実施において、親が運動相手であることが多いと言われている。つまり子ども運動への親の関与が子どもの運動好きに影響する。また、
              運動遊びが親子の触れ合いにとって有効なツールであることは言うまでもなく、親子関係の構築や親のメンタルヘルスにも有効であるとも言われている。
            </span>
          </p>
        </div>

        <div class="title">
          <h3 class="display-6 program-font-style">③きつい楽しい</h3>
        </div>
        <div class="text-style">
          <p class="fw-normal font-style">
            <span>
              アウトドアでの運動の楽しさ、気晴らしを目的とするが、楽しいだけではなく運動による爽快感を得ることが重要となる。「きつかったけど楽しかっ
              た！」、また参加したいと思えるアウトドアでの運動遊びならではの充実感、爽快感、子のつながり感を得てもらうことが肝要となる。
            </span>
          </p>
        </div>

        <div class="title">
          <h3 class="display-6 program-font-style">③きつい楽しい</h3>
        </div>
        <div class="text-style">
          <p class="fw-normal font-style">
            <span>
              アウトドアでの運動の楽しさ、気晴らしを目的とするが、楽しいだけではなく運動による爽快感を得ることが重要となる。「きつかったけど楽しかっ
              た！」、また参加したいと思えるアウトドアでの運動遊びならではの充実感、爽快感、子のつながり感を得てもらうことが肝要となる。
            </span>
          </p>
        </div>

        <div class="title">
          <h3 class="display-6 program-font-style">
            ④運動遊びの構成要件は、身体活動が原則
          </h3>
        </div>
        <div class="text-style">
          <p class="fw-normal font-style">
            <span>
              運動遊びは、身体活動であって、運動を伴わない運動遊びは運動遊びではない。遊びであれば何でもいいわけではなく身体を動かすことが原則となる。
              体育やスポーツ的要素を含んだ遊び的エクササイズを中心に構成させる。プログラム化しプログラムに基づく進行よりも、流れや雰囲気で臨機応変にプ
              ログラムを変化させることが肝要。
            </span>
          </p>
        </div>

        <div class="title">
          <h3 class="display-6 program-font-style">
            ⑤体力運動能力を刺激する
          </h3>
        </div>
        <div class="text-style">
          <p class="fw-normal font-style">
            <span>
              エネルギー系体力と運動能力の両方を刺激することで体力運動能力向上を図る。本プログラムの効果として遊びながら、体力運動能力向上することが求
              められ、それが結果として運動好きにつながる。
            </span>
          </p>
        </div>
      </div>
    </div>

    <div v-show="page === 2">
      <div class="home">
        <div class="title">
          <h4 class="display-6 program-font-style h4-title">
            Fitness Camp
            Kidsにおける認定コーチの役割は、指導やコーチングではなくプ
            レイリーダーやガイドのようなものです
          </h4>
        </div>
        <br />
        <div class="text-style">
          <p class="font-weight-bold title-p">プレイリーダーとは？</p>
          <p class="fw-normal font-style">
            <span>
              プレイリーダーとはなんでしょうか。<br />
              特定の定義はありませんが、概ね「子どもたちが伸び伸びと遊べるように環境を整え
              たり、補助する人」と言われています。つまり、遊び方を指導したり、遊びを決めるの
              ではなく、子どもや参加者が遊びを楽しめる環境を提供するということです。<br />
              動遊びは、運動やスポーツ、体育的要素を含んだ遊びです。言い換えると、身体活動を伴う遊びで、鬼ごっこやかくれんぼ、かけっこや縄跳びなどが運動遊びです。おま
              まごとやお絵かきのような、あまり身体活動を伴わない遊びは運動遊びとは呼びません。
              遊びとは何でしょうか？遊びについての定義はホイジンガ
              やカイヨワが定義していま―
              す。それらの説明をすると膨大な情報量になるので、ここでは割愛し、重要なポイント
              だけ紹介します
            </span>
          </p>
        </div>
      </div>
    </div>

    <div v-show="page === 3">
      <div class="home">
        <div class="text-style">
          <p class="fw-normal font-style">
            <span>
              ①子どもの体力問題<br />
              ②子どもの運動スポーツ環境<br />
              ③運動と外遊びの効果<br />
              ④体力と運動能力
            </span>
          </p>
        </div>
      </div>
      <div class="link-div">
        <a :href="theory_url" target="_blank" class="card-link a-link"
          >▶︎キッズ理論編を見る</a
        >
      </div>
    </div>

    <div v-show="page === 4">
      <div class="home">
        <div class="text-style">
          <p class="fw-normal font-style">
            <span>
              ①Fitness CampKidsプログラム構成<br />
              ②「運動能力系運動遊び」の基礎知識とプログラミングのポイント<br />
              ③「エネルギー体力系運動遊び」の基礎知識とプログラミングのポイント<br />
              ④Fitness Camp Kidsキッズ指導のポイント
            </span>
          </p>
        </div>
      </div>
      <div class="link-div">
        <a :href="guidance_url" target="_blank" class="card-link a-link"
          >▶︎キッズ指導編を見る</a
        >
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Fitnesscampis",
  props: {
    page: null,
  },
  data() {
    return {
      theory_url:
        "https://firebasestorage.googleapis.com/v0/b/fitnesscamp-428f2.appspot.com/o/program%2Fkids%2Fkids_pdf%2F%E2%91%A2Kids%E3%83%9E%E3%83%8B%E3%83%A5%E3%82%A2%E3%83%AB%E7%90%86%E8%AB%96%E7%B7%A8.pdf?alt=media&token=9e71d77b-dfd0-47b5-8292-6fdcb56e23c5",
      guidance_url:
        "https://firebasestorage.googleapis.com/v0/b/fitnesscamp-428f2.appspot.com/o/program%2Fkids%2Fkids_pdf%2F%E2%91%A3Kids%E3%83%9E%E3%83%8B%E3%83%A5%E3%82%A2%E3%83%AB%E6%8C%87%E5%B0%8E%E7%B7%A8.pdf?alt=media&token=ec5ec1ae-88da-49b7-a41d-03aaa2f69c3b",
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.title {
  padding-top: 4%;
  text-align: left;
}
.home > img {
  padding-top: 1%;
  width: 96%;
  height: auto;
  min-height: 100px;
}
.home > img::before {
  content: "";
  display: block;
  padding-top: calc(((720 / 1280) * 100%));
}
.program-font-style {
  font-weight: 600;
  color: #000;
}
.text-style {
  padding-top: 2%;
  text-align: left;
  font-size: 14px;
  font-weight: 500;
}
.font-style {
  letter-spacing: 1px;
  line-height: 25px;
  padding-top: 8px;
}
.span-style {
}
.span-start {
  background: linear-gradient(transparent 50%, #fcf49c 0%);
}
.span-end {
  margin-left: -4px;
  margin-right: -4px;
  background: linear-gradient(transparent 50%, #fcf49c 0%);
}
.sapace-style {
  margin-top: 10%;
}
/* table */
table {
  margin-top: 8%;
  margin-left: 2%;
  margin-right: 2%;
  width: 96%;
  border-collapse: collapse;
}

table tr {
  width: 20px;
  border-bottom: solid 0 white;
}

table tr:last-child {
  border-bottom: none;
}

table th {
  position: relative;
  text-align: left;
  width: 25%;
  height: auto;
  background-color: rgb(247, 247, 247);
  padding: 10px 0;
  font-size: 14px;
  font-weight: 500;
}
table td {
  position: relative;
  text-align: left;
  height: auto;
  background-color: rgb(247, 247, 247);
  text-align: left;
  padding: 10px 10px;
  font-size: 14px;
  font-weight: 500;
}

.table-td-th-color_1 {
  color: #004e44ff;
}
.table-td-th-color_2 {
  color: #279a00ff;
}
.table-td-th-color_3 {
  color: #5c5252ff;
}
.table-td-th-color_4 {
  color: #208fedff;
}
.title-p {
  font-size: 14px;
  color: #000;
}
.h4-title {
  line-height: 20px;
}
.link-div {
  font-size: 14px;
  text-align: left;
}
a {
  color: #000;
  text-align: left;
}
.a-link {
  text-align: left;
}
</style>
