<template>
  <div>
    <div class="home">
      <div class="about">
        <a href="#" v-on:click.stop.prevent="openAbotModal()" class="alert-link">>>About</a>
      </div>
      <div v-if="isModal">
        <div class="popContainer">
          <div class="heard-style">
            <h2>4.ダイナミックストレッチ</h2>
          </div>
          <div class="card card-modal">
            <div class="card-body">
              <DynamicMovementAbout />
              <br />
              <br />
            </div>
          </div>
          <div class="close-button">
            <div class="close-button-div">
              <a
                href="#"
                v-on:click.stop.prevent="closeAbotModal()"
                class="alert-link close-button-text"
              >閉じる</a>
            </div>
          </div>
        </div>
      </div>

      <div>
        <!-- 説明部分 -->
        <div class="card border-light mb-3 card-style">
          <div class="card-body">
            <p class="card-text title-conten-font">
              ここから少しずつ動きのテンポを上げたり、動く大きさを広げて実際に動きに近づけていきましょう。
              <br />ダイナミックストレッチは7種目です、1種目の時間が短いです。
              <br />手の先や足の先に力が入らないように柔らかい動作を意識して、全体の連動を感じながら行いましょう。
            </p>
          </div>
        </div>
      </div>

      <div>
        <!-- ビデオ -->
        <div class="card video-div-style">
          <video :src="video_url_1" controls preload="metadata" class="video-style" />
        </div>
        <!-- タイトル -->
        <p class="h4 h4-title">ショルダーサークル</p>
        <!-- 説明 -->
        <div class="card border-light mb-3 card-style">
          <div class="card-body">
            <p class="card-text title-conten-font">
              まずは上半身3種目行いましょう。
              <br />足を肩幅よりも広めに開いて安定した姿勢を作りましょう。
              <br />首を長く保ったまま、胸から手が生えているイメージで肩を回しましょう。
              <br />手の先に力が入らないように大きく回しましょう。
            </p>
          </div>
        </div>
        <!-- 目的、動作、ポイントtable -->
        <div>
          <table>
            <tr>
              <th>目的</th>
              <td>
                胸椎回旋のモビリティ胸背部
                <br />アームラインのダイナミックストレッチ
              </td>
            </tr>
            <tr>
              <th>動作</th>
              <td>
                １．肩幅に足を開き膝を軽度屈曲する
                <br />２．アライメントを正す
                <br />３．胸椎から肩を回旋する
                <br />４．前回り、後ろ回りを10回ずつ行う
              </td>
            </tr>
            <tr>
              <th>ポイント</th>
              <td>手の先に余分な力が入らないように胸筋からしなやかに動く</td>
            </tr>
          </table>
        </div>
      </div>

      <div>
        <!-- ビデオ -->
        <div class="card video-div-style">
          <video :src="video_url_2" controls preload="metadata" class="video-style" />
        </div>
        <!-- タイトル -->
        <p class="h4 h4-title">ショルダーバックアンドフォース</p>
        <!-- 説明 -->
        <div class="card border-light mb-3 card-style">
          <div class="card-body">
            <p class="card-text title-conten-font">
              次にショルダーサークルと同じように首を長く保ったまま、胸から手が生えているイメージで肩を上下に動かしましょう。
              <br />手の先に力が入らないように。
            </p>
          </div>
        </div>
        <!-- 目的、動作、ポイントtable -->
        <div>
          <table>
            <tr>
              <th>目的</th>
              <td>
                胸椎伸展のモビリティ
                <br />胸背部、アームラインのダイナミックストレッチ
              </td>
            </tr>
            <tr>
              <th>動作</th>
              <td>
                １．肩幅に足を開き膝を軽度屈曲する
                <br />２．アライメントを正す
                <br />３．胸椎から肩を屈曲伸展する
                <br />４．10回行う
              </td>
            </tr>
            <tr>
              <th>ポイント</th>
              <td>手の先に余分な力が入らないように胸椎からしなやかに動く</td>
            </tr>
          </table>
        </div>
      </div>
      <div>
        <!-- ビデオ -->
        <div class="card video-div-style">
          <video :src="video_url_3" controls preload="metadata" class="video-style" />
        </div>
        <!-- タイトル -->
        <p class="h4 h4-title">ショルダーローテーション</p>
        <!-- 説明 -->
        <div class="card border-light mb-3 card-style">
          <div class="card-body">
            <p class="card-text title-conten-font">
              最後に肩を回旋させましょう。
              <br />肩だけの動作にならないように胸
              <br />から手が生えているように大きく回しましょう。
              <br />手先に力が入らないように。
            </p>
          </div>
        </div>
        <!-- 目的、動作、ポイントtable -->
        <div>
          <table>
            <tr>
              <th>目的</th>
              <td>肩の内外旋のダイナミックストレッチ</td>
            </tr>
            <tr>
              <th>動作</th>
              <td>
                １．肩幅に足を開き膝を軽度屈曲する
                <br />２．アライメントを正す
                <br />３．姿勢を保ったまま内外旋する
                <br />４．10回行う
              </td>
            </tr>
            <tr>
              <th>ポイント</th>
              <td>内外旋に伴う体幹の前後傾に注意する</td>
            </tr>
          </table>
        </div>
      </div>

      <div>
        <!-- ビデオ -->
        <div class="card video-div-style">
          <video :src="video_url_4" controls preload="metadata" class="video-style" />
        </div>
        <!-- タイトル -->
        <p class="h4 h4-title">トランクローテーション</p>
        <!-- 説明 -->
        <div class="card border-light mb-3 card-style">
          <div class="card-body">
            <p class="card-text title-conten-font">
              下半身から上半身全体の4種目いきましょう。
              <br />胸の前に手を組んで足を広げましょう。
              <br />腰を中心として大きく円を描くように動かしていきましょう。
            </p>
          </div>
        </div>
        <!-- 目的、動作、ポイントtable -->
        <div>
          <table>
            <tr>
              <th>目的</th>
              <td>
                脊柱の動的安定性
                <br />回旋機能全体のダイナミックストレッチ
              </td>
            </tr>
            <tr>
              <th>動作</th>
              <td>
                １．肩幅に足を開き膝を軽度屈曲する
                <br />２．アライメントを正す
                <br />３．手を胸の前で組み股関節から回旋する
                <br />４．片側5周ずつ行う
              </td>
            </tr>
            <tr>
              <th>ポイント</th>
              <td>脊柱（3面動作）全体でしなりを利用して回す</td>
            </tr>
          </table>
        </div>
      </div>

      <div>
        <!-- ビデオ -->
        <div class="card video-div-style">
          <video :src="video_url_5" controls preload="metadata" class="video-style" />
        </div>
        <!-- タイトル -->
        <p class="h4 h4-title">側方リーチ</p>
        <!-- 説明 -->
        <div class="card border-light mb-3 card-style">
          <div class="card-body">
            <p class="card-text title-conten-font">
              足を腰の幅くらいまで狭めて体をしならせるように体を横に倒しましょう。
              <br />上の手で遠くのものを掴むように。
            </p>
          </div>
        </div>
        <!-- 目的、動作、ポイントtable -->
        <div>
          <table>
            <tr>
              <th>目的</th>
              <td>体側全体の（ラテラルライン）のダイナミックストレッチ</td>
            </tr>
            <tr>
              <th>動作</th>
              <td>
                １．腰幅に足を開く
                <br />２．外側の足に体重をかける
                <br />３．同時に肩を外転し側屈する
                <br />４．左右滑らかにテンポ良く行う
                <br />５．交互に10回行う
              </td>
            </tr>
            <tr>
              <th>ポイント</th>
              <td>体側全体の伸びを感じる</td>
            </tr>
          </table>
        </div>
      </div>

      <div>
        <!-- ビデオ -->
        <div class="card video-div-style">
          <video :src="video_url_6" controls preload="metadata" class="video-style" />
        </div>
        <!-- タイトル -->
        <p class="h4 h4-title">後方ローテーションリーチ</p>
        <!-- 説明 -->
        <div class="card border-light mb-3 card-style">
          <div class="card-body">
            <p class="card-text title-conten-font">
              足を肩幅よりも軽く開いて立ちましょう。
              <br />後ろにパンチするように体を回旋させましょう。
            </p>
          </div>
        </div>
        <!-- 目的、動作、ポイントtable -->
        <div>
          <table>
            <tr>
              <th>目的</th>
              <td>
                胸椎・股関節中心のモビリティ
                <br />ファンクショナルライン
                <br />スパイラルラインのダイナミックストレッチ
              </td>
            </tr>
            <tr>
              <th>動作</th>
              <td>
                １．肩幅よりも広めに足を開く
                <br />２．軸足に中心に重心を移動して回旋する
                <br />３．股関節中心に体幹、胸椎を回旋する
                <br />４．交互に10回行
              </td>
            </tr>
            <tr>
              <th>ポイント</th>
              <td>手先に余分な力が入らないように股関節から連動して動く</td>
            </tr>
          </table>
        </div>
      </div>

      <div>
        <!-- ビデオ -->
        <div class="card video-div-style">
          <video :src="video_url_7" controls preload="metadata" class="video-style" />
        </div>
        <!-- タイトル -->
        <p class="h4 h4-title">上方ローテーションリーチ</p>
        <!-- 説明 -->
        <div class="card border-light mb-3 card-style">
          <div class="card-body">
            <p class="card-text title-conten-font">
              足を肩幅よりも軽く開いて立ちましょう。
              <br />斜め上をパンチするように回旋させましょう。
            </p>
          </div>
        </div>
        <!-- 目的、動作、ポイントtable -->
        <div>
          <table>
            <tr>
              <th>目的</th>
              <td>
                胸椎・股関節中心のモビリティ
                <br />フロントライン
                <br />ファンクショナルライン
                <br />スパイラルラインのダイナミックストレッチ
              </td>
            </tr>
            <tr>
              <th>動作</th>
              <td>
                １．肩幅よりも広めに足を開く
                <br />２．軸足に中心に重心を移動する
                <br />３．股関節中心に体幹、胸椎を伸展回旋する
                <br />４．交互に10回行う
              </td>
            </tr>
            <tr>
              <th>ポイント</th>
              <td>手先に余分な力が入らないように股関節から連動して動く</td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DynamicMovementAbout from "../commentary/DynamicMovementAbout";
export default {
  name: "DynamicMovement",
  components: {
    DynamicMovementAbout
  },
  props: {},
  data() {
    return {
      isModal: false,
      video_url_1:
        "https://firebasestorage.googleapis.com/v0/b/fitnesscamp-428f2.appspot.com/o/program%2Fcontent%2Fmovie%2Fdynamic%2Fshoulder_circle.mp4?alt=media&token=4fcfbecc-dc47-49de-826b-03648b9a5986",
      video_url_2:
        "https://firebasestorage.googleapis.com/v0/b/fitnesscamp-428f2.appspot.com/o/program%2Fcontent%2Fmovie%2Fdynamic%2Fshoulder_forth.mp4?alt=media&token=f27ca2b0-c62e-4b9d-b5cf-6bc9cb825ad3",
      video_url_3:
        "https://firebasestorage.googleapis.com/v0/b/fitnesscamp-428f2.appspot.com/o/program%2Fcontent%2Fmovie%2Fdynamic%2Fshoulder_rotation.mp4?alt=media&token=7cfca8a0-496b-41dd-821f-79e471e45bf6",
      video_url_4:
        "https://firebasestorage.googleapis.com/v0/b/fitnesscamp-428f2.appspot.com/o/program%2Fcontent%2Fmovie%2Fdynamic%2Ftrunk_rotation.mp4?alt=media&token=838dd481-edac-4d7f-aa57-3847ca547c27",
      video_url_5:
        "https://firebasestorage.googleapis.com/v0/b/fitnesscamp-428f2.appspot.com/o/program%2Fcontent%2Fmovie%2Fdynamic%2Fside_reach.mp4?alt=media&token=f08a2db3-785c-45cb-8c2e-3fda6a0868ae",
      video_url_6:
        "https://firebasestorage.googleapis.com/v0/b/fitnesscamp-428f2.appspot.com/o/program%2Fcontent%2Fmovie%2Fdynamic%2Fback_reach.mp4?alt=media&token=ce769c76-6f85-4eaf-85e5-b744fa0a94ae",
      video_url_7:
        "https://firebasestorage.googleapis.com/v0/b/fitnesscamp-428f2.appspot.com/o/program%2Fcontent%2Fmovie%2Fdynamic%2Fup_reach.mp4?alt=media&token=e23556af-3a98-43da-94a6-9d69eff07095"
    };
  },
  methods: {
    openAbotModal() {
      this.isModal = true;
    },
    closeAbotModal() {
      this.isModal = false;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.heard-style {
  padding-top: 14px;
  height: 50px;
  background-color: #04545c;
  border: 0;
  margin-top: 5%;
  margin-left: 8%;
  margin-right: 8%;
}
.heard-style img {
  float: left;
  margin-left: 2%;
}
.heard-style > h2 {
  color: rgb(255, 255, 255);
  margin-right: 4%;
  font-weight: 900;
}
.close-button {
  height: 50px;
  background-color: #ffffff;
  border: 0;
  margin-left: 8%;
  margin-right: 8%;
}
.close-button-div {
  background-color: rgb(255, 255, 255);
  text-align: right;
  margin-right: 4%;
  padding-top: 12px;
}
.close-button-text {
  font-size: 16px;
  font-weight: 400;
  color: rgb(163, 163, 163);
}

.about {
  text-align: right;
  margin-top: 2%;
  margin-bottom: 3%;
}
.about > a:link,
a:visited,
a:hover,
a:active {
  text-decoration: none;
  font-size: 16px;
  font-weight: 400;
  color: rgb(163, 163, 163);
}
.title {
  padding-top: 4%;
  text-align: left;
}

.card-style {
  max-width: 100rem;
  margin: 2%;
  background-color: rgb(248, 248, 248);
}
.title-font {
  color: #000;
  font-weight: 600;
  text-align: left;
}
.title-conten-font {
  color: #000;
  font-size: 14px;
  font-weight: 500;
  text-align: left;
  letter-spacing: 1px;
  line-height: 20px;
}
.video-div-style {
  height: auto;
  width: auto;
  margin-top: 15%;
  margin-left: 2%;
  margin-right: 2%;
  border: 0;
  background-color: rgb(233, 233, 233);
}
.video-style {
  height: 250px;
}
.h4-title {
  margin-top: 5%;
  margin-bottom: 4%;
  margin-left: 2%;
  text-align: left;
  font-weight: 600;
  color: #000;
}
.home > img {
  padding-top: 1%;
  width: 96%;
}
.program-font-style {
  font-weight: 600;
  color: #000;
}
.text-style {
  padding-top: 2%;
  text-align: left;
  font-size: 14px;
  font-weight: 500;
}
.font-style {
  letter-spacing: 2px;
  line-height: 25px;
  padding-top: 8px;
}
.span-style {
  margin-left: -5px;
  margin-right: -5px;
  background: linear-gradient(transparent 50%, #f5f100 0%);
}

/* table */
table {
  margin-top: 8%;
  margin-left: 2%;
  margin-right: 2%;
  width: 96%;
  border-collapse: collapse;
}

table tr {
  width: 30px;
  border-bottom: solid 1px white;
}

table tr:last-child {
  border-bottom: none;
}

table th {
  position: relative;
  text-align: left;
  width: 25%;
  height: auto;
  background-color: #e4ecec;
  color: rgb(0, 0, 0);
  text-align: center;
  padding: 10px 0;
  font-size: 14px;
  font-weight: 400;
}
table td {
  position: relative;
  text-align: left;
  height: auto;
  background-color: rgb(247, 247, 247);
  color: rgb(0, 0, 0);
  text-align: left;
  padding: 10px 10px;
  font-size: 14px;
  font-weight: 400;
}
.popContainer {
  z-index: 2;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(43, 43, 43, 0.3);
}
.card-modal {
  overflow: auto;
  z-index: 2;
  margin-top: 0%;
  margin-left: 8%;
  margin-right: 8%;
  height: 75%;
  background-color: rgb(255, 255, 255);
}
</style>
