<template>
  <div>
    <div class="Login-img">
      <img src="@/assets/login/login.png" height="400" />
    </div>
    <form>
      <div class="cp_iptxt">
        <input
          class="ef"
          type="email"
          placeholder="メールアドレス"
          v-model="user.email"
        />
        <label>
          <img src="@/assets/login/mail.png" />
        </label>
        <span class="focus_line">
          <i></i>
        </span>
      </div>
      <div class="cp_iptxt">
        <input
          class="ef"
          type="password"
          placeholder="パスワード"
          v-model="user.password"
        />
        <label>
          <img src="@/assets/login/password.png" />
        </label>
        <span class="focus_line">
          <i></i>
        </span>
      </div>
      <div
        class="alert alert-light failure"
        role="alert"
        v-if="errorMessage !== ''"
      >
        <p class="failure">{{ errorMessage }}</p>
      </div>
      <div class="login-button">
        <a href v-on:click.stop.prevent="login()" class="btn btn--orange"
          >ログイン</a
        >
      </div>
    </form>
    <br />
    <a href="#/resetPassword" class="resetpassword-style"
      >パスワードをお忘れですか？</a
    >
  </div>
</template>

<script>
// @ is an alias to /src
import firebase from "firebase/compat";
export default {
  name: "Login",
  components: {},
  data() {
    return {
      user: {
        email: "",
        password: "",
      },
      errorMessage: "",
    };
  },
  methods: {
    login() {
      //全角->半角
      this.user.email = this.user.email.replace(/[Ａ-Ｚａ-ｚ０-９]/g, function(
        s
      ) {
        return String.fromCharCode(s.charCodeAt(0) - 0xfee0);
      });

      firebase
        .auth()
        .signInWithEmailAndPassword(this.user.email, this.user.password)
        .then((user) => {
          if (user) {
            this.$router.push("/");
          }
        })
        .catch((error) => {
          switch (error.code) {
            case "auth/wrong-password":
              this.errorMessage = "パスワードが違います";
              break;
            case "auth/invalid-email":
              this.errorMessage = "無効のメールアドレスです";
              break;
            case "auth/user-not-found":
              this.errorMessage = "ユーザーが存在しません";
              break;
            case "auth/weak-password":
              this.errorMessage = "6文字以上でパスワードを設定してください";
              break;
            case "auth/email-already-in-use":
              this.errorMessage = "すでに存在しているメールアドレスです";
              break;
            default:
              this.errorMessage = "認証失敗しました";
              break;
          }
        });
    },
  },
};
</script>

<style>
.Login-img > img {
  width: 100%;
  /* height: auto; */
  max-height: 500px;
}

.cp_iptxt {
  position: relative;
  width: 80%;
  margin: 20px 10%;
  text-align: left;
}
.cp_iptxt input[type="text"],
.cp_iptxt input[type="email"],
.cp_iptxt input[type="password"] {
  font: 16px/24px sans-serif;
  box-sizing: border-box;
  width: 100%;
  height: 50px;
  letter-spacing: 1px;
  padding-left: 3em;
  font-size: 16px;
}
.cp_iptxt input[type="text"]:focus,
.cp_iptxt input[type="email"],
.cp_iptxt input[type="password"]:focus {
  outline: none;
}
.ef {
  padding: 7px 14px;
  transition: 0.4s;
  border: 1px solid #c7c7c7;
  background: transparent;
}
.ef ~ .focus_line:before,
.ef ~ .focus_line:after {
  position: absolute;
  top: -1px;
  left: 50%;
  width: 0;
  height: 2px;
  content: "";
  transition: 0.4s;
  background-color: #00645c;
}
.ef ~ .focus_line:after {
  top: auto;
  bottom: 0;
}
.ef ~ .focus_line i:before,
.ef ~ .focus_line i:after {
  position: absolute;
  top: 50%;
  left: 0;
  width: 2px;
  height: 0;
  content: "";
  transition: 0.6s;
  background-color: #00645c;
}
.ef ~ .focus_line i:after {
  right: 0;
  left: auto;
}
.ef:focus ~ .focus_line:before,
.ef:focus ~ .focus_line:after,
.cp_iptxt.ef ~ .focus_line:before,
.cp_iptxt.ef ~ .focus_line:after {
  left: 0;
  width: 100%;
  transition: 0.4s;
}
.ef:focus ~ .focus_line i:before,
.ef:focus ~ .focus_line i:after,
.cp_iptxt.ef ~ .focus_line i:before,
.cp_iptxt.ef ~ .focus_line i:after {
  top: -1px;
  height: 100%;
  transition: 0.6s;
}
.ef ~ label {
  position: absolute;
  z-index: -1;
  top: 12px;
  left: 14px;
  width: 100%;
  transition: 0.3s;
  letter-spacing: 0.5px;
  color: #aaaaaa;
}
.ef:focus ~ label,
.cp_iptxt.ef ~ label {
  font-size: 10px;
  transition: 0.3s;
  color: #00645c;
}

/*その他と主な共通部分は省略*/
/*まずはお決まりのボックスサイズ算出をborer-boxに */
*,
*:before,
*:after {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
}
html {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-size: 62.5%; /*rem算出をしやすくするために*/
}
.resetpassword-style {
  font-size: 14px;
  color: #00645c;
  text-decoration: underline;
}

.btn,
a.btn,
button.btn {
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 1.5;
  position: relative;
  display: inline-block;
  padding: 1rem 4rem;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  text-align: center;
  vertical-align: middle;
  text-decoration: none;
  letter-spacing: 0.1em;
  color: #212529;
  border-radius: 0.5rem;
  width: 60%;
  height: 50px;
}
.btn--orange,
a.btn--orange {
  color: #fff;
  background-color: #00645c;
}
.btn--orange:hover,
a.btn--orange:hover {
  color: #fff;
  background: #00645c;
}
.failure {
  font-size: 16px;
  font-weight: 600;
}
.failure > p {
  color: rgb(177, 0, 0);
}
</style>
