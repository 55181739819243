<template>
  <div class="home">
    <div class="title">
      <h2 class="display-6 program-font-style">目的</h2>
    </div>
    <div class="text-style">
      <p class="fw-normal font-style">
        <span class="span-style span-start">筋と筋膜の柔軟性を高めることを目的としています。</span>
        <span>
          Fitness Campのスタティックストレッチは、一般的なスタティックストレッチだけでなく、相反抑制を利用したストレッチも含めます。
        </span>
      </p>
    </div>

    <img src="@/assets/commentary/stretch_girl.jpg" height="250"/>

    <div class="text-style">
      <p class="fw-normal font-style">
        <span>筋膜によって、足の裏から頭まで繋がっています。そのため、</span>
        <span class="span-style span-end">ある一部分の柔軟性の欠如により、他の部位に影響し姿勢不良、また代償動作につながります。</span>
      </p>
    </div>

    <div class="text-style">
      <p class="fw-normal font-style">
        <span>そこで、</span>
        <span class="span-style span-end">アライメントを最適したのちに主要な筋肉群を中心としてスタティックストレッチを行います。</span>
        <span>
          ストレッチを行う際に筋肉の弛緩を促すために、自己抑制系の種目についてはストレッチを感じる部位に呼吸を送り込むようにして、その姿勢を最低10秒ほどキープします。
        </span>
      </p>
    </div>

    <div class="text-style">
      <p class="fw-normal font-style">
        <span>相反抑制系の複合動作種目については、最大可動域で5秒ホールドして動作を切り替えます。</span>
        <span class="span-style span-end">両方のストレッチともに呼吸を止めずに行うことが大変重要です。</span>
      </p>
    </div>

    <div class="text-style">
      <p class="fw-normal font-style">
        <span class="span-style span-start">現代社会では座位でいる時間が長い</span>
        <span>
          ため、股関節周辺筋群の柔軟性の欠如、また大胸筋、小胸筋の緊張が高いです。そのため、これらの筋肉群のストレッチエクササイズを重視して行います。
        </span>
      </p>
    </div>
    <img src="@/assets/commentary/stady_pc.jpg" height="250"/>
  </div>
</template>

<script>
export default {
  name: "StaticStrechAbout",
  props: {}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.title {
  padding-top: 4%;
  text-align: left;
}
.home > img {
  padding-top: 1%;
  width: 96%;
  height: auto;
  min-height: 100px;
}
.home > img::before {
  content: "";
  display: block;
  padding-top: calc(((720 / 1280) * 100%));
}
.program-font-style {
  font-weight: 600;
  color: #000;
}
.text-style {
  padding-top: 2%;
  text-align: left;
  font-size: 14px;
  font-weight: 500;
}
.font-style {
  letter-spacing: 1px;
  line-height: 25px;
  padding-top: 8px;
}
.span-style {
}
.span-start {
  background: linear-gradient(transparent 50%, #fcf49c 0%);
}
.span-end {
  margin-left: -4px;
  margin-right: -4px;
  background: linear-gradient(transparent 50%, #fcf49c 0%);
}
.sapace-style {
  margin-top: 10%;
}

</style>
